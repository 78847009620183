import { Mapper } from './Mapper';

export interface OtpResponse {
  id: number;
  status: string;
  valid: boolean;
  code: string;
  createdDate: Date;
}

/* eslint-disable camelcase */
export interface ServerOtpResponse {
  id: number;
  status: string;
  valid: boolean;
  code: string;
  date_created: string;
}

/* eslint-enable camelcase */

/**
 * Map OtpResponse model
 */
export abstract class OtpResponseMapper
  implements Mapper<OtpResponse, ServerOtpResponse> {
  /**
   * @param {Partial<ServerOtpResponse>} o
   * @return {OtpResponse}
   */
  static map(o: Partial<ServerOtpResponse>): OtpResponse {
    return <OtpResponse>{
      id: o.id,
      status: o.status,
      valid: o.valid,
      code: o.code,
      createdDate: o.date_created ? new Date(o.date_created) : undefined,
    };
  }

  /**
   * @param {Partial<OtpResponse>} o
   * @return {ServerOtpResponse}
   */
  static mapReverse(o: Partial<OtpResponse>): ServerOtpResponse {
    return <ServerOtpResponse>{
      id: o.id,
      status: o.status,
      valid: o.valid,
      code: o.code,
      date_created: o.createdDate?.toISOString() || null,
    };
  }

  /**
   * @param {ServerOtpResponse[]} models
   * @return {OtpResponse[]}
   */
  static mapArray(models: ServerOtpResponse[]): OtpResponse[] {
    return models.map(m => OtpResponseMapper.map(m));
  }

  /**
   * @param {OtpResponse[]} models
   * @return {ServerOtpResponse[]}
   */
  static mapReverseArray(models: OtpResponse[]): ServerOtpResponse[] {
    return models.map(m => OtpResponseMapper.mapReverse(m));
  }
}
