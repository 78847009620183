import { defaultsDeep, get } from 'lodash';

const readLanguage = (language: string) => ({
  ...require(`./${language}.json`),
});

const DEFAULT_LANG = 'it';
const DEFAULT = readLanguage(DEFAULT_LANG);
const selected = localStorage.getItem('locale') || DEFAULT_LANG;

const current = selected !== DEFAULT_LANG ? readLanguage(selected) : DEFAULT;

const lc = defaultsDeep(current, DEFAULT);

const locale = (id: string, args?: (string | number)[], count = 0): string => {
  const label = get(lc, id);
  if (!label) {
    return id;
  }
  if (typeof label !== 'string') {
    return bindArgs(count !== 1 ? label.plural : label.singular, args);
  }
  return bindArgs(label, args);
};

const bindArgs = (label: string, args?: (string | number)[]) => {
  let result = label;
  args &&
    args.forEach((arg: string | number, i: number) => {
      result = result.split(`$${i}`).join(`${arg}`);
    });
  return result;
};

export const localeValidationMessages = () => {
  return get(lc, 'validationMessages');
};

export default locale;
