import React from 'react';
import { Button, Form, Input, message, Modal, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { otpActions, otpSelector, otpSendResponse } from '../../otpSlice';
import Countdown from 'antd/lib/statistic/Countdown';
import { Utils } from '../../../../models/Utils';

/**
 *
 * @constructor
 */
export default function OtpResponseModal() {
  const {
    isResponseModalVisible,
    requestExpirationDate,
    otp,
    phone,
  } = useSelector(otpSelector);
  const dispatch = useDispatch();

  const onCountdownFinish = () => {
    message.error(locale('errors.otpCodeExpired'));
    dispatch(otpActions.closeResponseModal());
    dispatch(otpActions.openRequestModal());
  };

  const isFormValid = otp?.trim().length >= 6;

  return (
    <Modal
      className="otp-response-modal"
      title={locale('titles.confirmOtp')}
      visible={isResponseModalVisible}
      maskClosable={false}
      onCancel={() => {
        dispatch(otpActions.closeResponseModal());
        dispatch(otpActions.openRequestModal());
      }}
      destroyOnClose
      centered
      footer={
        <Button
          onClick={() => dispatch(otpSendResponse())}
          type="primary"
          size="large"
          block
          disabled={!isFormValid}
        >
          {locale('actions.confirmOtpCode')}
        </Button>
      }
    >
      <Typography.Paragraph>
        {locale('subtitles.otpResponseModal', [
          Utils.getFormattedTelephone(phone),
        ])}
      </Typography.Paragraph>
      <Countdown
        title={locale('labels.yourCodeWillExpire')}
        value={requestExpirationDate?.getTime()}
        onFinish={onCountdownFinish}
      />
      <Form layout="vertical" style={{ marginTop: '1rem' }}>
        <Form.Item
          rules={[{ required: true }]}
          initialValue={otp}
          label={locale('labels.otp')}
          name="otp"
          required
        >
          <Input
            value={otp}
            onChange={e => dispatch(otpActions.setOtp(e.target.value))}
            placeholder={locale('placeholders.otp')}
          />
        </Form.Item>
      </Form>
      <Typography.Paragraph className="mb-0">
        <Typography.Text>
          {locale('subtitles.didNotReceiveTheCode')}
        </Typography.Text>
        <Typography.Link
          className="ml-1"
          onClick={() => {
            dispatch(otpActions.closeResponseModal());
            dispatch(otpActions.openRequestModal());
          }}
        >
          {locale('actions.tryAgain')}
        </Typography.Link>
      </Typography.Paragraph>
    </Modal>
  );
}
