import React, { Suspense } from 'react';
import Loader from './features/loader/Loader';
import { AppRouter } from './models/appRouter/AppRouter';
import PlatformLayout from './layouts/platformLayout/PlatformLayout';
import './App.scss';
import { ConfigProvider } from 'antd';
import { localeValidationMessages } from './locale';
import moment from 'moment';
import 'moment/locale/it';
import itIT from 'antd/es/locale/it_IT';
import { connect } from 'react-redux';
import { RootState } from './store/store';
import { authCheckState } from './features/auth/authSlice';
import EmptyLayout from './layouts/emptyLayout/EmptyLayout';
import { AppRoute } from './models/appRouter/AppRoute.enum';
import { RouteComponentProps, withRouter } from 'react-router';
import { Utils } from './models/Utils';

moment.locale('it');

interface AppProps extends RouteComponentProps {
  isAuthenticated?: boolean;
  isCustomer?: boolean;
  authCheckState?: () => void;
}

/**
 * Main App
 * @constructor
 */
class App extends React.Component<AppProps> {
  /**
   * Check if there is an authenticated user
   */
  componentDidMount() {
    const { authCheckState } = this.props;
    authCheckState && authCheckState();
  }

  /**
   * @return {JSX.Element}
   */
  getRoutes() {
    const { isCustomer, isAuthenticated, location } = this.props;
    if (isAuthenticated) {
      if (isCustomer) {
        return AppRouter.getCustomerRoutes();
      }
      const isContractPreview: boolean = Utils.isRoute(
        AppRoute.ContractPreview,
        location.pathname,
      );
      if (isContractPreview) {
        return <EmptyLayout>{AppRouter.getEmptyRoutes()}</EmptyLayout>;
      }
      return <PlatformLayout>{AppRouter.getAuthRoutes()}</PlatformLayout>;
    }
    return AppRouter.getPublicRoutes();
  }

  /**
   * @return {JSX.Element}
   */
  render(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <Loader />
        <ConfigProvider
          form={{ validateMessages: localeValidationMessages() }}
          locale={itIT}
        >
          {this.getRoutes()}
        </ConfigProvider>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: !!state.auth.token,
  isCustomer: !!state.auth.user?.isCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  authCheckState: () => dispatch(authCheckState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
