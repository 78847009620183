import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCloserSelector,
  settlementCloserUpdateAndConfirmData,
} from '../../settlementCloserSlice';
import { Card, Col, Row } from 'antd';
import { SettlementDataSection } from '../../../settlementDetail/components/settlementDataSection/SettlementDataSection';
import { SettlementDataFieldValue } from '../../../../models/SettlementDataFieldValue';

const CloserData = () => {
  const dispatch = useDispatch();

  const { model } = useSelector(settlementCloserSelector);

  const onSubmit = (dataFieldValues: SettlementDataFieldValue[]) =>
    dispatch(settlementCloserUpdateAndConfirmData({ dataFieldValues }));

  if (!model) {
    return null;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Card>
          <SettlementDataSection
            settlement={model}
            onSubmit={onSubmit}
            onConfirm={() => undefined}
            canEdit
            isCustomer
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CloserData;
