import React, { useState } from 'react';
import { Modal, Upload } from 'antd';
import { Utils } from '../../models/Utils';
import { FileExtension } from '../../models/FileExtension.enum';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';

interface MediaUploaderProps {
  canEdit: boolean;
  files: any[];
  onFilesChange: (files: any[]) => void;
  onDelete?: (id: string) => void;
  limit?: number;
}

/**
 *
 * @constructor
 */
export default function MediaUploader({
  canEdit,
  files,
  onFilesChange,
  onDelete,
  limit,
}: MediaUploaderProps) {
  const [preview, setPreview] = useState<{
    visible: boolean;
    url: string;
    title: string;
  }>({ visible: false, url: '', title: '' });

  const handleChange = async ({ fileList }: UploadChangeParam) => {
    if (onDelete && fileList.length < files.length) {
      const found = files.find(f => fileList.every(f2 => f2.uid !== f.uid));
      if (found) {
        onDelete(found.uid);
      } else {
        return;
      }
    }
    onFilesChange(fileList.map(f => ({ ...f, status: 'done' })));
  };

  const handlePreview = async (file: any) => {
    if (file.url) {
      window.open(file.url);
      return;
    }

    if (!file.preview) {
      try {
        file.preview = await Utils.toBase64(file.originFileObj);
      } catch (e) {
        console.log(e);
      }
    }

    setPreview({
      visible: true,
      url: file.url || file.preview,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const handleCancel = () =>
    preview && setPreview({ ...preview, visible: false });

  return (
    <>
      <Upload
        customRequest={() => undefined}
        accept={Utils.getEnumAsArray(FileExtension).toString()}
        listType="picture-card"
        fileList={files}
        onPreview={handlePreview}
        onChange={handleChange}
        disabled={!canEdit}
        maxCount={limit}
      >
        <PlusOutlined style={{ fontSize: '1rem' }} />
      </Upload>
      <Modal
        visible={preview.visible}
        title={preview.title}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt={preview.title} style={{ width: '100%' }} src={preview.url} />
      </Modal>
    </>
  );
}
