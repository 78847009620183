import React, { useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';
import locale from '../../../../locale';
import { Product } from '../../../../models/Product';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorFetchProducts,
  settlementCreatorSelector,
} from '../../settlementCreatorSlice';

interface SettlementCreatorProductSetterProps {
  onProductSelected: (product: Product) => void;
}

const SettlementCreatorProductSetter = ({
  onProductSelected,
}: SettlementCreatorProductSetterProps) => {
  const dispatch = useDispatch();
  const { product, products } = useSelector(settlementCreatorSelector);
  const [cProduct, setCProduct] = useState<Product | undefined>(
    product || products?.[0],
  );

  useEffect(() => {
    setCProduct(product || products?.[0]);
    dispatch(settlementCreatorFetchProducts());
  }, []);

  useEffect(() => {
    setCProduct(product || products[0]);
  }, [product, products]);

  return (
    <div className="p-2">
      <Form layout="vertical">
        <Form.Item
          label={locale('labels.product')}
          initialValue={cProduct?.id}
          required
          rules={[{ required: true }]}
          name="product"
          extra={locale('subtitles.selectContractProduct')}
          getValueProps={() => ({ product: cProduct?.id })}
        >
          <Select
            value={cProduct?.id}
            placeholder={locale('placeholders.selectProduct')}
            onChange={value => {
              const found = products.find(p => p.id === value);
              setCProduct(found);
            }}
          >
            {products?.map(p => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          type="primary"
          block
          disabled={!cProduct}
          onClick={() => cProduct && onProductSelected(cProduct)}
        >
          {locale('actions.confirmProduct')}
        </Button>
      </Form>
    </div>
  );
};

export default SettlementCreatorProductSetter;
