import React from 'react';
import { Button, Col, Dropdown, Menu, Row, Typography } from 'antd';
import {
  LinkOutlined,
  MailOutlined,
  MessageOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import locale from '../../../../locale';
import environment from '../../../../../environment';
import { AppRoute } from '../../../../models/appRouter/AppRoute.enum';
import { Settlement } from '../../../../models/Settlement';
import { Utils } from '../../../../models/Utils';

interface SettlementSharerProps {
  settlement: Settlement;
  title?: string;
  subtitle?: string;
  getShareLink?: (link: string) => Promise<string> | string;
  collapsed?: boolean;
}

/**
 *
 * @constructor
 */
export default function SettlementSharer({
  settlement,
  title,
  subtitle,
  getShareLink,
  collapsed,
}: SettlementSharerProps) {
  const onShare = async (type: 'email' | 'wa' | 'sms' | 'link') => {
    let shareLink = `${window.location.origin}${AppRoute.Sign}?uuid=${settlement.uuid}`;

    if (getShareLink) {
      shareLink = await getShareLink(shareLink);
    }

    const subject = locale('titles.shareSubject', [environment.appName]);
    const message = encodeURI(
      locale('messages.shareMessage', [
        settlement.contact?.firstName || '',
        environment.appName,
        shareLink,
      ]),
    );

    switch (type) {
      case 'email':
        const emailLink = `mailto:${settlement.contact?.email}?subject=${subject}&body=${message}`;
        window.open(emailLink, '_blank');
        break;
      case 'wa':
        const waLink = `https://api.whatsapp.com/send/?phone=${
          settlement.contact?.phone?.prefix ||
          '' + settlement.contact?.phone?.number ||
          ''
        }&text=${message}`;
        window.open(waLink, '_blank');
        break;
      case 'sms':
        const smsLink = `sms:${settlement.contact?.phone?.number}?&body=${message}`;
        window.open(smsLink, '_blank');
        break;
      case 'link':
        Utils.copyToClipboard(shareLink);
        break;
    }
  };

  if (collapsed) {
    return (
      <Dropdown.Button
        className="mr-2"
        trigger={['click']}
        icon={<ShareAltOutlined />}
        overlay={
          <Menu
            onClick={e => {
              e.domEvent.stopPropagation();
              onShare(e.key as 'email' | 'wa' | 'sms' | 'link');
            }}
          >
            <Menu.Item key="email" icon={<MailOutlined />}>
              {locale('labels.email')}
            </Menu.Item>
            <Menu.Item key="wa" icon={<WhatsAppOutlined />}>
              {locale('labels.WhatsApp')}
            </Menu.Item>
            <Menu.Item key="sms" icon={<MessageOutlined />}>
              {locale('labels.sms')}
            </Menu.Item>
            <Menu.Item key="link" icon={<LinkOutlined />}>
              {locale('labels.link')}
            </Menu.Item>
          </Menu>
        }
      />
    );
  }

  return (
    <Row justify="center" gutter={[0, 6]}>
      <Col xs={24}>
        {title && (
          <Typography.Title level={4} className="text-center">
            {title}
          </Typography.Title>
        )}
        {subtitle && (
          <Typography.Paragraph type="secondary" className="text-center">
            {subtitle}
          </Typography.Paragraph>
        )}
      </Col>
      <Col xs={24}>
        <Row justify="center" gutter={[16, 16]} align="middle">
          <Col>
            <Button
              icon={<MailOutlined />}
              type="default"
              shape="circle"
              onClick={() => onShare('email')}
              size="large"
            />
          </Col>
          <Col>
            <Button
              icon={<WhatsAppOutlined />}
              type="default"
              shape="circle"
              onClick={() => onShare('wa')}
              size="large"
            />
          </Col>
          <Col>
            <Button
              icon={<MessageOutlined />}
              type="default"
              shape="circle"
              onClick={() => onShare('sms')}
              size="large"
            />
          </Col>
          <Col>
            <Button
              icon={<LinkOutlined />}
              type="default"
              shape="circle"
              onClick={() => onShare('link')}
              size="large"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
