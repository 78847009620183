import { Affix, Button, Input, List as AntdList, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ListItem } from './ListItem';
import classNames from 'classnames';
import './List.scss';
import locale from '../../locale';
import { RightOutlined } from '@ant-design/icons';

interface ListProps {
  items: ListItem[];
  paginated?: boolean;
  loading?: boolean;
  hasMore?: boolean;
  loadMore?: () => void;
  onItemClick: (key: string) => void;
  search?: string;
  onSearch?: (value: string) => void;
  description?: string;
  affixContainer?: HTMLElement;
}

export const List = (props: ListProps) => {
  const {
    items,
    onItemClick,
    onSearch,
    description,
    loading,
    hasMore,
    loadMore,
    paginated,
    search,
    affixContainer,
  } = props;
  const [currentSearch, setCurrentSearch] = useState<string>('');

  useEffect(() => {
    setCurrentSearch(search || '');
  }, [search]);

  return (
    <>
      {(onSearch || description) && (
        <div className="container list-pre">
          {onSearch && (
            <Input.Search
              value={currentSearch}
              defaultValue={currentSearch}
              onChange={e => setCurrentSearch(e.target.value)}
              placeholder={locale('actions.search') + '...'}
              onSearch={onSearch}
            />
          )}
          {description && (
            <Typography.Paragraph type="secondary">
              {description}
            </Typography.Paragraph>
          )}
        </div>
      )}
      <AntdList
        loading={loading}
        className="list"
        dataSource={items}
        loadMore={
          paginated &&
          hasMore &&
          !loading && (
            <div className="load-more-item">
              <Button type="primary" onClick={loadMore}>
                {locale('actions.loadMore')}
              </Button>
            </div>
          )
        }
        renderItem={item => {
          const listItem = (
            <AntdList.Item
              key={item.key}
              className={classNames(item.className || '', {
                'section-title': item.isSectionTitle,
                active: item.active,
                disabled: !!item.disabled,
              })}
              onClick={() =>
                !item.isSectionTitle && !item.disabled && onItemClick(item.key)
              }
            >
              <AntdList.Item.Meta
                avatar={item.leftItem}
                title={item.title}
                description={item.description}
              />
              <div onClick={e => e.stopPropagation()}>{item.rightItems}</div>
              {!item.hideArrow && !item.isSectionTitle && <RightOutlined />}
            </AntdList.Item>
          );
          if (!item.isSectionTitle) {
            return listItem;
          }
          return (
            <Affix
              target={() => affixContainer || window}
              offsetTop={affixContainer ? 0 : 44}
            >
              {listItem}
            </Affix>
          );
        }}
      />
    </>
  );
};
