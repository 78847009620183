import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { otpActions, otpSelector, otpSendRequest } from '../../otpSlice';
import { TelephoneField } from '../../../../components/telephoneField/TelephoneField';
import { Utils } from '../../../../models/Utils';
import { authSelector } from '../../../auth/authSlice';
import { settlementCloserSelector } from '../../../settlementCloser/settlementCloserSlice';

/**
 *
 * @constructor
 */
export default function OtpRequestModal() {
  const {
    isRequestModalVisible,
    firstName,
    lastName,
    businessname,
    phone,
  } = useSelector(otpSelector);
  const { user } = useSelector(authSelector);
  const { model } = useSelector(settlementCloserSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    !firstName &&
      model?.contact?.firstName &&
      dispatch(otpActions.setFirstName(model?.contact.firstName));
    !lastName &&
      model?.contact?.lastName &&
      dispatch(otpActions.setLastName(model?.contact.lastName));
    !businessname &&
      model?.contact?.company &&
      dispatch(otpActions.setBusinessname(model?.contact.company));
    !phone?.number &&
      model?.contact?.phone &&
      dispatch(
        otpActions.setPhone({
          prefix: '+39',
          number: model?.contact?.phone?.number || '',
        }),
      );
  }, [user, model]);

  const isFormValid = !!(
    firstName?.trim().length &&
    lastName?.trim().length &&
    businessname?.trim().length &&
    phone?.prefix?.length &&
    Utils.isPrefix(phone.prefix) &&
    phone?.number?.trim().length > 5
  );

  return (
    <Modal
      className="otp-request-modal"
      title={locale('titles.verifyYourIdentity')}
      visible={isRequestModalVisible}
      maskClosable={false}
      closable={false}
      destroyOnClose
      centered
      footer={
        <Button
          onClick={() => dispatch(otpSendRequest())}
          type="primary"
          size="large"
          block
          disabled={!isFormValid}
        >
          {locale('actions.sendOtpCode')}
        </Button>
      }
    >
      <Typography.Paragraph>
        {locale('subtitles.otpRequestModal')}
      </Typography.Paragraph>
      <Form layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          initialValue={firstName}
          label={locale('labels.firstName')}
          name="firstName"
          required
        >
          <Input
            value={firstName}
            onChange={e => dispatch(otpActions.setFirstName(e.target.value))}
            placeholder={locale('placeholders.firstName')}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          initialValue={lastName}
          label={locale('labels.lastName')}
          name="lastName"
          required
        >
          <Input
            value={lastName}
            onChange={e => dispatch(otpActions.setLastName(e.target.value))}
            placeholder={locale('placeholders.lastName')}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          initialValue={businessname}
          label={locale('labels.businessName')}
          name="businessname"
          required
        >
          <Input
            value={businessname}
            onChange={e => dispatch(otpActions.setBusinessname(e.target.value))}
            placeholder={locale('placeholders.businessName')}
          />
        </Form.Item>
        <Form.Item
          style={{ margin: '0.5em 0 0' }}
          rules={[{ required: true }]}
          label={locale('labels.telephone')}
          required
        >
          <TelephoneField
            telephone={phone}
            extra={locale('subtitles.otpTelephone')}
            onPhoneChange={number =>
              dispatch(otpActions.setPhone({ ...phone, number }))
            }
            onPrefixChange={prefix =>
              dispatch(otpActions.setPhone({ ...phone, prefix }))
            }
            required
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
