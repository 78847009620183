import { Mapper } from './Mapper';
import {
  AmountOption,
  AmountOptionMapper,
  ServerAmountOption,
} from './AmountOption';
import { Product, ProductMapper, ServerProduct } from './Product';
import { Contract, ContractMapper, ServerContract } from './Contract';
import { Duration, DurationMapper, ServerDuration } from './Duration';

export interface Offer {
  id: number;
  name: string;
  description: string;
  product: Product;
  prices: AmountOption[];
  deposits: AmountOption[];
  numberOfInstallmentsList: AmountOption[];
  contract: Contract;
  currency: 'EUR';
  contractDurations: Duration[];
  firstInstallmentTimeFromApprovalDuration?: Duration;
}

/* eslint-disable camelcase */
export interface ServerOffer {
  id: number;
  name: string;
  description: string;
  product: ServerProduct;
  prices: ServerAmountOption[];
  deposits: ServerAmountOption[];
  number_of_installments_set: ServerAmountOption[];
  contract: ServerContract;
  currency: 'EUR';
  contract_duration_set: ServerDuration[];
  first_installment_time_from_approval_duration?: ServerDuration;
}

/* eslint-enable camelcase */

/**
 * Map Offer model
 */
export abstract class OfferMapper implements Mapper<Offer, ServerOffer> {
  /**
   * @param {Partial<ServerOffer>} o
   * @return {Offer}
   */
  static map(o: Partial<ServerOffer>): Offer {
    return <Offer>{
      id: o.id,
      name: o.name,
      description: o.description,
      product: o.product ? ProductMapper.map(o.product) : null,
      prices: o.prices ? AmountOptionMapper.mapArray(o.prices) : [],
      deposits: o.deposits ? AmountOptionMapper.mapArray(o.deposits) : [],
      currency: o.currency,
      numberOfInstallmentsList: o.number_of_installments_set
        ? AmountOptionMapper.mapArray(o.number_of_installments_set)
        : [],
      contract: o.contract ? ContractMapper.map(o.contract) : undefined,
      contractDurations: DurationMapper.mapArray(o.contract_duration_set || []),
      firstInstallmentTimeFromApprovalDuration: o.first_installment_time_from_approval_duration
        ? DurationMapper.map(o.first_installment_time_from_approval_duration)
        : undefined,
    };
  }

  /**
   * @param {Partial<Offer>} o
   * @return {ServerOffer}
   */
  static mapReverse(o: Partial<Offer>): ServerOffer {
    return <ServerOffer>{
      id: o.id,
      name: o.name,
      description: o.description,
      product: o.product ? ProductMapper.mapReverse(o.product) : null,
      prices: o.prices ? AmountOptionMapper.mapReverseArray(o.prices) : [],
      currency: o.currency,
      deposits: o.deposits
        ? AmountOptionMapper.mapReverseArray(o.deposits)
        : [],
      number_of_installments_set: o.numberOfInstallmentsList
        ? AmountOptionMapper.mapReverseArray(o.numberOfInstallmentsList)
        : [],
      contract: o.contract ? ContractMapper.mapReverse(o.contract) : null,
      contract_duration_set: DurationMapper.mapReverseArray(
        o.contractDurations || [],
      ),
      first_installment_time_from_approval_duration: o.firstInstallmentTimeFromApprovalDuration
        ? DurationMapper.mapReverse(o.firstInstallmentTimeFromApprovalDuration)
        : null,
    };
  }

  /**
   * @param {ServerOffer[]} models
   * @return {Offer[]}
   */
  static mapArray(models: ServerOffer[]): Offer[] {
    return models.map(m => OfferMapper.map(m));
  }

  /**
   * @param {Offer[]} models
   * @return {ServerOffer[]}
   */
  static mapReverseArray(models: Offer[]): ServerOffer[] {
    return models.map(m => OfferMapper.mapReverse(m));
  }
}
