import locale from '../locale';
import React from 'react';
import imageCompression from 'browser-image-compression';
import AppHistory from './AppHistory';
import { Telephone } from './Telephone';
import {
  audioExtensions,
  FileExtension,
  imageExtensions,
  videoExtensions,
} from './FileExtension.enum';
import { message, Modal } from 'antd';
import environment from '../../environment';
import { phoneNumberPrefixes } from '../../assets/js/phoneNumberPrefixes';
import { SettlementDataType } from './SettlementDataField';
import { Attachment } from './Attachment';
import axios from '../middlewares/axios';
import { UploadFile } from 'antd/es/upload/interface';
import { Settlement, SettlementStatus } from './Settlement';
import { AppRoute } from './appRouter/AppRoute.enum';
import { DurationType } from './DurationType.enum';
import { SettlementStep } from './SettlementStep.enum';

export interface AskForConfirmationData {
  title?: React.ReactNode;
  content?: React.ReactNode;
  okText?: string;
  isDanger?: boolean;
  cancelText?: string;
  icon?: React.ReactNode;
}

/**
 * utils functions to use wherever you need
 */
export abstract class Utils {
  /**
   * omit a list of keys from an object
   * @param {T} target
   * @param {K[]} omitKeys
   * @return {Omit<T, K>}
   */
  static omit<T extends Record<string, any>, K extends keyof T>(
    target: T,
    ...omitKeys: K[]
  ): Omit<T, K> {
    return (Object.keys(target) as K[]).reduce((res, key) => {
      if (!omitKeys.includes(key)) {
        res[key] = target[key];
      }
      return res;
    }, {} as any);
  }

  /**
   * get array from Enum
   * @param {any} en: the enum class
   * @param {boolean} onlyValues
   * @return {T[]}
   */
  static getEnumAsArray<T>(en: any, onlyValues?: boolean): T[] {
    const res = Object.keys(en).map(l => en[l]);
    return !onlyValues ? res : res.filter(v => +v >= 0);
  }

  /**
   * get if object is empty
   * @param {Record<string, any>} obj
   * @return {boolean}
   */
  static isObjectEmpty(obj: Record<string, any>): boolean {
    return obj && !Object.keys(obj).length;
  }

  /**
   * check if two objects are equal
   * @param {Record<string, any>} obj
   * @param {Record<string, any>} obj1
   * @return {boolean}
   */
  static isObjectEqual(
    obj?: Record<string, any>,
    obj1?: Record<string, any>,
  ): boolean {
    return !!(
      (!obj && !obj1) ||
      (obj && obj1 && JSON.stringify(obj) === JSON.stringify(obj1))
    );
  }

  /**
   * check if two arrays are equal
   * @param {Array<any>} a
   * @param {Array<any>} b
   * @return {boolean}
   */
  static isArrayEqual(a: Array<any>, b: Array<any>): boolean {
    return (
      (!a && !b) ||
      (a &&
        a &&
        a.length === b.length &&
        a.every((obj, i) => Utils.isObjectEqual(obj, b[i])))
    );
  }

  /**
   * check if the element is not defined
   * @param {any} val
   * @return {boolean}
   */
  static isNotDefined(val: any): boolean {
    return val === undefined || val === null;
  }

  /**
   * update an object with new values
   * merge two objects
   * @param {T} oldObject
   * @param {Partial<T>} updatedProperties
   * @return {T}
   */
  static updateObject<T>(oldObject: T, updatedProperties: Partial<T>): T {
    return {
      ...oldObject,
      ...(updatedProperties || {}),
    };
  }

  /**
   * copy string to clipboard
   * @param {string} text
   */
  static copyToClipboard(text: string): void {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    message.success(locale('messages.valueCopiedInClipboard'));
    textField.remove();
  }

  /**
   * check if image exists
   * @param {string} imageUrl
   * @return {boolean}
   */
  static imageExists(imageUrl: string): boolean {
    try {
      const http = new XMLHttpRequest();
      http.open('HEAD', imageUrl, false);
      http.send();
      return http.status !== 404;
    } catch (e) {
      return false;
    }
  }

  /**
   * check if is in production domain
   * @return {boolean}
   */
  static isProdDomain(): boolean {
    return (
      window.location.href.indexOf(environment.productionDomain) > -1 || false
    );
  }

  /**
   * Show confirmation modal.
   * Override actions with actionable cta (es. "Yes" => "Save")
   *
   * @param {any} data
   * @return {Promise<boolean>}
   */
  static askForConfirmation(data?: AskForConfirmationData): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      Modal.confirm({
        title: data?.title || locale('labels.areYouSure'),
        icon: data?.icon || null,
        content: data?.content || null,
        cancelText: data?.cancelText || locale('actions.cancel'),
        okText: data?.okText || locale('actions.confirm'),
        okType: data?.isDanger ? 'danger' : 'default',
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  /**
   * transform array of array in array
   * @param {Array<Array<T>>} list
   * @return {Array<T>}
   */
  static flatArrayWithoutDuplicates<T extends { uuid: string }>(
    list: T[][],
  ): T[] {
    const array: T[] = [];
    Array.prototype.concat.apply([], list).forEach(el => {
      if (el && !array.some(element => element?.uuid === el?.uuid)) {
        array.push(el);
      }
    });
    return array;
  }

  /**
   * transform array of array in array
   * @param {Array<Array<T>>} list
   * @return {Array<T>}
   */
  static flatArray<T>(list: T[][]): T[] {
    const array: T[] = [];
    Array.prototype.concat.apply([], list).forEach(el => {
      if (el) {
        array.push(el);
      }
    });
    return array;
  }

  /**
   * check if the user is on mobile browser
   * @return {boolean}
   */
  static isMobileDevice(): boolean {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }

  /**
   * check if the browser is Safari
   * @return {boolean}
   */
  static isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  /**
   * get window dimensions
   * @return {{ width, height }}
   */
  static getWindowDimensions(): { width: number; height: number } {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  /**
   * get formatted IT price
   * @param {number} n
   * @param {'EUR'} currency
   * @param {boolean} showPlusDigit
   * @return {string}
   */
  static getFormattedPrice(
    n: number,
    currency: 'EUR' = 'EUR',
    showPlusDigit?: boolean,
  ): string {
    if (!(n >= 0 || n <= 0)) {
      return '--';
    }
    return `${showPlusDigit && n > 0 ? '+' : ''}${(n / 100)
      .toFixed(2)
      .replace('.', ',')} ${locale(`currency.${currency}`)}`;
  }

  /**
   * get formatted IT price
   * @param {number} n
   * @param {'EUR'} currency
   * @param {number} vatPercentage
   * @return {React.ReactNode}
   */
  static getFormattedPriceWithVat(
    n: number,
    currency: 'EUR' = 'EUR',
    vatPercentage: number,
  ): React.ReactNode {
    const amount = Utils.getFormattedPrice(n, currency);
    const vat = vatPercentage ? (
      <small className="text-secondary font-weight-normal">
        {locale('labels.plusVat', [vatPercentage])}
      </small>
    ) : (
      ''
    );
    return (
      <>
        {amount} {vat}
      </>
    );
  }

  /**
   * get price from formatted IT price
   * @param {string} p
   * @return {number}
   */
  static getUnformattedPrice(p: string): number {
    return (
      parseFloat(p.replace(locale('labels.currency'), '').replace(',', '.')) *
      100
    );
  }

  /**
   * download a file automatically
   * @param {string} url
   * @param {string} fileName
   */
  static saveData = (url: string, fileName: string) => {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.target = '_blank';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  /**
   * convert File to base64
   * @param {Blob} file
   * @return {Promise<string | ArrayBuffer | null>}
   */
  static toBase64(file: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * convert file from url to base64
   * @param {string} url
   * @return {Promise<any>}
   */
  static urlToBase64(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(e);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  /**
   * compress a File
   * @param {Blob} imageFile
   * @param {number} maxSizeMB
   * @param {number} maxWidthOrHeight
   * @return {Promise<File | Blob>}
   */
  static async compressFile(
    imageFile: File | Blob,
    maxSizeMB = 0.1,
    maxWidthOrHeight = 640,
  ): Promise<File | Blob> {
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = { maxSizeMB, maxWidthOrHeight, useWebWorker: true };
    const compressedFile = await imageCompression(imageFile as File, options);
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return compressedFile;
  }

  /**
   * upload a File
   * @param {{ file }} file
   * @param {FileExtension[]} allowedExtensions
   * @param {number} maxSizeMB
   * @param {string} errorMessage
   * @param {number} maxWidthOrHeight
   */
  static async handleUpload(
    { file }: { file: any },
    allowedExtensions: FileExtension[] = Utils.getEnumAsArray(FileExtension),
    maxSizeMB = 0.1,
    errorMessage: string = locale('errors.fileNotUploaded'),
    maxWidthOrHeight = 640,
  ): Promise<string> {
    const { type } = file;
    if (allowedExtensions.includes(type)) {
      let comressedFile;
      try {
        comressedFile = await Utils.compressFile(
          file,
          maxSizeMB,
          maxWidthOrHeight,
        );
      } catch (e) {
        console.log(e);
        comressedFile = file;
      }
      if (comressedFile.size < 1024 * 1024 * maxSizeMB) {
        try {
          return (await Utils.toBase64(comressedFile)) as string;
        } catch (e) {
          throw new Error(errorMessage);
        }
      } else {
        throw new Error(locale('errors.imageTooLarge', [`${maxSizeMB}`]));
      }
    } else {
      throw new Error(locale('errors.imageError', [type]));
    }
  }

  /**
   * get Telephone from string
   * @param {string} privatePhone
   * @param {string} prefix
   * @return {Telephone}
   */
  static getTelephoneFromString(
    privatePhone: string,
    prefix = '+39',
  ): Telephone {
    const phone: Telephone = { prefix, number: '' };
    if (privatePhone?.trim().startsWith(prefix)) {
      phone.number = privatePhone.split(prefix)[1];
    }
    return phone;
  }

  /**
   * get formatted Telephone
   * @param {Telephone} phone
   * @return {string}
   */
  static getFormattedTelephone(phone: Telephone): string {
    return `${phone.prefix || ''}${phone.number || ''}`;
  }

  /**
   * check if the string is a supported prefix
   * @param {string} prefix
   * @return {boolean}
   */
  static isPrefix(prefix: string): boolean {
    return phoneNumberPrefixes.some(p => p.code === prefix);
  }

  /**
   * Get the path + current query parameters
   * @param {string} path
   * @return {string}
   */
  static preserveQueryParameters(path: string): string {
    return path + AppHistory.location.search;
  }

  /**
   * Get the object that contains all the query parameters
   * @param {string} search
   * @return {Record<string, string>}
   */
  static getQueryParameters(
    search: string = AppHistory.location.search,
  ): Record<string, string> {
    const parameters: Record<string, string> = {};
    if (search?.length) {
      const list = search.split('?')[1].split('&');
      list?.forEach(param => {
        const values = param.split('=');
        parameters[values[0]] = values[1];
      });
    }
    return parameters;
  }

  /**
   * check if is image
   * @param {FileExtension} extension
   * @return {boolean}
   */
  static isImage(extension: FileExtension): boolean {
    return !!extension && imageExtensions.includes(extension);
  }

  /**
   * check if is audio
   * @param {FileExtension} extension
   * @return {boolean}
   */
  static isAudio(extension: FileExtension): boolean {
    return audioExtensions.includes(extension);
  }

  /**
   * check if is video
   * @param {FileExtension} extension
   * @return {boolean}
   */
  static isVideo(extension: FileExtension): boolean {
    return videoExtensions.includes(extension);
  }

  /**
   * get correct Settlement Data Field Value
   * @param {any} value
   * @param {SettlementDataType} type
   * @return {string | number | boolean}
   */
  static getSettlementDataFieldValue(
    value: any,
    type: SettlementDataType,
  ): string | number | boolean {
    switch (type) {
      case SettlementDataType.Text:
      case SettlementDataType.Email:
      case SettlementDataType.Phone:
      case SettlementDataType.Select:
        return value !== null ? `${value}` : '';
      case SettlementDataType.Checkbox:
      case SettlementDataType.Switch:
        return value === 'True' || value === 'true';
    }
  }

  /**
   * Upload list of attachments
   *
   * @param {UploadFile[]} files
   * @param {string} url
   * @param {any} additionalData
   * @return {Promise<Attachment[]>}
   */
  static uploadFiles(
    files: UploadFile[],
    url: string,
    additionalData?: any,
  ): Promise<Attachment[]> {
    return Promise.all(
      files.map(async (file, i) => {
        const arrayBuffer = await file.originFileObj?.arrayBuffer();
        if (arrayBuffer) {
          const blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: file.type,
          });
          const data = new FormData();
          data.append('file', blob, file.name);
          data.append('name', file.name);
          data.append('order', `${i}`);
          additionalData &&
            Object.keys(additionalData).forEach(k =>
              data.append(k, additionalData[k]),
            );
          return await axios.post<any, Attachment>(url, data);
        }
        throw new Error('no fileText');
      }),
    );
  }

  /**
   * Get current step for settlement
   * @param {Settlement} model
   * @return {number}
   */
  static getSettlementStep(model: Settlement): SettlementStep {
    let s;
    switch (model?.status) {
      case SettlementStatus.Created:
        s = SettlementStep.InsertData;
        break;
      case SettlementStatus.DataFilled:
        s = SettlementStep.ConfirmData;
        break;
      case SettlementStatus.DataConfirmed:
      case SettlementStatus.Signed:
      case SettlementStatus.Paid:
        s = SettlementStep.SignPay;
        break;
      case SettlementStatus.ReadyToFinalize:
        s = SettlementStep.Finalize;
        break;
      case SettlementStatus.Finalized:
        s = SettlementStep.FinalOverview;
        break;
      case SettlementStatus.Approved:
        s = SettlementStep.FinalOverview;
        break;
    }
    return s;
  }

  /**
   * Check if the path is an app route
   *
   * @param {AppRoute} route
   * @param {string} pathname
   * @return {boolean}
   */
  static isRoute(route: AppRoute, pathname: string): boolean {
    const routePaths = route.split('/');
    const pathnamePaths = pathname.split('/');
    return (
      routePaths.length === pathnamePaths.length &&
      pathnamePaths.every(
        (path, i) =>
          path === routePaths[i] ||
          (routePaths[i] === ':id' && +path) ||
          (routePaths[i] === ':uuid' && path.length),
      )
    );
  }

  /**
   * @param {Date} date
   * @return {string}
   */
  static getFormattedDate(date: Date): string {
    return date?.toLocaleString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  /**
   * @param {Date} date
   * @return {string}
   */
  static getFormattedDateTime(date: Date): string {
    return date?.toLocaleString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  /**
   *
   * @param {number} value
   * @param {DurationType} durationType
   * @return {number}
   */
  static getDurationAsDays(
    value = 0,
    durationType: DurationType = DurationType.DAYS,
  ): number {
    const daysOfType = {
      [`${DurationType.DAYS}`]: 1,
      [`${DurationType.WEEKS}`]: 7,
      [`${DurationType.MONTHS}`]: 30,
      [`${DurationType.YEARS}`]: 365,
    };
    return value * daysOfType[`${durationType}`];
  }
}
