import React from 'react';
import { Button } from 'antd';
import locale from '../../../../locale';

interface SettlementCreatorConfirmOfferProps {
  onConfirm: () => void;
}

const SettlementCreatorConfirmOffer = ({
  onConfirm,
}: SettlementCreatorConfirmOfferProps) => {
  return (
    <div className="p-2">
      <Button type="primary" block onClick={onConfirm}>
        {locale('actions.confirmOffer')}
      </Button>
    </div>
  );
};
export default SettlementCreatorConfirmOffer;
