import { Mapper } from './Mapper';

export interface Telephone {
  id?: number;
  country?: string;
  telephone?: string;
  prefix: string;
  number: string;
}

/* eslint-disable camelcase */
export interface ServerTelephone {
  id: number | undefined;
  prefix: string;
  number: string;
}

/* eslint-enable camelcase */

/**
 * Map Telephone Model
 */
export abstract class TelephoneMapper
  implements Mapper<Telephone, ServerTelephone> {
  /**
   * @param {ServerTelephone} o
   * @return {Telephone}
   */
  static map(o: ServerTelephone): Telephone {
    return {
      id: o.id,
      prefix: o.prefix,
      number: o.number,
    };
  }

  /**
   * @param {Telephone} o
   * @return {ServerTelephone}
   */
  static mapReverse(o: Telephone): ServerTelephone {
    return {
      id: o.id,
      prefix: o.prefix,
      number: o.number,
    };
  }

  /**
   * @param {ServerTelephone[]} models
   * @return {Telephone[]}
   */
  static mapArray(models: ServerTelephone[]): Telephone[] {
    return models.map(TelephoneMapper.map);
  }

  /**
   * @param {Telephone[]} models
   * @return {ServerTelephone[]}
   */
  static mapReverseArray(models: Telephone[]): ServerTelephone[] {
    return models.map(TelephoneMapper.mapReverse);
  }
}
