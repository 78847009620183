import React from 'react';

interface EmptyLayoutProps {
  children: React.ReactNode;
}

/**
 *
 * @param {EmptyLayoutProps} children
 * @constructor
 */
export default function EmptyLayout({ children }: EmptyLayoutProps) {
  return <>{children}</>;
}
