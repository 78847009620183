import { Mapper } from './Mapper';
import {
  ServerSettlementData,
  SettlementData,
  SettlementDataMapper,
} from './SettlementData';
import {
  PaymentMethodType,
  PaymentMethodTypeMapper,
  ServerPaymentMethodType,
} from './PaymentMethodType';
import {
  ServerContractBlock,
  ContractBlock,
  ContractBlockMapper,
} from './ContractBlock';

export interface SettlementConfig {
  id: number;
  name: string;
  data: SettlementData;
  installmentsPaymentMethodTypes: PaymentMethodType[];
  advancePaymentMethodTypes: PaymentMethodType[];
  depositPaymentMethodTypes: PaymentMethodType[];
  settlementSignBlocks: ContractBlock[];
}

/* eslint-disable camelcase */
export interface ServerSettlementConfig {
  id: number;
  name: string;
  settlement_data: ServerSettlementData;
  installments_payment_method_types: ServerPaymentMethodType[];
  advance_payment_method_types: ServerPaymentMethodType[];
  deposit_payment_method_types: ServerPaymentMethodType[];
  settlementsignblock_set: ServerContractBlock[];
}

/* eslint-enable camelcase */

/**
 * Map SettlementConfig model
 */
export abstract class SettlementConfigMapper
  implements Mapper<SettlementConfig, ServerSettlementConfig> {
  /**
   * @param {Partial<ServerSettlementConfig>} o
   * @return {SettlementConfig}
   */
  static map(o: Partial<ServerSettlementConfig>): SettlementConfig {
    return <SettlementConfig>{
      id: o.id,
      name: o.name,
      data: o.settlement_data
        ? SettlementDataMapper.map(o.settlement_data)
        : null,
      installmentsPaymentMethodTypes: o.installments_payment_method_types
        ? PaymentMethodTypeMapper.mapArray(o.installments_payment_method_types)
        : [],
      advancePaymentMethodTypes: o.advance_payment_method_types
        ? PaymentMethodTypeMapper.mapArray(o.advance_payment_method_types)
        : [],
      depositPaymentMethodTypes: o.deposit_payment_method_types
        ? PaymentMethodTypeMapper.mapArray(o.deposit_payment_method_types)
        : [],
      settlementSignBlocks: o.settlementsignblock_set
        ? ContractBlockMapper.mapArray(o.settlementsignblock_set)
        : [],
    };
  }

  /**
   * @param {Partial<SettlementConfig>} o
   * @return {ServerSettlementConfig}
   */
  static mapReverse(o: Partial<SettlementConfig>): ServerSettlementConfig {
    return <ServerSettlementConfig>{
      id: o.id,
      name: o.name,
      settlement_data: o.data ? SettlementDataMapper.mapReverse(o.data) : null,
      installments_payment_method_types: o.installmentsPaymentMethodTypes
        ? PaymentMethodTypeMapper.mapReverseArray(
            o.installmentsPaymentMethodTypes,
          )
        : [],
      advance_payment_method_types: o.advancePaymentMethodTypes
        ? PaymentMethodTypeMapper.mapReverseArray(o.advancePaymentMethodTypes)
        : [],
      deposit_payment_method_types: o.depositPaymentMethodTypes
        ? PaymentMethodTypeMapper.mapReverseArray(o.depositPaymentMethodTypes)
        : [],
      settlementsignblock_set: o.settlementSignBlocks
        ? ContractBlockMapper.mapReverseArray(o.settlementSignBlocks)
        : [],
    };
  }

  /**
   * @param {ServerSettlementConfig[]} models
   * @return {SettlementConfig[]}
   */
  static mapArray(models: ServerSettlementConfig[]): SettlementConfig[] {
    return models.map(m => SettlementConfigMapper.map(m));
  }

  /**
   * @param {SettlementConfig[]} models
   * @return {ServerSettlementConfig[]}
   */
  static mapReverseArray(models: SettlementConfig[]): ServerSettlementConfig[] {
    return models.map(m => SettlementConfigMapper.mapReverse(m));
  }
}
