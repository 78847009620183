import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authLogout } from '../authSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authLogout());
  }, []);
  return null;
};

export default Logout;
