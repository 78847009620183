import React from 'react';
import { Button, Card, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout, authSelector } from '../../../auth/authSlice';

/**
 * Visible if no settlement specified in the url
 * @constructor
 */
export default function CloserProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  return (
    <Card
      className="mt-3"
      title={locale('titles.contractNotFound')}
      extra={[
        <Button key="logout" type="link" onClick={() => dispatch(authLogout())}>
          {locale('actions.logout')}
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        {locale('subtitles.wrongLink', [user?.firstName || ''])}
      </Typography.Paragraph>
    </Card>
  );
}
