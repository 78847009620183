import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  contactDetailActions,
  contactDetailSelector,
} from '../../contactDetailSlice';
import locale from '../../../../locale';
import ContactDetail from '../../ContactDetail';

/**
 * @constructor
 */
export default function ContactDetailModal() {
  const dispatch = useDispatch();
  const { isModalVisible, isEdit } = useSelector(contactDetailSelector);

  return (
    <Modal
      visible={isModalVisible}
      title={locale(`titles.${isEdit ? 'editContact' : 'newContact'}`)}
      className="settlement-creator-modal"
      onCancel={() => dispatch(contactDetailActions.closeModal())}
      footer={null}
    >
      <ContactDetail />
    </Modal>
  );
}
