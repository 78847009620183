import React from 'react';
import { Layout } from 'antd';
import styles from './PublicLayout.module.scss';
import Header from './header/Header';
import locale from '../../locale';
import { Footer } from './footer/Footer';

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout = (props: PublicLayoutProps) => {
  return (
    <Layout className={styles.layout}>
      <Layout>
        <Layout.Content className={styles.content}>
          <Header title={locale('titles.login')} />
          {props.children}
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
