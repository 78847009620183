import React from 'react';
import { Contact } from '../../models/Contact';
import { Pagination } from '../../models/Pagination';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { ListItem } from '../../components/list/ListItem';
import { Utils } from '../../models/Utils';
import { Button, Col, Row } from 'antd';
import { List } from '../../components/list/List';
import { contactsInit, contactsNextPage } from './contactsSlice';
import locale from '../../locale';
import { EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { contactDetailActions } from '../contactDetail/contactDetailSlice';

interface ContactsProps {
  list?: Contact[];
  pagination?: Pagination;
  search?: string;
  loading?: boolean;
  init?: (search: string) => void;
  nextPage?: () => void;
  onCreate?: () => void;
  onEdit?: (contact: Contact) => void;
  onContactSelected: (contact: Contact) => void;
}

interface ContactsState {
  listItems: ListItem[];
}

/**
 *
 */
class Contacts extends React.Component<ContactsProps, ContactsState> {
  state = {
    listItems: [],
  };

  /**
   *
   */
  componentDidMount() {
    const { init, search, list } = this.props;
    !list?.length && init?.(search || '');
    this.setState({ listItems: this.getListItems() });
  }

  /**
   * @param {Readonly<ContactsProps>} prevProps
   */
  componentDidUpdate(prevProps: Readonly<ContactsProps>) {
    if (!Utils.isArrayEqual(prevProps.list || [], this.props.list || [])) {
      this.setState({ listItems: this.getListItems() });
    }
  }

  /**
   * @return {ListItem[]}
   */
  getListItems(): ListItem[] {
    const { onEdit, list } = this.props;
    return [
      {
        key: `new`,
        title: locale('titles.newContact'),
        leftItem: <UserAddOutlined />,
      },
      ...(list?.map(item => {
        return {
          key: `${item.id}`,
          title: `${item.fullName} - ${item.company}`,
          description: `${item.phone?.prefix || ''}${
            item.phone?.number || ''
          } | ${item.email}`,
          rightItems: [
            <Button
              key="edit"
              type="text"
              icon={<EditOutlined />}
              onClick={e => {
                e.stopPropagation();
                onEdit?.(item);
              }}
            />,
          ],
        };
      }) || []),
    ];
  }

  /**
   *
   * @param {string} id
   */
  onItemClick(id: string) {
    const { list, onContactSelected, onCreate } = this.props;
    if (id === 'new') {
      onCreate?.();
    } else {
      const contact = list?.find(c => c.id === +id);
      contact && onContactSelected(contact);
    }
  }

  /**
   *
   */
  onLoadMore() {
    !this.props.loading && this.props.nextPage?.();
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const { listItems } = this.state;
    const { pagination, loading, init, search } = this.props;

    return (
      <>
        <Row>
          <Col xs={24}>
            {(!loading || !!listItems.length) && (
              <List
                paginated
                search={search}
                hasMore={!!pagination?.hasNext}
                loadMore={() => this.onLoadMore()}
                loading={loading}
                items={listItems}
                onItemClick={id => this.onItemClick(id)}
                onSearch={init}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  search: state.contacts.search,
  list: state.contacts.list,
  pagination: state.contacts.pagination,
  loading: state.loader.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  init: (search: string) => dispatch(contactsInit({ search })),
  onCreate: () => dispatch(contactDetailActions.openModal(null)),
  onEdit: (contact: Contact) =>
    dispatch(contactDetailActions.openModal(contact)),
  nextPage: () => dispatch(contactsNextPage({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
