import { ApiUrl } from '../models/ApiUrl.enum';
import axios from '../middlewares/axios';
import { Offer, OfferMapper, ServerOffer } from '../models/Offer';

/**
 * Offer APIs
 */
export abstract class OfferApiService {
  /**
   * Get list of Offer
   * @param {number} idProduct
   * @return {Promise<Offer[]>}
   */
  static async fetchOffers(idProduct: number): Promise<Offer[]> {
    const params = { product: idProduct };
    const sOffers: ServerOffer[] = await axios.get(ApiUrl.Offers, { params });
    return OfferMapper.mapArray(sOffers || []);
  }
}
