import React from 'react';
import { useSelector } from 'react-redux';
import { settlementCloserSelector } from '../../settlementCloserSlice';
import { Button, Card, Col, Row, Typography } from 'antd';
import logo from '../../../../../logo.png';
import styles from './CloserOverview.module.scss';
import locale from '../../../../locale';
import SettlementOfferOverview from '../../../../components/settlementOfferOverview/SettlementOfferOverview';
import environment from '../../../../../environment';

interface CloserOverviewProps {
  onSubmit: () => void;
}

const CloserOverview = ({ onSubmit }: CloserOverviewProps) => {
  const { model } = useSelector(settlementCloserSelector);
  return model ? (
    <Row gutter={[16, 16]}>
      <Col xs={24} className="d-flex justify-content-center mt-2 mb-3">
        <div className="d-flex">
          <img
            src={model.account?.logo || logo}
            alt="logo"
            className={styles.logo}
          />
          <div className="d-flex flex-direction-column ml-2">
            <Typography.Title className="mb-0">
              {model.account?.name || environment.appName}
            </Typography.Title>
            <Typography.Text>
              {model.account.shortDescription ||
                locale('subtitles.signAndPayInSafe')}
            </Typography.Text>
          </div>
        </div>
      </Col>
      {model.settlementOffers?.map(so => (
        <Col key={so.id} xs={24}>
          <Card
            title={
              <Typography.Title level={3}>{so.offer.name}</Typography.Title>
            }
          >
            <Typography.Title level={5}>
              {locale('titles.servicesAndFeatures')}
            </Typography.Title>
            <Typography.Paragraph>
              <span
                dangerouslySetInnerHTML={{ __html: so.offer.description }}
              />
            </Typography.Paragraph>
            {(so.priceValue !== 0 || so.depositValue !== 0) && (
              <>
                <Typography.Title level={5} className="mt-3">
                  {locale('titles.offerOverview')}
                </Typography.Title>
                <SettlementOfferOverview settlementOffer={so} />
              </>
            )}
          </Card>
        </Col>
      ))}
      <Col xs={24}>
        <Button type="primary" size="large" block onClick={onSubmit}>
          {locale('actions.goAhead')}
        </Button>
      </Col>
    </Row>
  ) : null;
};

export default CloserOverview;
