import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractPreviewGetById,
  contractPreviewSelector,
} from './contractPreviewSlice';
import styles from './ContractPreview.module.scss';
import { Button, Checkbox } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import locale from '../../locale';

interface RouteProps {
  id: string;
}

type ContractPreviewProps = RouteComponentProps<RouteProps>;

/**
 *
 * @param {ContractPreviewProps} children
 * @constructor
 */
export default function ContractPreview({
  match: {
    params: { id },
  },
}: ContractPreviewProps) {
  const dispatch = useDispatch();
  const content = useSelector(contractPreviewSelector)?.content;
  const [
    isSignaturePreviewActive,
    setIsSignaturePreviewActive,
  ] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      contractPreviewGetById({
        id: +id,
        showSignatures: isSignaturePreviewActive,
      }),
    );
  }, [id, dispatch, isSignaturePreviewActive]);

  return (
    <div className={styles.contractPreview}>
      {content && (
        <div className={styles.contractPreviewContainer}>
          <div>
            <Checkbox
              checked={isSignaturePreviewActive}
              onChange={e => setIsSignaturePreviewActive(e.target.checked)}
            >
              {locale('actions.signaturePreview')}
            </Checkbox>
            <Button
              size="middle"
              icon={<PrinterOutlined />}
              onClick={() => {
                window.frames[1].focus();
                window.frames[1].print();
              }}
            >
              {locale('actions.print')}
            </Button>
          </div>
          <iframe className={styles.contractIframe} srcDoc={content} />
        </div>
      )}
    </div>
  );
}
