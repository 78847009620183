import { Telephone } from '../../models/Telephone';
import { ApiUrl } from '../../models/ApiUrl.enum';
import axios from '../../middlewares/axios';

/**
 * OTP APIs
 */
export abstract class OtpApiService {
  /**
   *
   * @param {{ idSettlement, otp, phone, firstName, lastName, businessname }} data
   * @return {Promise<number>} request
   */
  static async sendRequest({
    idSettlement,
    phone,
    firstName,
    lastName,
    businessname,
  }: {
    idSettlement: number;
    phone: Telephone;
    firstName: string;
    lastName: string;
    businessname: string;
  }): Promise<number> {
    const res: { request: number } = await axios.post(ApiUrl.OtpRequest, {
      settlement: idSettlement,
      phone,
      first_name: firstName,
      last_name: lastName,
      business_name: businessname,
    });
    return res.request;
  }

  /**
   *
   * @param {{ phone, request, otp }} data
   * @return {Promise<{token: string, expiresIn: number}>}
   */
  static async sendResponse(data: {
    request: number;
    otp: string;
    phone: Telephone;
  }): Promise<{ token: string; expiresIn: number }> {
    /* eslint-disable camelcase */
    const res: {
      response: string;
      token: {
        access_token: string;
        expires_in: number;
        refresh_token: string;
        scope: string;
        token_type: string;
      };
    } = await axios.post(ApiUrl.OtpResponse, {
      phone: data.phone,
      request: data.request,
      code: data.otp,
    });
    /* eslint-enable camelcase */

    if (res.response !== 'valid' && res.response !== 'approved') {
      throw new Error();
    }
    return {
      token: res.token.access_token,
      expiresIn: res.token.expires_in * 1000,
    };
  }
}
