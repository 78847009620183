import React, { useState } from 'react';
import { PaymentMethodItem } from '../../models/PaymentMethodItem';
import locale from '../../locale';
import SettlementSharer from '../../features/settlementDetail/components/settlementSharer/SettlementSharer';
import { Settlement } from '../../models/Settlement';
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import { Utils } from '../../models/Utils';
import MediaUploader from '../mediaUploader/MediaUploader';
import { UploadFile } from 'antd/es/upload/interface';
import moment from 'moment';
import environment from '../../../environment';
import { MethodType } from '../../models/PaymentMethodType';
import { FieldData } from '../../models/FieldData';
import { useDispatch } from 'react-redux';
import { settlementDetailActions } from '../../features/settlementDetail/settlementDetailSlice';

interface PaymentHandlerProps {
  settlement: Settlement;
  paymentMethodItem: PaymentMethodItem;
  currentOption?: MethodType;
  amount: number;
  onPay: (data?: Record<string, any>) => void;
}

/**
 * Handle payment methods
 * @constructor
 */
export default function PaymentHandler({
  settlement,
  paymentMethodItem,
  currentOption,
  amount,
  onPay,
}: PaymentHandlerProps) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<UploadFile[]>(
    paymentMethodItem.paymentMethod?.attachments?.map(attachment => ({
      uid: `${attachment.id}`,
      url: attachment.file,
      thumbUrl: attachment.thumbnailUrl,
      name: attachment.name,
    })) || [],
  );

  const [fields, setFields] = useState<FieldData[]>([
    {
      name: 'paymentDate',
      value: moment(paymentMethodItem.paymentDate || undefined),
    },
    {
      name: 'bankAccountHolder',
      value: paymentMethodItem.paymentMethod?.bankAccountHolder || '',
    },
    { name: 'iban', value: paymentMethodItem.paymentMethod?.iban || '' },
    {
      name: 'bankAuthorizationId',
      value: paymentMethodItem.paymentMethod?.bankAuthorizationId || '',
    },
    { name: 'name', value: paymentMethodItem.paymentMethod?.firstName || '' },
    {
      name: 'lastName',
      value: paymentMethodItem.paymentMethod?.lastName || '',
    },
    {
      name: 'fiscalCode',
      value: paymentMethodItem.paymentMethod?.fiscalCode || '',
    },
    { name: 'address', value: paymentMethodItem.paymentMethod?.address || '' },
    { name: 'zipCode', value: paymentMethodItem.paymentMethod?.zipCode || '' },
    { name: 'city', value: paymentMethodItem.paymentMethod?.city || '' },
    {
      name: 'district',
      value: paymentMethodItem.paymentMethod?.district || '',
    },
  ]);
  const [isFormValid, setIsFormValid] = useState<boolean>(
    fields.every(f => f.value),
  );
  const [paymentDate, setPaymentDate] = useState<moment.Moment | undefined>(
    moment(paymentMethodItem.paymentDate || undefined),
  );

  const renderSharer = (subtitle?: string) => {
    return (
      <SettlementSharer
        subtitle={subtitle}
        settlement={settlement}
        getShareLink={async link => {
          if (
            settlement.settlementContracts?.[0].isSigned ||
            (await Utils.askForConfirmation({
              title: locale('titles.sureToSkipContractSign'),
              content: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: locale('subtitles.sureToSkipContractSign'),
                  }}
                />
              ),
              okText: locale('actions.noSignBeforePay'),
              cancelText: locale('actions.yesPayWithoutSign'),
            }))
          ) {
            return link;
          }
          return link + '&step=payment';
        }}
      />
    );
  };

  const renderCash = () => (
    <>
      <Form layout="vertical" className="mt-1">
        <Form.Item
          className="align-items-center mb-1"
          label={locale('labels.paymentDate')}
          tooltip={locale('subtitles.paymentDate')}
          required
        >
          <DatePicker
            value={paymentDate}
            format="DD/MM/YYYY"
            onChange={value => setPaymentDate(value || undefined)}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        disabled={!paymentDate}
        onClick={async () =>
          (await Utils.askForConfirmation({
            title: locale('titles.markAsPaid'),
            content: locale('subtitles.markAsPaidCash', [
              locale(`paymentMethodType.${paymentMethodItem.type}`),
            ]),
            okText: locale('actions.markAsPaid'),
          })) && onPay({ paymentDate: paymentDate?.toDate() })
        }
      >
        {locale('actions.markAsPaid')}
      </Button>
    </>
  );

  const renderCard = () => {
    return (
      <Button
        className="mt-1"
        onClick={() =>
          dispatch(
            settlementDetailActions.openStripeCardModal({ paymentMethodItem }),
          )
        }
      >
        {locale(
          `titles.${paymentMethodItem?.mustPayNow ? 'payWithCard' : 'setCard'}`,
        )}
      </Button>
    );
  };

  const renderRidOffline = () => (
    <>
      <Form
        layout="vertical"
        className="mt-2 text-left"
        fields={fields}
        onFieldsChange={(_, allFields) => {
          setFields(allFields);
          setIsFormValid(allFields.every(f => f.value));
        }}
      >
        <Row gutter={[0, 0]}>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.bankAccountHolder')}
              name="bankAccountHolder"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.fullName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.iban')}
              name="iban"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.iban')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.vatNumber')}
              name="bankAuthorizationId"
              rules={[{ required: true }]}
              tooltip={locale('subtitles.bankAuthorizationId')}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.vatNumber')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.firstName')}
              name="name"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.firstName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.lastName')}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.lastName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.fiscalCode')}
              name="fiscalCode"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.fiscalCode')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.address')}
              name="address"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.address')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.zipCode')}
              name="zipCode"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.zipCode')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.city')}
              name="city"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.city')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.district')}
              name="district"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.district')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.paymentDate')}
              tooltip={locale('subtitles.paymentDate')}
              name="paymentDate"
              rules={[{ required: true }]}
            >
              <DatePicker className="w-100" format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Typography.Text type="secondary">
        {locale('subtitles.uploadRid')}
      </Typography.Text>
      <MediaUploader canEdit={true} files={files} onFilesChange={setFiles} />
      <Button
        disabled={!files.length || !isFormValid}
        type="primary"
        className="mt-1"
        onClick={async () => {
          const data: Record<string, any> = {};
          fields.forEach(f => {
            data[(f.name as string[])[0]] = f.value;
          });
          onPay({ files, ...data });
        }}
      >
        {locale('actions.confirmPaymentInfo')}
      </Button>
    </>
  );

  const renderSepaDebitStripe = () => {
    return (
      <Button
        className="mt-1"
        onClick={() =>
          dispatch(
            settlementDetailActions.openStripeIbanModal({ paymentMethodItem }),
          )
        }
      >
        {locale(
          `titles.${
            paymentMethodItem?.mustPayNow ? 'payWithSDDStripe' : 'setSDDStripe'
          }`,
        )}
      </Button>
    );
  };

  const renderBankTransfer = () => {
    const description = locale('labels.ibanDescription', [
      settlement.uuid || '--',
      locale(`paymentMethodType.${paymentMethodItem.type}`),
      environment.appName,
    ]);
    return (
      <Space size="large" direction="vertical">
        <div>
          <Descriptions
            size="small"
            className="mb-1 text-left"
            title={locale('titles.bankAccountDetails')}
            column={1}
            bordered
          >
            <Descriptions.Item label={locale('labels.bankName')}>
              <Typography.Text copyable>
                {environment.bankAccountDetails.bankName}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={locale('labels.accountHolder')}>
              <Typography.Text copyable>
                {environment.bankAccountDetails.accountHolder}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={locale('labels.iban')}>
              <Typography.Text copyable>
                {environment.bankAccountDetails.iban}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={locale('labels.description')}>
              <Typography.Text copyable>{description}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label={locale('labels.amount')}>
              {amount ? Utils.getFormattedPrice(amount) : '--'}
            </Descriptions.Item>
          </Descriptions>
          <SettlementSharer
            settlement={settlement}
            getShareLink={() =>
              locale('messages.bankAccountDetails', [
                environment.bankAccountDetails.bankName,
                environment.bankAccountDetails.accountHolder,
                environment.bankAccountDetails.iban,
                Utils.getFormattedPrice(amount) as string,
                description,
              ])
            }
          />
        </div>
        <Form layout="vertical" className="mt-1">
          <Form.Item
            className="align-items-center mb-1"
            label={locale('labels.paymentDate')}
            tooltip={locale('subtitles.paymentDate')}
            required
          >
            <DatePicker
              format="DD/MM/YYYY"
              value={paymentDate}
              onChange={value => setPaymentDate(value || undefined)}
            />
          </Form.Item>
        </Form>
        <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">
              {locale('subtitles.uploadReceipt')}
            </div>
          </div>
          <MediaUploader
            canEdit={true}
            files={files}
            onFilesChange={setFiles}
          />
        </div>
        <Button
          type="primary"
          disabled={!paymentDate}
          onClick={async () =>
            (await Utils.askForConfirmation({
              title: locale('titles.markAsPaid'),
              content: locale('subtitles.markAsPaidBankTransfer', [
                locale(`paymentMethodType.${paymentMethodItem.type}`),
              ]),
              okText: locale('actions.markAsPaid'),
            })) && onPay({ files, paymentDate: paymentDate?.toDate() })
          }
        >
          {locale('actions.markAsPaid')}
        </Button>
      </Space>
    );
  };

  return (
    <Space direction="vertical" className="w-100 text-center mt-2">
      <>
        <Typography.Title level={4} className="mb-0">
          {locale('titles.paymentWith', [
            locale(`paymentMethodOption.${currentOption || 'otp'}`),
          ])}
        </Typography.Title>
        <div className="d-flex flex-direction-column align-items-center justify-content-center">
          <Typography.Text type="secondary">
            {locale('labels.total')}:{' '}
          </Typography.Text>
          <Typography.Text>
            <b>
              {Utils.getFormattedPriceWithVat(
                amount,
                settlement.currency,
                settlement.vatPercentage,
              )}
            </b>
          </Typography.Text>
          {/* TODO */}
          {/* {paymentMethodItem.type === PaymentMethodItemType.Installments && (*/}
          {/*  <Form layout="vertical" className="mt-1">*/}
          {/*    <Form.Item*/}
          {/*      className="align-items-center mb-1"*/}
          {/*      label={locale('labels.firstSubscriptionDate')}*/}
          {/*      tooltip={locale('subtitles.firstSubscriptionDate')}*/}
          {/*      required*/}
          {/*    >*/}
          {/*      <DatePicker*/}
          {/*        value={subscriptionsDate}*/}
          {/*        disabledDate={d =>*/}
          {/*          !d ||*/}
          {/*          (!!paymentMethodItem.maxDate &&*/}
          {/*            d.isAfter(moment(paymentMethodItem.maxDate))) ||*/}
          {/*          (!!paymentMethodItem.minDate &&*/}
          {/*            d.isSameOrBefore(moment(paymentMethodItem.minDate)))*/}
          {/*        }*/}
          {/*        onChange={value => setSubscriptionsDate(value || undefined)}*/}
          {/*      />*/}
          {/*    </Form.Item>*/}
          {/*  </Form>*/}
          {/* )}*/}
        </div>
      </>
      {!currentOption && renderSharer(locale('subtitles.payWithOtp'))}
      {currentOption === MethodType.Cash && renderCash()}
      {currentOption === MethodType.BankSepaDebitOnline &&
        renderSharer(locale('subtitles.payRidOnlineWithOtp'))}
      {currentOption === MethodType.BankSepaDebitOffline && renderRidOffline()}
      {currentOption === MethodType.StripeCard && renderCard()}
      {currentOption === MethodType.BankTransfer && renderBankTransfer()}
      {currentOption === MethodType.StripeSepaDebit && renderSepaDebitStripe()}
    </Space>
  );
}
