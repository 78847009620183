import { Redirect, Route, Switch } from 'react-router';
import React from 'react';
import { AppRoute } from './AppRoute.enum';
import { Utils } from '../Utils';
import { SessionService } from '../SessionService';
import Login from '../../features/auth/login/Login';
import Logout from '../../features/auth/logout/Logout';
import NotFound from '../../components/notFound/NotFound';
import Settlements from '../../features/settlements/Settlements';
import SettlementDetail from '../../features/settlementDetail/SettlementDetail';
import SettlementCloser from '../../features/settlementCloser/SettlementCloser';
import ContractPreview from '../../features/contractPreview/ContractPreview';

/**
 * get App routes
 */
export abstract class AppRouter {
  /**
   * get routes for authenticated user
   * @return {Partial<Switch>}
   */
  static getAuthRoutes(): Partial<Switch> {
    const { returnUrl } = Utils.getQueryParameters();
    const redirect = returnUrl || AppRoute.Root;
    return (
      <Switch>
        <Route path={AppRoute.Settlements} component={Settlements} exact />
        <Route path={AppRoute.SettlementDetail} component={SettlementDetail} />
        <Route path={AppRoute.Logout} component={Logout} exact />
        <Route path={AppRoute.NotFound} component={NotFound} exact />
        <Redirect from={AppRoute.Login} to={redirect} exact />
        <Redirect from={AppRoute.Root} to={AppRoute.Settlements} exact />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }

  /**
   * get routes for customer user
   * @return {Partial<Switch>}
   */
  static getCustomerRoutes(): Partial<Switch> {
    const { returnUrl } = Utils.getQueryParameters();
    const redirect = returnUrl || AppRoute.Root;
    return (
      <Switch>
        <Route path={AppRoute.Sign} component={SettlementCloser} exact />
        <Route path={AppRoute.Logout} component={Logout} exact />
        <Redirect from={AppRoute.Login} to={redirect} exact />
        <Redirect from={AppRoute.Root} to={AppRoute.Sign} exact />
        <Redirect path="*" to={redirect} />
      </Switch>
    );
  }

  /**
   * get routes for unauthenticated user
   * @return {Partial<Switch>}
   */
  static getPublicRoutes(): Partial<Switch> {
    return (
      <Switch>
        <Route path={AppRoute.Login} component={Login} />
        <Route path={AppRoute.Sign} component={SettlementCloser} />
        {!SessionService.isLogged() && (
          <Redirect path="*" to={AppRoute.Login} />
        )}
      </Switch>
    );
  }

  /**
   * get routes for authenticated user but without layout
   * @return {Partial<Switch>}
   */
  static getEmptyRoutes(): Partial<Switch> {
    return (
      <Switch>
        <Route path={AppRoute.ContractPreview} component={ContractPreview} />
      </Switch>
    );
  }
}
