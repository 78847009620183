import axios, { setApiAuthorization } from '../../middlewares/axios';
import { ApiUrl } from '../../models/ApiUrl.enum';
import { ServerUser, User, UserMapper } from '../../models/User';
import { AuthSessionData } from '../../models/SessionService';
import environment from '../../../environment';
import locale from '../../locale';

/* eslint-disable camelcase */
interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

/* eslint-enable camelcase */

/**
 * Handle auth APIs
 */
export abstract class AuthApiService {
  /**
   * Perform login APIs
   * @return {Promise<AuthSessionData>}
   * @param {{email, password, refreshToken}} data
   */
  static async login(data: {
    email?: string;
    password?: string;
    refreshToken?: string;
    loginToken?: string;
    expiresIn?: number;
    otp?: string;
  }): Promise<AuthSessionData> {
    let token = '';
    let refreshToken = '';
    let expiresIn = 0;

    if (!data.loginToken) {
      const formData = new FormData();
      if (data.refreshToken) {
        formData.append('refresh_token', data.refreshToken);
        formData.append('grant_type', 'refresh_token');
      } else {
        formData.append('username', data.email || '');
        formData.append('password', data.password || '');
        formData.append('grant_type', 'password');
      }
      formData.append('client_id', environment.clientId);
      formData.append('client_secret', environment.clientSecret);
      const response = await axios.post<TokenResponse, TokenResponse>(
        ApiUrl.Token,
        formData,
      );
      token = response.access_token;
      refreshToken = response.refresh_token;
      expiresIn = response.expires_in * 1000;
    } else {
      token = data.loginToken;
      expiresIn = data.expiresIn || 900000;
    }
    setApiAuthorization(token, data.otp);
    const list = await axios.get<ServerUser[], ServerUser[]>(ApiUrl.Users);
    const user: User = UserMapper.mapArray(list)[0];
    if (!data.loginToken && user.isCustomer) {
      setApiAuthorization();
      throw new Error(locale('errors.loginAsCustomer'));
    }
    // TODO handle user.firebaseUid
    const firebaseToken = '';
    const expirationDate = new Date(new Date().getTime() + expiresIn);
    return {
      token,
      refreshToken,
      firebaseToken,
      expiresIn,
      expirationDate,
      user,
    };
  }

  /**
   * Revoke auth token
   * @param {string} token
   * @return {Promise<void>}
   */
  static async logout(token: string): Promise<void> {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('client_id', environment.clientId);
    try {
      await axios.post<TokenResponse>(ApiUrl.RevokeToken, formData);
    } catch (error) {
      console.error(error);
    }
  }
}
