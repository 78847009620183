import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorGetRateNumbers,
  settlementCreatorSelector,
  SettlementCreatorSliceState,
} from '../../settlementCreatorSlice';
import { AmountOption } from '../../../../models/AmountOption';
import { Utils } from '../../../../models/Utils';

interface SettlementCreatorRateNumberSetterProps {
  onRateNumberSelected: (numberOfInstallments?: AmountOption) => void;
}

const SettlementCreatorRateNumberSetter = ({
  onRateNumberSelected,
}: SettlementCreatorRateNumberSetterProps) => {
  const dispatch = useDispatch();
  const {
    priceValue,
    depositValue,
    numberOfInstallmentsValue,
    numberOfInstallments,
    numberOfInstallmentsList,
  }: SettlementCreatorSliceState = useSelector(settlementCreatorSelector);
  const [cRateNumber, setCRateNumber] = useState<AmountOption | undefined>(
    numberOfInstallmentsList?.find(rn => rn.id === numberOfInstallments?.id) ||
      numberOfInstallmentsList?.[0],
  );

  useEffect(() => {
    setCRateNumber(
      numberOfInstallmentsList?.find(
        rn => rn.id === numberOfInstallments?.id,
      ) || numberOfInstallmentsList?.[0],
    );
    dispatch(settlementCreatorGetRateNumbers());
  }, []);

  useEffect(() => {
    setCRateNumber(
      numberOfInstallmentsList?.find(
        rn => rn.id === numberOfInstallments?.id,
      ) || numberOfInstallmentsList?.[0],
    );
  }, [numberOfInstallments, numberOfInstallmentsList]);

  const [cRateNumberValue, setCRateNumberValue] = useState<number>(
    numberOfInstallmentsValue || cRateNumber?.amount || 0,
  );

  return (
    <div className="p-2">
      <Typography.Paragraph>
        <pre>{locale('subtitles.numberOfInstallments')}</pre>
      </Typography.Paragraph>
      {!!numberOfInstallmentsList?.length && (
        <Form layout="vertical">
          <Form.Item
            label={locale('labels.numberOfInstallments')}
            initialValue={cRateNumber?.id}
            required
            rules={[{ required: true }]}
            name="amount"
            extra={locale('subtitles.selectContractRateNumber')}
            getValueProps={() => ({ amount: cRateNumber?.id })}
          >
            <Select
              value={cRateNumber?.id}
              placeholder={locale('placeholders.selectRateNumber')}
              onChange={value => {
                const found = numberOfInstallmentsList.find(
                  p => p.id === value,
                );
                setCRateNumber(found);
              }}
            >
              {numberOfInstallmentsList?.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {p.isCustom && locale('labels.other')}
                  {!p.isCustom &&
                    locale('labels.rateNumberWithAmount', [
                      p.amount,
                      Utils.getFormattedPrice(
                        ((priceValue || 0) - (depositValue || 0)) / p.amount,
                      ),
                    ])}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {cRateNumber?.isCustom && (
            <Form.Item
              label={locale('labels.customRateNumber')}
              initialValue={cRateNumberValue}
              required
              rules={[{ required: true }]}
              name="camount"
              extra={locale('subtitles.customRateNumber')}
              getValueProps={() => ({ camount: cRateNumberValue })}
              tooltip={locale('subtitles.customChoice')}
            >
              <InputNumber
                className="w-100"
                value={cRateNumberValue}
                placeholder="2"
                min={0}
                decimalSeparator=","
                onChange={setCRateNumberValue}
              />
            </Form.Item>
          )}
          <Button
            type="primary"
            block
            disabled={!cRateNumber}
            onClick={() =>
              onRateNumberSelected({
                ...(cRateNumber || {}),
                ...(cRateNumber?.isCustom ? { amount: cRateNumberValue } : {}),
              } as AmountOption)
            }
          >
            {locale('actions.confirmRateNumber')}
          </Button>
        </Form>
      )}
      {!numberOfInstallmentsList.length && (
        <>
          <Typography.Paragraph>
            {locale('subtitles.noRatesForCurrentOffer')}
          </Typography.Paragraph>
          <Button type="primary" block onClick={() => onRateNumberSelected()}>
            {locale('actions.goAhead')}
          </Button>
        </>
      )}
    </div>
  );
};
export default SettlementCreatorRateNumberSetter;
