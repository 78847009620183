import React, { useEffect, useState } from 'react';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../../../../../../models/PaymentMethodItem';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import locale from '../../../../../../locale';
import { Utils } from '../../../../../../models/Utils';
import PaymentHandler from '../../../../../../components/paymentHandler/PaymentHandler';
import { Settlement } from '../../../../../../models/Settlement';
import MediaUploader from '../../../../../../components/mediaUploader/MediaUploader';
import { UploadFile } from 'antd/es/upload/interface';
import { MethodType } from '../../../../../../models/PaymentMethodType';
import { useDispatch } from 'react-redux';
import {
  settlementDetailAddAdvance,
  settlementDetailRemovePaymentMethod,
} from '../../../../settlementDetailSlice';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

interface PaymentMethodItemSectionProps {
  canEdit: boolean;
  paymentMethodItem: PaymentMethodItem;
  settlement: Settlement;
  onPay: (option: MethodType, data?: Record<string, any>) => void;
}

/**
 *
 * @constructor
 */
export default function PaymentMethodItemSection({
  paymentMethodItem,
  settlement,
  onPay,
  canEdit,
}: PaymentMethodItemSectionProps) {
  const dispatch = useDispatch();

  const [currentOption, setCurrentOption] = useState<MethodType>(
    paymentMethodItem.paymentMethod?.paymentMethodType?.type || 0,
  );
  const [isEdit, setIsEdit] = useState<boolean>(
    canEdit && !paymentMethodItem.isReady,
  );
  const [files, setFiles] = useState<UploadFile[]>(
    paymentMethodItem.paymentMethod?.attachments?.map(attachment => ({
      uid: `${attachment.id}`,
      url: attachment.file,
      thumbnail: attachment.thumbnailUrl,
      name: attachment.name,
    })) || [],
  );

  useEffect(() => {
    setIsEdit(canEdit && !paymentMethodItem.isReady);
    setFiles(
      paymentMethodItem.paymentMethod?.attachments?.map(attachment => ({
        uid: `${attachment.id}`,
        url: attachment.file,
        thumbUrl: attachment.thumbnailUrl,
        name: attachment.name,
      })) || [],
    );
  }, [canEdit, paymentMethodItem]);

  const renderDescriptions = () => {
    const { paymentMethod: pm } = paymentMethodItem;
    const paidInfo: Record<string, any> = {
      ...(pm?.iban ? { iban: pm?.iban } : {}),
      ...(pm?.bankAccountHolder
        ? { bankAccountHolder: pm?.bankAccountHolder }
        : {}),
      ...(pm?.bankAuthorizationId
        ? { bankAuthorizationId: pm?.bankAuthorizationId }
        : {}),
      ...(pm?.firstName ? { firstName: pm?.firstName } : {}),
      ...(pm?.lastName ? { lastName: pm?.lastName } : {}),
      ...(pm?.fiscalCode ? { fiscalCode: pm?.fiscalCode } : {}),
      ...(pm?.address ? { address: pm?.address } : {}),
      ...(pm?.zipCode ? { zipCode: pm?.zipCode } : {}),
      ...(pm?.city ? { city: pm?.city } : {}),
      ...(pm?.district ? { district: pm?.district } : {}),
      ...(pm?.country ? { country: pm?.country } : {}),
      ...(pm?.last4 ? { cardNumber: pm?.last4 } : {}),
      ...(pm?.otpRequest
        ? {
            otpRequest: `${pm.otpRequest.otpResponse?.code || '--'} | ${
              pm.otpRequest.lastName || '--'
            } ${pm.otpRequest.firstName || '--'} | ${
              pm.otpRequest.phone || '--'
            } | ${pm.signDate ? Utils.getFormattedDate(pm.signDate) : '--'} | ${
              pm.otpRequest.ipAddress || '--'
            }`,
          }
        : {}),
      ...(pm?.stripePaymentMethod
        ? { stripePaymentMethod: pm?.stripePaymentMethod }
        : {}),
    };
    return (
      <Descriptions
        size="small"
        bordered
        className="mb-2"
        column={1}
        title={
          <>
            {locale(`paymentMethodType.${paymentMethodItem.type}`)}
            {paymentMethodItem.mustPayNow && (
              <Tag
                color="gold"
                title={locale('labels.mustPayNow')}
                className="ml-1"
              >
                {locale('labels.mustPayNow')}
              </Tag>
            )}
          </>
        }
        extra={
          canEdit && (
            <Button
              type="link"
              danger
              onClick={async () =>
                (await Utils.askForConfirmation({
                  title: locale('titles.remove'),
                  content: locale('subtitles.removePaymentMethod'),
                  okText: locale('actions.remove'),
                  isDanger: true,
                })) &&
                dispatch(
                  settlementDetailRemovePaymentMethod({
                    paymentMethodItemType: paymentMethodItem.type,
                  }),
                )
              }
            >
              {locale('actions.remove')}
            </Button>
          )
        }
      >
        <Descriptions.Item label={locale('labels.status')}>
          {!paymentMethodItem.mustPayNow && (
            <>
              <CheckCircleOutlined className="text-success mr-1" />
              <Typography.Text>
                {locale('labels.paymentMethodSet')}
              </Typography.Text>
            </>
          )}
          {paymentMethodItem.mustPayNow && paymentMethodItem.isPaid && (
            <>
              <CheckCircleOutlined className="text-success mr-1" />
              <Typography.Text>{locale('labels.paid')}</Typography.Text>
            </>
          )}
          {paymentMethodItem.mustPayNow && !paymentMethodItem.isPaid && (
            <>
              <ClockCircleOutlined className="mr-1" />
              <Typography.Text>
                {locale('labels.paymentPending')}
              </Typography.Text>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.amount')}>
          {Utils.getFormattedPriceWithVat(
            paymentMethodItem.amount,
            settlement.currency,
            settlement.vatPercentage,
          )}
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.paymentMethod')}>
          {locale(
            `paymentMethodOption.${paymentMethodItem.paymentMethod?.paymentMethodType.type}`,
          )}
        </Descriptions.Item>
        {paymentMethodItem.paymentMethod?.paymentDate && (
          <Descriptions.Item label={locale('labels.paymentDate')}>
            {Utils.getFormattedDate(
              paymentMethodItem.paymentMethod.paymentDate,
            )}
          </Descriptions.Item>
        )}
        {Object.keys(paidInfo).map(k => (
          <Descriptions.Item key={k} label={locale(`labels.${k}`)}>
            {paidInfo[k] || '--'}
          </Descriptions.Item>
        ))}
        {!!files?.length && (
          <Descriptions.Item label={locale('labels.attachments')}>
            <MediaUploader
              canEdit={false}
              files={files}
              onFilesChange={setFiles}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  const getActions = () => {
    if (paymentMethodItem.type === PaymentMethodItemType.Advance) {
      return !settlement.depositIsReady && !settlement.advanceIsReady
        ? [
            <Button
              key="delete"
              danger
              onClick={async () =>
                (await Utils.askForConfirmation()) &&
                dispatch(settlementDetailAddAdvance({ amount: 0 }))
              }
            >
              {locale('actions.deleteAdvance')}
            </Button>,
          ]
        : [];
    }
    if (paymentMethodItem.isReady) {
      return [
        <Button type="default" key="cancel" onClick={() => setIsEdit(false)}>
          {locale('actions.cancel')}
        </Button>,
      ];
    }
    return [];
  };

  return (
    <>
      {isEdit && (
        <Card
          key={paymentMethodItem.type}
          size="small"
          type="inner"
          title={
            <>
              {locale(
                `paymentMethodTypeSectionTitle.${paymentMethodItem.type}`,
              )}
              {paymentMethodItem.mustPayNow && (
                <Tag
                  color="gold"
                  title={locale('labels.mustPayNow')}
                  className="ml-1"
                >
                  {locale('labels.mustPayNow')}
                </Tag>
              )}
            </>
          }
          extra={
            canEdit && (
              <>
                {isEdit && (
                  <Typography.Text type="secondary">
                    <b>
                      {Utils.getFormattedPriceWithVat(
                        paymentMethodItem.amount,
                        settlement.currency,
                        settlement.vatPercentage,
                      )}
                    </b>
                  </Typography.Text>
                )}
              </>
            )
          }
          actions={getActions()}
        >
          <Row>
            <Col xs={24} md={12}>
              <Typography.Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: locale(
                      `subtitles.${
                        settlement.vatPercentage
                          ? 'paymentMethod'
                          : 'paymentMethodNoVat'
                      }.${paymentMethodItem.type}`,
                      [
                        Utils.getFormattedPrice(paymentMethodItem.amount),
                        settlement.vatPercentage,
                      ],
                    ),
                  }}
                />
              </Typography.Paragraph>
              <Radio.Group
                onChange={e => setCurrentOption(e.target.value)}
                value={currentOption}
              >
                <Space direction="vertical">
                  <Radio key="otpRequest" value={0}>
                    {locale(`paymentMethodOption.payWithOtp`)}
                  </Radio>
                  {paymentMethodItem.paymentMethodTypes.map(o => (
                    <Radio key={o.type} value={o.type}>
                      {locale(`paymentMethodOption.${o.type}`)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Col>
            <Col xs={24} md={12}>
              <PaymentHandler
                settlement={settlement}
                paymentMethodItem={paymentMethodItem}
                currentOption={currentOption}
                amount={paymentMethodItem.amount}
                onPay={data => onPay(currentOption, data)}
              />
            </Col>
          </Row>
        </Card>
      )}
      {!isEdit && renderDescriptions()}
    </>
  );
}
