import { Mapper } from './Mapper';
import { Contact, ContactMapper, ServerContact } from './Contact';
import { Utils } from './Utils';
import {
  ServerSettlementOffer,
  SettlementOffer,
  SettlementOfferMapper,
} from './SettlementOffer';
import { ServerSettlementDataFieldValue } from './SettlementDataFieldValue';
import {
  ServerSettlementConfig,
  SettlementConfig,
  SettlementConfigMapper,
} from './SettlementConfig';
import {
  ServerSettlementContract,
  SettlementContract,
  SettlementContractMapper,
} from './SettlementContract';
import {
  PaymentMethod,
  PaymentMethodMapper,
  ServerPaymentMethod,
} from './PaymentMethod';
import { Attachment, AttachmentMapper, ServerAttachment } from './Attachment';
import { Account, AccountMapper, ServerAccount } from './Account';

/* eslint-disable camelcase */
export interface SettlementDataSuggestion {
  id?: number;
  data_field: number;
  suggest: string | number | boolean;
}

/* eslint-enable camelcase */
export enum SettlementStatus {
  Created = 0,
  DataFilled = 1,
  DataConfirmed = 2,
  Signed = 3,
  Paid = 4,
  ReadyToFinalize = 5,
  Finalized = 6,
  Approved = 7,
}
export interface Settlement {
  id: number;
  uuid: string;
  name: string;
  account: Account;
  status: SettlementStatus;
  contact: Contact;
  settlementOffers: SettlementOffer[];
  config: SettlementConfig;
  sellerNotes: string;
  customerNotes: string;
  identityDocuments: Attachment[];
  isIdentityDocumentRequired: boolean;
  isSigned: boolean;
  settlementContracts: SettlementContract[];
  seller: number;

  advancePaymentMethod?: PaymentMethod;
  advanceMustPayNow: boolean;
  advanceIsReady: boolean;
  /** If 0 means no advance */
  advanceAmount: number;
  isAdvancePaid: boolean;

  depositPaymentMethod?: PaymentMethod;
  depositMustPayNow: boolean;
  depositIsReady: boolean;
  totalDeposit: number;
  isDepositPaid: boolean;

  installmentsPaymentMethod?: PaymentMethod;
  installmentsMustPayNow: boolean;
  installmentsIsReady: boolean;
  isInstallmentsPaid: boolean;
  /** Amount in currency of installments */
  totalInstallments: number;
  /** Max number of rates */
  maxInstallmentsRate: number;

  totalPrice: number;
  currency: 'EUR';
  vatPercentage: number;
}

/* eslint-disable camelcase */
export interface ServerSettlement {
  id: number;
  uuid: string;
  name: string;
  account: ServerAccount;
  status: SettlementStatus;
  contact: ServerContact;
  settlement_config: ServerSettlementConfig;
  settlementoffer_set: ServerSettlementOffer[];
  datafieldvalue_set: ServerSettlementDataFieldValue[];
  datasuggestion_set: SettlementDataSuggestion[];
  seller_notes?: string;
  customer_notes?: string;
  identity_documents?: ServerAttachment[];
  is_identity_document_required: boolean;
  is_signed: boolean;
  settlementcontract_set: ServerSettlementContract[];
  seller: number;

  advance_payment_method?: ServerPaymentMethod;
  advance_must_pay_now: boolean;
  advance_is_ready: boolean;
  advance_amount: number;
  is_advance_paid: boolean;

  deposit_payment_method?: ServerPaymentMethod;
  deposit_must_pay_now: boolean;
  deposit_is_ready: boolean;
  total_deposit: number;
  is_deposit_paid: boolean;

  installments_payment_method?: ServerPaymentMethod;
  installments_must_pay_now: boolean;
  installments_is_ready: boolean;
  is_installments_paid: boolean;
  total_installments: number;
  max_installments_rate: number;
  installments_min_date?: string;
  installments_max_date?: string;
  installments_start_date?: string;

  total_price: number;
  currency: 'EUR';
  vat_percentage: number;
}

/* eslint-enable camelcase */

/**
 * Map Settlement model
 */
export abstract class SettlementMapper
  implements Mapper<Settlement, ServerSettlement> {
  /**
   * @param {Partial<ServerSettlement>} o
   * @return {Settlement}
   */
  static map(o: Partial<ServerSettlement>): Settlement {
    /** assign values & suggest in data */

    let config: SettlementConfig | undefined;
    if (o.settlement_config) {
      config = SettlementConfigMapper.map(o.settlement_config);
      const values: Record<string, any> = {};
      const suggests: Record<string, any> = {};
      o.datafieldvalue_set?.forEach(v => (values[v.data_field] = v.value));
      o.datasuggestion_set?.forEach(s => (suggests[s.data_field] = s.suggest));
      config.data = {
        ...config.data,
        sections:
          config.data.sections?.map(s => ({
            ...s,
            fields:
              s.fields?.map(f => {
                if (!Utils.isNotDefined(values[f.id])) {
                  f.value = Utils.getSettlementDataFieldValue(
                    values[f.id],
                    f.type,
                  );
                }
                if (!Utils.isNotDefined(suggests[f.id])) {
                  f.suggest = suggests[f.id];
                }
                return f;
              }) || [],
          })) || [],
      };
    }
    return <Settlement>{
      id: o.id,
      uuid: o.uuid,
      name: o.name,
      account: o.account ? AccountMapper.map(o.account) : undefined,
      contact: o.contact ? ContactMapper.map(o.contact) : undefined,
      settlementOffers: o.settlementoffer_set
        ? SettlementOfferMapper.mapArray(o.settlementoffer_set)
        : [],
      config,
      sellerNotes: o.seller_notes || '',
      customerNotes: o.customer_notes || '',
      identityDocuments: o.identity_documents
        ? AttachmentMapper.mapArray(o.identity_documents)
        : [],
      isIdentityDocumentRequired: !!o.is_identity_document_required,
      status: o.status,
      isSigned: o.is_signed,
      settlementContracts: o.settlementcontract_set
        ? SettlementContractMapper.mapArray(o.settlementcontract_set)
        : undefined,
      seller: o.seller,
      advancePaymentMethod: o.advance_payment_method
        ? PaymentMethodMapper.map(o.advance_payment_method)
        : undefined,
      advanceMustPayNow: o.advance_must_pay_now,
      advanceIsReady: o.advance_is_ready,
      advanceAmount: o.advance_amount || 0,
      isAdvancePaid: o.is_advance_paid,
      depositPaymentMethod: o.deposit_payment_method
        ? PaymentMethodMapper.map(o.deposit_payment_method)
        : undefined,
      depositMustPayNow: o.deposit_must_pay_now,
      depositIsReady: o.deposit_is_ready,
      totalDeposit: o.total_deposit || 0,
      isDepositPaid: o.is_deposit_paid,
      installmentsPaymentMethod: o.installments_payment_method
        ? PaymentMethodMapper.map(o.installments_payment_method)
        : undefined,
      installmentsMustPayNow: o.installments_must_pay_now,
      installmentsIsReady: o.installments_is_ready,
      isInstallmentsPaid: o.is_installments_paid,
      totalInstallments: o.total_installments || 0,
      maxInstallmentsRate: o.max_installments_rate || 0,
      totalPrice: o.total_price || 0,
      currency: o.currency,
      vatPercentage: o.vat_percentage,
    };
  }

  /**
   * @param {Partial<Settlement>} o
   * @return {ServerSettlement}
   */
  static mapReverse(o: Partial<Settlement>): ServerSettlement {
    return <ServerSettlement>{
      id: o.id,
      uuid: o.uuid,
      name: o.name,
      account: o.account ? AccountMapper.mapReverse(o.account) : undefined,
      contact: o.contact ? ContactMapper.mapReverse(o.contact) : undefined,
      settlementoffer_set: o.settlementOffers
        ? SettlementOfferMapper.mapReverseArray(o.settlementOffers)
        : [],
      settlement_config: o.config
        ? SettlementConfigMapper.mapReverse(o.config)
        : null,
      seller_notes: o.sellerNotes,
      customer_notes: o.customerNotes,
      identity_documents: o.identityDocuments
        ? AttachmentMapper.mapReverseArray(o.identityDocuments)
        : [],
      is_identity_document_required: o.isIdentityDocumentRequired,
      status: o.status,
      is_signed: o.isSigned,
      settlementcontract_set: o.settlementContracts
        ? SettlementContractMapper.mapReverseArray(o.settlementContracts)
        : undefined,
      seller: o.seller,
      advance_payment_method: o.advancePaymentMethod
        ? PaymentMethodMapper.mapReverse(o.advancePaymentMethod)
        : undefined,
      advance_must_pay_now: o.advanceMustPayNow,
      advance_is_ready: o.advanceIsReady,
      advance_amount: o.advanceAmount,
      deposit_payment_method: o.depositPaymentMethod
        ? PaymentMethodMapper.mapReverse(o.depositPaymentMethod)
        : undefined,
      deposit_must_pay_now: o.depositMustPayNow,
      deposit_is_ready: o.depositIsReady,
      total_deposit: o.totalDeposit,
      installments_payment_method: o.installmentsPaymentMethod
        ? PaymentMethodMapper.mapReverse(o.installmentsPaymentMethod)
        : undefined,
      installments_must_pay_now: o.installmentsMustPayNow,
      installments_is_ready: o.installmentsIsReady,
      is_installments_paid: o.isInstallmentsPaid,
      total_installments: o.totalInstallments,
      max_installments_rate: o.maxInstallmentsRate,
      total_price: o.totalPrice,
      currency: o.currency,
      vat_percentage: o.vatPercentage,
    };
  }

  /**
   * @param {ServerSettlement[]} models
   * @return {Settlement[]}
   */
  static mapArray(models: ServerSettlement[]): Settlement[] {
    return models.map(m => SettlementMapper.map(m));
  }

  /**
   * @param {Settlement[]} models
   * @return {ServerSettlement[]}
   */
  static mapReverseArray(models: Settlement[]): ServerSettlement[] {
    return models.map(m => SettlementMapper.mapReverse(m));
  }
}
