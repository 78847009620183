import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import locale from '../../../../locale';
import { SettlementDataSection } from '../../../settlementDetail/components/settlementDataSection/SettlementDataSection';
import { useSelector } from 'react-redux';
import { settlementCloserSelector } from '../../settlementCloserSlice';
import { DownloadOutlined } from '@ant-design/icons';

/**
 * Final Overview of the finalized settlement
 * @constructor
 */
export default function CloserFinalOverview() {
  const { model } = useSelector(settlementCloserSelector);
  const [downloads, setDownloads] = useState<{ link: string; name: string }[]>(
    [],
  );

  useEffect(() => {
    const nextDownloads: { link: string; name: string }[] = [];
    model?.settlementContracts?.forEach(sc => {
      const found = model?.settlementOffers.find(
        so => so.offer.contract.id === sc.contractId,
      );
      nextDownloads.push({ link: sc.pdf, name: found?.offer.name || '' });
    });
    setDownloads(nextDownloads);
  }, [model]);

  if (!model) {
    return null;
  }

  return (
    <Row gutter={[0, 16]}>
      <Col xs={24}>
        <Card
          size="small"
          actions={[
            ...(downloads?.map(el => (
              <Button
                key={`download_${el.link}`}
                type="primary"
                href={el.link}
                target="_blank"
                icon={<DownloadOutlined />}
              >
                {locale('actions.downloadContract')} {`"${el.name}"`}
              </Button>
            )) || []),
          ]}
        >
          <Typography.Title level={3} className="mb-1">
            {locale('titles.contractFinalized')}
          </Typography.Title>
          <Typography.Paragraph type="secondary" className="mb-0">
            {locale('subtitles.contractFinalized')}
          </Typography.Paragraph>
        </Card>
      </Col>
      <Col xs={24}>
        <Card size="small" title={locale('titles.contractData')}>
          <SettlementDataSection
            settlement={model}
            onSubmit={() => undefined}
            onConfirm={() => undefined}
            canEdit={false}
            isCustomer
          />
        </Card>
      </Col>
    </Row>
  );
}
