import React from 'react';
import environment from '../../../../environment';
import styles from './Footer.module.scss';
import { Layout } from 'antd';

/**
 *
 * @constructor
 */
export function Footer() {
  return (
    <Layout.Footer className={styles.footer}>
      {environment.appName} v{process.env.REACT_APP_VERSION} ©
      {new Date().getFullYear()} by Reddoak
    </Layout.Footer>
  );
}
