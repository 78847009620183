import { Md5 } from 'md5-typescript';
import { User, UserMapper } from './User';
import { Telephone } from './Telephone';

export interface AuthSessionData {
  token: string;
  firebaseToken: string;
  refreshToken: string;
  expiresIn: number;
  expirationDate: Date;
  user: User;
}

export interface OtpSessionData {
  otp: string;
  accessToken: string;
  firstName: string;
  lastName: string;
  businessname: string;
  phone: Telephone;
  expirationDate?: Date;
  request?: number;
}

/**
 *
 */
export abstract class SessionService {
  private static SESSION_MD5 = 'csMetaKey';
  private static SESSION = 'csMeta';
  private static OTP_SESSION_MD5 = 'csOtpKey';
  private static OTP_SESSION = 'csOtp';

  /**
   * @return {boolean}
   */
  static isLogged(): boolean {
    return !!SessionService.getSession().user;
  }

  /**
   * @return {AuthSessionData}
   */
  static getSession(): AuthSessionData {
    try {
      const d: AuthSessionData = JSON.parse(
        localStorage.getItem(SessionService.SESSION) || '{}',
      );
      if (
        localStorage.getItem(SessionService.SESSION_MD5) !==
        Md5.init('#$/N0nPu01Fr3g4r3R3dd0@k/*@' + Md5.init(d))
      ) {
        throw new Error('Manipulated session');
      }
      d.user = UserMapper.map(d.user);
      d.expirationDate = new Date(d.expirationDate);
      d.expiresIn = +d.expiresIn;
      return d;
    } catch (e) {
      SessionService.clearSession();
      return {} as AuthSessionData;
    }
  }

  /**
   *
   * @param {AuthSessionData} data
   */
  static setSession(data: AuthSessionData) {
    const d: string = JSON.stringify({
      ...data,
      user: data.user ? UserMapper.mapReverse(data.user) : null,
      expirationDate: data.expirationDate?.toISOString(),
    });
    localStorage.setItem(
      SessionService.SESSION_MD5,
      Md5.init('#$/N0nPu01Fr3g4r3R3dd0@k/*@' + Md5.init(d)),
    );
    localStorage.setItem(SessionService.SESSION, d);
  }

  /**
   * @return {OtpSessionData}
   */
  static getOtpSession(): OtpSessionData {
    try {
      const d = JSON.parse(
        localStorage.getItem(SessionService.OTP_SESSION) || '{}',
      );
      if (
        localStorage.getItem(SessionService.OTP_SESSION_MD5) !==
        Md5.init('#$/N0nPu01Fr3g4r3R3dd0@k/*@' + Md5.init(d))
      ) {
        throw new Error('Manipulated session');
      }
      d.expirationDate = d.expirationDate
        ? new Date(d.expirationDate)
        : undefined;
      return d;
    } catch (e) {
      SessionService.clearOtpSession();
      return {} as OtpSessionData;
    }
  }

  /**
   *
   * @param {OtpSessionData} data
   */
  static setOtpSession(data: OtpSessionData) {
    const d: string = JSON.stringify({
      otp: data.otp,
      accessToken: data.accessToken,
      firstName: data.firstName,
      lastName: data.lastName,
      businessname: data.businessname,
      phone: data.phone,
      request: data.request,
      expirationDate: data.expirationDate
        ? data.expirationDate.toISOString()
        : undefined,
    });

    localStorage.setItem(
      SessionService.OTP_SESSION_MD5,
      Md5.init('#$/N0nPu01Fr3g4r3R3dd0@k/*@' + Md5.init(d)),
    );
    localStorage.setItem(SessionService.OTP_SESSION, d);
  }

  /**
   *
   */
  static clearOtpSession() {
    localStorage.removeItem(SessionService.OTP_SESSION);
    localStorage.removeItem(SessionService.OTP_SESSION_MD5);
  }

  /**
   *
   */
  static clearSession() {
    localStorage.clear();
  }
}
