import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Settlement, SettlementStatus } from '../../models/Settlement';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../../models/PaymentMethodItem';
import { loaderActions } from '../loader/loaderSlice';
import { SettlementApiService } from '../../services/SettlementApiService';
import { message } from 'antd';
import locale from '../../locale';
import { ContractBlock } from '../../models/ContractBlock';
import { SettlementDataFieldValue } from '../../models/SettlementDataFieldValue';
import { PaymentMethod } from '../../models/PaymentMethod';
import { UploadFile } from 'antd/es/upload/interface';
import { settlementDetailGetById } from '../settlementDetail/settlementDetailSlice';
import { MethodType } from '../../models/PaymentMethodType';

export const settlementCloserGetById = createAsyncThunk(
  'settlementCloser/getById',
  async (payload: { id: number }, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await SettlementApiService.getById(payload.id);
      dispatch(loaderActions.decrement());
      return model;
    } catch (e) {
      message.error(locale('errors.getSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCloserGetByUuid = createAsyncThunk(
  'settlementCloser/getByUuid',
  async (payload: { uuid: string }, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await SettlementApiService.getPublicSettlementByUuid(
        payload.uuid,
      );
      dispatch(loaderActions.decrement());
      return model;
    } catch (e) {
      message.error(locale('errors.getSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCloserSignBlock = createAsyncThunk(
  'settlementCloser/signBlock',
  async (
    { block: { id: blockId } }: { block: ContractBlock },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const {
        model: { id: settlementId, settlementContracts, settlementOffers },
      }: { model: Settlement } = (<RootState>getState()).settlementCloser;
      const contract = settlementOffers.find(o =>
        o.offer?.contract?.blocks?.some(b => b.id === blockId),
      )?.offer.contract;
      if (contract) {
        const settlementContract = settlementContracts.find(
          sc => sc.contractId === contract.id,
        );
        if (settlementContract) {
          const settlementContractBlockId = settlementContract.settlementContractBlocks.find(
            scb => scb.contractBlockId === blockId,
          )?.id;
          if (settlementContractBlockId) {
            const sign = await SettlementApiService.signBlock(
              settlementId,
              settlementContractBlockId,
              blockId,
            );
            dispatch(settlementCloserGetById({ id: settlementId }));
            dispatch(loaderActions.decrement());
            return sign;
          }
          throw new Error('no settlement contract block id found');
        }
        throw new Error('no settlement contract id found');
      }
      throw new Error('no settlement contract found');
    } catch (e) {
      console.log(e);
      message.error(locale('errors.signBlock'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCloserUpdateAndConfirmData = createAsyncThunk(
  'settlementCloser/updateAndConfirmData',
  async (
    { dataFieldValues }: { dataFieldValues: SettlementDataFieldValue[] },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const { id, status } = (<RootState>getState()).settlementCloser.model;
      if (status < SettlementStatus.DataFilled) {
        await SettlementApiService.updateData(id, dataFieldValues);
      } else {
        await SettlementApiService.confirmData(id);
      }
      dispatch(settlementCloserGetById({ id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updateSettlement'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserGetPaymentMethodItems = createAsyncThunk(
  'settlementCloser/getPaymentMethodItems',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(loaderActions.increment());
      const {
        auth: {
          user: { isCustomer },
        },
        settlementCloser: { model },
      } = <RootState>getState();
      const res = await SettlementApiService.getPaymentMethodItems(
        model,
        isCustomer,
      );
      dispatch(loaderActions.decrement());
      return res;
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.fetchList'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserGetPaymentMethods = createAsyncThunk(
  'settlementCloser/getPaymentMethods',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(loaderActions.increment());
      const {
        model: {
          contact: { id },
        },
      } = (<RootState>getState()).settlementCloser;
      const paymentMethods: PaymentMethod[] = await SettlementApiService.getPaymentMethods(
        id,
      );
      dispatch(loaderActions.decrement());
      return { paymentMethods };
    } catch (e) {
      dispatch(loaderActions.decrement());
      console.log(locale('errors.getPaymentMethods'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserAddPaymentMethodStripe = createAsyncThunk(
  'settlementCloser/addPaymentMethodStripe',
  async (
    {
      idStripePaymentMethod,
      idPaymentMethodType,
      paymentMethodType,
      paymentMethodItemTypes,
      mustPayNow,
    }: {
      idStripePaymentMethod: string;
      idPaymentMethodType: number;
      paymentMethodType: MethodType;
      paymentMethodItemTypes: PaymentMethodItemType[];
      mustPayNow: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const {
        model: { id },
      } = (<RootState>getState()).settlementCloser;
      const paymentMethod: PaymentMethod = await SettlementApiService.addPaymentMethod(
        id,
        idPaymentMethodType,
        { stripe_payment_method_id: idStripePaymentMethod },
      );

      await dispatch(
        settlementCloserSetPaymentMethodStripe({
          paymentMethod,
          paymentMethodType,
          paymentMethodItemTypes,
          mustPayNow,
        }),
      );

      dispatch(loaderActions.decrement());
      return paymentMethod;
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updatePaymentMethod'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserSetPaymentMethodStripe = createAsyncThunk(
  'settlementCloser/settlementCloserSetPaymentMethodStripe',
  async (
    {
      paymentMethod,
      paymentMethodType,
      paymentMethodItemTypes,
      mustPayNow,
    }: {
      paymentMethod: PaymentMethod;
      paymentMethodType: MethodType;
      paymentMethodItemTypes: PaymentMethodItemType[];
      mustPayNow: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const {
        model: { id },
      } = (<RootState>getState()).settlementCloser;
      await SettlementApiService.setPaymentMethods(
        id,
        paymentMethod.id,
        paymentMethodItemTypes,
      );
      if (mustPayNow) {
        dispatch(
          settlementCloserPayNow({
            stripePaymentMethodId: `${paymentMethod.stripePaymentMethod}`,
            paymentMethodItemTypes,
            paymentMethodType,
          }),
        );
      } else {
        message.success(locale('messages.paymentMethodUpdated'));
        dispatch(settlementCloserGetById({ id }));
      }
      dispatch(loaderActions.decrement());
      return paymentMethod;
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updatePaymentMethod'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserAddPaymentMethodSepaOnline = createAsyncThunk(
  'settlementCloser/addPaymentMethodSepaOnline',
  async (
    {
      idPaymentMethodType,
      paymentMethodItemTypes,
      partialPaymentMethod,
    }: {
      idPaymentMethodType: number;
      paymentMethodItemTypes: PaymentMethodItemType[];
      partialPaymentMethod: Partial<PaymentMethod>;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const { id } = (<RootState>getState()).settlementCloser.model;
      const paymentMethod: PaymentMethod = await SettlementApiService.addPaymentMethodSepaOnline(
        id,
        idPaymentMethodType,
        partialPaymentMethod,
      );
      if (paymentMethodItemTypes.length) {
        await SettlementApiService.setPaymentMethods(
          id,
          paymentMethod.id,
          paymentMethodItemTypes,
        );
      }
      message.success(locale('messages.paymentMethodUpdated'));
      dispatch(settlementCloserGetById({ id }));
      dispatch(loaderActions.decrement());
      return paymentMethod;
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updatePaymentMethod'));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserPayNow = createAsyncThunk(
  'settlementCloser/payNow',
  async (
    {
      stripePaymentMethodId,
      paymentMethodItemTypes,
      paymentMethodType,
    }: {
      stripePaymentMethodId: string;
      paymentMethodItemTypes: PaymentMethodItemType[];
      paymentMethodType: MethodType;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const {
        model: { id },
      } = (<RootState>getState()).settlementCloser;
      const {
        clientSecret,
      }: { clientSecret: string } = await SettlementApiService.payNow(
        id,
        stripePaymentMethodId,
        paymentMethodItemTypes,
      );
      dispatch(loaderActions.decrement());
      return { clientSecret, paymentMethodType };
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.paymentFailed', ['']));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserDeleteAttachment = createAsyncThunk(
  'settlementCloser/deleteAttachment',
  async ({ id }: { id: number }, { dispatch, getState, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    const { id: sId } = (<RootState>getState()).settlementCloser.model;
    try {
      await SettlementApiService.deleteAttachment(sId, id);
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.deleteAttachment'));
      dispatch(loaderActions.decrement());
      dispatch(settlementDetailGetById({ id: sId }));
      return rejectWithValue({});
    }
  },
);

export const settlementCloserFinalize = createAsyncThunk(
  'settlementCloser/finalize',
  async (
    { files, notes }: { files: UploadFile[]; notes: string },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementCloser.model;

      files.length && (await SettlementApiService.uploadAttachments(id, files));
      await SettlementApiService.finalize(id, notes);

      dispatch(loaderActions.decrement());
      dispatch(settlementCloserGetById({ id }));
    } catch (e) {
      dispatch(loaderActions.decrement());
      message.error(locale('errors.finalize'));
      return rejectWithValue({});
    }
  },
);

export interface SettlementCloserSliceState {
  notFound: boolean;
  step: number;
  model?: Settlement;
  paymentMethod?: PaymentMethod;
  paymentMethods: PaymentMethod[];
  stripeSecret?: { clientSecret: string; methodType: MethodType };
  paymentMethodItems: PaymentMethodItem[];
}

const initialState: SettlementCloserSliceState = {
  notFound: false,
  step: -1,
  model: undefined,
  paymentMethod: undefined,
  paymentMethods: [],
  stripeSecret: undefined,
  paymentMethodItems: [],
};

export const settlementCloserSlice = createSlice({
  name: 'settlementCloser',
  initialState,
  reducers: {
    setStep(state, { payload }) {
      state.step = payload;
    },
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${settlementCloserGetById.fulfilled}`]: (state, { payload }) => {
      state.notFound = false;
      state.model = payload;
    },
    [`${settlementCloserGetByUuid.fulfilled}`]: (state, { payload }) => {
      state.notFound = false;
      state.model = payload;
    },
    [`${settlementCloserGetById.rejected}`]: state => {
      state.notFound = true;
      state.model = undefined;
    },
    [`${settlementCloserGetByUuid.rejected}`]: state => {
      state.notFound = true;
      state.model = undefined;
    },
    [`${settlementCloserGetPaymentMethodItems.fulfilled}`]: (
      state,
      { payload },
    ) => {
      state.paymentMethodItems = payload;
    },
    [`${settlementCloserAddPaymentMethodStripe.fulfilled}`]: (
      state,
      { payload },
    ) => {
      state.paymentMethod = payload;
    },
    [`${settlementCloserSetPaymentMethodStripe.fulfilled}`]: (
      state,
      { payload },
    ) => {
      state.paymentMethod = payload;
    },
    [`${settlementCloserPayNow.fulfilled}`]: (state, { payload }) => {
      state.stripeSecret = payload;
    },
    [`${settlementCloserGetPaymentMethods.fulfilled}`]: (
      state,
      { payload: { paymentMethods } },
    ) => {
      state.paymentMethods = paymentMethods;
    },
    [`${settlementCloserGetPaymentMethods.rejected}`]: state => {
      state.paymentMethods = [];
    },
  },
});

export const settlementCloserSelector = (
  state: RootState,
): SettlementCloserSliceState => state.settlementCloser;
export const settlementCloserActions = settlementCloserSlice.actions;
