import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import locale from '../../../../locale';
import { Settlement } from '../../../../models/Settlement';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../../../../models/PaymentMethodItem';
import PaymentMethodItemSection from './components/paymentMethodSection/PaymentMethodItemSection';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { MethodType } from '../../../../models/PaymentMethodType';
import { AddAdvanceSection } from './components/addAdvanceSection/AddAdvanceSection';
import { settlementDetailGetPaymentMethods } from '../../settlementDetailSlice';
import { useDispatch } from 'react-redux';
import StripeCardModal from '../stripeCardModal/StripeCardModal';
import StripeIbanModal from '../stripeIbanModal/stripeIbanModal';
import InstallmentsMultiplePaymentMethodSection from './components/paymentMethodSection/InstallmentsMultiplePaymentMethodSection';

interface PaymentMethodItemsSectionProps {
  canEdit: boolean;
  settlement: Settlement;
  paymentMethodItems: PaymentMethodItem[];
  onPay: (
    paymentMethod: PaymentMethodItem,
    option: MethodType,
    data?: Record<string, any>,
  ) => void;
}

/**
 *
 * @param {PaymentMethodItemsSectionProps} props
 * @constructor
 */
export function PaymentMethodItemsSection({
  settlement,
  paymentMethodItems,
  onPay,
  canEdit,
}: PaymentMethodItemsSectionProps) {
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [hasAdvance, setHasAdvance] = useState<boolean>(
    paymentMethodItems.some(pm => pm.type === PaymentMethodItemType.Advance),
  );

  useEffect(
    () =>
      setHasAdvance(
        paymentMethodItems.some(
          pm => pm.type === PaymentMethodItemType.Advance,
        ),
      ),
    [paymentMethodItems],
  );

  useEffect(() => {
    dispatch(settlementDetailGetPaymentMethods());
  }, [settlement]);
  return settlement ? (
    <Card
      size="small"
      title={
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="cursor-pointer"
        >
          <Button
            size="small"
            type="text"
            className="mr-1"
            icon={collapsed ? <DownOutlined /> : <UpOutlined />}
          />
          {locale('titles.paymentMethods')}
        </div>
      }
    >
      {!collapsed && (
        <Row justify="space-between" gutter={[8, 16]}>
          {settlement.totalPrice === 0 && (
            <Col xs={24}>
              <Typography.Paragraph>
                {locale('subtitles.nothingToPay')}
              </Typography.Paragraph>
            </Col>
          )}
          {!hasAdvance &&
            !settlement.depositIsReady &&
            settlement.totalDeposit > 0 && (
              <Col xs={24}>
                <AddAdvanceSection depositAmount={settlement.totalDeposit} />
              </Col>
            )}
          {paymentMethodItems?.map(item => {
            if (item.type === PaymentMethodItemType.Installments) {
              // TODO: change to support multiple settlementOffers
              if (settlement.settlementOffers[0].hasCustomPaymentMethods) {
                return (
                  <Col xs={24} key={item.type}>
                    <InstallmentsMultiplePaymentMethodSection
                      settlement={settlement}
                      paymentMethodItem={item}
                      onPay={(option, data) => onPay(item, option, data)}
                      canEdit={canEdit}
                    />
                  </Col>
                );
              } else {
                return (
                  <Col xs={24} key={item.type}>
                    <PaymentMethodItemSection
                      settlement={settlement}
                      paymentMethodItem={item}
                      onPay={(option, data) => onPay(item, option, data)}
                      canEdit={canEdit}
                    />
                  </Col>
                );
              }
            } else {
              return (
                <Col xs={24} key={item.type}>
                  <PaymentMethodItemSection
                    settlement={settlement}
                    paymentMethodItem={item}
                    onPay={(option, data) => onPay(item, option, data)}
                    canEdit={canEdit}
                  />
                </Col>
              );
            }
          })}
          {!!paymentMethodItems?.length && <StripeCardModal onPay={onPay} />}
          {!!paymentMethodItems?.length && <StripeIbanModal onPay={onPay} />}
        </Row>
      )}
    </Card>
  ) : null;
}
