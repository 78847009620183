import { Mapper } from './Mapper';
import { MethodType } from './PaymentMethodType';

export interface PaymentConfig {
  id: number;
  amount: number;
  paymentMethodType: MethodType;
  paymentMethodToUse: number;
  settlementOfferId: number;
  discount: number;
  paymentDate?: Date;
}

/* eslint-disable camelcase */
export interface ServerPaymentConfig {
  id: number;
  amount: number;
  payment_method: MethodType;
  payment_method_to_use: number;
  settlement_offer: number;
  discount: number;
  payment_date?: string;
}

/* eslint-enable camelcase */

/**
 * Map PaymentConfig model
 */
export abstract class PaymentConfigMapper
  implements Mapper<PaymentConfig, ServerPaymentConfig> {
  /**
   * @param {Partial<ServerPaymentConfig>} o
   * @return {PaymentConfig}
   */
  static map(o: Partial<ServerPaymentConfig>): PaymentConfig {
    return <PaymentConfig>{
      id: o.id,
      amount: o.amount,
      paymentMethodType: o.payment_method,
      paymentMethodToUse: o.payment_method_to_use,
      settlementOfferId: o.settlement_offer,
      discount: o.discount,
      paymentDate: o.payment_date ? new Date(o.payment_date) : undefined,
    };
  }

  /**
   * @param {Partial<PaymentConfig>} o
   * @return {ServerPaymentConfig}
   */
  static mapReverse(o: Partial<PaymentConfig>): ServerPaymentConfig {
    return <ServerPaymentConfig>{
      id: o.id,
      amount: o?.amount?.toFixed(0) || 0,
      payment_method: o.paymentMethodType,
      payment_method_to_use: o.paymentMethodToUse,
      settlement_offer: o.settlementOfferId,
      discount: o.discount,
      payment_date: o.paymentDate?.toISOString().split('T')[0] || null,
    };
  }

  /**
   * @param {ServerPaymentConfig[]} models
   * @return {PaymentConfig[]}
   */
  static mapArray(models: ServerPaymentConfig[]): PaymentConfig[] {
    return models.map(m => PaymentConfigMapper.map(m));
  }

  /**
   * @param {PaymentConfig[]} models
   * @return {ServerPaymentConfig[]}
   */
  static mapReverseArray(models: PaymentConfig[]): ServerPaymentConfig[] {
    return models.map(m => PaymentConfigMapper.mapReverse(m));
  }
}
