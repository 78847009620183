import React, { useEffect } from 'react';
import { message, Modal } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { MethodType } from '../../../../models/PaymentMethodType';
import {
  settlementDetailActions,
  settlementDetailGetById,
  settlementDetailSelector,
} from '../../settlementDetailSlice';
import { PaymentMethodItem } from '../../../../models/PaymentMethodItem';
import IbanForm from '../../../../components/IbanForm/IbanForm';

interface StripeIbanModalProps {
  onPay: (
    pmItem: PaymentMethodItem,
    currentOption: MethodType,
    data?: Record<string, any>,
  ) => void;
}

/**
 * Modal to set Stripe Card
 * @constructor
 */
export default function StripeIbanModal({ onPay }: StripeIbanModalProps) {
  const dispatch = useDispatch();

  const {
    model,
    isStripeIbanModalVisible: isVisible,
    currentStripeIbanModalItem: pmItem,
    clientSecret,
  } = useSelector(settlementDetailSelector);

  useEffect(() => {
    if (isVisible && clientSecret) {
      model?.id && dispatch(settlementDetailGetById({ id: model.id }));
      message.success(locale('messages.successfullyPaid'));
      dispatch(settlementDetailActions.closeStripeIbanModal());
    }
  }, [clientSecret, isVisible]);

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      title={locale(
        `titles.${pmItem?.mustPayNow ? 'payWithSDDStripe' : 'setRidInfo'}`,
      )}
      onCancel={() => dispatch(settlementDetailActions.closeStripeIbanModal())}
      footer={null}
    >
      {model && (
        <IbanForm
          onIbanFormSubmit={stripePaymentMethodId => {
            pmItem &&
              onPay(pmItem, MethodType.StripeSepaDebit, {
                stripe_payment_method_id: stripePaymentMethodId,
              });
          }}
          countryCode="IT"
          mustPayNow={pmItem?.mustPayNow || false}
          idSettlement={model?.id}
        />
      )}
    </Modal>
  );
}
