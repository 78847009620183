import React, { useState } from 'react';
import { Button, Card } from 'antd';
import locale from '../../../../locale';
import { Settlement } from '../../../../models/Settlement';
import SettlementSharer from '../settlementSharer/SettlementSharer';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';

interface ShareSectionProps {
  step: number;
  settlement?: Settlement;
}

/**
 *
 * @param {ShareSectionProps} props
 * @constructor
 */
export function ShareSection({ settlement }: ShareSectionProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return settlement ? (
    <Card
      size="small"
      className={classNames({ 'h-100': !collapsed })}
      title={
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="cursor-pointer"
        >
          <Button
            size="small"
            type="text"
            className="mr-1"
            icon={collapsed ? <DownOutlined /> : <UpOutlined />}
          />
          {locale('titles.shareWithContact')}
        </div>
      }
    >
      {!collapsed && (
        <SettlementSharer
          title={locale('titles.shareWithContact')}
          subtitle={locale('subtitles.shareWithContact', [
            settlement.contact?.fullName || locale('placeholders.contact'),
          ])}
          settlement={settlement}
        />
      )}
    </Card>
  ) : null;
}
