import React, { useState } from 'react';
import locale from '../../../../locale';
import { Button, Card, Col, Row } from 'antd';
import { SignSection } from './signSection/SignSection';
import { Settlement, SettlementStatus } from '../../../../models/Settlement';
import { UploadFile } from 'antd/es/upload/interface';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

interface SignsSectionProps {
  settlement: Settlement;
  step: number;
  onDeleteSign: (idSettlementContract: number) => void;
  onDeleteSettlementContractAttachment: (
    idSettlementContract: number,
    id: number,
  ) => void;
  onSign: (
    signedDate: Date,
    files: UploadFile[],
    idSettlementContract: number,
  ) => void;
}
/**
 *
 * @constructor
 */
export default function SignsSection({
  settlement,
  step,
  onDeleteSign,
  onDeleteSettlementContractAttachment,
  onSign,
}: SignsSectionProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const hasManyContracts = settlement?.settlementContracts?.length > 1;

  return (
    <Card
      title={
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="cursor-pointer"
        >
          <Button
            size="small"
            type="text"
            className="mr-1"
            icon={collapsed ? <DownOutlined /> : <UpOutlined />}
          />
          {locale('titles.sign')}
        </div>
      }
      size="small"
    >
      {!collapsed && (
        <Row gutter={[8, 16]}>
          {settlement.settlementContracts?.map(sc => (
            <Col key={sc.id} xs={24} xxl={hasManyContracts ? 12 : 24}>
              <SignSection
                step={step}
                settlement={settlement}
                onDeleteSign={() => onDeleteSign(sc.id)}
                onDeleteAttachment={id =>
                  onDeleteSettlementContractAttachment(sc.id, id)
                }
                onSign={(signedDate, files) =>
                  onSign(
                    signedDate,
                    files?.filter(f =>
                      sc.rawAttachments?.every(a => a.id !== +f.uid),
                    ) || [],
                    sc.id,
                  )
                }
                canEdit={settlement.status < SettlementStatus.Finalized}
                settlementContract={sc}
              />
            </Col>
          ))}
        </Row>
      )}
    </Card>
  );
}
