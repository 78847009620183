import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { Contact } from '../../models/Contact';
import {
  contactDetailActions,
  contactDetailCreateContact,
  contactDetailUpdateContact,
} from './contactDetailSlice';
import { Button, Col, Form, Input, Row } from 'antd';
import { Utils } from '../../models/Utils';
import { FieldData } from '../../models/FieldData';
import locale from '../../locale';
import { TelephoneField } from '../../components/telephoneField/TelephoneField';
import { Telephone } from '../../models/Telephone';

interface ContactDetailProps {
  isEdit: boolean;
  model?: Contact;
  onCreate?: (data: Partial<Contact>) => void;
  onUpdate?: (contact: Contact) => void;
  clearState?: () => void;
}

interface ContactDetailState {
  fields: FieldData[];
  isFormValid: boolean;
}

/**
 *
 */
class ContactDetail extends React.Component<
  ContactDetailProps,
  ContactDetailState
> {
  /**
   * @param {ContactDetailProps} props
   */
  constructor(props: ContactDetailProps) {
    super(props);
    const fields = this.getFields();
    this.state = {
      fields,
      isFormValid: this.getIsFormValid(fields),
    };
  }

  /**
   * @param {Readonly<ContactDetailProps>} prevProps
   */
  componentDidUpdate(prevProps: Readonly<ContactDetailProps>) {
    const { model } = this.props;
    if (!Utils.isObjectEqual(model, prevProps.model)) {
      const fields = this.getFields();
      this.setState({
        fields,
        isFormValid: this.getIsFormValid(fields),
      });
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.clearState?.();
  }

  /**
   * @return {FieldData[]}
   */
  getFields(): FieldData[] {
    const { model } = this.props;
    return [
      { name: 'firstName', value: model?.firstName || '' },
      { name: 'lastName', value: model?.lastName || '' },
      { name: 'company', value: model?.company || '' },
      { name: 'email', value: model?.email || '' },
      { name: 'phonePrefix', value: model?.phone?.prefix || '' },
      { name: 'phone', value: model?.phone?.number || '' },
    ];
  }

  /**
   * @param {FieldData[]} fields
   * @return {boolean}
   */
  getIsFormValid(fields: FieldData[]): boolean {
    return fields.every(f => f.name !== 'firstName' || !!f.value.trim().length);
  }

  /**
   *
   */
  async onSubmit() {
    if (await Utils.askForConfirmation()) {
      const { fields } = this.state;
      const { isEdit, model, onCreate, onUpdate } = this.props;
      const newFields: Record<string, any> = {};
      fields.forEach(f => {
        if (!newFields['phone']) {
          newFields['phone'] = { prefix: '', number: '' };
        }

        if (f.name === 'phonePrefix') {
          newFields['phone'].prefix = f.value;
        } else if (f.name === 'phone') {
          newFields['phone'].number = f.value;
        } else {
          newFields[`${f.name}`] = f.value;
        }
      });
      if (isEdit) {
        model &&
          onUpdate?.({
            ...model,
            ...newFields,
          });
      } else {
        onCreate?.({
          ...newFields,
        });
      }
    }
  }

  /**
   * @param {string} value
   */
  onPhonePrefixChange(value: string) {
    const { fields } = this.state;
    const idx = fields.findIndex(f => f.name === 'phonePrefix');
    fields[idx].value = value;
    this.setState({ fields });
  }

  /**
   * @return {JSX.Element | null}
   */
  render(): JSX.Element | null {
    const { isEdit } = this.props;
    const { fields, isFormValid } = this.state;

    const telephone: Telephone = {
      prefix: fields.find(f => f.name === 'phonePrefix')?.value || '',
      number: fields.find(f => f.name === 'phone')?.value || '',
    };

    return (
      <Form
        className="p-2"
        layout="vertical"
        fields={fields}
        onFieldsChange={(_, allFields) => {
          const nextFields = allFields.map(f => ({
            ...f,
            name: (f.name as string[])[0],
          }));

          const phonePrefix = this.state.fields.find(
            f => f.name === 'phonePrefix',
          );

          nextFields.push({
            value: phonePrefix?.value || '',
            name: 'phonePrefix',
          });

          this.setState({
            fields: nextFields,
            isFormValid: this.getIsFormValid(nextFields),
          });
        }}
      >
        <Row>
          <Col xs={24}>
            <Form.Item
              label={locale('labels.firstName')}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.firstName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={locale('labels.lastName')} name="lastName">
              <Input
                type="text"
                placeholder={locale('placeholders.lastName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={locale('labels.company')} name="company">
              <Input
                type="text"
                placeholder={locale('placeholders.businessName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={locale('labels.email')} name="email">
              <Input type="email" placeholder={locale('placeholders.email')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={locale('labels.phone')}>
              <TelephoneField
                telephone={telephone}
                onPrefixChange={this.onPhonePrefixChange.bind(this)}
                onPhoneChange={() => null} // already managed by Form's onFieldsChange method
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Button
              type="primary"
              block
              disabled={!isFormValid}
              onClick={() => this.onSubmit()}
            >
              {locale(`actions.${isEdit ? 'updateContact' : 'createContact'}`)}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isEdit: state.contactDetail.isEdit,
  model: state.contactDetail.model,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (data: Partial<Contact>) => {
    if (data.phone && !data.phone.prefix) {
      data.phone.prefix = '+39';
    }
    return dispatch(contactDetailCreateContact(data));
  },
  onUpdate: (contact: Contact) => dispatch(contactDetailUpdateContact(contact)),
  clearState: () => dispatch(contactDetailActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetail);
