export enum ApiUrl {
  Token = 'o/token/',
  RevokeToken = 'o/revoke_token/',

  Users = 'api/users/',
  UserDetail = 'api/users/:id/',

  Contacts = 'api/contacts/',
  ContactDetail = 'api/contacts/:id/',

  PublicSettlements = 'api/public-settlements/:uuid/',
  Settlements = 'api/settlements/',
  SettlementDetail = 'api/settlements/:id/',
  SettlementDetailPayNow = 'api/settlements/:id/pay_now/',
  SettlementDetailUpdateData = 'api/settlements/:id/update_data/',
  SettlementDetailSetupIntent = 'api/settlements/:id/setup_intent/',
  SettlementDetailPayRidOffline = 'api/settlements/:id/pay_rid_offline/',
  SettlementDetailFinalize = 'api/settlements/:id/finalize/',
  SettlementDetailUpdateOffers = 'api/settlements/:id/update_offers/',
  SettlementDetailSetAdvance = 'api/settlements/:id/set_advance/',
  SettlementDetailAddPaymentMethod = 'api/settlements/:id/add_payment_method/',
  SettlementContractSign = 'api/settlement-contracts/:id/sign/',
  SettlementContractPreview = 'api/settlement-contracts/:id/preview/',
  SettlementContractDeleteSign = 'api/settlement-contracts/:id/delete_sign/',
  SettlementUploadAttachment = 'api/settlements/:id/upload_attachment/',
  SettlementDeleteAttachment = 'api/settlements/:id/delete_attachment/',
  SettlementContractUploadAttachment = 'api/settlement-contracts/:id/upload_attachment/',
  SettlementContractDeleteAttachment = 'api/settlement-contracts/:id/delete_attachment/',

  SettlementContractsBlockDetail = 'api/settlement-contracts-blocks/:id/',
  SettlementContractsBlocksSign = 'api/settlement-contracts-blocks/:id/sign/',
  SettlementOfferDetail = 'api/settlement-offers/:id/',
  SettlementOfferHypoteticalPaymentDates = 'api/settlement-offers/:id/hypothetical_payment_dates/',
  SettlementOfferSetPaymentConfigs = 'api/settlement-offers/:id/set_payment_configs/',

  PaymentMethods = 'api/payment-methods/',
  PaymentMethodDetailUploadAttachment = 'api/payment-methods/:id/upload_attachment/',

  OtpRequest = '/api/otp/service/otp_request/',
  OtpResponse = '/api/otp/service/otp_response/',

  Products = 'api/products/',
  Offers = 'api/offers/',
  AttachmentDetail = 'api/attachments/:id/',
}
