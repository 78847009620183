import { Button, Card, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import locale from '../../../../../../locale';
import React from 'react';
import { SettlementContractBlockSign } from '../../../../../../models/SettlementContractBlockSign';
import { PopulatedContract } from '../../CloserSigner';
import { ContractBlock } from '../../../../../../models/ContractBlock';
import styles from './CloserSignerBlock.module.scss';
import { Utils } from '../../../../../../models/Utils';

interface CloserSignerBlockProps {
  block: PopulatedContract;
  prevBlock?: PopulatedContract;
  sign: SettlementContractBlockSign;
  onSign: (block: ContractBlock) => void;
}

/**
 * @param {PopulatedContract} block
 * @param {PopulatedContract} prevBlock
 * @param {SettlementContractBlockSign} sign
 * @param {Function} onSign
 * @constructor
 */
export default function CloserSignerBlock({
  block,
  prevBlock,
  sign,
  onSign,
}: CloserSignerBlockProps) {
  return (
    <Col key={block.id} xs={24}>
      {(!prevBlock || prevBlock?.contract.name !== block.contract.name) && (
        <Typography.Title level={4}>{block.contract.name}</Typography.Title>
      )}
      <Card size="small">
        <iframe className={styles.blockContent} srcDoc={block.content} />
      </Card>
      {!sign ? (
        <div className="mt-1 text-right">
          <Button
            type="primary"
            icon={<EditOutlined />}
            block
            size="large"
            onClick={() => onSign(block)}
          >
            {locale('actions.clickToSign')}
          </Button>
        </div>
      ) : (
        <>
          {sign.otpRequest ? (
            <div className={styles.closerSignerBlock}>
              <p className={styles.closerSignerBlockBackLabel}>
                {locale('labels.sign')}
              </p>
              <div className={styles.closerSignerBlockSignContent}>
                <h3 className="mb-0">
                  {sign.otpRequest.firstName} {sign.otpRequest.lastName}
                </h3>
                <small>
                  <i className="text-secondary">{locale('labels.phone')}:</i>{' '}
                  {sign.otpRequest.phone || '--'}
                </small>
                <br />
                <small>
                  <i className="text-secondary">OTP:</i>{' '}
                  {sign.otpRequest.otpResponse?.code || '--'}
                </small>
                <small>
                  {' '}
                  <i className="text-secondary">IP:</i>{' '}
                  {sign.otpRequest.ipAddress || '--'}
                </small>
                <br />
                <small>
                  <i className="text-secondary">{locale('labels.signDate')}:</i>{' '}
                  {sign.signDate
                    ? Utils.getFormattedDateTime(sign.signDate)
                    : '--'}
                </small>
              </div>
              <div className={styles.closerSignerBlockBackLine} />
            </div>
          ) : (
            <div className="mt-1 text-right">
              <Typography.Paragraph strong className="mb-0">
                {locale('labels.signed')}
              </Typography.Paragraph>
            </div>
          )}
        </>
      )}
    </Col>
  );
}
