import { Mapper } from './Mapper';

export interface Account {
  id: number;
  name: string;
  logo: string;
  logoLight?: string;
  shortDescription?: string;
}

/* eslint-disable camelcase */
export interface ServerAccount {
  id: number;
  name: string;
  logo: string;
  logo_light?: string;
  short_description?: string;
}
/* eslint-enable camelcase */

/**
 * Map Account model
 */
export abstract class AccountMapper implements Mapper<Account, ServerAccount> {
  /**
   * @param {Partial<ServerAccount>} o
   * @return {Account}
   */
  static map(o: Partial<ServerAccount>): Account {
    return <Account>{
      id: o.id,
      name: o.name,
      logo: o.logo,
      logoLight: o.logo_light,
      shortDescription: o.short_description,
    };
  }

  /**
   * @param {Partial<Account>} o
   * @return {ServerAccount}
   */
  static mapReverse(o: Partial<Account>): ServerAccount {
    return <ServerAccount>{
      id: o.id,
      name: o.name,
      logo: o.logo,
      logo_light: o.logoLight,
      short_description: o.shortDescription,
    };
  }

  /**
   * @param {ServerAccount[]} models
   * @return {Account[]}
   */
  static mapArray(models: ServerAccount[]): Account[] {
    return models.map(m => AccountMapper.map(m));
  }

  /**
   * @param {Account[]} models
   * @return {ServerAccount[]}
   */
  static mapReverseArray(models: Account[]): ServerAccount[] {
    return models.map(m => AccountMapper.mapReverse(m));
  }
}
