interface Environment {
  production: boolean;
  baseServerUrl: string;
  clientSecret: string;
  clientId: string;
  stripeApiKey: string;
  appName: string;
  appShortDescription: string;
  appVersion: string;
  productionDomain: string;
  bankAccountDetails: {
    bankName: string;
    accountHolder: string;
    iban: string;
  };
}

const environment: Environment = {
  production: process.env.REACT_APP_PRODUCTION === '1',
  clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY || '',
  baseServerUrl: process.env.REACT_APP_BASE_SERVER_URL || '',
  productionDomain: process.env.REACT_APP_PRODUCTION_DOMAIN || '',
  appName: process.env.REACT_APP_WEBSITE_NAME || '',
  appShortDescription: process.env.REACT_APP_WEBSITE_DESCRIPTION || '',
  appVersion: process.env.REACT_APP_VERSION || '',
  bankAccountDetails: {
    bankName: process.env.REACT_APP_BANK_TRANSFER_BANK_NAME || '',
    accountHolder: process.env.REACT_APP_BANK_TRANSFER_ACCOUNT_HOLDER || '',
    iban: process.env.REACT_APP_BANK_TRANSFER_IBAN || '',
  },
};

export default environment;
