import { Mapper } from './Mapper';
import { Account, AccountMapper, ServerAccount } from './Account';

export interface User {
  id: number;
  accounts: Account[];
  acceptedMarketing: boolean;
  acceptedMarketingDate: Date;
  acceptedPrivacy: boolean;
  birthDate: Date;
  country: string;
  email: string;
  firstName: string;
  image: string;
  isActive: boolean;
  isGuest: boolean;
  isStaff: boolean;
  isCustomer: boolean;
  languageCode: string;
  lastLogin: Date;
  mailBounced: boolean;
  mailComplaints: boolean;
  mailConfirmed: boolean;
  phone: string;
  registrationDate: Date;
  sex: string;
  socialOauthId: string;
  socialOauthPlatform: string;
  stripeCustomerId: string;
  lastName: string;
}

/* eslint-disable camelcase */
export interface ServerUser {
  id: number;
  accounts: ServerAccount[];
  accepted_marketing: boolean;
  accepted_marketing_datetime: string;
  accepted_privacy: boolean;
  birth_date: string;
  country: string;
  email: string;
  first_name: string;
  image: string;
  is_active: boolean;
  is_guest: boolean;
  is_staff: boolean;
  is_customer: boolean;
  language_code: string;
  last_login: string;
  mail_bounced: boolean;
  mail_complaints: boolean;
  mail_confirmed: boolean;
  phone: string;
  registration_datetime: string;
  sex: string;
  social_oauth_id: string;
  social_oauth_platform: string;
  stripe_customer_id: string;
  last_name: string;
}

/* eslint-enable camelcase */

/**
 * Map User model
 */
export abstract class UserMapper implements Mapper<User, ServerUser> {
  /**
   * @param {Partial<ServerUser>} o
   * @return {User}
   */
  static map(o: Partial<ServerUser>): User {
    return <User>{
      id: o.id,
      accounts: AccountMapper.mapArray(o.accounts || []),
      acceptedMarketing: o.accepted_marketing,
      acceptedMarketingDate: o.accepted_marketing_datetime
        ? new Date(o.accepted_marketing_datetime)
        : null,
      acceptedPrivacy: o.accepted_privacy,
      birthDate: o.birth_date ? new Date(o.birth_date) : null,
      country: o.country,
      email: o.email,
      firstName: o.first_name,
      image: o.image,
      isActive: o.is_active,
      isGuest: o.is_guest,
      isStaff: o.is_staff,
      isCustomer: o.is_customer,
      languageCode: o.language_code,
      lastLogin: o.last_login ? new Date(o.last_login) : null,
      mailBounced: o.mail_bounced,
      mailComplaints: o.mail_complaints,
      mailConfirmed: o.mail_confirmed,
      phone: o.phone,
      registrationDate: o.registration_datetime
        ? new Date(o.registration_datetime)
        : null,
      sex: o.sex,
      socialOauthId: o.social_oauth_id,
      socialOauthPlatform: o.social_oauth_platform,
      stripeCustomerId: o.stripe_customer_id,
      lastName: o.last_name,
    };
  }

  /**
   * @param {Partial<User>} o
   * @return {ServerUser}
   */
  static mapReverse(o: Partial<User>): ServerUser {
    return <ServerUser>{
      id: o.id,
      accounts: AccountMapper.mapReverseArray(o.accounts || []),
      accepted_marketing: o.acceptedMarketing,
      accepted_marketing_datetime:
        o.acceptedMarketingDate?.toISOString() || null,
      accepted_privacy: o.acceptedPrivacy,
      birth_date: o.birthDate?.toISOString() || null,
      country: o.country,
      email: o.email,
      first_name: o.firstName,
      image: o.image,
      is_active: o.isActive,
      is_guest: o.isGuest,
      is_staff: o.isStaff,
      is_customer: o.isCustomer,
      language_code: o.languageCode,
      last_login: o.lastLogin?.toISOString() || null,
      mail_bounced: o.mailBounced,
      mail_complaints: o.mailComplaints,
      mail_confirmed: o.mailConfirmed,
      phone: o.phone,
      registration_datetime: o.registrationDate?.toISOString() || null,
      sex: o.sex,
      social_oauth_id: o.socialOauthId,
      social_oauth_platform: o.socialOauthPlatform,
      stripe_customer_id: o.stripeCustomerId,
      last_name: o.lastName,
    };
  }

  /**
   * @param {ServerUser[]} models
   * @return {User[]}
   */
  static mapArray(models: ServerUser[]): User[] {
    return models.map(m => UserMapper.map(m));
  }

  /**
   * @param {User[]} models
   * @return {ServerUser[]}
   */
  static mapReverseArray(models: User[]): ServerUser[] {
    return models.map(m => UserMapper.mapReverse(m));
  }
}
