import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { logger } from '../middlewares/logger';
import { loaderSlice } from '../features/loader/loaderSlice';
import {
  authLogout,
  authLogoutCustomer,
  authRefreshToken,
  authSlice,
} from '../features/auth/authSlice';
import { settlementsSlice } from '../features/settlements/settlementsSlice';
import { otpActions, otpSlice } from '../features/otp/otpSlice';
import { settlementDetailSlice } from '../features/settlementDetail/settlementDetailSlice';
import { settlementCloserSlice } from '../features/settlementCloser/settlementCloserSlice';
import { settlementCreatorSlice } from '../features/settlementCreator/settlementCreatorSlice';
import { contactsSlice } from '../features/contacts/contactsSlice';
import { contactDetailSlice } from '../features/contactDetail/contactDetailSlice';
import axios from '../middlewares/axios';
import axiosRetry from 'axios-retry';
import { Reducer } from 'react';
import { SessionService } from '../models/SessionService';
import { contractPreviewSlice } from '../features/contractPreview/contractPreviewSlice';

const appReducer: Reducer<any, any> = combineReducers({
  [loaderSlice.name]: loaderSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [otpSlice.name]: otpSlice.reducer,
  [settlementsSlice.name]: settlementsSlice.reducer,
  [settlementCreatorSlice.name]: settlementCreatorSlice.reducer,
  [settlementDetailSlice.name]: settlementDetailSlice.reducer,
  [settlementCloserSlice.name]: settlementCloserSlice.reducer,
  [contactsSlice.name]: contactsSlice.reducer,
  [contactDetailSlice.name]: contactDetailSlice.reducer,
  [contractPreviewSlice.name]: contractPreviewSlice.reducer,
});

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === `${authLogout.typePrefix}/fulfilled`) {
      state = {};
    }
    return appReducer(state, action);
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

axiosRetry(axios, {
  retries: 4, // number of retries
  retryDelay: (retryCount, error) => {
    console.log(`retry attempt: ${retryCount}`);

    /** If was 401 and is the first time try to refresh the token */
    const shouldRefreshToken =
      error.response?.status === 401 && retryCount === 1;
    if (shouldRefreshToken) {
      store.dispatch(authRefreshToken());
    }

    /** if is the 4th time means refresh didn't work so refresh the page */
    if (retryCount === 4) {
      window.location.reload();
    }

    return retryCount * (retryCount < 3 ? 2000 : 0); // time interval between retries
  },
  retryCondition: error => {
    /** Retry 401 only */
    const is401 = error.response?.status === 401;
    if (!is401) {
      return false;
    }

    /** If isCustomer user must log in again */
    const isCustomer = !!store.getState().auth?.user?.isCustomer;
    if (isCustomer) {
      store.dispatch(otpActions.clearResponseData());
      SessionService.clearOtpSession();
      store.dispatch(authLogoutCustomer());
    } else if (!SessionService.getSession()?.refreshToken) {
      return false;
    }

    return !isCustomer;
  },
});
