import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import locale from '../../../../locale';
import Stepper from '../../../../components/stepper/Stepper';

interface SettlementCreatorStepperProps {
  step: number;
  onBack?: () => void;
  showBackButton?: boolean;
}

/**
 * @param {SettlementCreatorStepperProps} props
 * @constructor
 */
export default function SettlementCreatorStepper({
  step,
  onBack,
  showBackButton,
}: SettlementCreatorStepperProps) {
  const { user } = useSelector(authSelector);
  const [steps, setSteps] = useState<{ key: string; title: string }[]>([]);

  useEffect(() => {
    setSteps([
      { key: 'contact', title: locale(`labels.contact`) },
      { key: 'product', title: locale(`labels.product`) },
      { key: 'offer', title: locale(`labels.offer`) },
      { key: 'totalPrice', title: locale(`labels.totalPrice`) },
      { key: 'activation', title: locale(`labels.activation`) },
      { key: 'subscriptions', title: locale(`labels.subscriptions`) },
      { key: 'duration', title: locale(`labels.duration`) },
      { key: 'confirmOffer', title: locale(`labels.confirmOffer`) },
    ]);
  }, [user]);

  return (
    <div className="p-2 pb-0">
      <Stepper
        step={step}
        steps={steps}
        showCurrentOnly
        {...{ showBackButton, onBack }}
      />
    </div>
  );
}
