import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Row,
  Typography,
} from 'antd';
import locale from '../../../../../locale';
import { Settlement } from '../../../../../models/Settlement';
import SettlementSharer from '../../settlementSharer/SettlementSharer';
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { UploadFile } from 'antd/es/upload/interface';
import './SignSection.scss';
import MediaUploader from '../../../../../components/mediaUploader/MediaUploader';
import {
  SettlementContract,
  SettlementSignType,
} from '../../../../../models/SettlementContract';
import { Utils } from '../../../../../models/Utils';

interface SignSectionProps {
  step: number;
  canEdit: boolean;
  settlement: Settlement;
  settlementContract: SettlementContract;
  onSign: (signedDate: Date, files: UploadFile[]) => void;
  onDeleteAttachment: (id: number) => void;
  onDeleteSign: () => void;
}

/**
 *
 * @param {SignSectionProps} props
 * @constructor
 */
export function SignSection({
  settlement,
  settlementContract,
  onSign,
  onDeleteSign,
  onDeleteAttachment: deleteAttachment,
  canEdit,
}: SignSectionProps) {
  const [isEdit, setIsEdit] = useState<boolean>();
  const [isPaper, setIsPaper] = useState<boolean>();
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [signedDate, setSignedDate] = useState<moment.Moment | undefined>();

  const initData = () => {
    setIsEdit(canEdit && !settlementContract?.isSigned);
    setIsPaper(settlementContract?.signType === SettlementSignType.Paper);
    setFiles(
      settlementContract.rawAttachments?.map(attachment => ({
        uid: `${attachment.id}`,
        url: attachment.file,
        thumbUrl: attachment.thumbnailUrl,
        name: attachment.name,
      })) || [],
    );
    setSignedDate(
      settlementContract.signDate
        ? moment(settlementContract.signDate)
        : undefined,
    );
  };

  useEffect(initData, [settlement]);

  const canUpload = !!files.length && signedDate;
  const wasSigned = settlementContract.isSigned;
  const offer = settlement.settlementOffers.find(
    o => o.offer.contract.id === settlementContract.contractId,
  );

  const onDeleteAttachment = (uid: string) => {
    if (settlementContract.rawAttachments?.find(a => a.id === +uid)) {
      deleteAttachment(+uid);
    }
  };

  return settlement ? (
    <Card
      type="inner"
      size="small"
      className="h-100"
      title={
        <>
          {locale('titles.sign')}
          <span>
            {' '}
            {'"'}
            {offer?.offer.name}
            {'"'}
          </span>
        </>
      }
      extra={
        canEdit
          ? [
              <Button
                key="edit"
                type="link"
                hidden={isEdit}
                onClick={() => setIsEdit(true)}
              >
                {locale('actions.edit')}
              </Button>,
            ]
          : []
      }
      actions={
        isEdit
          ? [
              ...(wasSigned
                ? [
                    <Button key="cancel" type="default" onClick={initData}>
                      {locale('actions.cancel')}
                    </Button>,
                    <Button
                      key="delete"
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={onDeleteSign}
                    >
                      {locale('actions.deleteSign')}
                    </Button>,
                  ]
                : isPaper
                ? [
                    <Button
                      key="cancel"
                      type="default"
                      onClick={() => setIsPaper(false)}
                    >
                      {locale('actions.cancel')}
                    </Button>,
                  ]
                : []),
            ]
          : []
      }
    >
      {!isEdit && (
        <Row justify="center" gutter={[16, 8]}>
          <Col xs={24}>
            <Descriptions
              size="small"
              bordered
              column={1}
              title={locale('titles.contractSigned')}
            >
              <Descriptions.Item label={locale('labels.signStatus')}>
                <span className="text-success mr-1">
                  {locale('titles.signed')}
                </span>
                |
                <span className="ml-1">
                  {locale(`settlementSignType.${settlementContract.signType}`)}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={locale('labels.contractDate')}>
                {signedDate
                  ? Utils.getFormattedDate(signedDate.toDate())
                  : '--'}
              </Descriptions.Item>
              <Descriptions.Item label={locale('labels.attachments')}>
                {!!files?.length && (
                  <MediaUploader
                    files={files}
                    onFilesChange={setFiles}
                    canEdit={false}
                  />
                )}
                {settlementContract.pdf && (
                  <Button
                    type="primary"
                    href={settlementContract.pdf}
                    target="_blank"
                    icon={<DownloadOutlined />}
                  >
                    {locale('actions.downloadSignedContract')}
                  </Button>
                )}
                {!files?.length && !settlementContract.pdf && '--'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      )}
      {isEdit && (
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Typography.Title level={4} className="text-center">
              {locale('titles.uploadPaperSignedContract')}
            </Typography.Title>
            <Typography.Paragraph type="secondary" className="text-center">
              {locale(
                wasSigned
                  ? 'subtitles.deleteContractToUpload'
                  : 'subtitles.uploadPaperSignedContract',
              )}
            </Typography.Paragraph>
            {!isPaper && !wasSigned && (
              <div className="w-100 text-center">
                <Button type="default" onClick={() => setIsPaper(true)}>
                  {locale('actions.uploadContract')}
                </Button>
              </div>
            )}
            {isPaper && !wasSigned && (
              <>
                <Form layout="vertical">
                  <Form.Item
                    className="align-items-center"
                    label={locale('labels.contractDate')}
                    tooltip={locale('subtitles.contractDate')}
                    required
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={signedDate}
                      onChange={value => setSignedDate(value || undefined)}
                    />
                  </Form.Item>
                </Form>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="text-center">
                    <MediaUploader
                      files={files}
                      onFilesChange={setFiles}
                      onDelete={onDeleteAttachment}
                      canEdit={true}
                    />
                  </Col>
                  <Col xs={24} className="text-center">
                    <Button
                      key="confirm"
                      type="primary"
                      icon={<UploadOutlined />}
                      disabled={!canUpload}
                      onClick={() =>
                        signedDate && onSign(signedDate?.toDate(), files)
                      }
                    >
                      {locale('actions.uploadContract')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col xs={24} md={12} className="text-center">
            {wasSigned && (
              <>
                <Typography.Title level={4}>
                  {locale('titles.signWithOtp')}
                </Typography.Title>
                <Typography.Paragraph type="secondary">
                  {locale('subtitles.deleteContractToSignWithOtp')}
                </Typography.Paragraph>
              </>
            )}

            {!wasSigned && (
              <SettlementSharer
                title={locale('titles.signWithOtp')}
                subtitle={locale('subtitles.signWithOtp')}
                settlement={settlement}
              />
            )}
          </Col>
        </Row>
      )}
    </Card>
  ) : null;
}
