import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { AuthApiService } from './AuthApiService';
import { AuthSessionData, SessionService } from '../../models/SessionService';
import { setApiAuthorization } from '../../middlewares/axios';
import moment from 'moment';
import locale from '../../locale';
import { otpUpdateState } from '../otp/otpSlice';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import { loaderActions } from '../loader/loaderSlice';

let authTokenTimeout: NodeJS.Timeout;

export const authLoginUser = createAsyncThunk(
  'auth/login',
  async (
    payload: {
      email?: string;
      password?: string;
      refreshToken?: string;
      loginToken?: string;
      expiresIn?: number;
      otp?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const authData: AuthSessionData = await AuthApiService.login(payload);
      if (payload.expiresIn) {
        authData.expiresIn = payload.expiresIn;
      }
      SessionService.setSession(authData);
      dispatch(authCheckTimeout({ expiresIn: authData.expiresIn }));
      return authData;
    } catch (e) {
      console.log(e);
      let errorMessage: string;
      if (SessionService.isLogged()) {
        dispatch(authLogout());
        errorMessage = locale('errors.sessionExpired');
      } else {
        errorMessage =
          e?.response?.data?.error_description ||
          e?.response?.data?.error ||
          e.message;
      }
      return rejectWithValue({ message: `${errorMessage}` });
    }
  },
);

export const authRefreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (payload, { getState, dispatch }) => {
    const { refreshToken } = (<RootState>getState()).auth;
    if (!refreshToken) {
      if (SessionService.isLogged()) {
        dispatch(authLogout());
      }
      return;
    }
    dispatch(authLoginUser({ refreshToken }));
  },
);

export const authLogout = createAsyncThunk(
  'auth/logout',
  async (payload, { getState, dispatch }) => {
    const { token, user } = (<RootState>getState()).auth;
    SessionService.clearOtpSession();
    SessionService.clearSession();
    authTokenTimeout && clearTimeout(authTokenTimeout);
    dispatch(authActions.clearState());
    token && AuthApiService.logout(token);
    if (!user.isCustomer) {
      AppHistory.push(AppRoute.Login);
    } else {
      dispatch(loaderActions.increment());
      location.reload();
    }
  },
);

export const authLogoutCustomer = createAsyncThunk(
  'auth/logoutCustomer',
  async (payload, { dispatch }) => {
    dispatch(authActions.clearState());
    authTokenTimeout && clearTimeout(authTokenTimeout);
    SessionService.clearSession();
  },
);

export const authCheckState = createAsyncThunk(
  'auth/checkState',
  async (payload, { dispatch }) => {
    const authState = SessionService.getSession();
    if (SessionService.isLogged() && !authState.token) {
      return dispatch(authLogout());
    }
    // check if there is an otp session stored
    const otpState = SessionService.getOtpSession();

    const expiresIn: number = moment(authState.expirationDate).diff(new Date());

    if (expiresIn <= 0) {
      console.log('token expired');
      return dispatch(authRefreshToken());
    }

    setApiAuthorization(authState.token, otpState?.otp);
    dispatch(authCheckTimeout({ expiresIn }));

    if (otpState) {
      dispatch(otpUpdateState(otpState));
    }

    return authState;
  },
);

export const authCheckTimeout = createAsyncThunk(
  'auth/checkTimeout',
  async ({ expiresIn }: { expiresIn: number }, { dispatch }) => {
    authTokenTimeout && clearTimeout(authTokenTimeout);
    authTokenTimeout = setTimeout(
      () => dispatch(authRefreshToken()),
      expiresIn,
    );
  },
);

interface AuthState extends Partial<AuthSessionData> {
  errorMessage?: string;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  errorMessage: undefined,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${authLoginUser.fulfilled}`]: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.expirationDate = payload.expirationDate;
      state.expiresIn = payload.expiresIn;
      state.firebaseToken = payload.firebaseToken;
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = undefined;
      return state;
    },
    [`${authLoginUser.rejected}`]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [`${authLoginUser.pending}`]: state => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = undefined;
    },
    [`${authLogout.fulfilled}`]: state => {
      state = { ...state, ...initialState };
      return state;
    },
    [`${authCheckState.fulfilled}`]: (state, { payload }) => {
      state = { ...state, ...payload };
      return state;
    },
  },
});

export const authSelector = (state: RootState): AuthState => state.auth;
export const authActions = authSlice.actions;
