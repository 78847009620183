import { Mapper } from './Mapper';

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
  hasNext: boolean;
}

/* eslint-disable camelcase */
export interface ServerPagination {
  page: number;
  page_size: number;
  count?: number;
  next?: string;
}

/* eslint-enable camelcase */

export const initialPagination: (pageSize?: number) => Pagination = (
  pageSize = 50,
) => ({
  page: 0,
  hasNext: false,
  pageSize,
  total: 0,
});

/**
 *
 */
export abstract class PaginationMapper
  implements Mapper<Pagination, ServerPagination> {
  /**
   * @param {Partial<ServerPagination>} o
   * @return {Pagination}
   */
  static map(o: Partial<ServerPagination>): Pagination {
    return <Pagination>{
      page: o.page,
      pageSize: o.page_size,
      hasNext: !!o.next?.length,
      total: o.count,
    };
  }

  /**
   * @param {Partial<Pagination>} o
   * @return {ServerPagination}
   */
  static mapReverse(o: Partial<Pagination>): ServerPagination {
    return <ServerPagination>{
      page_size: o.pageSize,
      page: o.page,
    };
  }

  /**
   * @param {ServerPagination[]} models
   * @return {Pagination[]}
   */
  static mapArray(models: ServerPagination[]): Pagination[] {
    return models.map(m => PaginationMapper.map(m));
  }

  /**
   * @param {Pagination[]} models
   * @return {ServerPagination[]}
   */
  static mapReverseArray(models: Pagination[]): ServerPagination[] {
    return models.map(m => PaginationMapper.mapReverse(m));
  }
}
