import { Mapper } from './Mapper';
import { OtpRequest, OtpRequestMapper, ServerOtpRequest } from './OtpRequest';

export interface SettlementContractBlockSign {
  id: number;
  contractBlockId: number;
  otpRequest: OtpRequest;
  signDate: Date;
}

/* eslint-disable camelcase */
export interface ServerSettlementContractBlockSign {
  id: number;
  contract_block: number;
  otp_request: ServerOtpRequest;
  sign_datetime: string;
}
/* eslint-enable camelcase */

/**
 * Map SettlementContractBlockSign model
 */
export abstract class SettlementContractBlockSignMapper
  implements
    Mapper<SettlementContractBlockSign, ServerSettlementContractBlockSign> {
  /**
   * @param {Partial<ServerSettlementContractBlockSign>} o
   * @return {SettlementContractBlockSign}
   */
  static map(
    o: Partial<ServerSettlementContractBlockSign>,
  ): SettlementContractBlockSign {
    return <SettlementContractBlockSign>{
      id: o.id,
      contractBlockId: o.contract_block,
      otpRequest: o.otp_request
        ? OtpRequestMapper.map(o.otp_request)
        : undefined,
      signDate: o.sign_datetime ? new Date(o.sign_datetime) : undefined,
    };
  }

  /**
   * @param {Partial<SettlementContractBlockSign>} o
   * @return {ServerSettlementContractBlockSign}
   */
  static mapReverse(
    o: Partial<SettlementContractBlockSign>,
  ): ServerSettlementContractBlockSign {
    return <ServerSettlementContractBlockSign>{
      id: o.id,
      contract_block: o.contractBlockId,
      otp_request: o.otpRequest
        ? OtpRequestMapper.mapReverse(o.otpRequest)
        : null,
      sign_datetime: o.signDate?.toISOString() || null,
    };
  }

  /**
   * @param {ServerSettlementContractBlockSign[]} models
   * @return {SettlementContractBlockSign[]}
   */
  static mapArray(
    models: ServerSettlementContractBlockSign[],
  ): SettlementContractBlockSign[] {
    return models.map(m => SettlementContractBlockSignMapper.map(m));
  }

  /**
   * @param {SettlementContractBlockSign[]} models
   * @return {ServerSettlementContractBlockSign[]}
   */
  static mapReverseArray(
    models: SettlementContractBlockSign[],
  ): ServerSettlementContractBlockSign[] {
    return models.map(m => SettlementContractBlockSignMapper.mapReverse(m));
  }
}
