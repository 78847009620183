import React, { useEffect } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { Utils } from '../../../models/Utils';
import locale from '../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import AppHistory from '../../../models/AppHistory';
import { AppRoute } from '../../../models/appRouter/AppRoute.enum';
import { authSelector, authLoginUser } from '../authSlice';
import PublicLayout from '../../../layouts/publicLayout/PublicLayout';

const Login = () => {
  const dispatch = useDispatch();
  const { isFetching, isSuccess, errorMessage } = useSelector(authSelector);
  const { token } = Utils.getQueryParameters(window.location.search);

  const onSubmit = (values: { email: string; password: string }) => {
    dispatch(authLoginUser(values));
  };

  useEffect(() => {
    token && dispatch(authLoginUser({ loginToken: token }));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      AppHistory.push(Utils.preserveQueryParameters(AppRoute.Root));
    }
  }, [isSuccess]);

  return (
    <PublicLayout>
      <Row>
        <Col xs={24} md={{ span: 12, offset: 6 }}>
          {errorMessage && (
            <Form.Item>
              <Alert message={errorMessage} type="error" />
            </Form.Item>
          )}
          <fieldset disabled={isFetching}>
            <Form layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label={locale('labels.email')}
                name="email"
                rules={[{ required: true, type: 'email' }]}
              >
                <Input type="email" autoComplete="email" />
              </Form.Item>

              <Form.Item
                label={locale('labels.password')}
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password autoComplete="current-password" />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={isFetching}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {locale('actions.login')}
                </Button>
              </Form.Item>
            </Form>
          </fieldset>
        </Col>
      </Row>
    </PublicLayout>
  );
};

export default Login;
