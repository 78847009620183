import { ApiUrl } from '../models/ApiUrl.enum';
import axios from '../middlewares/axios';
import { Product, ProductMapper, ServerProduct } from '../models/Product';

/**
 * Product APIs
 */
export abstract class ProductApiService {
  /**
   * Get list of Product
   * @return {Promise<Product[]>}
   */
  static async fetchProducts(): Promise<Product[]> {
    const sProducts: ServerProduct[] = await axios.get(ApiUrl.Products);
    return ProductMapper.mapArray(sProducts || []);
  }
}
