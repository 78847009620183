import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { OtpApiService } from './OtpApiService';
import { message } from 'antd';
import locale from '../../locale';
import moment from 'moment';
import { OtpSessionData, SessionService } from '../../models/SessionService';
import { Utils } from '../../models/Utils';
import { authLoginUser, authLogoutCustomer } from '../auth/authSlice';
import { loaderActions } from '../loader/loaderSlice';

let otpSessionTimeout: NodeJS.Timeout;

export const otpSendRequest = createAsyncThunk(
  'otp/sendRequest',
  async (_, { rejectWithValue, getState, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { phone, firstName, lastName, businessname } = (<RootState>(
        getState()
      )).otp;
      const { model: settlement } = (<RootState>getState()).settlementCloser;
      const data = {
        idSettlement: settlement.id,
        phone: phone.prefix + phone.number,
        firstName,
        lastName,
        email: settlement?.contact?.email || null,
        businessname,
      };
      const request = await OtpApiService.sendRequest(data);
      message.success(locale('messages.otpRequestSent'));
      dispatch(loaderActions.decrement());
      return { request };
    } catch (e) {
      dispatch(loaderActions.decrement());
      console.log(e.response);
      message.error(
        e.response?.data?.error || locale('errors.otpRequestNotSent'),
      );
      return rejectWithValue({});
    }
  },
);

export const otpSendResponse = createAsyncThunk(
  'otp/sendResponse',
  async (_, { rejectWithValue, getState, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { phone, request, otp } = (<RootState>getState()).otp;
      const data = {
        phone: phone.prefix + phone.number,
        request: request || 0,
        otp,
      };
      const { token, expiresIn } = await OtpApiService.sendResponse(data);
      otpSessionTimeout && clearTimeout(otpSessionTimeout);
      otpSessionTimeout = setTimeout(() => {
        dispatch(otpActions.clearResponseData());
        SessionService.clearOtpSession();
      }, expiresIn);
      const expirationDate = moment().add(expiresIn, 'milliseconds').toDate();
      message.success(locale('messages.otpResponseValid'));
      dispatch(authLoginUser({ loginToken: token, expiresIn, otp }));
      dispatch(loaderActions.decrement());
      return { expirationDate, accessToken: token };
    } catch (e) {
      message.error(locale('errors.otpResponseNotValid'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const otpUpdateState = createAsyncThunk(
  'otp/updateState',
  async (payload: OtpSessionData, { dispatch }) => {
    dispatch(otpActions.patchState(payload));
    const expiresIn = moment(payload.expirationDate).diff(
      moment(),
      'milliseconds',
    );
    if (expiresIn > 0) {
      otpSessionTimeout && clearTimeout(otpSessionTimeout);
      otpSessionTimeout = setTimeout(() => {
        dispatch(otpActions.clearResponseData());
        SessionService.clearOtpSession();
        dispatch(authLogoutCustomer());
      }, expiresIn);
    }
  },
);

interface OtpSliceState extends OtpSessionData {
  isRequestModalVisible: boolean;
  isResponseModalVisible: boolean;
  requestExpirationDate?: Date;
}

const initialState: OtpSliceState = {
  isRequestModalVisible: false,
  isResponseModalVisible: false,
  accessToken: '',
  otp: '',
  firstName: '',
  lastName: '',
  businessname: '',
  phone: { prefix: '+39', number: '' },
  expirationDate: undefined,
  requestExpirationDate: undefined,
  request: undefined,
};

export const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    openRequestModal(state) {
      state.request = undefined;
      state.requestExpirationDate = undefined;
      state.expirationDate = undefined;
      state.otp = '';
      state.isRequestModalVisible = true;
    },
    closeRequestModal(state) {
      state.isRequestModalVisible = false;
    },
    openResponseModal(state) {
      state.isResponseModalVisible = true;
    },
    closeResponseModal(state) {
      state.isResponseModalVisible = false;
    },
    setFirstName(state, { payload }) {
      state.firstName = payload;
    },
    setLastName(state, { payload }) {
      state.lastName = payload;
    },
    setBusinessname(state, { payload }) {
      state.businessname = payload;
    },
    setAccessToken(state, { payload }) {
      state.accessToken = payload;
    },
    setPhone(state, { payload }) {
      state.phone = payload;
    },
    setOtp(state, { payload }) {
      state.otp = payload;
    },
    patchState(state, { payload }) {
      return Utils.updateObject(state, payload);
    },
    clearResponseData(state) {
      return {
        ...state,
        accessToken: '',
        otp: '',
        expirationDate: undefined,
        requestExpirationDate: undefined,
        request: undefined,
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${otpSendRequest.fulfilled}`]: (state, { payload }) => {
      state.request = payload.request;
      state.requestExpirationDate = moment().add(10, 'minutes').toDate();
      state.isRequestModalVisible = false;
      state.isResponseModalVisible = true;
      return state;
    },
    [`${otpSendRequest.rejected}`]: state => {
      state.request = undefined;
      return state;
    },
    [`${otpSendResponse.fulfilled}`]: (state, { payload }) => {
      state.requestExpirationDate = undefined;
      state.accessToken = payload.accessToken;
      state.expirationDate = payload.expirationDate;
      state.isResponseModalVisible = false;
      SessionService.setOtpSession(state);
      return state;
    },
  },
});

export const otpSelector = (state: RootState): OtpSliceState => state.otp;
export const otpActions = otpSlice.actions;
