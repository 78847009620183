import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Contact } from '../../models/Contact';
import { initialPagination, Pagination } from '../../models/Pagination';
import { RootState } from '../../store/store';
import { message } from 'antd';
import locale from '../../locale';
import { loaderActions } from '../loader/loaderSlice';
import { ContactApiService } from '../../services/ContactApiService';

export const contactsInit = createAsyncThunk(
  'contacts/init',
  async (payload: { search?: string }, { dispatch }) => {
    dispatch(contactsActions.clearState());
    dispatch(contactsNextPage(payload));
  },
);

export const contactsNextPage = createAsyncThunk(
  'contacts/nextPage',
  async (
    payload: { search?: string },
    { getState, rejectWithValue, dispatch },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const state: ContactsSliceState = (<RootState>getState()).contacts;

      const search = payload.search || state.search;
      const pagination: Pagination = {
        ...state.pagination,
        page: state.pagination.page + 1,
      };

      const { list, resPagination } = await ContactApiService.getList(
        pagination,
        search,
      );

      dispatch(loaderActions.decrement());

      return {
        search,
        list: [...state.list, ...list],
        pagination: {
          ...resPagination,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      };
    } catch (error) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({ error });
    }
  },
);

interface ContactsSliceState {
  isModalVisible: boolean;
  search: string;
  list: Contact[];
  pagination: Pagination;
}

const initialState: ContactsSliceState = {
  isModalVisible: false,
  search: '',
  list: [],
  pagination: initialPagination(),
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    openModal(state) {
      state.isModalVisible = true;
    },
    closeModal(state) {
      state.isModalVisible = false;
    },
    clearState(state) {
      return {
        ...initialState,
        isModalVisible: state.isModalVisible,
      };
    },
  },
  extraReducers: {
    [<any>contactsNextPage.fulfilled]: (
      state,
      { payload: { search, list, pagination } },
    ) => {
      state.search = search;
      state.list = list;
      state.pagination = pagination;
    },
  },
});

export const contactsSelector = (state: RootState): ContactsSliceState =>
  state.contacts;
export const contactsActions = contactsSlice.actions;
