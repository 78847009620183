import React, { useEffect } from 'react';
import OtpRequestModal from './components/otpRequestModal/OtpRequestModal';
import moment from 'moment';
import { otpActions, otpSelector } from './otpSlice';
import { useDispatch, useSelector } from 'react-redux';
import OtpResponseModal from './components/otpResponseModal/OtpResponseModal';
import { settlementCloserSelector } from '../settlementCloser/settlementCloserSlice';

/**
 *
 * @constructor
 */
export default function OtpHandler() {
  const dispatch = useDispatch();
  const {
    isRequestModalVisible,
    expirationDate,
    requestExpirationDate,
  } = useSelector(otpSelector);
  const { step } = useSelector(settlementCloserSelector);

  useEffect(() => {
    const showRequestModal =
      step > -1 &&
      !isRequestModalVisible &&
      !requestExpirationDate &&
      (!expirationDate || moment(expirationDate).isBefore(new Date()));
    showRequestModal && dispatch(otpActions.openRequestModal());
  }, [isRequestModalVisible, expirationDate, step]);

  return (
    <>
      <OtpRequestModal />
      <OtpResponseModal />
    </>
  );
}
