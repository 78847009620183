import { Settlement, SettlementStatus } from '../../models/Settlement';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { SettlementApiService } from '../../services/SettlementApiService';
import { message } from 'antd';
import locale from '../../locale';
import { loaderActions } from '../loader/loaderSlice';
import { UploadFile } from 'antd/es/upload/interface';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../../models/PaymentMethodItem';
import { SettlementDataFieldValue } from '../../models/SettlementDataFieldValue';
import { Contact } from '../../models/Contact';
import { PaymentMethod } from '../../models/PaymentMethod';
import { contactsActions } from '../contacts/contactsSlice';
import { MethodType } from '../../models/PaymentMethodType';
import { SettlementContract } from '../../models/SettlementContract';
import { SettlementContractBlock } from '../../models/SettlementContractBlock';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import { DurationType } from '../../models/DurationType.enum';
import { SettlementOffer } from '../../models/SettlementOffer';
import { PaymentConfig } from '../../models/PaymentConfig';
import { SettlementStep } from '../../models/SettlementStep.enum';

export const settlementDetailGetById = createAsyncThunk(
  'settlementDetail/getById',
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await SettlementApiService.getById(id);
      dispatch(loaderActions.decrement());
      return model;
    } catch (e) {
      message.error(locale('errors.getSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailGetByUuid = createAsyncThunk(
  'settlementDetail/getByUuid',
  async ({ uuid }: { uuid: string }, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await SettlementApiService.getByUuid(uuid);
      dispatch(loaderActions.decrement());
      return model;
    } catch (e) {
      message.error(locale('errors.getSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailGetPaymentMethodItems = createAsyncThunk(
  'settlementDetail/getPaymentMethodItems',
  async (_, { getState }) => {
    const {
      auth: {
        user: { isCustomer },
      },
      settlementDetail: { model },
    } = <RootState>getState();
    return await SettlementApiService.getPaymentMethodItems(model, isCustomer);
  },
);

export const settlementDetailUpdateName = createAsyncThunk(
  'settlementDetail/updateName',
  async (
    { name }: { name: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const {
        model: { id },
      } = (<RootState>getState()).settlementDetail;
      const settlement = await SettlementApiService.updateName(id, name);
      dispatch(loaderActions.decrement());
      return { name: settlement.name };
    } catch (e) {
      message.error(locale('errors.updateSettlementName'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailDelete = createAsyncThunk(
  'settlementDetail/delete',
  async (_, { getState, dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const {
        model: { id },
      } = (<RootState>getState()).settlementDetail;
      await SettlementApiService.delete(id);
      dispatch(loaderActions.decrement());
      message.success(locale('messages.settlementDeleted'));
      AppHistory.push(AppRoute.Settlements);
      return {};
    } catch (e) {
      message.error(locale('errors.deleteSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailUpdateContract = createAsyncThunk(
  'settlementDetail/updateContract',
  async (
    {
      settlementContractBlocks,
    }: { settlementContractBlocks: SettlementContractBlock[] },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      await SettlementApiService.updateContractBlocks(settlementContractBlocks);
      const { id } = (<RootState>getState()).settlementDetail.model;
      dispatch(loaderActions.decrement());
      dispatch(settlementDetailGetById({ id }));
      message.success(locale('messages.contractUpdated'));
      return {};
    } catch (e) {
      message.error(locale('errors.updateSettlementContract'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailUpdateData = createAsyncThunk(
  'settlementDetail/updateData',
  async (
    payload: { dataFieldValues: SettlementDataFieldValue[] },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.updateData(id, payload.dataFieldValues);
      dispatch(settlementDetailGetById({ id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.updateSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailUpdateContact = createAsyncThunk(
  'settlementDetail/updateContact',
  async (
    { contact }: { contact: Contact },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.updateContact(id, contact);
      dispatch(contactsActions.closeModal());
      dispatch(settlementDetailGetById({ id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.updateSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailAddAdvance = createAsyncThunk(
  'settlementDetail/addAdvance',
  async (
    { amount }: { amount: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.addAdvance(id, amount);
      message.success(locale('messages.advanceAdded'));
      dispatch(loaderActions.decrement());
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      message.error(locale('errors.addAdvance'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailUpdateFirstInstallmentTimeFromApproval = createAsyncThunk(
  'settlementDetail/updateFirstInstallmentTimeFromApproval',
  async (
    { id, value, type }: { id: number; value: number; type: DurationType },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      await SettlementApiService.updateSettlementOffer(id, {
        first_installment_time_from_approval: value,
        first_installment_time_from_approval_duration_type: type,
      });
      const { model } = (<RootState>getState()).settlementDetail;
      message.success(locale('messages.contractUpdated'));
      dispatch(settlementDetailGetById({ id: model.id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.updateSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementOfferDetailUpdateVat = createAsyncThunk(
  'settlementDetail/updateVat',
  async (
    { id, value }: { id: number; value: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      await SettlementApiService.updateSettlementOffer(id, {
        vat_percentage: value,
      });
      const { model } = (<RootState>getState()).settlementDetail;
      message.success(locale('messages.contractUpdated'));
      dispatch(settlementDetailGetById({ id: model.id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.updateSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailUpdatePaymentConfigs = createAsyncThunk(
  'settlementDetail/updatePaymentConfigs',
  async (
    { id, paymentConfigs }: { id: number; paymentConfigs: PaymentConfig[] },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      await SettlementApiService.setSettlementOfferPaymentConfigs(
        id,
        paymentConfigs,
      );
      const { model } = (<RootState>getState()).settlementDetail;
      dispatch(loaderActions.decrement());
      message.success(locale('messages.contractUpdated'));
      dispatch(settlementDetailGetById({ id: model.id }));
    } catch (e: any) {
      message.error(
        e?.response?.data?.errors?.[0] || locale('errors.updateSettlement'),
      );
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailGetHypoteticalPaymentDates = createAsyncThunk(
  'settlementDetail/getHypoteticalPaymentDates',
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const res = await SettlementApiService.getSettlementOfferHypoteticalPaymentDates(
        id,
      );
      dispatch(
        settlementDetailActions.setCurrentPaymentConfigsModalPaymentDates(res),
      );
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.genericError'));
      console.log(e);
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailConfirmData = createAsyncThunk(
  'settlementDetail/confirmData',
  async (_, { dispatch, getState, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.confirmData(id);
      dispatch(loaderActions.decrement());
      message.success(locale('messages.contractConfirmed'));
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      message.error(locale('errors.confirmData'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementDetailSign = createAsyncThunk(
  'settlementDetail/sign',
  async (
    {
      signedDate,
      files,
      idSettlementContract,
    }: { signedDate: Date; files: UploadFile[]; idSettlementContract: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;

      /** Upload new attachments if needed */
      if (files.length) {
        await SettlementApiService.uploadContractAttachments(
          id,
          idSettlementContract,
          files,
        );
      }

      await SettlementApiService.sign(id, idSettlementContract, signedDate);
      dispatch(loaderActions.decrement());
      message.success(locale('messages.contractSigned'));
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.sign'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailDeleteAttachment = createAsyncThunk(
  'settlementDetail/deleteAttachment',
  async ({ id }: { id: number }, { dispatch, getState, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    const { id: sId } = (<RootState>getState()).settlementDetail.model;
    try {
      await SettlementApiService.deleteSettlementContractAttachment(sId, id);
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.deleteAttachment'));
      dispatch(loaderActions.decrement());
      dispatch(settlementDetailGetById({ id: sId }));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailDeleteSettlementContractAttachment = createAsyncThunk(
  'settlementDetail/deleteSettlementContractAttachment',
  async (
    { idSettlementContract, id }: { idSettlementContract: number; id: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      await SettlementApiService.deleteSettlementContractAttachment(
        idSettlementContract,
        id,
      );
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.deleteAttachment'));
      dispatch(loaderActions.decrement());
      const { id: sId } = (<RootState>getState()).settlementDetail.model;
      dispatch(settlementDetailGetById({ id: sId }));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailAddPaymentMethod = createAsyncThunk(
  'settlementDetail/addPaymentMethod',
  async (
    {
      methodType,
      paymentMethodItem,
      data,
    }: {
      methodType: MethodType;
      paymentMethodItem: PaymentMethodItem;
      data: Record<string, any>;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;

      const idPaymentMethodType = paymentMethodItem.paymentMethodTypes.find(
        t => t.type === methodType,
      )?.id;

      if (!idPaymentMethodType) {
        throw new Error('Missing Payment Method Type');
      }

      const nData: Record<string, any> = {};
      const mappedKeys: Record<string, string> = {
        paymentDate: 'payment_date',
        bankAccountHolder: 'bank_account_holder',
        bankAuthorizationId: 'bank_authorization_id',
        firstName: 'first_name',
        lastName: 'last_name',
        fiscalCode: 'fiscal_code',
        zipCode: 'zip_code',
        otpRequest: 'otp_request',
      };

      let paymentMethodAttachments: UploadFile[] = [];

      Object.keys(data).forEach(k => {
        const key = mappedKeys[k] || k;

        if (k === 'paymentDate') {
          nData[key] = data[k].toISOString().split('T')[0];
        } else if (k === 'files') {
          paymentMethodAttachments = data[k];
        } else {
          nData[key] = data[k];
        }
      });
      const paymentMethod: PaymentMethod = await SettlementApiService.addPaymentMethod(
        id,
        idPaymentMethodType,
        nData,
      );

      await SettlementApiService.setPaymentMethods(id, paymentMethod.id, [
        paymentMethodItem.type,
      ]);

      if (paymentMethodAttachments?.length) {
        await SettlementApiService.uploadPaymentMethodAttachments(
          paymentMethod.id,
          paymentMethodAttachments,
        );
      }

      dispatch(loaderActions.decrement());
      message.success(
        locale('messages.markedAsPaid', [
          locale(`paymentMethodType.${paymentMethodItem.type}`),
        ]),
      );
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      dispatch(loaderActions.decrement());
      console.log(e);
      message.error(locale('errors.setAsPaid'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailRemovePaymentMethod = createAsyncThunk(
  'settlementDetail/removePaymentMethod',
  async (
    { paymentMethodItemType }: { paymentMethodItemType: PaymentMethodItemType },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.setPaymentMethods(id, null, [
        paymentMethodItemType,
      ]);
      dispatch(settlementDetailGetById({ id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      dispatch(loaderActions.decrement());
      console.log(e);
      message.error(locale('errors.genericError'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailPayWithStripe = createAsyncThunk(
  'settlementDetail/payWithStripe',
  async (
    {
      paymentMethodItem,
      idStripePaymentMethod,
      paymentMethodType,
    }: {
      paymentMethodItem: PaymentMethodItem;
      idStripePaymentMethod: string;
      paymentMethodType: MethodType;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const idPaymentMethodType = paymentMethodItem.paymentMethodTypes.find(
        t => t.type === paymentMethodType,
      )?.id;

      if (!idPaymentMethodType) {
        throw new Error('Missing Payment Method Type');
      }

      const {
        model: { id },
      } = (<RootState>getState()).settlementDetail;

      const paymentMethod: PaymentMethod = await SettlementApiService.addPaymentMethod(
        id,
        idPaymentMethodType,
        { stripe_payment_method_id: idStripePaymentMethod },
      );

      const { payload } = await dispatch(
        settlementDetailSetPaymentMethodStripe({
          paymentMethod,
          paymentMethodItem,
        }),
      );

      dispatch(loaderActions.decrement());
      return payload;
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updatePaymentMethod'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailSetPaymentMethodStripe = createAsyncThunk(
  'settlementDetail/setPaymentMethodStripe',
  async (
    {
      paymentMethod,
      paymentMethodItem,
    }: {
      paymentMethod: PaymentMethod;
      paymentMethodItem: PaymentMethodItem;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      dispatch(loaderActions.increment());

      const {
        model: { id },
      } = (<RootState>getState()).settlementDetail;

      await SettlementApiService.setPaymentMethods(id, paymentMethod.id, [
        paymentMethodItem.type,
      ]);

      let clientSecret: string | undefined;
      if (paymentMethodItem.mustPayNow) {
        clientSecret = (
          await SettlementApiService.payNow(
            id,
            `${paymentMethod.stripePaymentMethod}`,
            [paymentMethodItem.type],
          )
        ).clientSecret;
      } else {
        message.success(locale('messages.paymentMethodUpdated'));
        dispatch(settlementDetailActions.closeStripeCardModal());
        dispatch(settlementDetailGetById({ id }));
      }

      dispatch(loaderActions.decrement());
      return { paymentMethod, clientSecret };
    } catch (e) {
      console.log(e);
      dispatch(loaderActions.decrement());
      message.error(locale('errors.updatePaymentMethod'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailFinalize = createAsyncThunk(
  'settlementDetail/finalize',
  async (
    { files, notes }: { files: UploadFile[]; notes: string },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;

      files.length && (await SettlementApiService.uploadAttachments(id, files));
      await SettlementApiService.finalize(id, undefined, notes);

      dispatch(loaderActions.decrement());
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      dispatch(loaderActions.decrement());
      message.error(locale('errors.finalize'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailDeleteSign = createAsyncThunk(
  'settlementDetail/deleteSign',
  async (
    { idSettlementContract }: { idSettlementContract: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const { id } = (<RootState>getState()).settlementDetail.model;
      await SettlementApiService.deleteSign(id, idSettlementContract);
      dispatch(loaderActions.decrement());
      message.success(locale('messages.signDeleted'));
      dispatch(settlementDetailGetById({ id }));
    } catch (e) {
      dispatch(loaderActions.decrement());
      message.error(locale('errors.deleteSign'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailStripeIbanSetupIntent = createAsyncThunk(
  'settlementDetail/stripeIbanSetupIntent',
  async (
    { idSettlement }: { idSettlement: number },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const clientSecret = (
        await SettlementApiService.setupIntent(idSettlement)
      ).clientSecret;
      dispatch(loaderActions.decrement());
      return { clientSecret };
    } catch (e) {
      dispatch(loaderActions.decrement());
      console.log(e);
      message.error(locale('errors.genericError'));
      return rejectWithValue({});
    }
  },
);

export const settlementDetailGetPaymentMethods = createAsyncThunk(
  'settlementDetail/getPaymentMethods',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(loaderActions.increment());
      const {
        model: {
          contact: { id },
        },
      } = (<RootState>getState()).settlementDetail;
      const paymentMethods: PaymentMethod[] = await SettlementApiService.getPaymentMethods(
        id,
      );
      dispatch(loaderActions.decrement());
      return { paymentMethods };
    } catch (e) {
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

interface SettlementDetailSliceState {
  step: SettlementStep;
  model?: Settlement;
  paymentMethods: PaymentMethod[];
  paymentMethod?: PaymentMethod;
  paymentMethodItems: PaymentMethodItem[];
  isStripeCardModalVisible: boolean;
  isStripeIbanModalVisible: boolean;
  currentStripeCardModalItem?: PaymentMethodItem;
  currentStripeIbanModalItem?: PaymentMethodItem;
  clientSecret?: string;
  setupIntentClientSecret?: string;
  isEditContractModalVisible: boolean;
  currentEditContractModalContract?: SettlementContract;
  isPaymentConfigsVisible: boolean;
  currentPaymentConfigsModalSettlementOffer?: SettlementOffer;
  currentPaymentConfigsModalPaymentDates?: Date[];
}

const initialState: SettlementDetailSliceState = {
  step: 0,
  model: undefined,
  paymentMethods: [],
  paymentMethod: undefined,
  paymentMethodItems: [],
  isStripeCardModalVisible: false,
  isStripeIbanModalVisible: false,
  currentStripeCardModalItem: undefined,
  currentStripeIbanModalItem: undefined,
  clientSecret: undefined,
  setupIntentClientSecret: undefined,
  isEditContractModalVisible: false,
  currentEditContractModalContract: undefined,
  isPaymentConfigsVisible: false,
  currentPaymentConfigsModalSettlementOffer: undefined,
  currentPaymentConfigsModalPaymentDates: undefined,
};

export const settlementDetailSlice = createSlice({
  name: 'settlementDetail',
  initialState,
  reducers: {
    setStep(state, { payload }) {
      state.step = payload;
    },
    setClientSecret(state, { payload }) {
      state.clientSecret = payload;
    },
    openStripeCardModal(state, { payload: { paymentMethodItem } }) {
      state.currentStripeCardModalItem = paymentMethodItem;
      state.isStripeCardModalVisible = true;
    },
    openStripeIbanModal(state, { payload: { paymentMethodItem } }) {
      state.currentStripeIbanModalItem = paymentMethodItem;
      state.isStripeIbanModalVisible = true;
    },
    closeStripeCardModal(state) {
      state.isStripeCardModalVisible = false;
      state.currentStripeCardModalItem = undefined;
    },
    closeStripeIbanModal(state) {
      state.isStripeIbanModalVisible = false;
      state.currentStripeIbanModalItem = undefined;
    },
    openEditContractModal(state, { payload: { settlementContract } }) {
      state.currentEditContractModalContract = settlementContract;
      state.isEditContractModalVisible = true;
    },
    closeEditContractModal(state) {
      state.isEditContractModalVisible = false;
      state.currentEditContractModalContract = undefined;
    },
    openPaymentConfigsModal(state, { payload: { settlementOffer } }) {
      state.currentPaymentConfigsModalSettlementOffer = settlementOffer;
      state.isPaymentConfigsVisible = true;
      state.currentPaymentConfigsModalPaymentDates = undefined;
    },
    setCurrentPaymentConfigsModalPaymentDates(state, { payload }) {
      state.currentPaymentConfigsModalPaymentDates = payload;
    },
    closePaymentConfigsModal(state) {
      state.isPaymentConfigsVisible = false;
      state.currentPaymentConfigsModalSettlementOffer = undefined;
      state.currentPaymentConfigsModalPaymentDates = undefined;
    },
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${settlementDetailGetById.fulfilled}`]: (state, { payload }) => {
      state.model = payload;
      if (state.currentPaymentConfigsModalSettlementOffer) {
        state.currentPaymentConfigsModalSettlementOffer = state.model?.settlementOffers?.find(
          so => so.id === state.currentPaymentConfigsModalSettlementOffer?.id,
        );
      }
    },
    [`${settlementDetailGetByUuid.fulfilled}`]: (state, { payload }) => {
      state.model = payload;
    },
    [`${settlementDetailGetById.rejected}`]: state => {
      state.model = undefined;
    },
    [`${settlementDetailGetByUuid.rejected}`]: state => {
      state.model = undefined;
    },
    [`${settlementDetailConfirmData.fulfilled}`]: state => {
      if (state.model) {
        state.model = {
          ...state.model,
          status: SettlementStatus.DataConfirmed,
        };
      }
    },
    [`${settlementDetailGetPaymentMethodItems.fulfilled}`]: (
      state,
      { payload },
    ) => {
      state.paymentMethodItems = payload;
    },
    [`${settlementDetailUpdateName.fulfilled}`]: (
      state,
      { payload: { name } },
    ) => {
      if (state.model) {
        state.model = { ...state.model, name };
      }
    },
    [`${settlementDetailUpdateName.rejected}`]: state => {
      if (state.model) {
        state.model = { ...state.model };
      }
    },
    [`${settlementDetailGetPaymentMethods.fulfilled}`]: (
      state,
      { payload: { paymentMethods } },
    ) => {
      state.paymentMethods = paymentMethods;
    },
    [`${settlementDetailGetPaymentMethods.rejected}`]: state => {
      state.paymentMethods = [];
    },
    [`${settlementDetailPayWithStripe.fulfilled}`]: (
      state,
      { payload: { paymentMethod, clientSecret } },
    ) => {
      state.paymentMethod = paymentMethod;
      state.clientSecret = clientSecret;
    },
    [`${settlementDetailStripeIbanSetupIntent.fulfilled}`]: (
      state,
      { payload: { clientSecret } },
    ) => {
      state.setupIntentClientSecret = clientSecret;
    },
    [`${settlementDetailSetPaymentMethodStripe.fulfilled}`]: (
      state,
      { payload: { paymentMethod, clientSecret } },
    ) => {
      state.paymentMethod = paymentMethod;
      state.clientSecret = clientSecret;
    },
  },
});

export const settlementDetailSelector = (
  state: RootState,
): SettlementDetailSliceState => state.settlementDetail;
export const settlementDetailActions = settlementDetailSlice.actions;
