import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Select, Typography } from 'antd';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import locale from '../../../../locale';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { loaderActions } from '../../../loader/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MethodType } from '../../../../models/PaymentMethodType';
import {
  settlementDetailActions,
  settlementDetailGetById,
  settlementDetailSelector,
} from '../../settlementDetailSlice';
import { PaymentMethodItem } from '../../../../models/PaymentMethodItem';
import { Utils } from '../../../../models/Utils';
import { SelectValue } from 'antd/lib/select';
import { PaymentMethod } from '../../../../models/PaymentMethod';

interface StripeCardModalProps {
  onPay: (
    pmItem: PaymentMethodItem,
    currentOption: MethodType,
    data?: Record<string, any>,
  ) => void;
}

/**
 * Modal to set Stripe Card
 * @constructor
 */
export default function StripeCardModal({ onPay }: StripeCardModalProps) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const {
    model,
    isStripeCardModalVisible: isVisible,
    currentStripeCardModalItem: pmItem,
    paymentMethods,
    clientSecret,
  } = useSelector(settlementDetailSelector);

  const cardPaymentMethods = paymentMethods?.filter(
    (pm: PaymentMethod) => pm.paymentMethodType.type === MethodType.StripeCard,
  );
  const cardsAvailable = !!cardPaymentMethods?.length;
  const [showCardSelect, setShowCardSelect] = useState(cardsAvailable);
  const [selectedCard, setSelectedCard] = useState<SelectValue>(
    cardPaymentMethods?.[0]?.id || '',
  );

  useEffect(() => {
    setShowCardSelect(cardsAvailable);
    setSelectedCard(cardPaymentMethods?.[0]?.id);
  }, [cardsAvailable]);

  const [isCardValid, setIsCardValid] = useState(false);

  const canSubmit =
    (showCardSelect && selectedCard) ||
    (!showCardSelect && stripe && isCardValid);

  useEffect(() => {
    isVisible && clientSecret && confirmCardPayment();
  }, [clientSecret, isVisible]);

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    if (!event.complete) {
      setIsCardValid(false);
    } else if (!event.error) {
      setIsCardValid(true);
    }
  };

  const handleCardSubmit = async () => {
    if (showCardSelect && stripe && selectedCard) {
      const registeredCard: PaymentMethod | undefined = paymentMethods?.find(
        (pm: PaymentMethod) => pm.id === selectedCard,
      );
      pmItem && onPay(pmItem, MethodType.StripeCard, { registeredCard });
    } else if (!showCardSelect && elements && stripe) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.update({ hidePostalCode: true });
        dispatch(loaderActions.increment());
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {},
        });
        if (!error) {
          pmItem && onPay(pmItem, MethodType.StripeCard, { paymentMethod });
        } else {
          message.error(error.message);
        }
        dispatch(loaderActions.decrement());
      }
    }
  };

  const confirmCardPayment = async () => {
    if (stripe && clientSecret) {
      dispatch(loaderActions.increment());
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: model?.contact?.paymentMethodId,
      });
      if (payload?.error) {
        message.error(
          locale('errors.paymentFailed', [payload.error.message || '']),
        );
      } else {
        message.success(locale('messages.successfullyPaid'));
        dispatch(settlementDetailActions.closeStripeCardModal());
        model?.id && dispatch(settlementDetailGetById({ id: model.id }));
      }
      dispatch(settlementDetailActions.setClientSecret(undefined));
      dispatch(loaderActions.decrement());
    }
  };

  const onCardSelectChange = (value: SelectValue) => {
    if (value === '+') {
      setShowCardSelect(false);
    } else {
      setSelectedCard(value);
    }
  };

  const cancelNewCard = () => setShowCardSelect(true);

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      title={locale(`titles.${pmItem?.mustPayNow ? 'payWithCard' : 'setCard'}`)}
      onCancel={() => dispatch(settlementDetailActions.closeStripeCardModal())}
      footer={
        <Button
          type="primary"
          htmlType="submit"
          disabled={!canSubmit}
          onClick={handleCardSubmit}
        >
          {locale(
            `actions.${pmItem?.mustPayNow ? 'payNowWithAmount' : 'setCard'}`,
            [
              pmItem?.amount
                ? Utils.getFormattedPrice(
                    pmItem?.amount * ((model?.vatPercentage || 0) / 100 + 1),
                  )
                : '--',
            ],
          )}
        </Button>
      }
    >
      {showCardSelect && (
        <Select
          className="w-100"
          value={selectedCard}
          onChange={onCardSelectChange}
        >
          {cardPaymentMethods?.map((pm: PaymentMethod) => (
            <Select.Option key={pm.id} value={pm.id}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{pm.last4}</span>
                <span>
                  <small>{locale('labels.expiration')}: </small>
                  {pm.expiration}
                </span>
              </div>
            </Select.Option>
          ))}

          <Select.Option value="+">
            + {locale('actions.addNewCard')}
          </Select.Option>
        </Select>
      )}
      {!showCardSelect && (
        <>
          <CardElement className="card-input" onChange={onCardChange} />
          {cardsAvailable && (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Typography.Link onClick={cancelNewCard}>Annulla</Typography.Link>
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
