import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../features/auth/authSlice';
import Stepper, { StepperStep } from '../stepper/Stepper';
import styles from '../../features/settlementDetail/SettlementDetail.module.scss';
import locale from '../../locale';
import { CloserStep } from '../../models/CloserStep.enum';
import { SettlementStep } from '../../models/SettlementStep.enum';

interface SettlementStepperProps {
  step: CloserStep | SettlementStep;
}

/**
 * @param {SettlementStepperProps} props
 * @constructor
 */
export default function SettlementStepper({ step }: SettlementStepperProps) {
  const { user } = useSelector(authSelector);
  const [steps, setSteps] = useState<StepperStep[]>([]);

  useEffect(() => {
    setSteps(
      user?.isCustomer
        ? [
            { key: 'insertData', title: locale(`labels.insertData`) },
            { key: 'sign', title: locale(`labels.sign`) },
            { key: 'payment', title: locale(`labels.payment`) },
            { key: 'additionalInfo', title: locale(`labels.additionalInfo`) },
          ]
        : [
            { key: 'insertData', title: locale(`labels.insertData`) },
            { key: 'confirmData', title: locale(`labels.confirmData`) },
            { key: 'signAndPay', title: locale(`labels.signAndPay`) },
            { key: 'finalize', title: locale(`labels.finalize`) },
            ...(step === 4
              ? [
                  {
                    key: 'finalized',
                    title: locale(`labels.finalized`),
                    status: 'finish' as const,
                  },
                ]
              : []),
          ],
    );
  }, [user, step]);

  return <Stepper step={step} steps={steps} className={styles.stepper} />;
}
