import React, { useState } from 'react';
import { PaymentMethodItemType } from '../../../../../../models/PaymentMethodItem';
import { Button, Card, Form, InputNumber, Typography } from 'antd';
import locale from '../../../../../../locale';
import { useDispatch } from 'react-redux';
import { Utils } from '../../../../../../models/Utils';
import { settlementDetailAddAdvance } from '../../../../settlementDetailSlice';

interface AddAdvanceSectionProps {
  depositAmount?: number;
}

/**
 *
 * @constructor
 */
export function AddAdvanceSection({ depositAmount }: AddAdvanceSectionProps) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);

  return (
    <Card
      key={PaymentMethodItemType.Advance}
      size="small"
      type="inner"
      title={locale(`paymentMethodType.${PaymentMethodItemType.Advance}`)}
      actions={
        isEdit
          ? [
              <Button
                key="cancel"
                onClick={() => {
                  setIsEdit(false);
                  setAmount(0);
                }}
              >
                {locale('actions.cancel')}
              </Button>,
              <Button
                key="add"
                disabled={!(amount > 0)}
                type="primary"
                onClick={async () =>
                  (await Utils.askForConfirmation()) &&
                  dispatch(settlementDetailAddAdvance({ amount }))
                }
              >
                {locale('actions.addAdvanceWithAmount', [
                  Utils.getFormattedPrice(amount),
                ])}
              </Button>,
            ]
          : []
      }
    >
      {!isEdit && (
        <>
          <Typography.Paragraph>
            {locale('subtitles.noAdvance')}
          </Typography.Paragraph>
          <Button onClick={() => setIsEdit(true)}>
            {locale('actions.setAdvance')}
          </Button>
        </>
      )}
      {isEdit && (
        <>
          <Typography.Paragraph>
            {locale('subtitles.setAdvance')}
          </Typography.Paragraph>
          <Form>
            <Form.Item
              rules={[{ required: true }]}
              initialValue={amount / 100}
              label={locale('labels.amountWithValue', [
                locale('labels.currency'),
              ])}
              name="amount"
              getValueProps={() => ({ amount: amount / 100 })}
              required
            >
              <InputNumber
                value={amount / 100}
                min={0}
                {...(depositAmount ? { max: depositAmount / 100 } : {})}
                decimalSeparator=","
                onChange={value => setAmount(value * 100)}
                placeholder={locale('placeholders.amount')}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );
}
