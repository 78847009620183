import { Mapper } from './Mapper';

export interface SettlementDataFieldValue {
  id?: number;
  dataField: number;
  value: string | number | boolean;
}

/* eslint-disable camelcase */
export interface ServerSettlementDataFieldValue {
  id?: number;
  data_field: number;
  value: string;
}

/* eslint-enable camelcase */

/**
 * Map SettlementDataFieldValue model
 */
export abstract class SettlementDataFieldValueMapper
  implements Mapper<SettlementDataFieldValue, ServerSettlementDataFieldValue> {
  /**
   * @param {Partial<ServerSettlementDataFieldValue>} o
   * @return {SettlementDataFieldValue}
   */
  static map(
    o: Partial<ServerSettlementDataFieldValue>,
  ): SettlementDataFieldValue {
    return <SettlementDataFieldValue>{
      id: o.id,
      dataField: o.data_field,
      value: o.value,
    };
  }

  /**
   * @param {Partial<SettlementDataFieldValue>} o
   * @return {ServerSettlementDataFieldValue}
   */
  static mapReverse(
    o: Partial<SettlementDataFieldValue>,
  ): ServerSettlementDataFieldValue {
    return <ServerSettlementDataFieldValue>{
      id: o.id,
      data_field: o.dataField,
      value: `${o.value}`,
    };
  }

  /**
   * @param {ServerSettlementDataFieldValue[]} models
   * @return {SettlementDataFieldValue[]}
   */
  static mapArray(
    models: ServerSettlementDataFieldValue[],
  ): SettlementDataFieldValue[] {
    return models.map(m => SettlementDataFieldValueMapper.map(m));
  }

  /**
   * @param {SettlementDataFieldValue[]} models
   * @return {ServerSettlementDataFieldValue[]}
   */
  static mapReverseArray(
    models: SettlementDataFieldValue[],
  ): ServerSettlementDataFieldValue[] {
    return models.map(m => SettlementDataFieldValueMapper.mapReverse(m));
  }
}
