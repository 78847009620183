import { Mapper } from './Mapper';
import { Utils } from './Utils';
import { ServerSettlementDataFieldCondition } from './SettlementDataFieldCondition';

export enum SettlementDataType {
  Text = 1,
  Email = 2,
  Phone = 3,
  Select = 4,
  Switch = 5,
  Checkbox = 6,
}

export interface SettlementDataField {
  id: number;
  type: SettlementDataType;
  name: string;
  label: string;
  value: string | number | boolean;
  placeholder?: string;

  /** Text shown as tooltip of a question mark */
  tooltip?: string;

  /** Text to show below the field */
  extra?: string;

  /** Text to show after checkbox for DataType.Checkbox */
  text?: string;

  /** Autocomplete value for the browser */
  autocomplete?: string;

  /** Suggest a value */
  suggest?: string | number;

  /** If true the field will be required */
  required?: boolean;

  /**
   * Require the field if the conditions match.
   * In the example below the field will be required if `fiscalCode`
   * is empty so equal to empty string.
   * ex. {..., "requiredConditions": { "fiscalCode": "" }}
   */
  requiredConditions?: Record<string, string | number | boolean>;

  /**
   * Hide the field if the conditions match.
   * In the example below the field will be hidden if `useCompanyEmailForUser`
   * value is `true`.
   * ex. {..., "hiddenConditions": { "useCompanyEmailForUser": true }}
   */
  hiddenConditions?: Record<string, string | number | boolean>;

  /**
   * Preview a value if the condition match.
   * In the example below will be shown the `companyEmail` when the value
   * attribute is `true`:
   * ex. {..., "previewValueCondition": {"companyEmail": true}}
   */
  previewValueCondition?: Record<string, string | number | boolean>;

  /** Options for the field of type DataType.Select */
  options?: {
    value: string;
    label: string;
  }[];

  /** Specify the size for the Col element. Leave empty for auto sizing */
  colSize?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
}

/* eslint-disable camelcase */
export interface ServerSettlementDataField {
  id: number;
  data_type: SettlementDataType;
  label: string;
  value: string | number | boolean;
  placeholder?: string;
  tooltip?: string;
  extra?: string;
  text?: string;
  autocomplete?: string;
  suggest?: string | number;
  required?: boolean;
  required_conditions?: ServerSettlementDataFieldCondition[];
  hidden_conditions?: ServerSettlementDataFieldCondition[];
  preview_value_condition?: ServerSettlementDataFieldCondition;
  dataoption_set?: {
    value: string;
    label: string;
  }[];
  col_size?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
}

/* eslint-enable camelcase */

/**
 * Map SettlementDataField model
 */
export abstract class SettlementDataFieldMapper
  implements Mapper<SettlementDataField, ServerSettlementDataField> {
  /**
   * @param {Partial<ServerSettlementDataField>} o
   * @param {Record<string, { name: string, dataType: SettlementDataType }>} fieldsInfo
   * @return {SettlementDataField}
   */
  static map(
    o: Partial<ServerSettlementDataField>,
    fieldsInfo: Record<string, { name: string; dataType: SettlementDataType }>,
  ): SettlementDataField {
    const requiredConditions: Record<string, string | number | boolean> = {};
    const hiddenConditions: Record<string, string | number | boolean> = {};
    const previewValueCondition: Record<string, string | number | boolean> = {};
    o.required_conditions?.forEach(
      c =>
        (requiredConditions[
          fieldsInfo[`${c.data_field}`].name
        ] = Utils.getSettlementDataFieldValue(
          c.value,
          fieldsInfo[`${c.data_field}`].dataType,
        )),
    );
    o.hidden_conditions?.forEach(
      c =>
        (hiddenConditions[
          fieldsInfo[`${c.data_field}`].name
        ] = Utils.getSettlementDataFieldValue(
          c.value,
          fieldsInfo[`${c.data_field}`].dataType,
        )),
    );
    if (o.preview_value_condition) {
      previewValueCondition[
        fieldsInfo[`${o.preview_value_condition.data_field}`].name
      ] = Utils.getSettlementDataFieldValue(
        o.preview_value_condition.value,
        o.data_type as SettlementDataType,
      );
    }
    return <SettlementDataField>{
      id: o.id,
      type: o.data_type,
      name: `field_${o.id}`,
      label: o.label,
      value: Utils.getSettlementDataFieldValue(
        o.value,
        o.data_type as SettlementDataType,
      ),
      placeholder: o.placeholder,
      tooltip: o.tooltip,
      extra: o.extra,
      text: o.text,
      autocomplete: o.autocomplete,
      suggest: o.suggest,
      required: o.required,
      requiredConditions,
      hiddenConditions,
      previewValueCondition,
      options: o.dataoption_set,
      colSize: o.col_size,
    };
  }

  /**
   * @param {Partial<SettlementDataField>} o
   * @return {ServerSettlementDataField}
   */
  static mapReverse(
    o: Partial<SettlementDataField>,
  ): ServerSettlementDataField {
    return <ServerSettlementDataField>{
      id: o.id,
      data_type: o.type,
      label: o.label,
      value: o.value,
      placeholder: o.placeholder,
      tooltip: o.tooltip,
      extra: o.extra,
      text: o.text,
      autocomplete: o.autocomplete,
      suggest: o.suggest,
      required: o.required,
      // requiredcondition_set: o.requiredConditions,
      // hiddencondition_set: o.hiddenConditions,
      // preview_value_condition: o.previewValueCondition,
      dataoption_set: o.options,
      col_size: o.colSize,
    };
  }

  /**
   * @param {ServerSettlementDataField[]} models
   * @param {Record<string, { name: string, dataType: SettlementDataType }>} fieldsInfo
   * @return {SettlementDataField[]}
   */
  static mapArray(
    models: ServerSettlementDataField[],
    fieldsInfo: Record<string, { name: string; dataType: SettlementDataType }>,
  ): SettlementDataField[] {
    return models.map(m => SettlementDataFieldMapper.map(m, fieldsInfo));
  }

  /**
   * @param {SettlementDataField[]} models
   * @return {ServerSettlementDataField[]}
   */
  static mapReverseArray(
    models: SettlementDataField[],
  ): ServerSettlementDataField[] {
    return models.map(m => SettlementDataFieldMapper.mapReverse(m));
  }
}
