import React from 'react';
import { Settlement } from '../../models/Settlement';
import { Pagination } from '../../models/Pagination';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { ListItem } from '../../components/list/ListItem';
import { Utils } from '../../models/Utils';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import { Affix, Button, Col, PageHeader, Row, Typography } from 'antd';
import { List } from '../../components/list/List';
import { settlementsInit, settlementsNextPage } from './settlementsSlice';
import locale from '../../locale';
import SettlementCreatorModal from '../settlementCreator/components/settlementCreatorModal/SettlementCreatorModal';
import { settlementCreatorActions } from '../settlementCreator/settlementCreatorSlice';
import { FireOutlined, UserOutlined } from '@ant-design/icons';
import SettlementStepper from '../../components/settlementStepper/SettlementStepper';
import './Settlements.scss';
import SettlementSharer from '../settlementDetail/components/settlementSharer/SettlementSharer';

interface SettlementsProps {
  list: Settlement[];
  pagination: Pagination;
  search: string;
  loading: boolean;
  init: (search: string) => void;
  nextPage: () => void;
  create: () => void;
}

interface SettlementsState {
  listItems: ListItem[];
}

/**
 *
 */
class Settlements extends React.Component<SettlementsProps, SettlementsState> {
  state = {
    listItems: [],
  };

  /**
   *
   */
  componentDidMount() {
    this.props.init(this.props.search);
    this.setState({ listItems: this.getListItems() });
  }

  /**
   * @param {Readonly<SettlementsProps>} prevProps
   */
  componentDidUpdate(prevProps: Readonly<SettlementsProps>) {
    if (!Utils.isArrayEqual(prevProps.list, this.props.list)) {
      this.setState({ listItems: this.getListItems() });
    }
  }

  /**
   * @return {ListItem[]}
   */
  getListItems(): ListItem[] {
    return this.props.list.map(item => {
      return {
        key: `${item.uuid}`,
        title: item.name,
        description: (
          <div className="d-flex flex-direction-column">
            <Typography.Text>
              <UserOutlined /> {item.contact?.fullName || '--'}
            </Typography.Text>
            {item.settlementOffers?.map(o => (
              <Typography.Text key={o.id}>
                <FireOutlined /> {o.offer.name || '--'}
              </Typography.Text>
            ))}
            <div className="item-stepper">
              <SettlementStepper step={Utils.getSettlementStep(item)} />
            </div>
          </div>
        ),
        rightItems: [
          <SettlementSharer
            key="sharer"
            title={locale('titles.shareWithContact')}
            subtitle={locale('subtitles.shareWithContact', [
              item.contact?.fullName || locale('placeholders.contact'),
            ])}
            settlement={item}
            collapsed
          />,
        ],
      };
    });
  }

  /**
   *
   * @param {string} uuid
   */
  onItemClick(uuid: string) {
    AppHistory.push(AppRoute.SettlementDetail.replace(':uuid', uuid));
  }

  /**
   *
   */
  onLoadMore() {
    !this.props.loading && this.props.nextPage();
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const { listItems } = this.state;
    const { pagination, loading, init, search } = this.props;

    return (
      <div className="settlements">
        <Affix>
          <PageHeader
            title={locale('titles.contracts')}
            extra={
              <Button type="primary" onClick={() => this.props.create()}>
                {locale('actions.new')}
              </Button>
            }
          />
        </Affix>
        <Row>
          <Col xs={24}>
            {(!loading || !!listItems.length) && (
              <List
                paginated
                search={search}
                hasMore={pagination.hasNext}
                loadMore={() => this.onLoadMore()}
                loading={loading}
                items={listItems}
                onItemClick={uuid => this.onItemClick(uuid)}
                onSearch={init}
              />
            )}
          </Col>
        </Row>
        <SettlementCreatorModal />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  search: state.settlements.search,
  list: state.settlements.list,
  pagination: state.settlements.pagination,
  loading: state.loader.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  init: (search: string) => dispatch(settlementsInit({ search })),
  nextPage: () => dispatch(settlementsNextPage({})),
  create: () => dispatch(settlementCreatorActions.setIsModalVisible(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
