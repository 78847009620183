import React from 'react';
import { Layout, Menu } from 'antd';
import styles from './PlatformLayout.module.scss';
import { Footer } from './footer/Footer';
import { Sider } from './sider/Sider';
import { Header } from 'antd/es/layout/layout';
import logo from '../../../logo.png';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import { ContainerOutlined, LogoutOutlined } from '@ant-design/icons';
import locale from '../../locale';
import { loadStripe } from '@stripe/stripe-js';
import environment from '../../../environment';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { authSelector } from '../../features/auth/authSlice';

interface PlatformLayoutProps {
  children: React.ReactNode;
}

const stripePromise = loadStripe(environment.stripeApiKey);

const PlatformLayout = (props: PlatformLayoutProps) => {
  const { user } = useSelector(authSelector);
  return (
    <Elements stripe={stripePromise}>
      <Layout>
        <Header className={styles.layoutHeader}>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
            <Menu.Item
              key="logo"
              className={styles.logoContainer}
              icon={
                <img
                  src={
                    user?.accounts?.[0]?.logoLight ||
                    user?.accounts?.[0]?.logo ||
                    logo
                  }
                  alt="logo"
                  className={styles.logo}
                />
              }
              onClick={() => AppHistory.push(AppRoute.Root)}
            />
            <Menu.Item
              key="settlementContracts"
              icon={<ContainerOutlined />}
              onClick={() => AppHistory.push(AppRoute.Settlements)}
            >
              {locale('titles.contracts')}
            </Menu.Item>
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              onClick={() => AppHistory.push(AppRoute.Logout)}
            >
              {locale('actions.logout')}
            </Menu.Item>
          </Menu>
        </Header>
        <Layout className={styles.layout}>
          <Sider />
          <Layout>
            <Layout.Content className={styles.content}>
              {props.children}
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
      </Layout>
    </Elements>
  );
};
export default PlatformLayout;
