import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import axios from '../../middlewares/axios';
import { ApiUrl } from '../../models/ApiUrl.enum';
import { message } from 'antd';
import locale from '../../locale';
import { loaderActions } from '../loader/loaderSlice';

export const contractPreviewGetById = createAsyncThunk(
  'contractPreview/getById',
  async (
    payload: { id: number; showSignatures: boolean },
    { dispatch, rejectWithValue },
  ) => {
    try {
      dispatch(loaderActions.increment());
      const params = { show_signatures: payload.showSignatures };
      const content = await axios.get(
        ApiUrl.SettlementContractPreview.replace(':id', `${payload.id}`),
        { params },
      );
      dispatch(loaderActions.decrement());
      return { content };
    } catch (e) {
      message.error(locale('errors.getSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export interface ContractPreviewState {
  content?: string;
}

const initialState: ContractPreviewState = {
  content: undefined,
};

export const contractPreviewSlice = createSlice({
  name: 'contractPreview',
  initialState,
  reducers: {
    setContent: (state, { payload }) => {
      state.content = payload;
    },
  },
  extraReducers: {
    [`${contractPreviewGetById.fulfilled}`]: (state, { payload }) => {
      state.content = payload.content;
      return state;
    },
    [`${contractPreviewGetById.rejected}`]: state => {
      state.content = undefined;
      return state;
    },
  },
});

export const contractPreviewSelector = (
  state: RootState,
): ContractPreviewState => state.contractPreview;

export const contractPreviewActions = contractPreviewSlice.actions;
