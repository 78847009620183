import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorGetDeposits,
  settlementCreatorSelector,
  SettlementCreatorSliceState,
} from '../../settlementCreatorSlice';
import { AmountOption } from '../../../../models/AmountOption';
import { Utils } from '../../../../models/Utils';

interface SettlementCreatorDepositSetterProps {
  onDepositSelected: (deposit: AmountOption) => void;
}

const SettlementCreatorDepositSetter = ({
  onDepositSelected,
}: SettlementCreatorDepositSetterProps) => {
  const dispatch = useDispatch();
  const {
    deposit,
    priceValue,
    depositValue,
    deposits,
  }: SettlementCreatorSliceState = useSelector(settlementCreatorSelector);
  const [cDeposit, setCDeposit] = useState<AmountOption | undefined>(
    deposits?.find(d => d.id === deposit?.id) || deposits?.[0],
  );
  const [cDepositValue, setCDepositValue] = useState<number>(
    depositValue || cDeposit?.amount || 0,
  );

  useEffect(() => {
    setCDeposit(deposits?.find(d => d.id === deposit?.id) || deposits?.[0]);
    dispatch(settlementCreatorGetDeposits());
  }, []);

  useEffect(() => {
    setCDeposit(deposits?.find(d => d.id === deposit?.id) || deposits?.[0]);
  }, [deposit, deposits]);

  useEffect(() => {
    setCDepositValue(depositValue || cDeposit?.amount || 0);
  }, [cDeposit, depositValue]);

  return (
    <div className="p-2">
      <Typography.Paragraph>
        <pre>{locale('subtitles.totalDeposit')}</pre>
      </Typography.Paragraph>
      <Form layout="vertical">
        <Form.Item
          label={locale('labels.deposit')}
          initialValue={cDeposit?.id}
          required
          rules={[{ required: true }]}
          name="deposit"
          extra={locale('subtitles.selectContractDeposit')}
          getValueProps={() => ({ deposit: cDeposit?.id })}
        >
          <Select
            value={cDeposit?.id}
            placeholder={locale('placeholders.selectDeposit')}
            onChange={value => {
              const found = deposits.find(p => p.id === value);
              setCDeposit(found);
            }}
          >
            {deposits?.map(p => (
              <Select.Option key={p.id} value={p.id}>
                {p.isCustom
                  ? locale('labels.other')
                  : Utils.getFormattedPrice(p.amount)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {cDeposit?.isCustom && (
          <Form.Item
            label={locale('labels.amountWithValue', [
              locale('labels.currency'),
            ])}
            initialValue={cDepositValue}
            required
            rules={[{ required: true }]}
            name="amount"
            extra={locale('subtitles.customDepositAmount')}
            getValueProps={() => ({ amount: cDepositValue })}
          >
            <InputNumber
              className="w-100"
              value={cDepositValue / 100}
              placeholder="1000"
              min={0}
              max={(priceValue || 0) / 100}
              decimalSeparator=","
              onChange={value => setCDepositValue(value * 100)}
            />
          </Form.Item>
        )}
        <Button
          type="primary"
          block
          disabled={!cDeposit}
          onClick={() =>
            onDepositSelected({
              ...(cDeposit || {}),
              ...(cDeposit?.isCustom ? { amount: cDepositValue } : {}),
            } as AmountOption)
          }
        >
          {locale('actions.confirmDeposit')}
        </Button>
      </Form>
    </div>
  );
};
export default SettlementCreatorDepositSetter;
