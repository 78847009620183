import { Mapper } from './Mapper';
import {
  ServerSettlementDataSection,
  SettlementDataSection,
  SettlementDataSectionMapper,
} from './SettlementDataSection';
import { SettlementDataType } from './SettlementDataField';

export interface SettlementData {
  id: number;
  sections: SettlementDataSection[];
}

/* eslint-disable camelcase */
export interface ServerSettlementData {
  id: number;
  datasection_set: ServerSettlementDataSection[];
}

/* eslint-enable camelcase */

/**
 * Map SettlementData model
 */
export abstract class SettlementDataMapper
  implements Mapper<SettlementData, ServerSettlementData> {
  /**
   * @param {Partial<ServerSettlementData>} o
   * @return {SettlementData}
   */
  static map(o: Partial<ServerSettlementData>): SettlementData {
    const fieldsInfo: Record<
      string,
      { name: string; dataType: SettlementDataType }
    > = {};
    o.datasection_set?.forEach(s =>
      s.datafield_set?.forEach(
        f =>
          (fieldsInfo[`${f.id}`] = {
            name: `field_${f.id}`,
            dataType: f.data_type,
          }),
      ),
    );
    return <SettlementData>{
      id: o.id,
      sections: o.datasection_set
        ? SettlementDataSectionMapper.mapArray(o.datasection_set, fieldsInfo)
        : [],
    };
  }

  /**
   * @param {Partial<SettlementData>} o
   * @return {ServerSettlementData}
   */
  static mapReverse(o: Partial<SettlementData>): ServerSettlementData {
    return <ServerSettlementData>{
      id: o.id,
      datasection_set: o.sections
        ? SettlementDataSectionMapper.mapReverseArray(o.sections)
        : [],
    };
  }

  /**
   * @param {ServerSettlementData[]} models
   * @return {SettlementData[]}
   */
  static mapArray(models: ServerSettlementData[]): SettlementData[] {
    return models.map(m => SettlementDataMapper.map(m));
  }

  /**
   * @param {SettlementData[]} models
   * @return {ServerSettlementData[]}
   */
  static mapReverseArray(models: SettlementData[]): ServerSettlementData[] {
    return models.map(m => SettlementDataMapper.mapReverse(m));
  }
}
