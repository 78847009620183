import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { contactsActions, contactsSelector } from '../../contactsSlice';
import locale from '../../../../locale';
import Contacts from '../../Contacts';
import { Contact } from '../../../../models/Contact';
import { contactDetailSelector } from '../../../contactDetail/contactDetailSlice';

interface ContactsModalProps {
  onContactSelected: (contact: Contact) => void;
}

/**
 * @constructor
 */
export default function ContactsModal({
  onContactSelected,
}: ContactsModalProps) {
  const dispatch = useDispatch();
  const { isModalVisible } = useSelector(contactsSelector);
  const { isModalVisible: isEditContactModalVisible } = useSelector(
    contactDetailSelector,
  );

  return (
    <Modal
      visible={isModalVisible && !isEditContactModalVisible}
      title={locale('titles.newContact')}
      className="settlement-creator-modal"
      onCancel={() => dispatch(contactsActions.closeModal())}
      footer={null}
    >
      <Contacts onContactSelected={onContactSelected} />
    </Modal>
  );
}
