import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorGetPrices,
  settlementCreatorSelector,
  SettlementCreatorSliceState,
} from '../../settlementCreatorSlice';
import { AmountOption } from '../../../../models/AmountOption';
import { Utils } from '../../../../models/Utils';

interface SettlementCreatorPriceSetterProps {
  onPriceSelected: (price: AmountOption) => void;
}

const SettlementCreatorPriceSetter = ({
  onPriceSelected,
}: SettlementCreatorPriceSetterProps) => {
  const dispatch = useDispatch();
  const {
    priceValue,
    price,
    prices,
  }: SettlementCreatorSliceState = useSelector(settlementCreatorSelector);
  const [cPrice, setCPrice] = useState<AmountOption | undefined>(
    prices?.find(p => p.id === price?.id) || prices?.[0],
  );
  const [cPriceValue, setCPriceValue] = useState<number>(
    priceValue || cPrice?.amount || 0,
  );

  useEffect(() => {
    setCPrice(prices?.find(p => p.id === price?.id) || prices?.[0]);
    dispatch(settlementCreatorGetPrices());
  }, []);

  useEffect(() => {
    setCPrice(prices?.find(p => p.id === price?.id) || prices?.[0]);
  }, [price, prices]);

  useEffect(() => {
    setCPriceValue(priceValue || cPrice?.amount || 0);
  }, [cPrice, priceValue]);

  return (
    <div className="p-2">
      <Typography.Paragraph>
        <pre>{locale('subtitles.totalPrice')}</pre>
      </Typography.Paragraph>
      <Form layout="vertical">
        <Form.Item
          label={locale('labels.price')}
          initialValue={cPrice?.id}
          required
          rules={[{ required: true }]}
          name="price"
          extra={locale('subtitles.selectContractTotalPrice')}
          getValueProps={() => ({ price: cPrice?.id })}
        >
          <Select
            value={cPrice?.id}
            placeholder={locale('placeholders.selectPrice')}
            onChange={value => {
              const found = prices.find(p => p.id === value);
              setCPrice(found);
            }}
          >
            {prices?.map((p, i) => (
              <Select.Option key={p.id || i} value={p.id}>
                {p.isCustom
                  ? locale('labels.other')
                  : Utils.getFormattedPrice(p.amount)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {cPrice?.isCustom && (
          <Form.Item
            label={locale('labels.amountWithValue', [
              locale('labels.currency'),
            ])}
            initialValue={cPriceValue}
            required
            rules={[{ required: true }]}
            name="amount"
            extra={locale('subtitles.customPriceAmount')}
            getValueProps={() => ({ amount: cPriceValue })}
          >
            <InputNumber
              className="w-100"
              value={cPriceValue / 100}
              placeholder="1000"
              min={0}
              decimalSeparator=","
              onChange={value => setCPriceValue(value * 100)}
            />
          </Form.Item>
        )}
        <Button
          type="primary"
          block
          disabled={!cPrice}
          onClick={() =>
            onPriceSelected({
              ...(cPrice || {}),
              ...(cPrice?.isCustom ? { amount: cPriceValue } : {}),
            } as AmountOption)
          }
        >
          {locale('actions.confirmTotalPrice')}
        </Button>
      </Form>
    </div>
  );
};
export default SettlementCreatorPriceSetter;
