import { SettlementStep } from './SettlementStep.enum';

export enum CloserStep {
  InitialOverview = -1,
  Data = 0,
  Signer = 1,
  Payer = 2,
  Finalizer = 3,
  FinalOverview = 4,
}

export const settlementStepCloserStepMap = {
  [SettlementStep.InsertData]: CloserStep.Data,
  [SettlementStep.ConfirmData]: CloserStep.Data,
  [SettlementStep.SignPay]: CloserStep.Signer,
  [SettlementStep.Finalize]: CloserStep.Finalizer,
  [SettlementStep.FinalOverview]: CloserStep.FinalOverview,
};
