import { Mapper } from './Mapper';
import {
  OtpResponse,
  OtpResponseMapper,
  ServerOtpResponse,
} from './OtpResponse';

export interface OtpRequest {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  ipAddress: string;
  otpResponse: OtpResponse;
  sendCodeAttemptDate: Date;
}

/* eslint-disable camelcase */
export interface ServerOtpRequest {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  ip_address: string;
  otp_response: ServerOtpResponse;
  send_code_attempt_datetime: string;
}

/* eslint-enable camelcase */

/**
 * Map OtpRequest model
 */
export abstract class OtpRequestMapper
  implements Mapper<OtpRequest, ServerOtpRequest> {
  /**
   * @param {Partial<ServerOtpRequest>} o
   * @return {OtpRequest}
   */
  static map(o: Partial<ServerOtpRequest>): OtpRequest {
    return <OtpRequest>{
      id: o.id,
      firstName: o.first_name,
      lastName: o.last_name,
      phone: o.phone,
      ipAddress: o.ip_address,
      otpResponse: o.otp_response
        ? OtpResponseMapper.map(o.otp_response)
        : undefined,
      sendCodeAttemptDate: o.send_code_attempt_datetime
        ? new Date(o.send_code_attempt_datetime)
        : undefined,
    };
  }

  /**
   * @param {Partial<OtpRequest>} o
   * @return {ServerOtpRequest}
   */
  static mapReverse(o: Partial<OtpRequest>): ServerOtpRequest {
    return <ServerOtpRequest>{
      id: o.id,
      first_name: o.firstName,
      last_name: o.lastName,
      phone: o.phone,
      ip_address: o.ipAddress,
      otp_response: o.otpResponse
        ? OtpResponseMapper.mapReverse(o.otpResponse)
        : undefined,
      send_code_attempt_datetime: o.sendCodeAttemptDate?.toISOString() || null,
    };
  }

  /**
   * @param {ServerOtpRequest[]} models
   * @return {OtpRequest[]}
   */
  static mapArray(models: ServerOtpRequest[]): OtpRequest[] {
    return models.map(m => OtpRequestMapper.map(m));
  }

  /**
   * @param {OtpRequest[]} models
   * @return {ServerOtpRequest[]}
   */
  static mapReverseArray(models: OtpRequest[]): ServerOtpRequest[] {
    return models.map(m => OtpRequestMapper.mapReverse(m));
  }
}
