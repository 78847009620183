import * as React from 'react';
import locale from '../../locale';
import { Result, Button } from 'antd';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';

const NotFound = () => {
  return (
    <Result
      status="404"
      title={locale('titles.pageNotFound')}
      subTitle={locale('messages.notFound')}
      extra={
        <Button type="primary" onClick={() => AppHistory.push(AppRoute.Root)}>
          {locale('actions.backToContracts')}
        </Button>
      }
    />
  );
};

export default NotFound;
