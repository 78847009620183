import { Mapper } from './Mapper';

export interface Product {
  id: number;
  name: string;
}

/* eslint-disable camelcase */
export interface ServerProduct {
  id: number;
  name: string;
}

/* eslint-enable camelcase */

/**
 * Map Product model
 */
export abstract class ProductMapper implements Mapper<Product, ServerProduct> {
  /**
   * @param {Partial<ServerProduct>} o
   * @return {Product}
   */
  static map(o: Partial<ServerProduct>): Product {
    return <Product>{
      id: o.id,
      name: o.name,
    };
  }

  /**
   * @param {Partial<Product>} o
   * @return {ServerProduct}
   */
  static mapReverse(o: Partial<Product>): ServerProduct {
    return <ServerProduct>{
      id: o.id,
      name: o.name,
    };
  }

  /**
   * @param {ServerProduct[]} models
   * @return {Product[]}
   */
  static mapArray(models: ServerProduct[]): Product[] {
    return models.map(m => ProductMapper.map(m));
  }

  /**
   * @param {Product[]} models
   * @return {ServerProduct[]}
   */
  static mapReverseArray(models: Product[]): ServerProduct[] {
    return models.map(m => ProductMapper.mapReverse(m));
  }
}
