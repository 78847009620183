import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Select, Typography } from 'antd';
import locale from '../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorGetDurations,
  settlementCreatorSelector,
  SettlementCreatorSliceState,
} from '../../settlementCreatorSlice';
import { AmountOption } from '../../../../models/AmountOption';
import { Duration } from '../../../../models/Duration';
import { DurationType } from '../../../../models/DurationType.enum';

interface SettlementCreatorDurationSetterProps {
  onSubmit: (data?: {
    contractDuration: AmountOption;
    contractDurationValue: number;
    contractDurationType: DurationType;
  }) => void;
}

const SettlementCreatorDurationSetter = ({
  onSubmit,
}: SettlementCreatorDurationSetterProps) => {
  const dispatch = useDispatch();
  const {
    numberOfInstallmentsValue,
    contractDuration,
    durationsList,
  }: SettlementCreatorSliceState = useSelector(settlementCreatorSelector);
  const [cDuration, setCDuration] = useState<Duration | undefined>(
    durationsList?.find(rn => rn.id === contractDuration?.id) ||
      durationsList?.[0],
  );

  useEffect(() => {
    setCDuration(
      durationsList?.find(rn => rn.id === contractDuration?.id) ||
        durationsList?.[0],
    );
    dispatch(settlementCreatorGetDurations());
  }, []);

  useEffect(() => {
    setCDuration(
      durationsList?.find(rn => rn.id === contractDuration?.id) ||
        durationsList?.[0],
    );
  }, [contractDuration, durationsList]);

  const [cDurationValue, setCDurationValue] = useState<number>(
    numberOfInstallmentsValue || cDuration?.duration?.amount || 0,
  );

  return (
    <div className="p-2">
      <Typography.Paragraph>
        <pre>{locale('subtitles.duration')}</pre>
      </Typography.Paragraph>
      {!!durationsList?.length && (
        <Form layout="vertical">
          <Form.Item
            label={locale('labels.duration')}
            initialValue={cDuration?.id}
            required
            rules={[{ required: true }]}
            name="amount"
            extra={locale('subtitles.selectContractDuration')}
            getValueProps={() => ({ amount: cDuration?.id })}
          >
            <Select
              value={cDuration?.id}
              placeholder={locale('placeholders.selectDuration')}
              onChange={value => {
                const found = durationsList.find(p => p.id === value);
                setCDuration(found);
              }}
            >
              {durationsList?.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {p.duration.isCustom &&
                    locale('labels.otherDuration', [
                      locale(`durationType.${p.durationType}`),
                    ])}
                  {!p.duration.isCustom && (
                    <>
                      {p.duration.amount}{' '}
                      {locale(
                        `durationType.${p.durationType}`,
                        undefined,
                        p.duration.amount || 2,
                      )}
                    </>
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {cDuration?.duration.isCustom && (
            <Form.Item
              label={locale('labels.customDuration')}
              initialValue={cDurationValue}
              required
              rules={[{ required: true }]}
              name="cduration"
              extra={locale('subtitles.customDuration')}
              getValueProps={() => ({ cduration: cDurationValue })}
              tooltip={locale('subtitles.customChoice')}
            >
              <div className="d-flex">
                <InputNumber
                  className="w-100"
                  value={cDurationValue}
                  placeholder="2"
                  min={0}
                  decimalSeparator=","
                  onChange={setCDurationValue}
                />
                <span className="input-number-suffix">
                  {locale(
                    `durationType.${cDuration?.durationType}`,
                    undefined,
                    cDurationValue || 2,
                  )}
                </span>
              </div>
            </Form.Item>
          )}
          <Button
            type="primary"
            block
            disabled={!cDuration}
            onClick={() =>
              cDuration &&
              onSubmit({
                contractDuration: cDuration.duration,
                contractDurationType: cDuration.durationType,
                contractDurationValue: cDurationValue,
              })
            }
          >
            {locale('actions.confirmDuration')}
          </Button>
        </Form>
      )}
      {!durationsList.length && (
        <>
          <Typography.Paragraph>
            {locale('subtitles.noRatesForCurrentOffer')}
          </Typography.Paragraph>
          <Button type="primary" block onClick={() => onSubmit()}>
            {locale('actions.goAhead')}
          </Button>
        </>
      )}
    </div>
  );
};
export default SettlementCreatorDurationSetter;
