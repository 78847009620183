import React from 'react';
import { RootState } from '../../store/store';
import { connect } from 'react-redux';
import styles from './Loader.module.scss';
import classNames from 'classnames';

interface LoaderProps {
  loading?: boolean;
}

/**
 *
 * @param {Props} props
 * @constructor
 */
function Loader(props: LoaderProps): JSX.Element | null {
  const { loading } = props;
  return loading ? (
    <div className={styles.loader}>
      <div
        className={classNames(
          'spinner-border spinner-border-sm te xt-primary',
          styles.spinner,
        )}
        role="status"
      >
        <span className={styles.loading}>Loading</span>
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state: RootState) => ({
  loading: state.loader.loading,
});

export default connect(mapStateToProps)(Loader);
