import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { Settlement, SettlementStatus } from '../../../../models/Settlement';
import { SettlementDataSection as SettlementDataSectionModel } from '../../../../models/SettlementDataSection';
import locale from '../../../../locale';
import {
  CheckCircleFilled,
  CheckOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  ImportOutlined,
  RollbackOutlined,
  SaveOutlined,
  ShopOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './SettlementDataSection.scss';
import { Utils } from '../../../../models/Utils';
import classNames from 'classnames';
import {
  SettlementDataField,
  SettlementDataType,
} from '../../../../models/SettlementDataField';
import { SettlementDataFieldValue } from '../../../../models/SettlementDataFieldValue';
import { FieldData } from '../../../../models/FieldData';

interface SettlementDataSectionProps {
  isCustomer?: boolean;
  settlement: Settlement;
  canEdit: boolean;
  onSubmit: (dataFieldValues: SettlementDataFieldValue[]) => void;
  onConfirm: () => void;
}

/**
 * @param {SettlementDataSectionProps} props
 * @constructor
 */
export function SettlementDataSection({
  isCustomer,
  settlement,
  canEdit,
  onSubmit,
  onConfirm,
}: SettlementDataSectionProps) {
  const sections = settlement.config?.data?.sections || [];
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [fields, setFields] = useState<FieldData[]>([]);
  const [currentFields, setCurrentFields] = useState<Record<string, FieldData>>(
    {},
  );
  const [collapsed, setCollapsed] = useState<boolean>(false);

  /** Set initial fields on component mount */
  useEffect(() => setIsEdit(settlement?.status == SettlementStatus.Created), [
    settlement,
  ]);

  /** Set initial fields on component mount */
  useEffect(() => updateFields(getInitialFields()), [settlement]);

  /**
   * Get initial fields values from Settlement
   *
   * @return {FieldData[]}
   */
  const getInitialFields = () => {
    const initialFields: FieldData[] = [];
    settlement.config?.data?.sections?.forEach(section =>
      section.fields?.forEach(field =>
        initialFields.push({ name: [field.name], value: field.value }),
      ),
    );
    return initialFields;
  };

  /**
   * Update fields and currentFields in the state so they are updated in the
   * same time and you can check currentFields with better performances because
   * is an object.
   *
   * @param {FieldData[]} list
   * @return {void}
   */
  const updateFields = (list: FieldData[]) => {
    const o: Record<string, FieldData> = {};
    list.forEach(f => (o[(f.name as string[])[0]] = f));
    setCurrentFields(o);
    setFields(list);
  };

  /** Map data and send to parent */
  const submit = async () => {
    const ids: Record<string, number> = {};
    settlement.config?.data?.sections?.forEach(section =>
      section.fields?.forEach(field => (ids[field.name] = field.id)),
    );
    const dataFieldValues: SettlementDataFieldValue[] = [];
    Object.keys(currentFields).forEach(k => {
      const dataField = ids[k];
      if (dataField) {
        const dataFieldValue = { dataField, value: '' };
        const el = currentFields[k];
        if (!el.errors?.length) {
          dataFieldValue.value = el.value;
        } else {
          const prevElement = fields.find(f => (f.name as string[])[0] === k);
          if (prevElement) {
            dataFieldValue.value = prevElement.value;
          }
        }
        dataFieldValues.push(dataFieldValue);
      }
    });
    onSubmit(dataFieldValues);
  };

  /**
   * Edit form
   * @return {void}
   */
  const onEdit = () => {
    setCollapsed(false);
    setIsEdit(true);
  };

  /** Confirm form data */
  const confirm = async () => {
    if (
      await Utils.askForConfirmation({ okText: locale('actions.confirmData') })
    ) {
      onConfirm();
    }
  };

  /** Reset form data with initialValues */
  const onCancel = async () => {
    updateFields(getInitialFields());
    if (settlement?.status >= SettlementStatus.DataFilled) {
      setIsEdit(false);
    }
    message.success(locale('messages.contractDataCanceled'));
  };

  const fillInput = (key: string, value: string | number) =>
    updateFields(
      fields.map(f => ((f.name as string[])[0] === key ? { ...f, value } : f)),
    );

  /**
   * Validate singe field
   *
   * @param {SettlementDataField} field
   * @return {boolean}
   */
  const isFieldValid = (field: SettlementDataField): boolean => {
    const isHidden = isFieldHidden(field);
    if (isHidden) {
      return true;
    }
    const hasErrors = !!currentFields[field.name]?.errors?.length;
    const isEmpty =
      ![SettlementDataType.Switch, SettlementDataType.Checkbox].includes(
        field.type,
      ) && !currentFields[field.name]?.value?.trim().length;
    return !hasErrors && !isEmpty;
  };

  /**
   * Get if field is required
   *
   * @param {SettlementDataField} field
   * @return {boolean}
   */
  const isFieldRequired = (field: SettlementDataField): boolean => {
    const hasRequiredConditions = !Utils.isObjectEmpty(
      field.requiredConditions || {},
    );
    return (
      (!hasRequiredConditions && field.required) ||
      (hasRequiredConditions &&
        Object.keys(field.requiredConditions || {}).every(
          k => currentFields[k]?.value === field.requiredConditions?.[k],
        ))
    );
  };

  /**
   * Get if field is hidden
   *
   * @param {SettlementDataField} field
   * @return {boolean}
   */
  const isFieldHidden = (field: SettlementDataField): boolean =>
    !!field.hiddenConditions &&
    !Utils.isObjectEmpty(field.hiddenConditions) &&
    Object.keys(field.hiddenConditions).every(
      k => currentFields[k]?.value === field.hiddenConditions?.[k],
    );

  /**
   * Get if a section is valid so completed
   *
   * @param {SettlementDataSection} section
   * @return {boolean}
   */
  const isSectionValid = (section: SettlementDataSectionModel): boolean =>
    section.fields.every(f => !isFieldRequired(f) || isFieldValid(f));

  /**
   * @return {React.ReactNode}
   */
  const renderSections = (): React.ReactNode => {
    if (isEdit) {
      return (
        <Form
          layout="vertical"
          fields={fields}
          onFieldsChange={(_, allFields) => updateFields(allFields)}
        >
          <Row gutter={[8, 16]}>
            {settlement.config?.data?.sections?.map(renderSection)}
          </Row>
        </Form>
      );
    }
    return (
      <Row gutter={[8, 16]}>
        {settlement.config?.data?.sections?.map(renderSection)}
      </Row>
    );
  };

  /**
   * @param {SettlementDataSectionModel} section
   * @return {React.ReactNode}
   */
  const renderSection = (
    section: SettlementDataSectionModel,
  ): React.ReactNode => {
    const fields = section.fields;

    let icon;
    switch (section.icon) {
      case 'ShopOutlined':
        icon = <ShopOutlined />;
        break;
      case 'FileTextOutlined':
        icon = <FileTextOutlined />;
        break;
      case 'EnvironmentOutlined':
        icon = <EnvironmentOutlined />;
        break;
      case 'UserOutlined':
        icon = <UserOutlined />;
        break;
    }

    return (
      <Col xs={24} md={12} xl={8} key={section.name}>
        {isEdit && (
          <Card
            type="inner"
            size="small"
            className="h-100"
            extra={
              isSectionValid(section) ? (
                <CheckCircleFilled className="text-success" />
              ) : null
            }
            title={
              <>
                {icon} {section.title}
              </>
            }
          >
            <Row gutter={[16, 0]}>{fields.map(renderField)}</Row>
          </Card>
        )}
        {!isEdit && (
          <Descriptions
            size="small"
            bordered
            key={section.name}
            className="mb-2"
            column={1}
            title={
              <>
                {icon} {section.title}
              </>
            }
          >
            {fields.map(renderField)}
          </Descriptions>
        )}
      </Col>
    );
  };

  /**
   * Render single field in a section
   *
   * @param {SettlementDataField} field
   * @return {React.ReactNode}
   */
  const renderField = (field: SettlementDataField): React.ReactNode => {
    let item;
    const dynamicProps: Record<string, any> = {};
    const currentField = currentFields[field.name];
    const hidden = isFieldHidden(field);

    if (!isEdit) {
      const value =
        field.type === SettlementDataType.Checkbox ||
        field.type === SettlementDataType.Switch
          ? locale(`labels.${field.value ? 'yes' : 'no'}`)
          : field.value;
      return (
        !hidden && (
          <Descriptions.Item label={field.label} key={field.name}>
            {value || '--'}
          </Descriptions.Item>
        )
      );
    }

    const isValid = isFieldValid(field);

    switch (field.type) {
      case SettlementDataType.Email:
      case SettlementDataType.Text:
      case SettlementDataType.Phone:
        if (field.type === SettlementDataType.Email) {
          dynamicProps.rules = [{ type: 'email' }];
        }
        if (currentField?.value?.length && isValid) {
          dynamicProps.hasFeedback = true;
          dynamicProps.validateStatus = 'success';
        }
        item = (
          <Input
            placeholder={field.placeholder}
            autoComplete={field.autocomplete || ''}
            suffix={
              field.suggest && (
                <Tooltip title={field.suggest}>
                  <ImportOutlined
                    onClick={() =>
                      field.suggest && fillInput(field.name, field.suggest)
                    }
                  />
                </Tooltip>
              )
            }
          />
        );
        break;
      case SettlementDataType.Select:
        if (currentField?.value?.length && isValid) {
          dynamicProps.hasFeedback = true;
          dynamicProps.validateStatus = 'success';
        }
        item = (
          <Select>
            <Select.Option value="" disabled>
              {locale('labels.noOne')}
            </Select.Option>
            {field.options?.map(opt => (
              <Select.Option key={opt.value} value={opt.value}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
        );
        break;
      case SettlementDataType.Switch:
        dynamicProps.valuePropName = 'checked';
        item = <Switch />;
        break;
      case SettlementDataType.Checkbox:
        dynamicProps.valuePropName = 'checked';
        item = <Checkbox>{field.text}</Checkbox>;
        break;
    }

    const required = isFieldRequired(field);
    const sizes = field.colSize ? field.colSize : { xs: 24 };
    let preview;
    if (
      field.previewValueCondition &&
      !Utils.isObjectEmpty(field.previewValueCondition)
    ) {
      const k = Object.keys(field.previewValueCondition)[0];
      if (
        field.previewValueCondition[k] === currentField?.value &&
        currentFields[k]?.value
      ) {
        preview = currentFields[k].value;
      }
    }

    return (
      <Col key={field.name} {...sizes}>
        <Form.Item
          label={field.label}
          name={field.name}
          tooltip={field.tooltip}
          required={required}
          hidden={hidden}
          extra={field.extra}
          help={preview}
          {...dynamicProps}
        >
          {item}
        </Form.Item>
      </Col>
    );
  };

  /**
   * Render card actions
   * @return {React.ReactNode[] | undefined}
   */
  const renderActions = (): React.ReactNode[] | undefined => {
    if (isEdit) {
      return [
        <Button
          key="cancel"
          type="default"
          icon={<RollbackOutlined />}
          onClick={onCancel}
        >
          {locale('actions.cancelChanges')}
        </Button>,
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={submit}
        >
          {locale('actions.save')}
        </Button>,
      ];
    } else if (settlement.status < SettlementStatus.DataConfirmed) {
      return [
        <Button
          key="edit"
          type="default"
          icon={<EditOutlined />}
          onClick={onEdit}
        >
          {locale('actions.edit')}
        </Button>,
        <Button
          key="confirmData"
          type="primary"
          icon={<CheckOutlined />}
          onClick={confirm}
        >
          {locale('actions.confirmData')}
        </Button>,
      ];
    }
  };

  const sectionsOverview = isEdit ? (
    <Typography.Text key="overview" type="secondary">
      <span
        dangerouslySetInnerHTML={{
          __html: locale('subtitles.completedSections', [
            `${sections.filter(isSectionValid).length}`,
            `${sections.length}`,
          ]),
        }}
      />
    </Typography.Text>
  ) : canEdit ? (
    <Button key="edit" type="link" onClick={onEdit}>
      {locale('actions.edit')}
    </Button>
  ) : null;

  if (isCustomer) {
    return (
      <>
        {renderSections()}
        {canEdit && (
          <Button
            key="save"
            type="primary"
            block
            size="large"
            className="mt-3"
            icon={<CheckOutlined />}
            onClick={submit}
          >
            {locale('actions.confirmData')}
          </Button>
        )}
      </>
    );
  }

  return settlement ? (
    <Card
      size="small"
      className={classNames('settlement-data', { 'h-100': !collapsed })}
      title={
        <div
          onClick={() => setCollapsed(!collapsed)}
          className="cursor-pointer"
        >
          <Button
            size="small"
            type="text"
            className="mr-1"
            icon={collapsed ? <DownOutlined /> : <UpOutlined />}
          />
          {locale('titles.contractData')}
        </div>
      }
      extra={sectionsOverview}
      actions={!collapsed ? renderActions() : []}
    >
      {!collapsed && renderSections()}
    </Card>
  ) : null;
}
