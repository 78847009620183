import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Tabs } from 'antd';
import locale from '../../../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementDetailActions,
  settlementDetailSelector,
  settlementDetailUpdateContract,
} from '../../../../settlementDetailSlice';
import { SettlementContractBlock } from '../../../../../../models/SettlementContractBlock';
import { EyeOutlined, SaveOutlined } from '@ant-design/icons';
import { AppRoute } from '../../../../../../models/appRouter/AppRoute.enum';

import Editor from 'react-simple-code-editor';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import './EditContractModal.scss';

/**
 * Modal to set Stripe Card
 * @constructor
 */
export default function EditContractModal() {
  const dispatch = useDispatch();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [contractBlocks, setContractBlocks] = useState<
    SettlementContractBlock[]
  >([]);

  const {
    isEditContractModalVisible: isVisible,
    currentEditContractModalContract,
  } = useSelector(settlementDetailSelector);

  useEffect(() => {
    setContractBlocks(
      currentEditContractModalContract?.settlementContractBlocks || [],
    );
  }, [currentEditContractModalContract]);

  useEffect(() => {
    setCanSubmit(contractBlocks.every(cb => cb.content?.trim().length));
  }, [contractBlocks]);

  return (
    <Modal
      className="edit-contract-modal modal-xxl"
      destroyOnClose
      visible={isVisible}
      title={locale('titles.editContract')}
      onCancel={() =>
        dispatch(settlementDetailActions.closeEditContractModal())
      }
      footer={
        <div className="d-flex justify-content-space-between">
          <Button
            htmlType="submit"
            disabled={!canSubmit}
            icon={<EyeOutlined />}
            target="_blank"
            href={AppRoute.ContractPreview.replace(
              ':id',
              `${currentEditContractModalContract?.id}`,
            )}
          >
            {locale('actions.preview')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            disabled={!canSubmit}
            onClick={() =>
              dispatch(
                settlementDetailUpdateContract({
                  settlementContractBlocks: contractBlocks.filter(
                    (cb, i) =>
                      cb.content !==
                      currentEditContractModalContract
                        ?.settlementContractBlocks[i].content,
                  ),
                }),
              )
            }
          >
            {locale('actions.save')}
          </Button>
        </div>
      }
    >
      <Form>
        <Tabs>
          {contractBlocks.map((cb, i) => (
            <Tabs.TabPane key={cb.id} tab={cb.name || `#${i + 1}`}>
              <Form.Item key={cb.contractBlockId}>
                <Editor
                  value={cb.content}
                  onValueChange={content =>
                    setContractBlocks(
                      contractBlocks.map((cb2, j) =>
                        i === j ? { ...cb, content } : cb2,
                      ),
                    )
                  }
                  highlight={code => highlight(code, languages.js)}
                  padding={10}
                  style={{ border: '1px solid #f0f0f0' }}
                />
              </Form.Item>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Form>
    </Modal>
  );
}
