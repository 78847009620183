import React from 'react';
import { Layout, Typography } from 'antd';
import styles from './CustomerLayout.module.scss';
import { Footer } from './footer/Footer';
import OtpHandler from '../../features/otp/OtpHandler';
import { Header } from 'antd/es/layout/layout';
import logo from '../../../logo.png';
import { loadStripe } from '@stripe/stripe-js';
import environment from '../../../environment';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { settlementCloserSelector } from '../../features/settlementCloser/settlementCloserSlice';
import { LockFilled } from '@ant-design/icons';
import locale from '../../locale';

interface CustomerLayoutProps {
  children: React.ReactNode;
}

const stripePromise = loadStripe(environment.stripeApiKey);

const CustomerLayout = (props: CustomerLayoutProps) => {
  const { model, step } = useSelector(settlementCloserSelector);

  return (
    <Elements stripe={stripePromise}>
      <Layout>
        {step > -1 && (
          <Header className={styles.layoutHeader}>
            <div className="d-flex justify-content-space-between h-100 px-2">
              <div className="d-flex align-items-center">
                <img
                  src={model?.account.logoLight || model?.account.logo || logo}
                  alt="logo"
                  className={styles.logo}
                />
                <Typography.Title
                  className={styles.layoutHeaderTitle}
                  level={5}
                >
                  {model?.settlementOffers
                    ?.map(so => so.offer.name)
                    .join(' | ') || '--'}
                </Typography.Title>
              </div>
              <div className="d-flex align-items-center text-white">
                <LockFilled />
                <Typography.Text className="text-white ml-1">
                  {locale('labels.safeCheckout')}
                </Typography.Text>
              </div>
            </div>
          </Header>
        )}
        <Layout className={styles.layout}>
          <Layout>
            <Layout.Content className={styles.content}>
              {props.children}
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
        {step > -1 && <OtpHandler />}
      </Layout>
    </Elements>
  );
};
export default CustomerLayout;
