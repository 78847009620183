import React, { useEffect, useState } from 'react';
import { List } from '../../../../components/list/List';
import { ListItem } from '../../../../components/list/ListItem';
import { useSelector } from 'react-redux';
import {
  settlementCreatorSelector,
  SettlementCreatorSliceState,
} from '../../settlementCreatorSlice';
import locale from '../../../../locale';
import {
  ClockCircleOutlined,
  CodeSandboxOutlined,
  DeleteOutlined,
  EuroOutlined,
  FireOutlined,
  NumberOutlined,
  SafetyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Utils } from '../../../../models/Utils';
import { SettlementOffer } from '../../../../models/SettlementOffer';

interface SettlementCreatorOverviewProps {
  onAddSettlementOffer: () => void;
  onDeleteSettlementOffer: (data: { index?: number; id?: number }) => void;
  onSetCurrentSettlementOffer: (settlementOffer: SettlementOffer) => void;
  onEdit: (step: number) => void;
}

const SettlementCreatorOverview = ({
  onDeleteSettlementOffer,
  onSetCurrentSettlementOffer,
  onAddSettlementOffer,
  onEdit,
}: SettlementCreatorOverviewProps) => {
  const {
    step,
    contact,
    product,
    offer,
    price,
    priceValue,
    deposit,
    depositValue,
    contractDuration,
    contractDurationValue,
    contractDurationType,
    numberOfInstallments,
    numberOfInstallmentsList,
    numberOfInstallmentsValue,
    installmentAmount,
    settlementOffers,
  }: SettlementCreatorSliceState = useSelector(settlementCreatorSelector);
  const [items, setItems] = useState<ListItem[]>([]);

  useEffect(() => setListItems(), []);
  useEffect(() => setListItems(), [step, settlementOffers]);

  const setListItems = () => {
    const nextItems: ListItem[] = [];
    if (price && step > 6) {
      nextItems.push(
        ...[
          {
            key: 'durationTitle',
            title: locale('titles.duration'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(6)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'duration',
            title: (
              <>
                {!!contractDuration && (
                  <span>
                    {contractDurationValue}{' '}
                    {locale(
                      `durationType.${contractDurationType}`,
                      undefined,
                      contractDurationValue || 2,
                    )}
                  </span>
                )}
              </>
            ),
            leftItem: <ClockCircleOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (price && step > 5) {
      nextItems.push(
        ...[
          {
            key: 'rateNumberTitle',
            title: locale('titles.numberOfInstallments'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(5)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'numberOfInstallments',
            title: (
              <>
                {!!numberOfInstallmentsList.length && (
                  <>
                    {locale('labels.rateNumberWithAmount', [
                      numberOfInstallmentsValue || '--',
                      Utils.getFormattedPrice(installmentAmount || 0),
                    ])}{' '}
                    {numberOfInstallments?.isCustom && (
                      <small>({locale('labels.isCustom')})</small>
                    )}
                  </>
                )}
                {!numberOfInstallmentsList?.length &&
                  locale('subtitles.noRatesForCurrentOffer')}
              </>
            ),
            leftItem: <NumberOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (price && step > 4) {
      nextItems.push(
        ...[
          {
            key: 'activationTitle',
            title: locale('titles.activation'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(4)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'activation',
            title: (
              <>
                {Utils.getFormattedPrice(depositValue || 0)}{' '}
                {deposit?.isCustom && (
                  <small>({locale('labels.isCustom')})</small>
                )}
              </>
            ),
            leftItem: <SafetyOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (price && step > 3) {
      nextItems.push(
        ...[
          {
            key: 'totalPriceTitle',
            title: locale('titles.totalPrice'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(3)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'totalPrice',
            title: (
              <>
                {Utils.getFormattedPrice(priceValue || 0)}{' '}
                {price.isCustom && <small>({locale('labels.isCustom')})</small>}
              </>
            ),
            leftItem: <EuroOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (offer && step > 2) {
      nextItems.push(
        ...[
          {
            key: 'offerTitle',
            title: locale('titles.offer'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(2)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'offer',
            title: offer.name,
            leftItem: <FireOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (product && step > 1) {
      nextItems.push(
        ...[
          {
            key: 'productTitle',
            title: locale('titles.product'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(1)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'product',
            title: product.name,
            leftItem: <CodeSandboxOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    if (step === -1) {
      nextItems.push(
        {
          key: 'settlementOffersTitle',
          title: locale('titles.settlementOffers'),
          rightItems: [
            <Tooltip key="add" title="⏱ Coming Soon">
              <Button disabled type="link" onClick={onAddSettlementOffer}>
                {locale('actions.addOffer')}
              </Button>
            </Tooltip>,
          ],
          isSectionTitle: true,
        },
        ...settlementOffers?.map((so, i) => ({
          key: `so_${i}`,
          title: so.offer.name,
          description: (
            <div className="d-flex flex-direction-column">
              <span>
                <i>{locale('labels.price')}</i>
                {': '}
                {Utils.getFormattedPrice(so.priceValue || 0)}
              </span>
              <span>
                <i>{locale('labels.deposit')}</i>
                {': '}
                {Utils.getFormattedPrice(so.depositValue || 0)}
              </span>
              {(!!so.numberOfInstallmentsValue ||
                !!numberOfInstallmentsList?.length) && (
                <span>
                  <i>{locale('labels.numberOfInstallments')}</i>
                  {': '}
                  {locale('labels.rateNumberWithAmount', [
                    so.numberOfInstallmentsValue || '--',
                    Utils.getFormattedPrice(so.installmentAmount || 0),
                  ])}
                </span>
              )}
              {!!so.contractDuration && (
                <span>
                  <i>{locale('labels.duration')}</i>
                  {': '}
                  {so.contractDurationValue}{' '}
                  {locale(
                    `durationType.${so.contractDurationType}`,
                    undefined,
                    so.contractDurationValue || 2,
                  )}
                </span>
              )}
            </div>
          ),
          rightItems: [
            <Button
              key="delete"
              type="text"
              icon={<DeleteOutlined />}
              onClick={async e => {
                e.stopPropagation();
                if (
                  await Utils.askForConfirmation({
                    title: locale('titles.remove'),
                    content: locale('subtitles.removeSettlementOffer'),
                    okText: locale('actions.remove'),
                    isDanger: true,
                  })
                ) {
                  onDeleteSettlementOffer({ index: i });
                }
              }}
            />,
          ],
        })),
      );
    }
    if (contact && step !== 0) {
      nextItems.push(
        ...[
          {
            key: 'contactTitle',
            title: locale('titles.contact'),
            isSectionTitle: true,
            rightItems: [
              <Button key="edit" type="link" onClick={() => onEdit(0)}>
                {locale('actions.edit')}
              </Button>,
            ],
          },
          {
            key: 'contact',
            title: contact.fullName,
            description: contact.company,
            leftItem: <UserOutlined />,
            hideArrow: true,
          },
        ],
      );
    }
    setItems(nextItems);
  };

  const onItemClick = (key: string) => {
    if (key.startsWith('so_')) {
      const index = key.split('so_')[1];
      if (index) {
        const so = settlementOffers[+index];
        so && onSetCurrentSettlementOffer(so);
      }
    }
  };

  return items?.length ? (
    <List items={items} onItemClick={onItemClick} />
  ) : null;
};

export default SettlementCreatorOverview;
