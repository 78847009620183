import React from 'react';
import './CloserFinalizer.scss';
import { Col, Row } from 'antd';
import { FinalizeSection } from '../../../settlementDetail/components/finalizeSection/FinalizeSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCloserDeleteAttachment,
  settlementCloserFinalize,
  settlementCloserSelector,
} from '../../settlementCloserSlice';
import { UploadFile } from 'antd/es/upload/interface';

/**
 * Finalize Settlement
 * @constructor
 */
export default function CloserFinalizer() {
  const dispatch = useDispatch();
  const { model } = useSelector(settlementCloserSelector);

  const onFinalize = (files: UploadFile[], notes: string) =>
    dispatch(settlementCloserFinalize({ files, notes }));

  const onDeleteAttachment = async (id: number) =>
    dispatch(settlementCloserDeleteAttachment({ id }));

  if (!model) {
    return null;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <FinalizeSection
          settlement={model}
          notes={model.customerNotes}
          onFinalize={onFinalize}
          onDeleteAttachment={onDeleteAttachment}
          canEdit
        />
      </Col>
    </Row>
  );
}
