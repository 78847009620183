import React from 'react';
import { Form, Select, Typography } from 'antd';
import { PaymentMethodItem } from '../../../../../models/PaymentMethodItem';
import locale from '../../../../../locale';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Utils } from '../../../../../models/Utils';

interface CloserPaymentMethodItemSectionProps {
  item: PaymentMethodItem;
  hidden: boolean;
  value: string;
}

/**
 *
 * @constructor
 */
export default function CloserPaymentMethodItemSection({
  item,
  hidden,
  value,
}: CloserPaymentMethodItemSectionProps) {
  if (item.isReady) {
    return (
      <>
        <p className="ant-form-item-label mb-0">
          <label>{locale(`paymentMethodTypeSectionTitle.${item.type}`)}</label>
          <Typography.Text type="secondary" strong className="ml-1">
            {Utils.getFormattedPrice(item.amount)}
          </Typography.Text>
        </p>
        <Typography.Paragraph>
          <CheckCircleOutlined className="text-success mr-1" />
          <Typography.Text strong>
            <span
              dangerouslySetInnerHTML={{
                __html: locale('subtitles.paidWith', [
                  item.paymentMethod?.paymentMethodType
                    ? locale(
                        'paymentMethodOption.' +
                          item.paymentMethod?.paymentMethodType.type,
                      )
                    : locale('labels.seller'),
                ]),
              }}
            />
          </Typography.Text>
        </Typography.Paragraph>
      </>
    );
  }

  /** Action from seller required if there aren't selectable options */
  if (!item.paymentMethodTypes?.length) {
    return (
      <>
        <p className="ant-form-item-label mb-0">
          <label>{locale(`paymentMethodTypeSectionTitle.${item.type}`)}</label>
          <Typography.Text type="secondary" strong className="ml-1">
            {Utils.getFormattedPrice(item.amount)}
          </Typography.Text>
        </p>
        <Typography.Paragraph>
          <span
            dangerouslySetInnerHTML={{ __html: locale('messages.youCantPay') }}
          />
        </Typography.Paragraph>
      </>
    );
  }

  return (
    <Form.Item
      key={item.type}
      label={
        <>
          {locale(`paymentMethodTypeSectionTitle.${item.type}`)}
          <Typography.Text type="secondary" strong className="ml-1">
            {Utils.getFormattedPrice(item.amount)}
          </Typography.Text>
        </>
      }
      name={`pm_${item.type}`}
      hidden={hidden}
      rules={[{ required: !!item.paymentMethodTypes?.length }]}
    >
      <Select value={value} placeholder={locale('labels.chooseHowToPayMethod')}>
        <Select.Option value="" disabled>
          <Typography.Text type="secondary">
            {locale('labels.chooseHowToPayMethod')}
          </Typography.Text>
        </Select.Option>
        {item.paymentMethodTypes.map(o => (
          <Select.Option key={o.type} value={`${o.type}`}>
            {locale(`paymentMethodOption.${o.type}`)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
