import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  InputNumber,
  Modal,
  Select,
  Table,
  Tooltip,
} from 'antd';

const { Option } = Select;
import locale from '../../../../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementDetailActions,
  settlementDetailGetHypoteticalPaymentDates,
  settlementDetailSelector,
  settlementDetailUpdateFirstInstallmentTimeFromApproval,
  settlementDetailUpdatePaymentConfigs,
} from '../../../../settlementDetailSlice';
import {
  ClearOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  ScheduleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { PaymentConfig } from '../../../../../../models/PaymentConfig';
import { ColumnsType } from 'antd/es/table';
import { Utils } from '../../../../../../models/Utils';
import { DurationType } from '../../../../../../models/DurationType.enum';
import classNames from 'classnames';
import moment from 'moment';
import styles from './PaymentConfigsModal.module.scss';

interface PaymentConfigsModalProps {
  canEditFirstInstallmentTimeFromApproval: boolean;
}

/**
 *
 * @param {boolean} canEditFirstInstallmentTimeFromApproval
 * @constructor
 */
export default function PaymentConfigsModal({
  canEditFirstInstallmentTimeFromApproval,
}: PaymentConfigsModalProps) {
  const dispatch = useDispatch();

  const {
    isPaymentConfigsVisible: isVisible,
    currentPaymentConfigsModalSettlementOffer: settlementOffer,
    currentPaymentConfigsModalPaymentDates: prevPaymentDates,
    model: model,
  } = useSelector(settlementDetailSelector);

  const [durationTypes] = useState<DurationType[]>(
    Utils.getEnumAsArray(DurationType, true),
  );

  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [listItems, setListItems] = useState<any[]>();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isEditDates, setIsEditDates] = useState<boolean>(false);
  const [paymentConfigs, setPaymentConfigs] = useState<PaymentConfig[]>([]);
  const [paymentDates, setPaymentDates] = useState<(moment.Moment | null)[]>(
    [],
  );
  const [
    isEditFirstInstallmentTimeFromApproval,
    setIsEditFirstInstallmentTimeFromApproval,
  ] = useState<boolean>(false);
  const [
    cFirstInstallmentTimeFromApproval,
    setCFirstInstallmentTimeFromApproval,
  ] = useState<number>(0);
  const [
    cFirstInstallmentTimeFromApprovalDurationType,
    setCFirstInstallmentTimeFromApprovalDurationType,
  ] = useState<DurationType>(DurationType.DAYS);

  useEffect(() => {
    setCFirstInstallmentTimeFromApproval(
      settlementOffer?.firstInstallmentTimeFromApproval || 0,
    );
    setCFirstInstallmentTimeFromApprovalDurationType(
      settlementOffer?.firstInstallmentTimeFromApprovalDurationType ||
        DurationType.DAYS,
    );
    setIsEditFirstInstallmentTimeFromApproval(false);
  }, [settlementOffer]);

  const onClose = useCallback(
    () => dispatch(settlementDetailActions.closePaymentConfigsModal()),
    [],
  );

  const onSubmit = useCallback(
    () =>
      dispatch(
        settlementDetailUpdatePaymentConfigs({
          id: settlementOffer?.id as number,
          paymentConfigs: paymentConfigs.map((config, i) => {
            return {
              ...config,
              ...(isEditDates
                ? { paymentDate: paymentDates[i]?.toDate() }
                : {}),
            };
          }),
        }),
      ),
    [paymentConfigs, settlementOffer, paymentDates, isEditDates],
  );

  const onDelete = useCallback(() => {
    setPaymentDates([]);
    dispatch(
      settlementDetailUpdatePaymentConfigs({
        id: settlementOffer?.id as number,
        paymentConfigs: paymentConfigs.map(config => ({
          ...config,
          paymentDate: undefined,
        })),
      }),
    );
  }, [paymentConfigs, settlementOffer]);

  const onSuggestDates = useCallback(
    () =>
      dispatch(
        settlementDetailGetHypoteticalPaymentDates({
          id: settlementOffer?.id as number,
        }),
      ),
    [settlementOffer],
  );

  const updateConfig = useCallback(
    (
      value: number | null,
      key:
        | 'amount'
        | 'timeFromFirstInstallment'
        | 'timeFromFirstInstallmentDurationType'
        | 'paymentMethodType',
      index: number,
    ) => {
      setPaymentConfigs(
        paymentConfigs.map((config, i) => ({
          ...config,
          ...(i === index ? { [key]: value } : {}),
        })),
      );
    },
    [paymentConfigs],
  );

  const getColumns = useCallback(() => {
    const total = paymentConfigs?.length
      ? paymentConfigs.reduce((config, res) => ({
          ...res,
          amount: res.amount + config.amount,
        })).amount
      : 0;

    const installmentTotalAmount =
      (settlementOffer?.priceValue || 0) - (settlementOffer?.depositValue || 0);

    return [
      {
        title: locale('labels.installment'),
        width: 1,
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: (
          <div className="d-flex justify-content-space-between align-items-center">
            <span>{locale('labels.amount')} </span>
            <small className="text-secondary">
              <span
                className={classNames({
                  'text-danger': total !== installmentTotalAmount,
                })}
              >
                {total !== installmentTotalAmount && (
                  <span>
                    {'('}
                    {Utils.getFormattedPrice(
                      total - installmentTotalAmount,
                      settlementOffer?.offer.currency,
                      true,
                    )}
                    {') '}
                  </span>
                )}
                {Utils.getFormattedPrice(
                  total,
                  settlementOffer?.offer.currency,
                )}
              </span>
            </small>
          </div>
        ),
        width: 100,
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: (
          <div className="d-flex justify-content-space-between align-items-center">
            <span>{locale('labels.date')}</span>
            {!isEditDates && (
              <div>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setIsEditDates(true);
                  }}
                >
                  {locale('actions.edit')}
                </Button>
                {settlementOffer?.hasCustomPaymentDates && (
                  <Button
                    danger
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={onDelete}
                  />
                )}
              </div>
            )}
            {isEditDates && (
              <div>
                <Tooltip title={locale('actions.suggest')}>
                  <Button
                    icon={<ScheduleOutlined />}
                    onClick={onSuggestDates}
                    size="small"
                    type="text"
                  />
                </Tooltip>
                <Tooltip title={locale('actions.clear')}>
                  <Button
                    type="text"
                    icon={<ClearOutlined />}
                    onClick={() => setPaymentDates([])}
                  />
                </Tooltip>
                <Tooltip title={locale('actions.cancel')}>
                  <Button
                    className="ml-1"
                    size="small"
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setIsEditDates(false);
                      setPaymentDates(
                        (settlementOffer?.paymentConfigs || [])?.map(config =>
                          config.paymentDate
                            ? moment(config.paymentDate.toISOString())
                            : null,
                        ),
                      );
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        ),
        width: 100,
        dataIndex: 'paymentDate',
        key: 'paymentDate',
      },
      {
        title: locale('labels.paymentMethod'),
        width: 100,
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
      },
    ];
  }, [paymentConfigs, isEditDates]);

  const getListItems = useCallback(() => {
    return paymentConfigs.map((config, i) => ({
      index: `${i + 1}ª`,
      key: `${i + 1}ª`,
      amount: (
        <div className="d-flex">
          <InputNumber
            className="w-100"
            value={config.amount / 100}
            min={0}
            decimalSeparator=","
            onChange={value => updateConfig(+value * 100, 'amount', i)}
          />
          <span className="input-number-suffix">
            {locale(`currency.${settlementOffer?.offer.currency}`)}
          </span>
        </div>
      ),
      paymentDate: (
        <>
          {isEditDates && (
            <DatePicker
              format="DD/MM/YYYY"
              className="w-100"
              disabledDate={current =>
                !!current?.isBefore(moment().startOf('day')) ||
                !!current?.isAfter(
                  moment()
                    .add(
                      Utils.getDurationAsDays(
                        settlementOffer?.contractDurationValue,
                        settlementOffer?.contractDurationType,
                      ),
                      'days',
                    )
                    .startOf('day'),
                )
              }
              showNow={false}
              value={paymentDates[i]}
              onChange={value =>
                setPaymentDates(
                  paymentDates.map((d, j) => (i === j ? value : d)),
                )
              }
            />
          )}
          {!isEditDates && (
            <>
              {!settlementOffer?.hasCustomPaymentDates && !i && (
                <>
                  {!isEditFirstInstallmentTimeFromApproval && (
                    <span>
                      {!settlementOffer?.firstInstallmentTimeFromApproval
                        ? locale('labels.immediately')
                        : locale('labels.afterDuration', [
                            settlementOffer?.firstInstallmentTimeFromApproval,
                            locale(
                              `durationType.${settlementOffer?.firstInstallmentTimeFromApprovalDurationType}`,
                              undefined,
                              settlementOffer?.firstInstallmentTimeFromApproval,
                            ),
                          ])}
                    </span>
                  )}
                  {canEditFirstInstallmentTimeFromApproval &&
                    !isEditFirstInstallmentTimeFromApproval && (
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => {
                          settlementOffer &&
                            setCFirstInstallmentTimeFromApproval(
                              settlementOffer?.firstInstallmentTimeFromApproval,
                            );
                          settlementOffer &&
                            setCFirstInstallmentTimeFromApprovalDurationType(
                              settlementOffer?.firstInstallmentTimeFromApprovalDurationType,
                            );
                          setIsEditFirstInstallmentTimeFromApproval(true);
                        }}
                      />
                    )}
                  {isEditFirstInstallmentTimeFromApproval && (
                    <div
                      style={{ display: 'inline-flex', columnGap: 6 }}
                      className="ml-1 align-items-center"
                    >
                      <span>{locale('labels.after')}</span>
                      <div className="d-flex">
                        <InputNumber
                          value={cFirstInstallmentTimeFromApproval}
                          min={1}
                          decimalSeparator=","
                          onChange={v =>
                            setCFirstInstallmentTimeFromApproval(v || 1)
                          }
                        />
                        <Select
                          style={{ minWidth: 105 }}
                          value={cFirstInstallmentTimeFromApprovalDurationType}
                          onChange={
                            setCFirstInstallmentTimeFromApprovalDurationType
                          }
                        >
                          {durationTypes.map(t => (
                            <Select.Option key={t} value={t}>
                              {locale(
                                `durationType.${t}`,
                                undefined,
                                cFirstInstallmentTimeFromApproval || 2,
                              )}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      <Button
                        icon={<UndoOutlined />}
                        type="text"
                        onClick={() => {
                          setIsEditFirstInstallmentTimeFromApproval(false);
                          settlementOffer &&
                            setCFirstInstallmentTimeFromApproval(
                              settlementOffer?.firstInstallmentTimeFromApproval,
                            );
                          settlementOffer &&
                            setCFirstInstallmentTimeFromApprovalDurationType(
                              settlementOffer?.firstInstallmentTimeFromApprovalDurationType,
                            );
                        }}
                      />
                      <Button
                        type="link"
                        icon={<SaveOutlined />}
                        onClick={async () => {
                          if (await Utils.askForConfirmation()) {
                            dispatch(
                              settlementDetailUpdateFirstInstallmentTimeFromApproval(
                                {
                                  id: settlementOffer?.id as number,
                                  value: cFirstInstallmentTimeFromApproval,
                                  type: cFirstInstallmentTimeFromApprovalDurationType,
                                },
                              ),
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {settlementOffer?.hasCustomPaymentDates && (
                <>
                  {config.paymentDate
                    ? Utils.getFormattedDate(config.paymentDate)
                    : '--'}
                </>
              )}
            </>
          )}
        </>
      ),
      paymentMethod: (
        <>
          <Select
            defaultValue={config.paymentMethodType}
            style={{ width: '100%' }}
            onChange={value =>
              updateConfig(+value || null, 'paymentMethodType', i)
            }
            allowClear
          >
            {model?.config.installmentsPaymentMethodTypes.map(pm => (
              <Option key={pm.type} value={+pm.type}>
                {locale(`paymentMethodOptionAbbr.${pm.type}`)}
              </Option>
            ))}
            {/* {Object.keys(MethodType)
              .filter(x => parseInt(x) >= 0)
              .map(methodKey => (
                <Option key={methodKey} value={+methodKey}>
                  {locale(`paymentMethodOptionAbbr.${methodKey}`)}
                </Option>
              ))} */}
          </Select>
          {config.paymentMethodType && (
            <small className={styles.caption}>
              {locale(`paymentMethodOption.${config.paymentMethodType}`)}
            </small>
          )}
        </>
      ),
    }));
  }, [
    paymentConfigs,
    isEditDates,
    paymentDates,
    isEditFirstInstallmentTimeFromApproval,
    cFirstInstallmentTimeFromApproval,
    cFirstInstallmentTimeFromApprovalDurationType,
  ]);

  useEffect(() => {
    setPaymentDates(
      (settlementOffer?.paymentConfigs || [])?.map(config =>
        config.paymentDate ? moment(config.paymentDate.toISOString()) : null,
      ),
    );
    setPaymentConfigs(settlementOffer?.paymentConfigs || []);
    setIsEditDates(false);
  }, [settlementOffer]);

  useEffect(() => {
    setColumns(getColumns());
    setListItems(getListItems());
    const installmentTotalAmount =
      (settlementOffer?.priceValue || 0) - (settlementOffer?.depositValue || 0);
    const total = paymentConfigs?.length
      ? paymentConfigs.reduce((config, res) => ({
          ...res,
          amount: res.amount + config.amount,
        })).amount
      : 0;
    setCanSubmit(total === installmentTotalAmount);
  }, [paymentConfigs, settlementOffer, getListItems, getColumns]);

  useEffect(() => {
    setPaymentDates(prevPaymentDates?.map(d => moment(d.toISOString())) || []);
  }, [prevPaymentDates]);

  return (
    <Modal
      className="edit-payment-configs-modal modal-xl"
      destroyOnClose
      visible={isVisible}
      title={locale('titles.editContract')}
      onCancel={onClose}
      footer={
        <div className="d-flex justify-content-space-between">
          <Button type="text" onClick={onClose}>
            {locale('actions.cancel')}
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            disabled={!canSubmit}
            onClick={onSubmit}
          >
            {locale('actions.save')}
          </Button>
        </div>
      }
    >
      <Table
        scroll={{ x: true }}
        className="table-striped-rows"
        size="small"
        bordered
        pagination={false}
        columns={columns}
        dataSource={listItems}
      />
    </Modal>
  );
}
