import { ApiUrl } from '../models/ApiUrl.enum';
import { ServerContact, Contact, ContactMapper } from '../models/Contact';
import axios from '../middlewares/axios';
import {
  Pagination,
  PaginationMapper,
  ServerPagination,
} from '../models/Pagination';

/**
 * Contact APIs
 */
export abstract class ContactApiService {
  /**
   * Create new Contact
   * @param {Partial<Contact>} data
   * @return {Promise<Contact>}
   */
  static async create(data: Partial<Contact>): Promise<Contact> {
    const sModel: ServerContact = await axios.post(
      ApiUrl.Contacts,
      ContactMapper.mapReverse(data),
    );
    return ContactMapper.map(sModel);
  }

  /**
   * Update new Contact
   * @param {Contact} contact
   * @return {Promise<Contact>}
   */
  static async update(contact: Contact): Promise<Contact> {
    const sModel: ServerContact = await axios.patch(
      ApiUrl.ContactDetail.replace(':id', `${contact.id}`),
      ContactMapper.mapReverse(contact),
    );
    return ContactMapper.map(sModel);
  }

  /**
   * Get paginated list of Contact
   * @param {Pagination} pagination
   * @param {string} search
   * @return {Promise<{ list: Contact[], resPagination: Pagination }>}
   */
  static async getList(
    pagination: Pagination,
    search: string,
  ): Promise<{ list: Contact[]; resPagination: Pagination }> {
    const params = {
      ordering: 'name',
      ...(search?.length ? { search } : {}),
      ...PaginationMapper.mapReverse(pagination),
    };

    const response: ServerPagination & {
      results: ServerContact[];
    } = await axios.get(ApiUrl.Contacts, { params });

    const list: Contact[] = ContactMapper.mapArray(response.results || []);
    const resPagination: Pagination = PaginationMapper.map(response);
    return { list, resPagination };
  }
}
