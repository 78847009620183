import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { Settlement, SettlementStatus } from '../../models/Settlement';
import { Affix, Button, Col, PageHeader, Row, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  settlementDetailActions,
  settlementDetailAddPaymentMethod,
  settlementDetailConfirmData,
  settlementDetailDelete,
  settlementDetailDeleteAttachment,
  settlementDetailDeleteSettlementContractAttachment,
  settlementDetailDeleteSign,
  settlementDetailFinalize,
  settlementDetailGetByUuid,
  settlementDetailGetPaymentMethodItems,
  settlementDetailPayWithStripe,
  settlementDetailSetPaymentMethodStripe,
  settlementDetailSign,
  settlementDetailUpdateContact,
  settlementDetailUpdateData,
  settlementDetailUpdateName,
} from './settlementDetailSlice';
import AppHistory from '../../models/AppHistory';
import { ContactSection } from './components/contactSection/ContactSection';
import styles from './SettlementDetail.module.scss';
import { SettlementDataSection } from './components/settlementDataSection/SettlementDataSection';
import { PaymentMethodItemsSection } from './components/paymentMethodItemsSection/PaymentMethodItemsSection';
import { FinalizeSection } from './components/finalizeSection/FinalizeSection';
import { ShareSection } from './components/shareSection/ShareSection';
import SettlementStepper from '../../components/settlementStepper/SettlementStepper';
import { UploadFile } from 'antd/es/upload/interface';
import { Utils } from '../../models/Utils';
import { PaymentMethodItem } from '../../models/PaymentMethodItem';
import { SettlementDataFieldValue } from '../../models/SettlementDataFieldValue';
import { MethodType } from '../../models/PaymentMethodType';
import SettlementCreatorModal from '../settlementCreator/components/settlementCreatorModal/SettlementCreatorModal';
import ContactsModal from '../contacts/components/contactsModal/ContactsModal';
import { Contact } from '../../models/Contact';
import SignsSection from './components/signsSection/SignsSection';
import OffersSection from './components/offersSection/OffersSection';
import { PaymentMethod } from '../../models/PaymentMethod';
import locale from '../../locale';
import { SettlementStep } from '../../models/SettlementStep.enum';

interface RouteProps {
  uuid: string;
}

interface SettlementDetailProps extends RouteComponentProps<RouteProps> {
  model: Settlement;
  step: SettlementStep;
  paymentMethodItems: PaymentMethodItem[];
  fetchModel: (uuid: string) => void;
  onUpdateData: (dataFieldValues: SettlementDataFieldValue[]) => void;
  onConfirmData: () => void;
  setStep: (step: SettlementStep) => void;
  onSign: (
    signedDate: Date,
    files: UploadFile[],
    idSettlementContract: number,
  ) => void;
  getPaymentMethodItems: () => void;
  onDeleteAttachment: (id: number) => void;
  onDeleteSettlementContractAttachment: (
    idSettlementContract: number,
    id: number,
  ) => void;
  onDeleteSign: (idSettlementContract: number) => void;
  addPaymentMethod: (
    methodType: MethodType,
    paymentMethod: PaymentMethodItem,
    data: Record<string, any>,
  ) => void;
  payWithStripe: (
    paymentMethodItem: PaymentMethodItem,
    idStripePaymentMethod: string,
    paymentMethodType: MethodType,
  ) => void;
  payWithRegisteredCard: (
    registeredCard: PaymentMethod,
    paymentMethodItem: PaymentMethodItem,
  ) => void;
  onUpdateSettlementContact: (contact: Contact) => void;
  onUpdateSettlementName: (name: string) => void;
  onDeleteSettlement: () => void;
  onFinalize: (files: UploadFile[], notes: string) => void;
  clearState: () => void;
}

/**
 *
 */
class SettlementDetail extends React.Component<SettlementDetailProps> {
  /**
   *
   */
  componentDidMount() {
    const { fetchModel, match } = this.props;
    fetchModel(match.params.uuid);
  }

  /**
   *
   * @param {Readonly<SettlementDetailProps>} prevProps
   */
  componentDidUpdate(prevProps: Readonly<SettlementDetailProps>) {
    const {
      model,
      paymentMethodItems,
      setStep,
      getPaymentMethodItems,
    } = this.props;

    const modelChanged = model && !Utils.isObjectEqual(model, prevProps.model);
    if (
      model &&
      (modelChanged ||
        (paymentMethodItems &&
          !Utils.isArrayEqual(
            paymentMethodItems,
            prevProps.paymentMethodItems,
          )))
    ) {
      setStep(Utils.getSettlementStep(model));
    }

    if (modelChanged) {
      getPaymentMethodItems();
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.clearState();
  }

  // onCustomPaymentMethodSet(
  //   paymentMethodItem: PaymentMethodItem,
  //   methodType: MethodType,
  //   data?: Record<string, any>,
  // ) {}

  /**
   * Handle payments
   * @param {PaymentMethodItem} paymentMethodItem
   * @param {MethodType} methodType
   * @param {Record<string, any>} data
   */
  onPay(
    paymentMethodItem: PaymentMethodItem,
    methodType: MethodType,
    data?: Record<string, any>,
  ) {
    switch (methodType) {
      case MethodType.StripeCard:
        const registeredCard = data?.registeredCard;
        if (data?.paymentMethod) {
          this.props.payWithStripe(
            paymentMethodItem,
            data?.paymentMethod.id,
            MethodType.StripeCard,
          );
        } else if (registeredCard) {
          this.props.payWithRegisteredCard(registeredCard, paymentMethodItem);
        }
        break;
      case MethodType.Cash:
      case MethodType.BankSepaDebitOffline:
      case MethodType.BankTransfer:
        this.props.addPaymentMethod(methodType, paymentMethodItem, data || {});
        break;
      case MethodType.StripeSepaDebit:
        if (paymentMethodItem?.mustPayNow || false) {
          this.props.payWithStripe(
            paymentMethodItem,
            data?.stripe_payment_method_id,
            MethodType.StripeSepaDebit,
          );
        } else {
          this.props.addPaymentMethod(
            methodType,
            paymentMethodItem,
            data || {},
          );
        }
    }
  }

  /**
   * @return {JSX.Element | null}
   */
  render(): JSX.Element | null {
    const {
      model,
      step,
      paymentMethodItems,
      onUpdateData,
      onConfirmData,
      onSign,
      onUpdateSettlementContact,
      onDeleteAttachment,
      onDeleteSettlementContractAttachment,
      onDeleteSign,
      onFinalize,
      onUpdateSettlementName,
      onDeleteSettlement,
    } = this.props;

    const isSigned = model?.isSigned;
    const isReady = !!paymentMethodItems?.some(pm => pm.isReady);
    const canEdit =
      model?.status < SettlementStatus.Finalized && !isSigned && !isReady;

    return model ? (
      <>
        <div className={styles.affixContainer}>
          <Affix className={styles.affix}>
            <div>
              <PageHeader
                title={
                  <Typography.Text
                    editable={{
                      onChange: name =>
                        name.trim() !== model.name.trim() &&
                        onUpdateSettlementName(name),
                    }}
                  >
                    {model.name}
                  </Typography.Text>
                }
                {...(model.status <= SettlementStatus.DataConfirmed
                  ? {
                      extra: (
                        <Button
                          danger
                          onClick={async () =>
                            (await Utils.askForConfirmation({
                              title: locale('titles.delete'),
                              content: locale('subtitles.deleteSettlement'),
                              okText: locale('actions.delete'),
                              isDanger: true,
                            })) && onDeleteSettlement()
                          }
                        >
                          {locale('actions.delete')}
                        </Button>
                      ),
                    }
                  : {})}
                onBack={() => AppHistory.goBack()}
              />
              <SettlementStepper step={step} />
            </div>
          </Affix>
        </div>
        <div className="platform-body">
          <Row gutter={[8, 16]}>
            {step < SettlementStep.FinalOverview && (
              <Col xs={24} md={12}>
                <ShareSection step={step} settlement={model} />
              </Col>
            )}
            <Col xs={24} md={step < SettlementStep.FinalOverview ? 12 : 24}>
              <ContactSection
                step={step}
                contact={model.contact}
                canEdit={canEdit}
              />
            </Col>
            <Col xs={24}>
              <OffersSection settlement={model} canEdit={canEdit} />
            </Col>
            <Col xs={24}>
              <SettlementDataSection
                settlement={model}
                onSubmit={onUpdateData}
                onConfirm={onConfirmData}
                canEdit={canEdit}
              />
            </Col>
            {step > SettlementStep.ConfirmData && (
              <>
                <Col xs={24}>
                  <SignsSection
                    settlement={model}
                    step={step}
                    onDeleteSign={onDeleteSign}
                    onSign={onSign}
                    onDeleteSettlementContractAttachment={
                      onDeleteSettlementContractAttachment
                    }
                  />
                </Col>
                <Col xs={24}>
                  <PaymentMethodItemsSection
                    settlement={model}
                    paymentMethodItems={paymentMethodItems}
                    onPay={(...args) => this.onPay(...args)}
                    canEdit={model.status < SettlementStatus.Finalized}
                  />
                </Col>
              </>
            )}
            {step > SettlementStep.SignPay && (
              <Col xs={24}>
                <FinalizeSection
                  settlement={model}
                  notes={model.sellerNotes}
                  onFinalize={onFinalize}
                  onDeleteAttachment={onDeleteAttachment}
                  canEdit={model.status < SettlementStatus.Finalized}
                />
              </Col>
            )}
          </Row>
        </div>
        <SettlementCreatorModal />
        <ContactsModal
          onContactSelected={contact => onUpdateSettlementContact(contact)}
        />
      </>
    ) : null;
  }
}

const mapStateToProps = (state: RootState) => ({
  model: state.settlementDetail.model,
  step: state.settlementDetail.step,
  paymentMethodItems: state.settlementDetail.paymentMethodItems,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchModel: (uuid: string) => dispatch(settlementDetailGetByUuid({ uuid })),
  onUpdateData: (dataFieldValues: SettlementDataFieldValue[]) =>
    dispatch(settlementDetailUpdateData({ dataFieldValues })),
  onUpdateSettlementContact: (contact: Contact) =>
    dispatch(settlementDetailUpdateContact({ contact })),
  onConfirmData: () => dispatch(settlementDetailConfirmData()),
  onDeleteSign: (idSettlementContract: number) =>
    dispatch(settlementDetailDeleteSign({ idSettlementContract })),
  onDeleteAttachment: (id: number) =>
    dispatch(settlementDetailDeleteAttachment({ id })),
  onDeleteSettlementContractAttachment: (
    idSettlementContract: number,
    id: number,
  ) =>
    dispatch(
      settlementDetailDeleteSettlementContractAttachment({
        idSettlementContract,
        id,
      }),
    ),
  getPaymentMethodItems: () =>
    dispatch(settlementDetailGetPaymentMethodItems()),
  setStep: (step: SettlementStep) =>
    dispatch(settlementDetailActions.setStep(step)),
  onSign: (
    signedDate: Date,
    files: UploadFile[],
    idSettlementContract: number,
  ) =>
    dispatch(settlementDetailSign({ signedDate, files, idSettlementContract })),
  addPaymentMethod: (
    methodType: MethodType,
    paymentMethodItem: PaymentMethodItem,
    data: Record<string, any>,
  ) =>
    dispatch(
      settlementDetailAddPaymentMethod({
        methodType,
        paymentMethodItem,
        data,
      }),
    ),
  payWithStripe: (
    paymentMethodItem: PaymentMethodItem,
    idStripePaymentMethod: string,
    paymentMethodType: MethodType,
  ) =>
    dispatch(
      settlementDetailPayWithStripe({
        paymentMethodItem,
        idStripePaymentMethod,
        paymentMethodType,
      }),
    ),
  payWithRegisteredCard: (
    registeredCard: PaymentMethod,
    paymentMethodItem: PaymentMethodItem,
  ) =>
    dispatch(
      settlementDetailSetPaymentMethodStripe({
        paymentMethod: registeredCard,
        paymentMethodItem,
      }),
    ),
  onUpdateSettlementName: (name: string) =>
    dispatch(settlementDetailUpdateName({ name })),
  onDeleteSettlement: () => dispatch(settlementDetailDelete()),
  onFinalize: (files: UploadFile[], notes: string) =>
    dispatch(settlementDetailFinalize({ files, notes })),
  clearState: () => dispatch(settlementDetailActions.clearState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SettlementDetail));
