import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Contact } from '../../models/Contact';
import { Product } from '../../models/Product';
import { Offer } from '../../models/Offer';
import { SettlementOffer } from '../../models/SettlementOffer';
import { loaderActions } from '../loader/loaderSlice';
import { message } from 'antd';
import locale from '../../locale';
import { AmountOption } from '../../models/AmountOption';
import { Settlement } from '../../models/Settlement';
import AppHistory from '../../models/AppHistory';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import { Utils } from '../../models/Utils';
import { settlementDetailGetById } from '../settlementDetail/settlementDetailSlice';
import { SettlementApiService } from '../../services/SettlementApiService';
import { ProductApiService } from '../../services/ProductApiService';
import { OfferApiService } from '../../services/OfferApiService';
import { Duration } from '../../models/Duration';
import { DurationType } from '../../models/DurationType.enum';

export const settlementCreatorFetchProducts = createAsyncThunk(
  'settlementCreator/fetchProducts',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const products: Product[] = await ProductApiService.fetchProducts();
      dispatch(loaderActions.decrement());
      return { products };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorFetchOffers = createAsyncThunk(
  'settlementCreator/fetchOffers',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const {
        product: { id },
      } = (<RootState>getState()).settlementCreator;
      const offers: Offer[] = await OfferApiService.fetchOffers(id);
      dispatch(loaderActions.decrement());
      return { offers };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorGetPrices = createAsyncThunk(
  'settlementCreator/getPrices',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const prices: AmountOption[] = [
        ...((<RootState>getState()).settlementCreator.offer?.prices || []),
      ];
      dispatch(loaderActions.decrement());
      return { prices };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorGetDeposits = createAsyncThunk(
  'settlementCreator/getDeposits',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { offer } = (<RootState>getState()).settlementCreator;
      const deposits: AmountOption[] = [
        ...((<RootState>getState()).settlementCreator.offer?.deposits?.filter(
          (d: AmountOption) =>
            (!d.priceMin || d.priceMin >= offer.price.amount) &&
            (!d.priceMax || d.priceMax <= offer.price.amount),
        ) || []),
      ];
      dispatch(loaderActions.decrement());
      return { deposits };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorGetRateNumbers = createAsyncThunk(
  'settlementCreator/getRateNumbers',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { offer } = (<RootState>getState()).settlementCreator;
      const numberOfInstallmentsList: AmountOption[] = [
        ...((<RootState>(
          getState()
        )).settlementCreator.offer?.numberOfInstallmentsList?.filter(
          (rn: AmountOption) =>
            (!rn.priceMin || rn.priceMin >= offer.price.amount) &&
            (!rn.priceMax || rn.priceMax <= offer.price.amount),
        ) || []),
      ];
      dispatch(loaderActions.decrement());
      return { numberOfInstallmentsList };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorGetDurations = createAsyncThunk(
  'settlementCreator/getDurations',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { offer } = (<RootState>getState()).settlementCreator;
      const durationsList: Duration[] = [
        ...((<RootState>(
          getState()
        )).settlementCreator.offer?.contractDurations?.filter(
          (duration: Duration) =>
            (!duration.duration.priceMin ||
              duration.duration.priceMin >= offer.price.amount) &&
            (!duration.duration.priceMax ||
              duration.duration.priceMax <= offer.price.amount),
        ) || []),
      ];
      dispatch(loaderActions.decrement());
      return { durationsList };
    } catch (e) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorSaveAsSettlementOffer = createAsyncThunk(
  'settlementCreator/saveAsSettlementOffer',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const data: SettlementCreatorSliceState = (<RootState>getState())
        .settlementCreator;
      return {
        settlementOffer: {
          ...(data.settlementOffer || {}),
          offer: data.offer,
          price: data.price,
          numberOfInstallments: data.numberOfInstallments,
          deposit: data.deposit,
          depositValue: data.depositValue,
          priceValue: data.priceValue,
          numberOfInstallmentsValue: data.numberOfInstallmentsValue,
          contractDuration: data.contractDuration,
          contractDurationType: data.contractDurationType,
          contractDurationValue: data.contractDurationValue,
          installmentAmount: data.installmentAmount,
        },
      };
    } catch (e) {
      message.error(locale('errors.saveAsSettlementOffer'));
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorCreateSettlement = createAsyncThunk(
  'settlementCreator/createSettlement',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const { contact, settlementOffers } = (<RootState>(
        getState()
      )).settlementCreator;
      const { id: idSeller } = (<RootState>getState()).auth.user;
      const { uuid }: Settlement = await SettlementApiService.createSettlement(
        idSeller,
        contact.id,
        settlementOffers,
      );
      AppHistory.push(AppRoute.SettlementDetail.replace(':uuid', `${uuid}`));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.createSettlement'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const settlementCreatorUpdateSettlementOffers = createAsyncThunk(
  'settlementCreator/updateSettlementOffers',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    dispatch(loaderActions.increment());
    try {
      const {
        settlementCreator: { settlementOffers },
        settlementDetail: {
          model: { id },
        },
      }: RootState = <RootState>getState();
      await SettlementApiService.updateSettlementOffers(id, settlementOffers);
      message.success(locale('messages.offersUpdated'));
      dispatch(settlementDetailGetById({ id }));
      dispatch(loaderActions.decrement());
    } catch (e) {
      message.error(locale('errors.updateSettlementOffers'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export interface SettlementCreatorSliceState {
  isModalVisible: boolean;
  isEdit: boolean;
  step: number;
  contact?: Contact;
  product?: Product;
  offer?: Offer;
  price?: AmountOption;
  numberOfInstallments?: AmountOption;
  deposit?: AmountOption;
  contractDuration?: AmountOption;
  contractDurationType?: DurationType;
  contractDurationValue?: number;
  settlementOffer?: SettlementOffer;
  priceValue?: number;
  depositValue?: number;
  numberOfInstallmentsValue?: number;
  installmentAmount?: number;
  products: Product[];
  offers: Offer[];
  prices: AmountOption[];
  deposits: AmountOption[];
  numberOfInstallmentsList: AmountOption[];
  durationsList: Duration[];
  settlementOffers: SettlementOffer[];
}

const initialState: SettlementCreatorSliceState = {
  isModalVisible: false,
  isEdit: false,
  step: 0,
  products: [],
  offers: [],
  prices: [],
  deposits: [],
  numberOfInstallmentsList: [],
  durationsList: [],
  settlementOffers: [],
};

export const settlementCreatorSlice = createSlice({
  name: 'settlementCreator',
  initialState,
  reducers: {
    setIsModalVisible(state, { payload }) {
      state.isModalVisible = payload;
    },
    closeModal(state) {
      state = {
        ...initialState,
        products: state.products,
      };
      return state;
    },
    setStep(state, { payload }) {
      state.step = payload;
    },
    setContact(state, { payload }) {
      state.contact = payload;
    },
    setProduct(state, { payload }) {
      state.product = payload;
    },
    setOffer(state, { payload }) {
      state.offer = payload;
    },
    setPrice(state, { payload }) {
      state.price = payload;
      state.priceValue = payload?.amount;
    },
    setDeposit(state, { payload }) {
      state.deposit = payload;
      state.depositValue = payload?.amount;
    },
    setRateNumber(state, { payload }) {
      state.numberOfInstallments = payload;
      state.numberOfInstallmentsValue = payload?.amount;
      state.installmentAmount =
        ((state.priceValue || 0) - (state.depositValue || 0)) /
        (state.numberOfInstallmentsValue || 0);
    },
    setContractDuration(state, { payload }) {
      state.contractDuration = payload?.contractDuration;
      state.contractDurationValue = payload?.contractDurationValue;
      state.contractDurationType = payload?.contractDurationType;
    },
    setProducts(state, { payload }) {
      state.products = [...payload];
    },
    setOffers(state, { payload }) {
      state.offers = [...payload];
    },
    setSettlementOffers(state, { payload }) {
      state.settlementOffers = [...payload];
    },
    editSettlementOffer(state, { payload }) {
      state.settlementOffers = [...payload.settlementOffers];
      state.isEdit = true;
      state.step = -1;
      state.isModalVisible = true;
    },
    setSettlementOffer(state, { payload }) {
      const so: SettlementOffer = { ...payload };
      state.settlementOffer = so;
      state.product = so.offer.product;
      state.offer = so.offer;
      state.price = so.price;
      state.numberOfInstallments = so.numberOfInstallments;
      state.deposit = so.deposit;
      state.priceValue = so.priceValue || so.price?.amount || 0;
      state.numberOfInstallmentsValue =
        so.numberOfInstallmentsValue || so.numberOfInstallments?.amount || 0;
      state.installmentAmount = so.installmentAmount || 0;
      state.depositValue = so.depositValue || so.deposit?.amount || 0;
      state.step = 1;
    },
    deleteSettlementOffer(state, { payload }) {
      const nextSettlementOffers: SettlementOffer[] = state.settlementOffers.filter(
        (so, i) => (payload.id ? so.id !== payload.id : i !== payload.index),
      );
      state.settlementOffers = nextSettlementOffers;
      if (!nextSettlementOffers?.length) {
        state.step = 1;
      }
    },
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${settlementCreatorFetchProducts.fulfilled}`]: (
      state,
      { payload: { products } },
    ) => {
      state.products = products;
      return state;
    },
    [`${settlementCreatorFetchOffers.fulfilled}`]: (
      state,
      { payload: { offers } },
    ) => {
      state.offers = offers;
      return state;
    },
    [`${settlementCreatorGetPrices.fulfilled}`]: (
      state,
      { payload: { prices } },
    ) => {
      state.prices = prices;
      return state;
    },
    [`${settlementCreatorGetRateNumbers.fulfilled}`]: (
      state,
      { payload: { numberOfInstallmentsList } },
    ) => {
      state.numberOfInstallmentsList = numberOfInstallmentsList;
      return state;
    },
    [`${settlementCreatorGetDurations.fulfilled}`]: (
      state,
      { payload: { durationsList } },
    ) => {
      state.durationsList = durationsList;
      return state;
    },
    [`${settlementCreatorGetDeposits.fulfilled}`]: (
      state,
      { payload: { deposits } },
    ) => {
      state.deposits = deposits;
      return state;
    },
    [`${settlementCreatorSaveAsSettlementOffer.fulfilled}`]: (
      state,
      { payload: { settlementOffer } },
    ) => {
      const settlementOffers = [
        ...state.settlementOffers.map(so => {
          if (
            state.settlementOffer &&
            Utils.isObjectEqual(so, state.settlementOffer)
          ) {
            return settlementOffer;
          }
          return so;
        }),
      ];
      if (!state.settlementOffer) {
        settlementOffers.push(settlementOffer);
      }
      return {
        ...initialState,
        isModalVisible: state.isModalVisible,
        isEdit: state.isEdit,
        step: state.step,
        contact: state.contact,
        products: state.products,
        settlementOffers: settlementOffers,
      };
    },
    [`${settlementCreatorCreateSettlement.fulfilled}`]: () => ({
      ...initialState,
    }),
    [`${settlementCreatorUpdateSettlementOffers.fulfilled}`]: () => ({
      ...initialState,
    }),
  },
});

export const settlementCreatorSelector = (
  state: RootState,
): SettlementCreatorSliceState => state.settlementCreator;
export const settlementCreatorActions = settlementCreatorSlice.actions;
