import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCloserAddPaymentMethodSepaOnline,
  settlementCloserAddPaymentMethodStripe,
  settlementCloserGetById,
  settlementCloserGetPaymentMethodItems,
  settlementCloserGetPaymentMethods,
  settlementCloserSelector,
  settlementCloserSetPaymentMethodStripe,
  SettlementCloserSliceState,
} from '../../settlementCloserSlice';
import { Alert, Card, Col, Descriptions, Form, message, Row } from 'antd';
import { FieldData } from '../../../../models/FieldData';
import CloserPaymentMethodItemSection from './closerPaymentMethodItemSection/CloserPaymentMethodItemSection';
import { MethodType } from '../../../../models/PaymentMethodType';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../../../../models/PaymentMethodItem';
import PaymentMethodTypeHandler from '../../../../components/paymentHandler/paymentMethodTypeHandler/PaymentMethodTypeHandler';
import locale from '../../../../locale';
import { loaderActions } from '../../../loader/loaderSlice';
import { Stripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '../../../../models/PaymentMethod';
import { Utils } from '../../../../models/Utils';

type CloserPaymentMethodType = {
  type: MethodType;
  mustPayNow: boolean;
  items: PaymentMethodItem[];
};

const CloserPayer = () => {
  const dispatch = useDispatch();
  const stripe: Stripe | null = useStripe();

  const {
    model,
    paymentMethodItems: pmItems,
    paymentMethods,
    stripeSecret,
  }: SettlementCloserSliceState = useSelector(settlementCloserSelector);

  const [paymentMethodItems, setPaymentMethodItems] = useState<
    PaymentMethodItem[]
  >([]);
  const [fields, setFields] = useState<FieldData[]>([]);
  const [paymentMethodTypes, setPaymentMethodTypes] = useState<
    CloserPaymentMethodType[]
  >([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    /** Pay only Advance if needed */
    const hasAdvanceToPay = pmItems?.some(
      item => item.type === PaymentMethodItemType.Advance && !item.isReady,
    );
    const nItems: PaymentMethodItem[] = [];
    pmItems?.forEach(item => {
      if (!hasAdvanceToPay || item.type === PaymentMethodItemType.Advance) {
        nItems.push(item);
      }
    });
    setPaymentMethodItems(nItems);
  }, [pmItems]);

  const getFields = () => {
    const hasAdvanceToPay = paymentMethodItems?.some(
      item => item.type === PaymentMethodItemType.Advance && !item.isReady,
    );
    const nFields: { name: string; value: string }[] = [];
    paymentMethodItems?.forEach(pm => {
      if (!hasAdvanceToPay || pm.type === PaymentMethodItemType.Advance) {
        nFields.push({
          name: `pm_${pm.type}`,
          value: '',
        });
      }
    });
    return nFields;
  };

  useEffect(() => {
    dispatch(settlementCloserGetPaymentMethods());
    dispatch(settlementCloserGetPaymentMethodItems());
  }, [model]);

  useEffect(() => {
    if (stripeSecret) {
      stripeSecret.methodType === MethodType.StripeCard && confirmCardPayment();
      if (stripeSecret.methodType === MethodType.StripeSepaDebit) {
        message.success(locale('messages.successfullyPaid'));
        model?.id && dispatch(settlementCloserGetById({ id: model.id }));
      }
    }
  }, [stripeSecret]);

  useEffect(() => {
    setFields(getFields());
  }, [paymentMethodItems]);

  /** Set unique PaymentMethod list from current Payment Method Items */
  useEffect(() => {
    const nextPaymentMethodTypes: CloserPaymentMethodType[] = [];
    fields.forEach(field => {
      const type: MethodType = +field.value;
      if (type && !nextPaymentMethodTypes.some(t => t.type === type)) {
        const items: PaymentMethodItem[] = [];
        fields
          .filter(field => +field.value === type)
          .forEach(t => {
            const found = paymentMethodItems.find(
              item => item.type === +`${t.name}`.substr(3),
            );
            found && items.push(found);
          });
        nextPaymentMethodTypes.push({
          type,
          items,
          mustPayNow: items.some(pm => pm.mustPayNow),
        });
      }
    });
    setPaymentMethodTypes(nextPaymentMethodTypes);
  }, [fields]);

  /**
   * Set Payment Methods for each item if possible
   * @param {FieldData[]} allFields
   */
  const setNextFieldsValue = (allFields: FieldData[]) => {
    const nextFields = [...allFields];
    paymentMethodItems?.forEach((pm, i) => {
      /** If the first item has not a value return */
      const firstValue = nextFields[0]?.value;
      if (!firstValue) return;

      /** If the previous item has not a value return */
      const prevItem: PaymentMethodItem = paymentMethodItems[i - 1];
      const prevItemValue =
        i &&
        nextFields.find(f => (f.name as string[])[0] === `pm_${prevItem?.type}`)
          ?.value;
      if (!prevItemValue) return;

      /** If the current item has a value return */
      const currentValue = nextFields.find(
        f => (f.name as string[])[0] === `pm_${pm.type}`,
      )?.value;
      if (currentValue) return;

      /** If the first value is an option for the current item => set it automatically */
      const hasFirstValueInOptions = pm.paymentMethodTypes?.some(
        o => o.type === +firstValue,
      );
      if (hasFirstValueInOptions) {
        const found = nextFields.find(
          f => (f.name as string[])[0] === `pm_${pm.type}`,
        );
        if (found) {
          found.value = firstValue;
        }
      }
    });

    setFields(nextFields);
    setIsFormValid(nextFields.every(f => f.value));
  };

  const confirmCardPayment = async () => {
    if (stripe && stripeSecret) {
      dispatch(loaderActions.increment());
      const payload = await stripe.confirmCardPayment(
        stripeSecret.clientSecret,
        {
          payment_method: model?.contact?.paymentMethodId,
        },
      );
      if (payload?.error) {
        message.error(
          locale('errors.paymentFailed', [payload.error.message || '']),
        );
      } else {
        message.success(locale('messages.successfullyPaid'));
        model?.id && dispatch(settlementCloserGetById({ id: model.id }));
      }
      dispatch(loaderActions.decrement());
    }
  };

  /**
   * Handle payments
   * @param {boolean} mustPayNow
   * @param {PaymentMethodItem[]} items
   * @param {MethodType} methodType
   * @param {Record<string, any>} data
   */
  const onPay = async (
    mustPayNow: boolean,
    items: PaymentMethodItem[],
    methodType: MethodType,
    data: Record<string, any>,
  ) => {
    const paymentMethodType = items[0]?.paymentMethodTypes.find(
      pmt => pmt.type === methodType,
    );
    if (!paymentMethodType) return;

    const paymentMethodItemTypes: PaymentMethodItemType[] = items.map(
      item => item.type,
    );

    /** Perform Payment */
    switch (methodType) {
      case MethodType.StripeCard:
        if (data?.paymentMethod) {
          dispatch(
            settlementCloserAddPaymentMethodStripe({
              idStripePaymentMethod: data.paymentMethod.id,
              idPaymentMethodType: paymentMethodType.id,
              paymentMethodType: MethodType.StripeCard,
              paymentMethodItemTypes,
              mustPayNow,
            }),
          );
        } else if (data?.registeredCard) {
          dispatch(
            settlementCloserSetPaymentMethodStripe({
              paymentMethod: data.registeredCard,
              paymentMethodType: MethodType.StripeCard,
              paymentMethodItemTypes,
              mustPayNow,
            }),
          );
        }
        break;
      case MethodType.BankSepaDebitOnline:
        const partialPaymentMethod: Partial<PaymentMethod> = {
          bankAccountHolder: data.bankAccountHolder,
          iban: data.iban,
          bankAuthorizationId: data.bankAuthorizationId,
          firstName: data.firstName,
          lastName: data.lastName,
          fiscalCode: data.fiscalCode,
          address: data.address,
          zipCode: data.zipCode,
          city: data.city,
          district: data.district,
        };
        dispatch(
          settlementCloserAddPaymentMethodSepaOnline({
            idPaymentMethodType: paymentMethodType.id,
            paymentMethodItemTypes,
            partialPaymentMethod,
          }),
        );
        break;
      case MethodType.StripeSepaDebit:
        dispatch(
          settlementCloserAddPaymentMethodStripe({
            idStripePaymentMethod: data?.stripe_payment_method_id,
            idPaymentMethodType: paymentMethodType.id,
            paymentMethodType: MethodType.StripeSepaDebit,
            paymentMethodItemTypes,
            mustPayNow,
          }),
        );
        break;
      default:
        message.error(locale('errors.unsupportedPaymentMethod'));
        break;
    }
  };

  const renderPaymentMethod = ({
    type,
    mustPayNow,
    items,
  }: CloserPaymentMethodType) => {
    let title = '';
    switch (type) {
      case MethodType.StripeCard:
        title = locale(`titles.${mustPayNow ? 'payWithCard' : 'setCard'}`);
        break;
      case MethodType.BankSepaDebitOnline:
        title = locale('titles.setRidInfo');
        break;
      case MethodType.BankTransfer:
        title = locale('titles.payWithBankTransfer');
        break;
    }
    return (
      <Col key={type} xs={24}>
        <Card title={title} size="small">
          <Descriptions
            size="small"
            className="mb-2 text-left"
            title={locale('titles.chosenPaymentMethods', [
              locale(`paymentMethodOption.${type}`),
            ])}
            column={1}
          >
            {items.map(item => (
              <Descriptions.Item
                key={item.type}
                label={
                  <b>{locale(`paymentMethodTypeSectionTitle.${item.type}`)}</b>
                }
              >
                <span className="mr-1">
                  {Utils.getFormattedPriceWithVat(
                    item.amount,
                    model?.currency,
                    model?.vatPercentage || 0,
                  )}
                </span>
                <small>
                  {item.mustPayNow && ' | ' + locale('labels.toPayNow')}
                </small>
              </Descriptions.Item>
            ))}
          </Descriptions>
          <PaymentMethodTypeHandler
            paymentMethods={paymentMethods}
            items={items}
            uuid={model?.uuid}
            type={type}
            vatPercentage={model?.vatPercentage || 0}
            mustPayNow={mustPayNow}
            onPay={(...args) => onPay(mustPayNow, items, ...args)}
            idSettlement={model?.id}
          />
        </Card>
      </Col>
    );
  };

  return model ? (
    <>
      <Card size="small" title={locale('titles.selectPaymentMethods')}>
        {!!paymentMethodItems?.length && (
          <Form
            layout="vertical"
            hideRequiredMark
            fields={fields}
            onFieldsChange={(_, allFields) => setNextFieldsValue(allFields)}
          >
            {paymentMethodItems.map((pm, i) => (
              <CloserPaymentMethodItemSection
                key={pm.type}
                item={pm}
                hidden={
                  i > 0 &&
                  !paymentMethodItems[i - 1].isReady &&
                  !fields[i - 1]?.value
                }
                value={fields[i]?.value || ''}
              />
            ))}
          </Form>
        )}
      </Card>

      <Row gutter={[16, 16]} className="mt-2">
        {(!isFormValid || !paymentMethodTypes) && (
          <Col xs={24}>
            <Alert
              banner
              message={locale('subtitles.selectAllPaymentMethods')}
              type="info"
            />
          </Col>
        )}
        {isFormValid && paymentMethodTypes.map(renderPaymentMethod)}
      </Row>
    </>
  ) : null;
};
export default CloserPayer;
