import { Mapper } from './Mapper';
import { Attachment, AttachmentMapper, ServerAttachment } from './Attachment';
import {
  ServerSettlementContractBlock,
  SettlementContractBlock,
  SettlementContractBlockMapper,
} from './SettlementContractBlock';

export enum SettlementSignType {
  None = 0,
  Paper = 1,
  Otp = 2,
}

export interface SettlementContract {
  id: number;
  signType: SettlementSignType;
  contractId: number;
  isSigned: boolean;
  signDate: Date;
  pdf: string;
  rawAttachments: Attachment[];
  settlementId: number;
  settlementContractBlocks: SettlementContractBlock[];
}

/* eslint-disable camelcase */
export interface ServerSettlementContract {
  id: number;
  sign_type: SettlementSignType;
  sign_datetime: string;
  contract: number;
  is_signed: boolean;
  pdf: string;
  raw_attachments: ServerAttachment[];
  settlement: number;
  settlementcontractblock_set: ServerSettlementContractBlock[];
}
/* eslint-enable camelcase */

/**
 * Map SettlementContract model
 */
export abstract class SettlementContractMapper
  implements Mapper<SettlementContract, ServerSettlementContract> {
  /**
   * @param {Partial<ServerSettlementContract>} o
   * @return {SettlementContract}
   */
  static map(o: Partial<ServerSettlementContract>): SettlementContract {
    return <SettlementContract>{
      id: o.id,
      signType: o.sign_type,
      contractId: o.contract,
      isSigned: o.is_signed,
      pdf: o.pdf,
      rawAttachments: AttachmentMapper.mapArray(o.raw_attachments || []),
      settlementId: o.settlement,
      signDate: o.sign_datetime ? new Date(o.sign_datetime) : undefined,
      settlementContractBlocks: SettlementContractBlockMapper.mapArray(
        o.settlementcontractblock_set || [],
      ),
    };
  }

  /**
   * @param {Partial<SettlementContract>} o
   * @return {ServerSettlementContract}
   */
  static mapReverse(o: Partial<SettlementContract>): ServerSettlementContract {
    return <ServerSettlementContract>{
      id: o.id,
      sign_type: o.signType,
      contract: o.contractId,
      is_signed: o.isSigned,
      pdf: o.pdf,
      raw_attachments: o.rawAttachments
        ? AttachmentMapper.mapReverseArray(o.rawAttachments)
        : null,
      settlement: o.settlementId,
      sign_datetime: o.signDate?.toISOString() || null,
      settlementcontractblock_set: SettlementContractBlockMapper.mapReverseArray(
        o.settlementContractBlocks || [],
      ),
    };
  }

  /**
   * @param {ServerSettlementContract[]} models
   * @return {SettlementContract[]}
   */
  static mapArray(models: ServerSettlementContract[]): SettlementContract[] {
    return models.map(m => SettlementContractMapper.map(m));
  }

  /**
   * @param {SettlementContract[]} models
   * @return {ServerSettlementContract[]}
   */
  static mapReverseArray(
    models: SettlementContract[],
  ): ServerSettlementContract[] {
    return models.map(m => SettlementContractMapper.mapReverse(m));
  }
}
