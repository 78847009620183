import { Mapper } from './Mapper';
import {
  PaymentMethodType,
  PaymentMethodTypeMapper,
  ServerPaymentMethodType,
} from './PaymentMethodType';
import { OtpRequest, OtpRequestMapper, ServerOtpRequest } from './OtpRequest';
import { Attachment, AttachmentMapper, ServerAttachment } from './Attachment';

export interface PaymentMethod {
  id: number;
  settlement: number;
  paymentMethodType: PaymentMethodType;
  paymentDate?: Date;
  stripePaymentMethod?: string;
  iban?: string;
  bankAccountHolder?: string;
  bankAuthorizationId?: string;
  firstName?: string;
  lastName?: string;
  fiscalCode?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  district?: string;
  country?: string;
  otpRequest?: OtpRequest;
  signDate?: Date;
  attachments?: Attachment[];
  expiration: string;
  last4: string;
}

/* eslint-disable camelcase */
export interface ServerPaymentMethod {
  id: number;
  settlement: number;
  payment_method_type: ServerPaymentMethodType;
  payment_date: string;
  stripe_payment_method?: string;
  iban?: string;
  bank_account_holder?: string;
  bank_authorization_id?: string;
  first_name?: string;
  last_name?: string;
  fiscal_code?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  district?: string;
  country?: string;
  otp_request?: ServerOtpRequest;
  sign_datetime?: string;
  attachments?: ServerAttachment[];
  expiration: string;
  last4: string;
}

/* eslint-enable camelcase */

/**
 * Map PaymentMethod model
 */
export abstract class PaymentMethodMapper
  implements Mapper<PaymentMethod, ServerPaymentMethod> {
  /**
   * @param {Partial<ServerPaymentMethod>} o
   * @return {PaymentMethod}
   */
  static map(o: Partial<ServerPaymentMethod>): PaymentMethod {
    return <PaymentMethod>{
      id: o.id,
      settlement: o.settlement,
      paymentMethodType: o.payment_method_type
        ? PaymentMethodTypeMapper.map(o.payment_method_type)
        : undefined,
      paymentDate: o.payment_date ? new Date(o.payment_date) : undefined,
      stripePaymentMethod: o.stripe_payment_method,
      iban: o.iban,
      bankAccountHolder: o.bank_account_holder,
      bankAuthorizationId: o.bank_authorization_id,
      firstName: o.first_name,
      lastName: o.last_name,
      fiscalCode: o.fiscal_code,
      address: o.address,
      zipCode: o.zip_code,
      city: o.city,
      district: o.district,
      country: o.country,
      otpRequest: o.otp_request
        ? OtpRequestMapper.map(o.otp_request)
        : undefined,
      signDate: o.sign_datetime ? new Date(o.sign_datetime) : undefined,
      attachments: o.attachments
        ? AttachmentMapper.mapArray(o.attachments)
        : [],
      expiration: o.expiration,
      last4: o.last4,
    };
  }

  /**
   * @param {Partial<PaymentMethod>} o
   * @return {ServerPaymentMethod}
   */
  static mapReverse(o: Partial<PaymentMethod>): ServerPaymentMethod {
    return <ServerPaymentMethod>{
      id: o.id,
      settlement: o.settlement || null,
      payment_method_type: o.paymentMethodType
        ? PaymentMethodTypeMapper.mapReverse(o.paymentMethodType)
        : null,
      payment_date: o.paymentDate ? o.paymentDate.toISOString() : null,
      stripe_payment_method: o.stripePaymentMethod || null,
      iban: o.iban || null,
      bank_account_holder: o.bankAccountHolder || null,
      bank_authorization_id: o.bankAuthorizationId || null,
      first_name: o.firstName || null,
      last_name: o.lastName || null,
      fiscal_code: o.fiscalCode || null,
      address: o.address || null,
      zip_code: o.zipCode || null,
      city: o.city || null,
      district: o.district || null,
      country: o.country || null,
      otp_request: o.otpRequest
        ? OtpRequestMapper.mapReverse(o.otpRequest)
        : null,
      sign_datetime: o.signDate?.toISOString() || null,
      attachments: o.attachments
        ? AttachmentMapper.mapReverseArray(o.attachments)
        : [],
      expiration: o.expiration,
      last4: o.last4,
    };
  }

  /**
   * @param {ServerPaymentMethod[]} models
   * @return {PaymentMethod[]}
   */
  static mapArray(models: ServerPaymentMethod[]): PaymentMethod[] {
    return models.map(m => PaymentMethodMapper.map(m));
  }

  /**
   * @param {PaymentMethod[]} models
   * @return {ServerPaymentMethod[]}
   */
  static mapReverseArray(models: PaymentMethod[]): ServerPaymentMethod[] {
    return models.map(m => PaymentMethodMapper.mapReverse(m));
  }
}
