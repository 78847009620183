export enum FileExtension {
  jpeg = 'image/jpeg',
  png = 'image/png',
  jpg = 'image/jpg',
  JPEG = 'image/JPEG',
  PNG = 'image/PNG',
  JPG = 'image/JPG',
  // gif = 'image/gif',
  // GIF = 'image/GIF',
  webp = 'image/webp',
  pdf = 'application/pdf',
  mpeg = 'audio/mpeg',
  ogg = 'audio/ogg',
  aac = 'audio/aac',
  amp4 = 'audio/mp4',
  mp4 = 'video/mp4',
  '3gpp' = 'video/3gpp',
  amr = 'audio/amr',
  // csv = 'text/csv',
  // plain = 'text/plain',
  // xml = 'text/xml',
  // xml2 = 'application/xml',
  // vndApiJson = 'application/vnd.api+json',
  // json = 'application/json',
  // ldJson = 'application/ld+json',
  // xlsFull = 'application/vnd.ms-excel', // (.xls)
  // pptFull = 'application/vnd.ms-powerpoint', // (.ppt)
  // odtFull = 'application/vnd.oasis.opendocument.text', // (.odt)
  // pptxFull = 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // (.pptx)
  // xlsxFull = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // (.xlsx)
  // docxFull = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // (.docx)
  // mswordFull = 'application/msword', // (.doc)
  // xls = '.xls',
  // ppt = '.ppt',
  // odt = '.odt',
  // pptx = '.pptx',
  // xlsx = '.xlsx',
  // docx = '.docx',
  // doc = '.doc',
}

export const imageExtensions: FileExtension[] = [
  FileExtension.jpeg,
  FileExtension.png,
  FileExtension.jpg,
  FileExtension.JPEG,
  FileExtension.PNG,
  FileExtension.JPG,
  FileExtension.webp,
  // FileExtension.gif,
  // FileExtension.GIF,
];

export const audioExtensions: FileExtension[] = [
  FileExtension.mpeg,
  FileExtension.ogg,
  FileExtension.aac,
  FileExtension.amp4,
  FileExtension.amr,
];

export const videoExtensions: FileExtension[] = [
  FileExtension.mp4,
  FileExtension['3gpp'],
];
