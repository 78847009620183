import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

export interface LoaderState {
  requests: number;
  loading: boolean;
}

const initialState: LoaderState = {
  requests: 0,
  loading: false,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    increment: state => {
      state.requests += 1;
      state.loading = true;
    },
    decrement: state => {
      state.requests = state.requests - 1 > 0 ? state.requests - 1 : 0;
      state.loading = state.requests - 1 > 0;
    },
    setRequests: (state, { payload }) => {
      state.requests = payload;
      state.loading = state.requests - 1 > 0;
    },
  },
});

export const selectLoading = (state: RootState): boolean =>
  state.loader.loading;
export const loaderSelector = (state: RootState): LoaderState => state.loader;
export const loaderActions = loaderSlice.actions;
