export enum AppRoute {
  Root = '/',
  Login = '/login',
  Logout = '/logout',
  NotFound = '/not-found',
  Settlements = '/settlements',
  SettlementDetail = '/settlements/:uuid',
  ContractPreview = '/contract/:id/preview',
  Sign = '/sign',
  Overview = '/overview',
}
