import React from 'react';
import SettlementCreatorStepper from './components/settlementCreatorStepper/SettlementCreatorStepper';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import SettlementCreatorContactSetter from './components/settlementCreatorContactSetter/SettlementCreatorContactSetter';
import { Contact } from '../../models/Contact';
import {
  settlementCreatorActions,
  settlementCreatorCreateSettlement,
  settlementCreatorSaveAsSettlementOffer,
  settlementCreatorUpdateSettlementOffers,
} from './settlementCreatorSlice';
import SettlementCreatorOverview from './components/settlementCreatorOverview/SettlementCreatorOverview';
import SettlementCreatorProductSetter from './components/settlementCreatorProductSetter/SettlementCreatorProductSetter';
import { Product } from '../../models/Product';
import { Offer } from '../../models/Offer';
import SettlementCreatorOfferSetter from './components/settlementCreatorOfferSetter/SettlementCreatorOfferSetter';
import SettlementCreatorPriceSetter from './components/settlementCreatorPriceSetter/SettlementCreatorPriceSetter';
import { AmountOption } from '../../models/AmountOption';
import SettlementCreatorRateNumberSetter from './components/settlementCreatorRateNumberSetter/SettlementCreatorRateNumberSetter';
import SettlementCreatorDepositSetter from './components/settlementCreatorDepositSetter/SettlementCreatorDepositSetter';
import SettlementCreatorConfirmOffer from './components/settlementCreatorConfirmOffer/SettlementCreatorConfirmOffer';
import { SettlementOffer } from '../../models/SettlementOffer';
import { Button } from 'antd';
import locale from '../../locale';
import SettlementCreatorDurationSetter from './components/settlementCreatorDurationSetter/SettlementCreatorDurationSetter';
import { DurationType } from '../../models/DurationType.enum';

interface SettlementCreatorProps {
  isModalVisible?: boolean;
  isEdit?: boolean;
  step?: number;
  offers?: Offer[];
  settlementOffers?: SettlementOffer[];
  setStep?: (step: number) => void;
  setContact?: (contact: Contact) => void;
  setProduct?: (product?: Product) => void;
  setOffer?: (offer?: Offer) => void;
  setPrice?: (price?: AmountOption) => void;
  setDeposit?: (deposit?: AmountOption) => void;
  setRateNumber?: (numberOfInstallments?: AmountOption) => void;
  setContractDuration?: (data?: {
    contractDuration: AmountOption;
    contractDurationValue: number;
    contractDurationType: DurationType;
  }) => void;
  deleteSettlementOffer?: (data: { index?: number; id?: number }) => void;
  setCurrentSettlementOffer?: (settlementOffer: SettlementOffer) => void;
  saveAsSettlementOffer?: () => void;
  updateSettlementOffers?: () => void;
  createSettlement?: () => void;
}

/**
 *
 */
class SettlementCreator extends React.Component<SettlementCreatorProps> {
  /**
   * Render the Step
   * @return {JSX.Element}
   */
  renderStep() {
    const {
      step,
      isEdit,
      offers,
      settlementOffers,
      setContact,
      setStep,
      setProduct,
      setOffer,
      setPrice,
      setDeposit,
      setRateNumber,
      setContractDuration,
      saveAsSettlementOffer,
      updateSettlementOffers,
      createSettlement,
    } = this.props;

    switch (step) {
      case -1:
        return (
          <>
            {settlementOffers?.length && (
              <div className="p-2">
                <Button
                  type="primary"
                  block
                  onClick={() =>
                    isEdit ? updateSettlementOffers?.() : createSettlement?.()
                  }
                >
                  {locale(
                    `actions.${isEdit ? 'updateOffers' : 'createContract'}`,
                  )}
                </Button>
              </div>
            )}
          </>
        );
      case 0:
        return (
          <SettlementCreatorContactSetter
            onContactSelected={contact => {
              setContact?.(contact);
              setStep?.(settlementOffers?.length ? -1 : step + 1);
            }}
          />
        );
      case 1:
        return (
          <SettlementCreatorProductSetter
            onProductSelected={product => {
              setProduct?.(product);
              setStep?.(step + 1);
            }}
          />
        );
      case 2:
        return (
          <SettlementCreatorOfferSetter
            onOfferSelected={offer => {
              const found = offers?.find(o => o.id === offer.id);
              setOffer?.(found || offer);
              setStep?.(step + 1);
            }}
          />
        );
      case 3:
        return (
          <SettlementCreatorPriceSetter
            onPriceSelected={price => {
              setPrice?.(price);
              setStep?.(step + 1);
            }}
          />
        );
      case 4:
        return (
          <SettlementCreatorDepositSetter
            onDepositSelected={deposit => {
              setDeposit?.(deposit);
              setStep?.(step + 1);
            }}
          />
        );
      case 5:
        return (
          <SettlementCreatorRateNumberSetter
            onRateNumberSelected={numberOfInstallments => {
              setRateNumber?.(numberOfInstallments);
              setStep?.(step + 1);
            }}
          />
        );
      case 6:
        return (
          <SettlementCreatorDurationSetter
            onSubmit={contractDuration => {
              setContractDuration?.(contractDuration);
              setStep?.(step + 1);
            }}
          />
        );
      case 7:
        return (
          <SettlementCreatorConfirmOffer
            onConfirm={() => {
              saveAsSettlementOffer?.();
              setStep?.(-1);
            }}
          />
        );
    }
  }

  /**
   * Edit a specific step
   * @param {number} step
   */
  onEditStep(step: number) {
    if (step >= 0 && step <= 5) {
      this.props.setContractDuration?.();
      if (step <= 4) {
        this.props.setRateNumber?.();
        if (step <= 3) {
          this.props.setDeposit?.();
          if (step <= 2) {
            this.props.setPrice?.();
            if (step <= 1) {
              this.props.setOffer?.();
              if (step === 0) {
                this.props.setProduct?.();
              }
            }
          }
        }
      }
    }
    this.props.setStep?.(step);
  }

  /**
   *
   */
  onBack() {
    const { step, settlementOffers } = this.props;
    if (settlementOffers && settlementOffers.length > 0 && step === 1) {
      this.onEditStep(-1);
    } else {
      this.onEditStep(step ? step - 1 : 0);
    }
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const {
      step,
      isModalVisible,
      settlementOffers,
      setStep,
      deleteSettlementOffer,
      setCurrentSettlementOffer,
    } = this.props;
    const showStepper =
      (step || 0) > 0 || ((step || 0) === 0 && !settlementOffers?.length);

    return isModalVisible ? (
      <>
        {showStepper && (
          <SettlementCreatorStepper
            step={step || 0}
            showBackButton
            onBack={this.onBack.bind(this)}
          />
        )}
        {this.renderStep()}
        <SettlementCreatorOverview
          onAddSettlementOffer={() => setStep?.(1)}
          onDeleteSettlementOffer={data => deleteSettlementOffer?.(data)}
          onSetCurrentSettlementOffer={so => setCurrentSettlementOffer?.(so)}
          onEdit={s => this.onEditStep(s)}
        />
      </>
    ) : null;
  }
}

const mapStateToProps = (state: RootState) => ({
  isEdit: state.settlementCreator.isEdit,
  step: state.settlementCreator.step,
  offers: state.settlementCreator.offers,
  settlementOffers: state.settlementCreator.settlementOffers,
  isModalVisible: state.settlementCreator.isModalVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
  setStep: (step: number) => dispatch(settlementCreatorActions.setStep(step)),
  setContact: (contact: Contact) =>
    dispatch(settlementCreatorActions.setContact(contact)),
  setProduct: (product?: Product) =>
    dispatch(settlementCreatorActions.setProduct(product)),
  setOffer: (offer?: Offer) =>
    dispatch(settlementCreatorActions.setOffer(offer)),
  setPrice: (price?: AmountOption) =>
    dispatch(settlementCreatorActions.setPrice(price)),
  setDeposit: (deposit?: AmountOption) =>
    dispatch(settlementCreatorActions.setDeposit(deposit)),
  setRateNumber: (numberOfInstallments?: AmountOption) =>
    dispatch(settlementCreatorActions.setRateNumber(numberOfInstallments)),
  setContractDuration: (data?: {
    contractDuration: AmountOption;
    contractDurationValue: number;
    contractDurationType: DurationType;
  }) => dispatch(settlementCreatorActions.setContractDuration(data)),
  setCurrentSettlementOffer: (settlementOffer: SettlementOffer) =>
    dispatch(settlementCreatorActions.setSettlementOffer(settlementOffer)),
  deleteSettlementOffer: (data: { index?: number; id?: number }) =>
    dispatch(settlementCreatorActions.deleteSettlementOffer(data)),
  saveAsSettlementOffer: () =>
    dispatch(settlementCreatorSaveAsSettlementOffer()),
  updateSettlementOffers: () =>
    dispatch(settlementCreatorUpdateSettlementOffers()),
  createSettlement: () => dispatch(settlementCreatorCreateSettlement()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SettlementCreator);
