import React from 'react';
import { Typography } from 'antd';
import styles from './Header.module.scss';
import logo from '../../../../logo.png';
import environment from '../../../../environment';
import locale from '../../../locale';

interface HeaderProps {
  title: string;
}

const Header = (props: HeaderProps) => {
  return (
    <div className={styles.header}>
      <div className="d-flex justify-content-center mt-2 mb-3">
        <div className="d-flex">
          <img src={logo} alt="logo" className={styles.logo} />
          <div className="d-flex flex-direction-column ml-2">
            <Typography.Title className="mb-0">
              {environment.appName}
            </Typography.Title>
            <Typography.Text>
              {locale('subtitles.signAndPayInSafe')}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Typography.Title level={2} className="text-center">
        {props.title}
      </Typography.Title>
    </div>
  );
};

export default Header;
