import { PaymentMethodType } from './PaymentMethodType';
import { PaymentMethod } from './PaymentMethod';

export enum PaymentMethodItemType {
  Advance = 1,
  Deposit = 2,
  Installments = 3,
}

export interface PaymentMethodItem {
  type: PaymentMethodItemType;
  paymentMethod?: PaymentMethod;
  mustPayNow: boolean;
  isReady: boolean;
  isPaid: boolean;
  amount: number;
  rate?: number;

  paymentMethodTypes: PaymentMethodType[];
  paymentDate?: Date;
}
