import { Settlement, SettlementStatus } from '../../models/Settlement';
import { PaymentMethodItem } from '../../models/PaymentMethodItem';
import React from 'react';
import {
  settlementCloserActions,
  settlementCloserGetById,
  settlementCloserGetByUuid,
  settlementCloserGetPaymentMethodItems,
} from './settlementCloserSlice';
import { Utils } from '../../models/Utils';
import CloserOverview from './components/closerOverview/CloserOverview';
import { Affix, message, Result } from 'antd';
import SettlementStepper from '../../components/settlementStepper/SettlementStepper';
import styles from './SettlementCloser.module.scss';
import CloserData from './components/closerData/CloserData';
import { RootState } from '../../store/store';
import CloserSigner from './components/closerSigner/CloserSigner';
import locale from '../../locale';
import CloserPayer from './components/closerPayer/CloserPayer';
import { User } from '../../models/User';
import CustomerLayout from '../../layouts/customerLayout/CustomerLayout';
import CloserFinalizer from './components/closerFinalizer/CloserFinalizer';
import CloserFinalOverview from './components/closerFinalOverview/CloserFinalOverview';
import CloserProfile from './components/closerProfile/CloserProfile';
import { connect } from 'react-redux';
import {
  CloserStep,
  settlementStepCloserStepMap,
} from '../../models/CloserStep.enum';

interface SettlementCloserProps {
  notFound: boolean;
  model: Settlement;
  user: User;
  step: CloserStep;
  paymentMethodItems: PaymentMethodItem[];
  isAuthenticated: boolean;
  fetchModel: (id: number) => void;
  fetchPublicModel: (uuid: string) => void;
  getPaymentMethodItems: () => void;
  setStep: (step: CloserStep) => void;
  clearState: () => void;
}

interface SettlementCloserState {
  uuid?: string;
  queryStep?: string;
}

/**
 *
 */
class SettlementCloser extends React.Component<
  SettlementCloserProps,
  SettlementCloserState
> {
  state = { uuid: undefined, queryStep: undefined };

  /**
   *
   */
  componentDidMount() {
    const { fetchPublicModel } = this.props;
    const { uuid, step: queryStep } = Utils.getQueryParameters();
    uuid && fetchPublicModel(uuid);
    this.setState({ uuid, queryStep });
  }

  /**
   *
   * @param {Readonly<SettlementCloserProps>} prevProps
   */
  componentDidUpdate(prevProps: Readonly<SettlementCloserProps>) {
    const {
      isAuthenticated,
      step,
      model,
      getPaymentMethodItems,
      fetchModel,
    } = this.props;

    /** Check model changes and set next step if needed */
    if (isAuthenticated && model) {
      /** In Overview => user must click on "next" button to proceed */
      if (step === CloserStep.InitialOverview) return;

      if (
        (prevProps.step === CloserStep.InitialOverview &&
          step > prevProps.step) ||
        !prevProps.isAuthenticated
      ) {
        fetchModel(model.id);
      }

      /** If data is confirmed and model is signed fetch Payment Method Items */
      const modelChanged = !Utils.isObjectEqual(prevProps.model, model);
      if (
        modelChanged &&
        model.status >= SettlementStatus.DataConfirmed &&
        model.isSigned
      ) {
        getPaymentMethodItems();
      }

      const dataConfirmed =
        prevProps.model?.status != model.status &&
        model.status == SettlementStatus.DataConfirmed;
      if (dataConfirmed) {
        step === CloserStep.Data &&
          message.success(locale('messages.dataFilledSuccessfully'));
        this.nextStep();
        return;
      }

      const signed = !prevProps.model?.isSigned && model.isSigned;
      if (signed) {
        step === CloserStep.Signer &&
          message.success(locale('messages.signedSuccessfully'));
        this.nextStep();
        return;
      }

      const paid =
        prevProps.model?.status != model.status &&
        model.status == SettlementStatus.Paid;
      if (paid) {
        step === CloserStep.Payer &&
          message.success(locale('messages.paidSuccessfully'));
        this.nextStep();
        return;
      }

      const finalized =
        prevProps.model?.status != model.status &&
        model.status == SettlementStatus.Finalized;
      if (finalized) {
        step === CloserStep.Finalizer &&
          message.success(locale('messages.finalizedSuccessfully'));
        this.nextStep();
        return;
      }
    }
  }

  /**
   * Set next Closer step
   */
  nextStep() {
    const { model, setStep } = this.props;
    const forcePayment = this.state.queryStep === 'payment';
    let nStep = settlementStepCloserStepMap[Utils.getSettlementStep(model)];
    if (nStep === CloserStep.Signer && (model.isSigned || forcePayment)) {
      nStep = CloserStep.Payer;
    }
    setStep(nStep);
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.clearState();
  }

  /**
   * @return {JSX.Element}
   */
  render(): JSX.Element {
    const { notFound, model, user, step, isAuthenticated } = this.props;
    const { uuid } = this.state;

    return (
      <CustomerLayout>
        {model && (
          <>
            {step > CloserStep.InitialOverview &&
              step < CloserStep.FinalOverview &&
              user && (
                <div className={styles.affixContainer}>
                  <Affix className={styles.affix}>
                    <SettlementStepper step={step} />
                  </Affix>
                </div>
              )}
            <div className="platform-body">
              {step === CloserStep.InitialOverview && (
                <CloserOverview onSubmit={() => this.nextStep()} />
              )}
              {isAuthenticated && (
                <>
                  {step === CloserStep.Data && <CloserData />}
                  {step === CloserStep.Signer && <CloserSigner />}
                  {step === CloserStep.Payer && <CloserPayer />}
                  {step === CloserStep.Finalizer && <CloserFinalizer />}
                  {step === CloserStep.FinalOverview && <CloserFinalOverview />}
                </>
              )}
            </div>
          </>
        )}
        {(!uuid || notFound) && (
          <>
            {!isAuthenticated && (
              <Result
                status="404"
                title="404"
                subTitle={locale('subtitles.pageNotFound')}
              />
            )}
            {isAuthenticated && <CloserProfile />}
          </>
        )}
      </CustomerLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  notFound: state.settlementCloser.notFound,
  model: state.settlementCloser.model,
  user: state.auth.user,
  step: state.settlementCloser.step,
  paymentMethodItems: state.settlementCloser.paymentMethodItems,
  isAuthenticated: !!state.auth.token,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchPublicModel: (uuid: string) =>
    dispatch(settlementCloserGetByUuid({ uuid })),
  fetchModel: (id: number) => dispatch(settlementCloserGetById({ id })),
  setStep: (step: number) => dispatch(settlementCloserActions.setStep(step)),
  getPaymentMethodItems: () =>
    dispatch(settlementCloserGetPaymentMethodItems()),
  clearState: () => dispatch(settlementCloserActions.clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettlementCloser);
