/**
 * Use the CSS tab above to style your Element's container.
 */
import React, { useEffect, useState } from 'react';
import { IbanElement, useElements } from '@stripe/react-stripe-js';
import styles from './IbanForm.module.scss';
import { Button, Form, Input, Typography, message } from 'antd';
import { StripeIbanElement } from '@stripe/stripe-js';
import locale from '../../locale';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementDetailActions,
  settlementDetailSelector,
  settlementDetailStripeIbanSetupIntent,
} from '../../features/settlementDetail/settlementDetailSlice';
interface IbanFormProps {
  idSettlement?: number;
  onIbanFormSubmit: (stripePaymentMethodId: string) => void;
  countryCode: string;
  mustPayNow: boolean;
}

/**
 * Handle insertion of SDD info
 * @constructor
 */
export default function IbanForm({
  idSettlement,
  onIbanFormSubmit,
  countryCode,
  mustPayNow,
}: IbanFormProps) {
  const [accountholder, setAccountholder] = useState('');
  const [email, setEmail] = useState('');
  const [ibanHelp, setIbanHelp] = useState<string | undefined>();
  const [ibanStatus, setIbanStatus] = useState<
    '' | 'success' | 'error' | 'warning' | 'validating' | undefined
  >('');
  const elements = useElements();
  const stripe = useStripe();
  const [iban, setIban] = useState<StripeIbanElement>();
  const { setupIntentClientSecret } = useSelector(settlementDetailSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stripe || !setupIntentClientSecret || !iban) {
      console.log(
        'one of stripe, setupIntentClientSecret, iban are falsy',
        stripe,
        setupIntentClientSecret,
        iban,
      );
      return;
    }
    stripe
      .confirmSepaDebitSetup(setupIntentClientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: accountholder,
            email: email,
          },
        },
      })
      .then(result => {
        if (result.error) {
          message.error(result.error.message);
        } else {
          result.setupIntent?.payment_method &&
            onIbanFormSubmit(result.setupIntent.payment_method);

          !mustPayNow &&
            dispatch(settlementDetailActions.closeStripeIbanModal());
          message.success(locale('messages.paymentMethodUpdated'));
        }
      });
  }, [setupIntentClientSecret]);

  const handleSubmit = async (
    iban: StripeIbanElement,
    email: string,
    accountholderName: string,
  ) => {
    setIban(iban);
    setEmail(email);
    setAccountholder(accountholderName);
    idSettlement &&
      dispatch(settlementDetailStripeIbanSetupIntent({ idSettlement }));
  };

  const onFinish = (values: any) => {
    if (!elements) {
      setIbanStatus('error');
      setIbanHelp(locale('errors.genericError'));
      return;
    }
    if (values?.iban?.error?.message) {
      setIbanStatus('error');
      setIbanHelp(values?.iban?.error?.message);
    } else {
      setIbanStatus('success');
      setIbanHelp(undefined);
      const iban = elements.getElement(IbanElement);
      if (!iban) {
        setIbanStatus('error');
        setIbanHelp(locale('errors.genericError'));
        return;
      }
      handleSubmit(iban, values.email, values['accountholder-name']);
    }
  };

  const IBAN_STYLE = {
    base: {
      color: '#32325d',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a',
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: countryCode,
    style: IBAN_STYLE,
  };

  const onFinishFailed = (errorInfo: any) => {
    for (const errorField of errorInfo.errorFields) {
      for (const [i, name] of errorField.name.entries()) {
        if (name === 'iban') {
          setIbanStatus('error');
          setIbanHelp(errorField.errors[i]);
        }
      }
    }
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={locale('labels.bankAccountHolder')}
          name="accountholder-name"
          rules={[{ required: true }]}
        >
          <Input
            value={accountholder}
            onChange={event => setAccountholder(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={locale('labels.email')}
          name="email"
          rules={[{ required: true }]}
        >
          <Input
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[{ required: true }]}
          validateStatus={ibanStatus}
          help={ibanHelp}
        >
          <IbanElement
            options={IBAN_ELEMENT_OPTIONS}
            className={`ant-input ${styles.ibanInput}`}
          />
        </Form.Item>
        <Typography.Paragraph
          type="secondary"
          className={styles.mandateAcceptance}
        >
          {locale('messages.SDDMandateAcceptance')}
        </Typography.Paragraph>
        <Button type="primary" htmlType="submit">
          {locale(`actions.${mustPayNow ? 'paySDD' : 'saveSDD'}`)}
        </Button>
      </Form>
    </>
  );
}
