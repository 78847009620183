import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCloserSelector,
  settlementCloserSignBlock,
} from '../../settlementCloserSlice';
import { Row } from 'antd';
import { ContractBlock } from '../../../../models/ContractBlock';
import { Utils } from '../../../../models/Utils';
import { SettlementContractBlockSign } from '../../../../models/SettlementContractBlockSign';
import { Contract } from '../../../../models/Contract';
import CloserSignerBlock from './components/closerSignerBlock/CloserSignerBlock';
import locale from '../../../../locale';

export type PopulatedContract = ContractBlock & {
  sign: SettlementContractBlockSign;
  contract: Contract;
};

const CloserSigner = () => {
  const dispatch = useDispatch();

  const { model } = useSelector(settlementCloserSelector);
  const [signs, setSigns] = useState<
    Record<string, SettlementContractBlockSign>
  >({});
  const [contents, setContents] = useState<Record<string, string>>({});
  const [blocks, setBlocks] = useState<PopulatedContract[]>([]);

  const { settlementContracts, settlementOffers } = model || {};

  useEffect(() => {
    const nextSigns: Record<string, SettlementContractBlockSign> = {};
    const nextContents: Record<string, string> = {};
    settlementContracts?.forEach(c => {
      c.settlementContractBlocks?.forEach(b => {
        nextSigns[`${b.contractBlockId}`] = b.sign;
        nextContents[`${b.contractBlockId}`] = b.previewContent;
      });
    });
    setSigns(nextSigns);
    setContents(nextContents);
  }, [settlementContracts]);

  useEffect(() => {
    const nextBlocks: PopulatedContract[] = [];
    settlementOffers?.forEach(o =>
      nextBlocks.push(
        ...(o.offer?.contract?.blocks?.map(b => ({
          ...b,
          sign: signs[`${b.id}`],
          content: contents[`${b.id}`],
          contract: o.offer.contract,
        })) || []),
      ),
    );
    setBlocks(nextBlocks);
  }, [settlementOffers, signs]);

  const onSign = async (block: ContractBlock) =>
    (await Utils.askForConfirmation({
      title: locale('titles.sign'),
      content: locale('subtitles.signContractBlock'),
      okText: locale('actions.signWithOtp'),
    })) && dispatch(settlementCloserSignBlock({ block }));

  return (
    <Row gutter={[16, 32]}>
      {blocks.map((block, i) => (
        <CloserSignerBlock
          key={block.id}
          prevBlock={blocks[i - 1]}
          block={block}
          sign={signs[block.id]}
          onSign={onSign}
        />
      ))}
    </Row>
  );
};

export default CloserSigner;
