import ax from 'axios';
import environment from '../../environment';

const axios = ax.create({
  baseURL: environment.baseServerUrl,
});

export const ignoredRequests = [];

const handleResponse = (
  isSuccess: boolean,
  error: any,
  handlerDisabled: boolean,
) => {
  if (!handlerDisabled) {
    // loaderActions.decrement();
  }
};

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (!config?.data?.handlerDisabled) {
      // loaderActions.increment();
    }
    return config;
  },
  error => {
    // Do something with request error
    handleResponse(false, error, error.config?.data?.handlerDisabled);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // Do something with response data
    handleResponse(true, null, response.request?.config?.data?.handlerDisabled);
    return response.data;
  },
  error => {
    // Do something with response error
    handleResponse(false, error, error.config?.data?.handlerDisabled);
    return Promise.reject(error);
  },
);

export const setApiAuthorization = (token?: string, otp?: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  if (otp) {
    axios.defaults.headers.common['X-Pro-Sign-Otp'] = otp;
  } else {
    delete axios.defaults.headers.common['X-Pro-Sign-Otp'];
  }
};

export default axios;
