import { Mapper } from './Mapper';
import { SettlementSignType } from './SettlementContract';

export interface SettlementSign {
  isSigned: boolean;
  type?: SettlementSignType;
  files?: string[];
  signedDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  settlementBlock?: number;
  otp?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

/* eslint-disable camelcase */
export interface ServerSettlementSign {
  is_signed: boolean;
  type?: SettlementSignType;
  files_set?: string[];
  signed_date?: string;
  min_date?: string;
  max_date?: string;
  settlement_block?: number;
  otp?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
}

/* eslint-enable camelcase */

/**
 * Map SettlementSign model
 */
export abstract class SettlementSignMapper
  implements Mapper<SettlementSign, ServerSettlementSign> {
  /**
   * @param {Partial<ServerSettlementSign>} o
   * @return {SettlementSign}
   */
  static map(o: Partial<ServerSettlementSign>): SettlementSign {
    return <SettlementSign>{
      isSigned: o.is_signed,
      type: o.type,
      files: o.files_set,
      settlementBlock: o.settlement_block,
      signedDate: o.signed_date ? new Date(o.signed_date) : undefined,
      minDate: o.min_date ? new Date(o.min_date) : undefined,
      maxDate: o.max_date ? new Date(o.max_date) : undefined,
      otp: o.otp,
      firstName: o.first_name,
      lastName: o.last_name,
      phone: o.phone,
    };
  }

  /**
   * @param {Partial<SettlementSign>} o
   * @return {ServerSettlementSign}
   */
  static mapReverse(o: Partial<SettlementSign>): ServerSettlementSign {
    return <ServerSettlementSign>{
      is_signed: o.isSigned,
      type: o.type,
      files_set: o.files,
      settlement_block: o.settlementBlock,
      signed_date: o.signedDate?.toISOString() || null,
      min_date: o.minDate?.toISOString() || null,
      max_date: o.maxDate?.toISOString() || null,
      otp: o.otp,
      first_name: o.firstName,
      last_name: o.lastName,
      phone: o.phone,
    };
  }

  /**
   * @param {ServerSettlementSign[]} models
   * @return {SettlementSign[]}
   */
  static mapArray(models: ServerSettlementSign[]): SettlementSign[] {
    return models.map(m => SettlementSignMapper.map(m));
  }

  /**
   * @param {SettlementSign[]} models
   * @return {ServerSettlementSign[]}
   */
  static mapReverseArray(models: SettlementSign[]): ServerSettlementSign[] {
    return models.map(m => SettlementSignMapper.mapReverse(m));
  }
}
