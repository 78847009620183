import { Contact } from '../../models/Contact';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { ContactApiService } from '../../services/ContactApiService';
import { message } from 'antd';
import locale from '../../locale';
import { loaderActions } from '../loader/loaderSlice';
import { contactsInit } from '../contacts/contactsSlice';
import { settlementDetailGetById } from '../settlementDetail/settlementDetailSlice';

export const contactDetailCreateContact = createAsyncThunk(
  'contactDetail/createContact',
  async (payload: Partial<Contact>, { dispatch, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await ContactApiService.create(payload);
      dispatch(contactsInit({}));
      dispatch(loaderActions.decrement());
      message.success(locale('messages.contactCreated'));
      return model;
    } catch (e) {
      message.error(locale('errors.createContact'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

export const contactDetailUpdateContact = createAsyncThunk(
  'contactDetail/updateContact',
  async (payload: Contact, { dispatch, getState, rejectWithValue }) => {
    dispatch(loaderActions.increment());
    try {
      const model = await ContactApiService.update(payload);
      dispatch(loaderActions.decrement());

      /** if settlement contact is the same => refresh */
      const settlement = (<RootState>getState()).settlementDetail.model;
      settlement?.contact.id === model.id &&
        dispatch(settlementDetailGetById({ id: settlement.id }));

      dispatch(contactsInit({}));
      message.success(locale('messages.contactUpdated'));
      return model;
    } catch (e) {
      message.error(locale('errors.updateContact'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({});
    }
  },
);

interface ContactDetailSliceState {
  isModalVisible: boolean;
  isEdit: boolean;
  model?: Contact;
}

const initialState: ContactDetailSliceState = {
  isModalVisible: false,
  isEdit: false,
  model: undefined,
};

export const contactDetailSlice = createSlice({
  name: 'contactDetail',
  initialState,
  reducers: {
    setIsModalVisible(state, { payload }) {
      state.isModalVisible = payload;
    },
    openModal(state, { payload }) {
      state.model = payload;
      state.isEdit = !!payload;
      state.isModalVisible = true;
    },
    closeModal(state) {
      state = {
        ...initialState,
      };
      return state;
    },
    setModel(state, { payload }) {
      state.model = payload;
    },
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [`${contactDetailCreateContact.fulfilled}`]: () => ({ ...initialState }),
    [`${contactDetailUpdateContact.fulfilled}`]: () => ({ ...initialState }),
  },
});

export const contactDetailSelector = (
  state: RootState,
): ContactDetailSliceState => state.contactDetail;
export const contactDetailActions = contactDetailSlice.actions;
