import { Mapper } from './Mapper';
import { DurationType } from './DurationType.enum';
import {
  AmountOption,
  AmountOptionMapper,
  ServerAmountOption,
} from './AmountOption';

export interface Duration {
  id: number;
  duration: AmountOption;
  durationType: DurationType;
}

/* eslint-disable camelcase */
export interface ServerDuration {
  id: number;
  duration: ServerAmountOption;
  duration_type: DurationType;
}

/* eslint-enable camelcase */

/**
 * Map Duration model
 */
export abstract class DurationMapper
  implements Mapper<Duration, ServerDuration> {
  /**
   * @param {Partial<ServerDuration>} o
   * @return {Duration}
   */
  static map(o: Partial<ServerDuration>): Duration {
    return <Duration>{
      id: o.id,
      duration: o.duration ? AmountOptionMapper.map(o.duration) : undefined,
      durationType: o.duration_type,
    };
  }

  /**
   * @param {Partial<Duration>} o
   * @return {ServerDuration}
   */
  static mapReverse(o: Partial<Duration>): ServerDuration {
    return <ServerDuration>{
      id: o.id,
      duration: o.duration ? AmountOptionMapper.mapReverse(o.duration) : null,
      duration_type: o.durationType,
    };
  }

  /**
   * @param {ServerDuration[]} models
   * @return {Duration[]}
   */
  static mapArray(models: ServerDuration[]): Duration[] {
    return models.map(m => DurationMapper.map(m));
  }

  /**
   * @param {Duration[]} models
   * @return {ServerDuration[]}
   */
  static mapReverseArray(models: Duration[]): ServerDuration[] {
    return models.map(m => DurationMapper.mapReverse(m));
  }
}
