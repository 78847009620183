import React, { useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';
import locale from '../../../../locale';
import { Offer } from '../../../../models/Offer';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorFetchOffers,
  settlementCreatorSelector,
} from '../../settlementCreatorSlice';

interface SettlementCreatorOfferSetterProps {
  onOfferSelected: (offer: Offer) => void;
}

const SettlementCreatorOfferSetter = ({
  onOfferSelected,
}: SettlementCreatorOfferSetterProps) => {
  const dispatch = useDispatch();
  const { offer, offers } = useSelector(settlementCreatorSelector);
  const [cOffer, setCOffer] = useState<Offer | undefined>(offer || offers?.[0]);

  useEffect(() => {
    setCOffer(offer || offers?.[0]);
    dispatch(settlementCreatorFetchOffers());
  }, []);

  useEffect(() => {
    setCOffer(offer || offers?.[0]);
  }, [offer, offers]);

  return (
    <div className="p-2">
      <Form layout="vertical">
        <Form.Item
          label={locale('labels.offer')}
          initialValue={cOffer?.id}
          required
          rules={[{ required: true }]}
          name="offer"
          extra={locale('subtitles.selectContractOffer')}
          getValueProps={() => ({ offer: cOffer?.id })}
        >
          <Select
            value={cOffer?.id}
            placeholder={locale('placeholders.selectOffer')}
            onChange={value => {
              const found = offers.find(o => o.id === value);
              setCOffer(found);
            }}
          >
            {offers?.map(o => (
              <Select.Option key={o.id} value={o.id}>
                {o.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          type="primary"
          block
          disabled={!cOffer}
          onClick={() => cOffer && onOfferSelected(cOffer)}
        >
          {locale('actions.confirmOffer')}
        </Button>
      </Form>
    </div>
  );
};

export default SettlementCreatorOfferSetter;
