import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  CloseOutlined,
  ContainerOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import styles from './Sider.module.scss';
import AppHistory from '../../../models/AppHistory';
import { AppRoute } from '../../../models/appRouter/AppRoute.enum';
import logo from '../../../../logo.png';
import locale from '../../../locale';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../features/auth/authSlice';

/**
 * @constructor
 */
export function Sider() {
  const { user } = useSelector(authSelector);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <Layout.Sider
      collapsed={collapsed}
      className={styles.sider}
      onCollapse={setCollapsed}
      collapsible
      trigger={collapsed ? <MenuOutlined /> : <CloseOutlined />}
    >
      <Menu
        theme="dark"
        mode="vertical"
        defaultSelectedKeys={['contracts']}
        className={styles.menu}
      >
        <Menu.Item
          key="logo"
          className={styles.logoContainer}
          icon={
            <img
              src={
                user?.accounts?.[0]?.logoLight ||
                user?.accounts?.[0]?.logo ||
                logo
              }
              alt="logo"
              className={styles.logo}
            />
          }
          onClick={() => AppHistory.push(AppRoute.Root)}
        />
        <Menu.Item
          key="settlementContracts"
          icon={<ContainerOutlined />}
          onClick={() => AppHistory.push(AppRoute.Settlements)}
        >
          {locale('titles.contracts')}
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => AppHistory.push(AppRoute.Logout)}
        >
          {locale('actions.logout')}
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
}
