import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  settlementCreatorActions,
  settlementCreatorSelector,
} from '../../settlementCreatorSlice';
import locale from '../../../../locale';
import SettlementCreator from '../../SettlementCreator';
import './SettlementCreatorModal.scss';
import { contactDetailSelector } from '../../../contactDetail/contactDetailSlice';

const SettlementCreatorModal = () => {
  const dispatch = useDispatch();
  const { isModalVisible, isEdit } = useSelector(settlementCreatorSelector);
  const { isModalVisible: isContactModalVisible } = useSelector(
    contactDetailSelector,
  );

  return (
    <Modal
      visible={isModalVisible && !isContactModalVisible}
      title={locale(`titles.${isEdit ? 'updateOffers' : 'newContract'}`)}
      className="settlement-creator-modal"
      onCancel={() => dispatch(settlementCreatorActions.closeModal())}
      footer={null}
    >
      <SettlementCreator />
    </Modal>
  );
};

export default SettlementCreatorModal;
