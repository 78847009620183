import { ApiUrl } from '../models/ApiUrl.enum';
import {
  ServerSettlement,
  Settlement,
  SettlementMapper,
} from '../models/Settlement';
import axios from '../middlewares/axios';
import { UploadFile } from 'antd/es/upload/interface';
import {
  PaymentMethodItem,
  PaymentMethodItemType,
} from '../models/PaymentMethodItem';
import {
  SettlementDataFieldValue,
  SettlementDataFieldValueMapper,
} from '../models/SettlementDataFieldValue';
import {
  ServerSettlementSign,
  SettlementSign,
  SettlementSignMapper,
} from '../models/SettlementSign';
import { Attachment } from '../models/Attachment';
import { PaymentMethodType } from '../models/PaymentMethodType';
import { Contact } from '../models/Contact';
import {
  ServerSettlementOffer,
  SettlementOffer,
  SettlementOfferMapper,
} from '../models/SettlementOffer';
import {
  Pagination,
  PaginationMapper,
  ServerPagination,
} from '../models/Pagination';
import {
  PaymentMethod,
  PaymentMethodMapper,
  ServerPaymentMethod,
} from '../models/PaymentMethod';
import { Utils } from '../models/Utils';
import { SettlementContractBlock } from '../models/SettlementContractBlock';
import {
  PaymentConfig,
  PaymentConfigMapper,
  ServerPaymentConfig,
} from '../models/PaymentConfig';

/**
 * Settlement APIs
 */
export abstract class SettlementApiService {
  /**
   * Get Settlement by id
   * @param {number} id
   * @return {Promise<Settlement>}
   */
  static async getById(id: number): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.get(
      ApiUrl.SettlementDetail.replace(':id', `${id}`),
    );
    return SettlementMapper.map(sModel);
  }

  /**
   * Delete Settlement by id
   * @param {number} id
   * @return {Promise<void>}
   */
  static async delete(id: number): Promise<void> {
    return await axios.delete(ApiUrl.SettlementDetail.replace(':id', `${id}`));
  }

  /**
   * Get Settlement by uuid
   * @param {string} uuid
   * @return {Promise<Settlement>}
   */
  static async getByUuid(uuid: string): Promise<Settlement> {
    const {
      results: sModels,
    }: ServerPagination & {
      results: ServerSettlement[];
    } = await axios.get(ApiUrl.Settlements, {
      params: { uuid },
    });
    const model: Settlement | undefined = sModels[0]
      ? SettlementMapper.map(sModels[0])
      : undefined;
    if (model) {
      return model;
    } else {
      throw new Error('Not found');
    }
  }

  /**
   * Get PublicSettlementByUuid by uuid
   * @param {string} uuid
   * @return {Promise<Settlement>}
   */
  static async getPublicSettlementByUuid(uuid: string): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.get(
      ApiUrl.PublicSettlements.replace(':uuid', uuid),
    );
    const model: Settlement | undefined = sModel
      ? SettlementMapper.map(sModel)
      : undefined;
    if (model) {
      return model;
    } else {
      throw new Error('Not found');
    }
  }

  /**
   * Get paginated list of Settlement
   * @param {Pagination} pagination
   * @param {string} search
   * @return {Promise<{ list: Settlement[], resPagination: Pagination }>}
   */
  static async getList(
    pagination: Pagination,
    search: string,
  ): Promise<{ list: Settlement[]; resPagination: Pagination }> {
    const params = {
      ordering: 'status',
      ...(search?.length ? { search } : {}),
      ...PaginationMapper.mapReverse(pagination),
    };

    const response: ServerPagination & {
      results: ServerSettlement[];
    } = await axios.get(ApiUrl.Settlements, { params });

    const list: Settlement[] = SettlementMapper.mapArray(
      response.results || [],
    );
    const resPagination: Pagination = PaginationMapper.map(response);
    return { list, resPagination };
  }

  /**
   * Update Settlement form data
   * @param {number} id
   * @param {SettlementDataFieldValue[]} dataFieldValues
   * @return {Promise<Settlement>}
   */
  static async updateData(
    id: number,
    dataFieldValues: SettlementDataFieldValue[],
  ): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.post(
      ApiUrl.SettlementDetailUpdateData.replace(':id', `${id}`),
      SettlementDataFieldValueMapper.mapReverseArray(dataFieldValues),
    );
    return SettlementMapper.map(sModel);
  }

  /**
   * Update Settlement form data
   * @param {number} id
   * @param {SettlementDataFieldValue[]} dataFieldValues
   * @return {Promise<{clientSecret: string}>}
   */
  static async setupIntent(id: number): Promise<{ clientSecret: string }> {
    return await axios.post(
      ApiUrl.SettlementDetailSetupIntent.replace(':id', `${id}`),
      {},
    );
  }

  /**
   * Add Advance to Settlement
   * @param {number} id
   * @param {number} amount
   * @return {Promise<Settlement>}
   */
  static async addAdvance(id: number, amount: number): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.post(
      ApiUrl.SettlementDetailSetAdvance.replace(':id', `${id}`),
      { amount },
    );
    return SettlementMapper.map(sModel);
  }

  /**
   * Update Settlement contact
   * @param {number} id
   * @param {Contact} contact
   * @return {Promise<Settlement>}
   */
  static async updateContact(
    id: number,
    contact: Contact,
  ): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.patch(
      ApiUrl.SettlementDetail.replace(':id', `${id}`),
      { contact: contact.id },
    );
    return SettlementMapper.map(sModel);
  }

  /**
   * Update Settlement name
   * @param {number} id
   * @param {string} name
   * @return {Promise<Settlement>}
   */
  static async updateName(id: number, name: string): Promise<Settlement> {
    const sModel: ServerSettlement = await axios.patch(
      ApiUrl.SettlementDetail.replace(':id', `${id}`),
      { name },
    );
    return SettlementMapper.map(sModel);
  }

  /**
   * Update Settlement contract blocks
   * @param {SettlementContractBlock} settlementContractBlocks
   * @return {Promise<void>}
   */
  static async updateContractBlocks(
    settlementContractBlocks: SettlementContractBlock[],
  ): Promise<void> {
    await Promise.all(
      settlementContractBlocks.map(cb =>
        axios.patch(
          ApiUrl.SettlementContractsBlockDetail.replace(':id', `${cb.id}`),
          { content: cb.content },
        ),
      ),
    );
  }

  /**
   * Confirm Settlement data
   * @param {number} id
   * @return {Promise<void>}
   */
  static async confirmData(id: number): Promise<void> {
    return await axios.patch(ApiUrl.SettlementDetail.replace(':id', `${id}`), {
      status: 2, // DATA_CONFIRMED = 2
    });
  }

  /**
   * Delete Settlement sign
   * @param {number} id
   * @param {number} idSettlementContract
   * @return {Promise<void>}
   */
  static async deleteSign(
    id: number,
    idSettlementContract: number,
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementContractDeleteSign.replace(
        ':id',
        `${idSettlementContract}`,
      ),
      {},
    );
  }

  /**
   * Sign Settlement
   * @param {number} id
   * @param {number} idSettlementContract
   * @param {Date} signedDate
   * @return {Promise<void>}
   */
  static async sign(
    id: number,
    idSettlementContract: number,
    signedDate: Date,
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementContractSign.replace(':id', `${idSettlementContract}`),
      { sign_datetime: signedDate.toISOString() },
    );
  }

  /**
   * Sign Settlement
   * @param {number} id
   * @param {number} idSettlementContractBlock
   * @param {number} idBlock
   * @return {Promise<void>}
   */
  static async signBlock(
    id: number,
    idSettlementContractBlock: number,
    idBlock: number,
  ): Promise<SettlementSign> {
    const sign: ServerSettlementSign = await axios.post(
      ApiUrl.SettlementContractsBlocksSign.replace(
        ':id',
        `${idSettlementContractBlock}`,
      ),
      { contract_block: idBlock },
    );
    return SettlementSignMapper.map(sign);
  }

  /**
   * Pay With Rid Offline
   * @param {number} id
   * @param {any} data
   * @return {Promise<void>}
   */
  static async payWithRidOffline(
    id: number,
    data: { paymentMethod: PaymentMethodItem; [k: string]: any },
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementDetailPayRidOffline.replace(':id', `${id}`),
      data,
    );
  }

  /**
   * Add Stripe Payment Method
   * @param {number} id
   * @param {any} data
   * @return {Promise<void>}
   */
  static async addStripePaymentMethod(
    id: number,
    data: { paymentMethod: PaymentMethodItem; [k: string]: any },
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementDetailPayRidOffline.replace(':id', `${id}`),
      data,
    );
  }

  /**
   * Finalize
   * @param {number} id
   * @param {string} customerNotes
   * @param {string} sellerNotes
   * @return {Promise<void>}
   */
  static async finalize(
    id: number,
    customerNotes?: string,
    sellerNotes?: string,
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementDetailFinalize.replace(':id', `${id}`),
      {
        ...(customerNotes ? { customer_notes: customerNotes } : {}),
        ...(sellerNotes ? { seller_notes: sellerNotes } : {}),
      },
    );
  }

  /**
   * Get Settlement Payment Methods
   * @param {Settlement} settlement
   * @param {boolean} isCustomer
   * @return {Promise<PaymentMethodItem[]>}
   */
  static async getPaymentMethodItems(
    settlement: Settlement,
    isCustomer: boolean,
  ): Promise<PaymentMethodItem[]> {
    const list: PaymentMethodItem[] = [];

    const canPay = (pm: PaymentMethodType) =>
      (isCustomer && pm.isEnabledForUser) ||
      (!isCustomer && pm.isEnabledForSeller);

    if (settlement.advanceAmount) {
      list.push({
        paymentMethod: settlement.advancePaymentMethod,
        mustPayNow: settlement.advanceMustPayNow,
        isReady: settlement.advanceIsReady,
        isPaid: settlement.isAdvancePaid,
        type: PaymentMethodItemType.Advance,
        amount: settlement.advanceAmount,
        paymentMethodTypes:
          settlement.config?.advancePaymentMethodTypes?.filter(canPay) || [],
      });
    }
    if (settlement.totalDeposit) {
      list.push({
        paymentMethod: settlement.depositPaymentMethod,
        mustPayNow: settlement.depositMustPayNow,
        isReady: settlement.depositIsReady,
        isPaid: settlement.isDepositPaid,
        type: PaymentMethodItemType.Deposit,
        amount: settlement.totalDeposit,
        paymentMethodTypes:
          settlement.config?.depositPaymentMethodTypes?.filter(canPay) || [],
      });
    }
    if (settlement.totalInstallments) {
      const firstPaymentConfig =
        settlement?.settlementOffers?.[0]?.paymentConfigs?.[0];

      if (firstPaymentConfig) {
        const amount = firstPaymentConfig.amount - firstPaymentConfig.discount;
        list.push({
          paymentMethod: settlement.installmentsPaymentMethod,
          mustPayNow: settlement.installmentsMustPayNow,
          isReady: settlement.installmentsIsReady,
          isPaid: settlement.isInstallmentsPaid,
          type: PaymentMethodItemType.Installments,
          amount,
          rate: 10,
          paymentMethodTypes:
            settlement.config?.installmentsPaymentMethodTypes?.filter(canPay) ||
            [],
        });
      }
    }
    return Promise.resolve(list);
  }

  /**
   *
   * @param {number} id
   * @param {number} idSettlementContract
   * @param {UploadFile[]} files
   * @return {Promise<Attachment[]>}
   */
  static async uploadContractAttachments(
    id: number,
    idSettlementContract: number,
    files: UploadFile[],
  ): Promise<Attachment[]> {
    return Utils.uploadFiles(
      files,
      ApiUrl.SettlementContractUploadAttachment.replace(
        ':id',
        `${idSettlementContract}`,
      ),
    );
  }

  /**
   *
   * @param {number} id
   * @param {UploadFile[]} files
   * @return {Promise<Attachment[]>}
   */
  static async uploadAttachments(
    id: number,
    files: UploadFile[],
  ): Promise<Attachment[]> {
    return Utils.uploadFiles(
      files.filter(f => !f.url),
      ApiUrl.SettlementUploadAttachment.replace(':id', `${id}`),
    );
  }

  /**
   * Delete Settlement Contract Attachment
   * @param {number} idSettlementContract
   * @param {number} id
   * @return {Promise<void>}
   */
  static async deleteSettlementContractAttachment(
    idSettlementContract: number,
    id: number,
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementContractDeleteAttachment.replace(
        ':id',
        `${idSettlementContract}`,
      ),
      { attachment: id },
    );
  }

  /**
   * Delete Attachment
   * @param {number} idSettlement
   * @param {number} id
   * @return {Promise<void>}
   */
  static async deleteAttachment(
    idSettlement: number,
    id: number,
  ): Promise<void> {
    return await axios.post(
      ApiUrl.SettlementContractDeleteAttachment.replace(
        ':id',
        `${idSettlement}`,
      ),
      { attachment: id },
    );
  }

  /**
   * Create Settlement
   * @param {number} idSeller
   * @param {number} idContact
   * @param {SettlementOffer} settlementOffers
   * @return {Promise<Offer[]>}
   */
  static async createSettlement(
    idSeller: number,
    idContact: number,
    settlementOffers: SettlementOffer[],
  ): Promise<Settlement> {
    const params = {
      seller: idSeller,
      contact: idContact,
      settlementoffer_set: SettlementOfferMapper.mapReverseArray(
        settlementOffers,
      ),
    };
    const sSettlement: ServerSettlement = await axios.post(
      ApiUrl.Settlements,
      params,
    );
    return SettlementMapper.map(sSettlement);
  }

  /**
   * Get list of Offer
   * @param {number} id
   * @param {SettlementOffer[]} settlementOffers
   * @return {Promise<Settlement>}
   */
  static async updateSettlementOffers(
    id: number,
    settlementOffers: SettlementOffer[],
  ): Promise<Settlement> {
    const sSettlement: ServerSettlement = await axios.post(
      ApiUrl.SettlementDetailUpdateOffers.replace(':id', `${id}`),
      SettlementOfferMapper.mapReverseArray(settlementOffers),
    );
    return SettlementMapper.map(sSettlement);
  }

  /**
   * Pay Now
   * @param {string} id
   * @param {string} stripePaymentMethodId
   * @param {PaymentMethodItemType[]} paymentMethodItemTypes
   * @return {Promise<{clientSecret: string}>}
   */
  static async payNow(
    id: string,
    stripePaymentMethodId: string,
    paymentMethodItemTypes: PaymentMethodItemType[],
  ): Promise<{ clientSecret: string }> {
    return await axios.post(ApiUrl.SettlementDetailPayNow.replace(':id', id), {
      stripe_payment_method_id: stripePaymentMethodId,
      to_pay_now: paymentMethodItemTypes,
    });
  }

  /**
   * Get Payment Methods
   * @param {number} idContact
   * @return {Promise<PaymentMethod[]>}
   */
  static async getPaymentMethods(idContact: number): Promise<PaymentMethod[]> {
    const {
      results: sPaymentMethods,
    }: ServerPagination & {
      results: ServerPaymentMethod[];
    } = await axios.get(ApiUrl.PaymentMethods, {
      params: { settlement__contact: idContact },
    });
    return PaymentMethodMapper.mapArray(sPaymentMethods || []);
  }

  /**
   * Add Payment Method Stripe Card
   * @param {string} id
   * @param {number} idPaymentMethodType
   * @param {Record<string, any>} data
   * @return {Promise<PaymentMethod>}
   */
  static async addPaymentMethod(
    id: string,
    idPaymentMethodType: number,
    data: Record<string, any> = {},
  ): Promise<PaymentMethod> {
    const sPaymentMethod: ServerPaymentMethod = await axios.post(
      ApiUrl.SettlementDetailAddPaymentMethod.replace(':id', id),
      {
        payment_method_type: idPaymentMethodType,
        ...data,
      },
    );
    return PaymentMethodMapper.map(sPaymentMethod);
  }

  /**
   * Add Payment Method Sepa Online
   * @param {string} id
   * @param {number} idPaymentMethodType
   * @param {Partial<PaymentMethod>} partialPaymentMethod
   * @return {Promise<PaymentMethod>}
   */
  static async addPaymentMethodSepaOnline(
    id: string,
    idPaymentMethodType: number,
    partialPaymentMethod: Partial<PaymentMethod>,
  ): Promise<PaymentMethod> {
    const sPaymentMethod: ServerPaymentMethod = await axios.post(
      ApiUrl.SettlementDetailAddPaymentMethod.replace(':id', id),
      {
        ...PaymentMethodMapper.mapReverse(partialPaymentMethod),
        payment_method_type: idPaymentMethodType,
      },
    );
    return PaymentMethodMapper.map(sPaymentMethod);
  }

  /**
   * Set Settlement Payment Methods
   * @param {string} id
   * @param {number | null} idPaymentMethod
   * @param {PaymentMethodItemType[]} paymentMethodItemTypes
   * @return {Promise<void>}
   */
  static async setPaymentMethods(
    id: string,
    idPaymentMethod: number | null,
    paymentMethodItemTypes: PaymentMethodItemType[],
  ): Promise<PaymentMethod> {
    const payload: Record<string, any> = {};
    paymentMethodItemTypes.forEach(t => {
      switch (t) {
        case PaymentMethodItemType.Advance:
          payload['advance_payment_method'] = idPaymentMethod;
          break;
        case PaymentMethodItemType.Deposit:
          payload['deposit_payment_method'] = idPaymentMethod;
          break;
        case PaymentMethodItemType.Installments:
          payload['installments_payment_method'] = idPaymentMethod;
          break;
      }
    });
    return await axios.patch(
      ApiUrl.SettlementDetail.replace(':id', id),
      payload,
    );
  }

  /**
   * Upload Payment Method Attachments
   * @param {number} idPaymentMethod
   * @param {UploadFile[]} files
   * @return {Promise<void>}
   */
  static async uploadPaymentMethodAttachments(
    idPaymentMethod: number,
    files: UploadFile[],
  ) {
    return Utils.uploadFiles(
      files.filter(f => !f.url),
      ApiUrl.PaymentMethodDetailUploadAttachment.replace(
        ':id',
        `${idPaymentMethod}`,
      ),
    );
  }

  /**
   * Delete Payment Method Attachment
   * @param {number} id
   * @return {Promise<void>}
   */
  static async deletePaymentMethodAttachment(id: number) {
    return axios.delete(ApiUrl.AttachmentDetail.replace(':id', `${id}`));
  }

  /**
   *
   * @param {number} id
   * @param {Partial<ServerSettlementOffer>} data
   * @return {Promise<SettlementOffer>}
   */
  static async updateSettlementOffer(
    id: number,
    data: Partial<ServerSettlementOffer>,
  ): Promise<SettlementOffer> {
    const sSettlementOffer: ServerSettlementOffer = await axios.patch(
      ApiUrl.SettlementOfferDetail.replace(':id', `${id}`),
      data,
    );
    return SettlementOfferMapper.map(sSettlementOffer);
  }

  /**
   *
   * @param {number} id
   * @param {PaymentConfig[]} paymentConfigs
   * @return {Promise<PaymentConfig[]>}
   */
  static async setSettlementOfferPaymentConfigs(
    id: number,
    paymentConfigs: PaymentConfig[],
  ): Promise<PaymentConfig[]> {
    const sPaymentConfigs: ServerPaymentConfig[] = await axios.post(
      ApiUrl.SettlementOfferSetPaymentConfigs.replace(':id', `${id}`),
      PaymentConfigMapper.mapReverseArray(paymentConfigs),
    );
    return PaymentConfigMapper.mapArray(sPaymentConfigs);
  }

  /**
   * @param {number} id
   * @return {Promise<Date[]>}
   */
  static async getSettlementOfferHypoteticalPaymentDates(
    id: number,
  ): Promise<Date[]> {
    const results: string[] = await axios.get(
      ApiUrl.SettlementOfferHypoteticalPaymentDates.replace(':id', `${id}`),
    );
    return results.map(iso => new Date(iso));
  }
}
