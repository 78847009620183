import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Typography,
} from 'antd';
import { Contact } from '../../../../models/Contact';
import locale from '../../../../locale';
import {
  DownOutlined,
  MailOutlined,
  MoreOutlined,
  PhoneOutlined,
  ShopOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styles from './ContactSection.module.scss';
import classNames from 'classnames';
import { contactDetailActions } from '../../../contactDetail/contactDetailSlice';
import { useDispatch } from 'react-redux';
import ContactDetailModal from '../../../contactDetail/components/contactDetailModal/ContactDetailModal';
import { contactsActions } from '../../../contacts/contactsSlice';

interface ContactSectionProps {
  step: number;
  contact?: Contact;
  canEdit: boolean;
}

/**
 *
 * @param {ContactSectionProps} props
 * @constructor
 */
export function ContactSection({ contact, canEdit }: ContactSectionProps) {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return contact ? (
    <>
      <Card
        size="small"
        className={classNames({ 'h-100': !collapsed })}
        title={
          <div
            onClick={() => setCollapsed(!collapsed)}
            className="cursor-pointer"
          >
            <Button
              size="small"
              type="text"
              className="mr-1"
              icon={collapsed ? <DownOutlined /> : <UpOutlined />}
            />
            {locale('titles.contact')}
          </div>
        }
        extra={
          canEdit
            ? [
                <Dropdown.Button
                  key="menu"
                  icon={<MoreOutlined />}
                  trigger={['click']}
                  overlay={
                    <Menu
                      onClick={({ key }) => {
                        key === 'edit' &&
                          dispatch(contactDetailActions.openModal(contact));
                        key === 'replace' &&
                          dispatch(contactsActions.openModal());
                      }}
                    >
                      <Menu.Item key="edit">{locale('actions.edit')}</Menu.Item>
                      <Menu.Item key="replace">
                        {locale('actions.replace')}
                      </Menu.Item>
                    </Menu>
                  }
                />,
              ]
            : []
        }
      >
        {!collapsed && (
          <Row justify="space-between" gutter={[16, 16]}>
            <Col className={styles.singleItem} xs={24}>
              <Avatar
                icon={<UserOutlined />}
                size="large"
                className={styles.singleItemAvatar}
              />
              <div className={styles.singleItemContent}>
                <Typography.Text type="secondary">
                  {locale('labels.firstNameAndLastName')}
                </Typography.Text>
                <Typography.Text>{contact.fullName}</Typography.Text>
              </div>
            </Col>
            <Col className={styles.singleItem} xs={24}>
              <Avatar
                icon={<ShopOutlined />}
                size="large"
                className={styles.singleItemAvatar}
              />
              <div className={styles.singleItemContent}>
                <Typography.Text type="secondary">
                  {locale('labels.shopName')}
                </Typography.Text>
                <Typography.Text>{contact.company}</Typography.Text>
              </div>
            </Col>
            <Col className={styles.singleItem} xs={24}>
              <Avatar
                icon={<PhoneOutlined />}
                size="large"
                className={styles.singleItemAvatar}
              />
              <div className={styles.singleItemContent}>
                <Typography.Text type="secondary">
                  {locale('labels.telephone')}
                </Typography.Text>
                <Typography.Text>
                  {contact.phone?.prefix}
                  {contact.phone?.number}{' '}
                  {!!contact.phone?.number.length && (
                    <Typography.Link href={'tel:' + contact.phone.number}>
                      {locale('actions.call')}
                    </Typography.Link>
                  )}
                </Typography.Text>
              </div>
            </Col>
            <Col className={styles.singleItem} xs={24}>
              <Avatar
                icon={<MailOutlined />}
                size="large"
                className={styles.singleItemAvatar}
              />
              <div className={styles.singleItemContent}>
                <Typography.Text type="secondary">
                  {locale('labels.email')}
                </Typography.Text>
                <Typography.Text>
                  {contact.email}{' '}
                  {!!contact.email?.length && (
                    <Typography.Link href={'mailto:' + contact.email}>
                      {locale('actions.sendMail')}
                    </Typography.Link>
                  )}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        )}
      </Card>
      <ContactDetailModal />
    </>
  ) : null;
}
