import { Mapper } from './Mapper';

export interface ContractBlock {
  id: number;
  content: string;
  order: number;
}

/* eslint-disable camelcase */
export interface ServerContractBlock {
  id: number;
  content: string;
  order: number;
}
/* eslint-enable camelcase */

/**
 * Map ContractBlock model
 */
export abstract class ContractBlockMapper
  implements Mapper<ContractBlock, ServerContractBlock> {
  /**
   * @param {Partial<ServerContractBlock>} o
   * @return {ContractBlock}
   */
  static map(o: Partial<ServerContractBlock>): ContractBlock {
    return <ContractBlock>{
      id: o.id,
      content: o.content,
      order: o.order,
    };
  }

  /**
   * @param {Partial<ContractBlock>} o
   * @return {ServerContractBlock}
   */
  static mapReverse(o: Partial<ContractBlock>): ServerContractBlock {
    return <ServerContractBlock>{
      id: o.id,
      content: o.content,
      order: o.order,
    };
  }

  /**
   * @param {ServerContractBlock[]} models
   * @return {ContractBlock[]}
   */
  static mapArray(models: ServerContractBlock[]): ContractBlock[] {
    return models.map(m => ContractBlockMapper.map(m));
  }

  /**
   * @param {ContractBlock[]} models
   * @return {ServerContractBlock[]}
   */
  static mapReverseArray(models: ContractBlock[]): ServerContractBlock[] {
    return models.map(m => ContractBlockMapper.mapReverse(m));
  }
}
