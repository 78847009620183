import { SettlementOffer } from '../../models/SettlementOffer';
import { SettlementContract } from '../../models/SettlementContract';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Col, InputNumber, Row, Typography } from 'antd';
import {
  ClockCircleOutlined,
  CodeSandboxOutlined,
  EditOutlined,
  EuroOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  NumberOutlined,
  PercentageOutlined,
  SafetyOutlined,
  SaveOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import locale from '../../locale';
import { Utils } from '../../models/Utils';
import {
  settlementDetailActions,
  settlementOfferDetailUpdateVat,
} from '../../features/settlementDetail/settlementDetailSlice';
import { AppRoute } from '../../models/appRouter/AppRoute.enum';
import React, { useEffect, useState } from 'react';
import styles from './SettlementOfferOverview.module.scss';

interface SettlementOfferOverviewProps {
  settlementOffer?: SettlementOffer;
  settlementContract?: SettlementContract;
  canEdit?: boolean;
}

/**
 *
 * @param {SettlementOffer} settlementOffer
 * @param {SettlementContract} settlementContract
 * @param {boolean} canEdit
 * @constructor
 */
export default function SettlementOfferOverview({
  settlementOffer,
  settlementContract,
  canEdit,
}: SettlementOfferOverviewProps) {
  const dispatch = useDispatch();
  const [
    isEditFirstInstallmentTimeFromApproval,
    setIsEditFirstInstallmentTimeFromApproval,
  ] = useState<boolean>(false);
  const [isEditVat, setIsEditVat] = useState<boolean>(false);
  const [cVat, setVat] = useState<number>(0);

  useEffect(() => {
    setVat(settlementOffer?.vatPercentage ?? 22);
    setIsEditFirstInstallmentTimeFromApproval(false);
    setIsEditVat(false);
  }, [settlementOffer]);

  return settlementOffer ? (
    <Row justify="space-between" gutter={[16, 16]}>
      <Col className={styles.singleItem} xs={24} sm={12}>
        <Avatar
          icon={<CodeSandboxOutlined />}
          size="large"
          className={styles.singleItemAvatar}
        />
        <div className={styles.singleItemContent}>
          <Typography.Text type="secondary">
            {locale('labels.product')}
          </Typography.Text>
          <Typography.Text>
            {settlementOffer.offer.product?.name || '--'}
          </Typography.Text>
        </div>
      </Col>
      <Col className={styles.singleItem} xs={24} sm={12}>
        <Avatar
          icon={<PercentageOutlined />}
          size="large"
          className={styles.singleItemAvatar}
        />
        <div className={styles.singleItemContent}>
          <Typography.Text type="secondary">
            {locale('labels.vat')}
            {canEdit && !isEditVat && (
              <Button
                type="link"
                size="small"
                className="mr-1"
                onClick={() => setIsEditVat(true)}
              >
                {locale('actions.edit')}
              </Button>
            )}
          </Typography.Text>
          <Typography.Text>
            {!isEditVat && (
              <>
                {settlementOffer.vatPercentage
                  ? settlementOffer.vatPercentage + '%'
                  : locale('labels.noVat')}
              </>
            )}
            {isEditVat && (
              <div
                style={{ display: 'inline-flex', columnGap: 6 }}
                className="ml-1 align-items-center"
              >
                <div className="d-flex">
                  <InputNumber
                    value={cVat}
                    placeholder="2"
                    min={0}
                    decimalSeparator=","
                    onChange={setVat}
                  />
                </div>
                <Button
                  type="link"
                  icon={<SaveOutlined />}
                  onClick={async () => {
                    dispatch(
                      settlementOfferDetailUpdateVat({
                        id: settlementOffer?.id as number,
                        value: cVat,
                      }),
                    );
                  }}
                />
              </div>
            )}
          </Typography.Text>
        </div>
      </Col>
      <Col className={styles.singleItem} xs={24} sm={12}>
        <Avatar
          icon={<EuroOutlined />}
          size="large"
          className={styles.singleItemAvatar}
        />
        <div className={styles.singleItemContent}>
          <Typography.Text type="secondary">
            {locale('labels.totalPrice')}
          </Typography.Text>
          <Typography.Text>
            {Utils.getFormattedPriceWithVat(
              settlementOffer.priceValue,
              settlementOffer.offer.currency,
              settlementOffer.vatPercentage,
            )}
          </Typography.Text>
        </div>
      </Col>
      <Col className={styles.singleItem} xs={24} sm={12}>
        <Avatar
          icon={<SafetyOutlined />}
          size="large"
          className={styles.singleItemAvatar}
        />
        <div className={styles.singleItemContent}>
          <Typography.Text type="secondary">
            {locale('labels.deposit')}
          </Typography.Text>
          <Typography.Text>
            {Utils.getFormattedPriceWithVat(
              settlementOffer.depositValue,
              settlementOffer.offer.currency,
              settlementOffer.vatPercentage,
            )}
          </Typography.Text>
        </div>
      </Col>
      {!!settlementOffer.numberOfInstallmentsValue && (
        <Col className={styles.singleItem} xs={24} sm={12}>
          <Avatar
            icon={<NumberOutlined />}
            size="large"
            className={styles.singleItemAvatar}
          />
          <div className={styles.singleItemContent}>
            <Typography.Text type="secondary">
              {locale('labels.subscriptions')}
              {canEdit && (
                <Button
                  type="link"
                  onClick={() =>
                    dispatch(
                      settlementDetailActions.openPaymentConfigsModal({
                        settlementOffer,
                      }),
                    )
                  }
                >
                  {locale('actions.edit')}
                </Button>
              )}
            </Typography.Text>
            <Typography.Text>
              {settlementOffer.hasCustomPaymentAmounts &&
                locale('labels.rateNumberCustomized', [
                  settlementOffer.numberOfInstallmentsValue || '--',
                ])}
              {!settlementOffer.hasCustomPaymentAmounts &&
                locale('labels.rateNumberWithAmount', [
                  settlementOffer.numberOfInstallmentsValue || '--',
                  Utils.getFormattedPrice(
                    settlementOffer.installmentAmount || 0,
                  ),
                ])}
            </Typography.Text>
            <Typography.Text>
              <FieldTimeOutlined style={{ marginRight: 4 }} />
              <span className="text-secondary">
                {locale('labels.payFirstInstallmentTimeFromApproval')}
                {': '}
                {settlementOffer.hasCustomPaymentDates &&
                  settlementOffer.paymentConfigs?.[0]?.paymentDate &&
                  Utils.getFormattedDate(
                    settlementOffer.paymentConfigs[0].paymentDate,
                  )}
                {!settlementOffer.hasCustomPaymentDates &&
                  !isEditFirstInstallmentTimeFromApproval && (
                    <span>
                      {!settlementOffer.firstInstallmentTimeFromApproval
                        ? locale('labels.immediately')
                        : locale('labels.afterDuration', [
                            settlementOffer.firstInstallmentTimeFromApproval,
                            locale(
                              `durationType.${settlementOffer.firstInstallmentTimeFromApprovalDurationType}`,
                              undefined,
                              settlementOffer.firstInstallmentTimeFromApproval ||
                                2,
                            ),
                          ])}
                    </span>
                  )}
              </span>
            </Typography.Text>
          </div>
        </Col>
      )}
      {!!settlementOffer.contractDuration && (
        <Col className={styles.singleItem} xs={24} sm={12}>
          <Avatar
            icon={<ClockCircleOutlined />}
            size="large"
            className={styles.singleItemAvatar}
          />
          <div className={styles.singleItemContent}>
            <Typography.Text type="secondary">
              {locale('labels.contractDuration')}
            </Typography.Text>
            <Typography.Text>
              {settlementOffer.contractDurationValue || '--'}{' '}
              {locale(
                `durationType.${settlementOffer.contractDurationType}`,
                undefined,
                settlementOffer.contractDurationValue || 2,
              )}
            </Typography.Text>
          </div>
        </Col>
      )}
      {settlementContract && (
        <Col className={styles.singleItem} xs={24} sm={12}>
          <Avatar
            icon={<SnippetsOutlined />}
            size="large"
            className={styles.singleItemAvatar}
          />
          <div className={styles.singleItemContent}>
            <Typography.Text type="secondary">
              {locale('labels.contract')}
            </Typography.Text>
            <div className="d-flex">
              {canEdit && (
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  className="mr-1"
                  onClick={() =>
                    dispatch(
                      settlementDetailActions.openEditContractModal({
                        settlementContract,
                      }),
                    )
                  }
                >
                  {locale('actions.edit')}
                </Button>
              )}
              <Button
                size="small"
                icon={<EyeOutlined />}
                target="_blank"
                href={AppRoute.ContractPreview.replace(
                  ':id',
                  `${settlementContract.id}`,
                )}
              >
                {locale('actions.preview')}
              </Button>
            </div>
          </div>
        </Col>
      )}
    </Row>
  ) : null;
}
