import React, { useState, useEffect } from 'react';
import { Input, AutoComplete, Form } from 'antd';
import { phoneNumberPrefixes } from '../../../assets/js/phoneNumberPrefixes';
import { Telephone } from '../../models/Telephone';
import locale from '../../locale';
import './TelephoneField.scss';

interface TelephoneFieldProps {
  telephone: Telephone;
  onPhoneChange: (phone: string) => void;
  onPrefixChange: (prefix: string, country?: string) => void;
  required?: boolean;
  disabled?: boolean;
  extra?: string;
}

export const TelephoneField = (props: TelephoneFieldProps) => {
  const { telephone, required, disabled, extra } = props;

  const [prefixesOptions, setPrefixesOptions] = useState<{ value: string }[]>(
    [],
  );
  const [prefix, setPrefix] = useState(telephone?.prefix || '+39');
  const [, setCountry] = useState<string | undefined>(
    telephone?.country || 'Italy',
  );
  const [phone, setPhone] = useState(telephone?.number || '');

  useEffect(() => {
    setPhone(telephone.number);
    setPrefix(telephone.prefix);
    setCountry(telephone.country);
  }, [props, telephone]);

  const onPrefixSearch = (searchText = '') => {
    const prefixes = phoneNumberPrefixes
      .filter(
        p =>
          p.code.includes(searchText.toLowerCase()) ||
          p.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      .map(obj => ({ value: obj.code }))
      .filter(
        (value, index, array) =>
          !array.filter(
            (v, i) => JSON.stringify(value) === JSON.stringify(v) && i < index,
          ).length,
      );
    setPrefixesOptions(prefixes);
  };

  const onPrefixChange = (value: string) => {
    setPrefix(value.trim());
    setCountry(phoneNumberPrefixes.find(p => p.code === value)?.name);
    props.onPrefixChange(
      value.trim(),
      phoneNumberPrefixes.find(p => p.code === value)?.name,
    );
  };

  const onPhoneChange = (value: string) => {
    const phone = value
      .trim()
      .split('')
      .filter(c => +c >= 0)
      .join('');
    setPhone(phone);
    props.onPhoneChange(phone);
  };

  return (
    <Input.Group compact className="telephone-field">
      <AutoComplete
        options={prefixesOptions}
        style={{ width: '80px' }}
        onSearch={onPrefixSearch}
        onChange={onPrefixChange}
        placeholder="+39"
        value={prefix}
        disabled={!!disabled}
      />
      <Form.Item
        rules={[{ required: !!required }]}
        name="phone"
        getValueProps={() => ({ phone })}
        initialValue={phone}
        extra={extra}
        className="phone-input"
      >
        <Input
          value={phone}
          onChange={event => onPhoneChange(event.target.value)}
          placeholder={locale('placeholders.telephone')}
          type="tel"
          disabled={!!disabled}
        />
      </Form.Item>
    </Input.Group>
  );
};
