import { Mapper } from './Mapper';
import { ServerTelephone, Telephone, TelephoneMapper } from './Telephone';

export interface Contact {
  id: number;
  acId: number;
  address: string;
  category: string;
  city: string;
  company: string;
  deals: any[];
  email: string;
  instagram: string;
  linkedin: string;
  firstName: string;
  notRecall: boolean;
  notes: string;
  phone?: Telephone;
  phoneSecondary?: Telephone;
  pinterest: string;
  province: string;
  socialOne: string;
  source: { id: number; name: string };
  state: string;
  lastName: string;
  website: string;
  fullName: string;
  paymentMethodId: string;
}

/* eslint-disable camelcase */
export interface ServerContact {
  id: number;
  ac_id: number;
  address: string;
  category: string;
  city: string;
  company: string;
  deals: any[];
  email: string;
  instagram: string;
  linkedin: string;
  first_name: string;
  not_recall: boolean;
  notes: string;
  telephone?: ServerTelephone;
  secondary_telephone?: ServerTelephone;
  pinterest: string;
  province: string;
  social_one: string;
  source: { id: number; name: string };
  state: string;
  last_name: string;
  website: string;
  payment_method?: string;
}

/* eslint-enable camelcase */

/**
 * Map Contact model
 */
export abstract class ContactMapper implements Mapper<Contact, ServerContact> {
  /**
   * @param {Partial<ServerContact>} o
   * @return {Contact}
   */
  static map(o: Partial<ServerContact>): Contact {
    return <Contact>{
      id: o.id,
      acId: o.ac_id,
      address: o.address,
      category: o.category,
      city: o.city,
      company: o.company,
      deals: o.deals,
      email: o.email,
      instagram: o.instagram,
      linkedin: o.linkedin,
      firstName: o.first_name,
      notRecall: o.not_recall,
      notes: o.notes,
      phone: o.telephone && TelephoneMapper.map(o.telephone),
      phoneSecondary:
        o.secondary_telephone && TelephoneMapper.map(o.secondary_telephone),
      pinterest: o.pinterest,
      province: o.province,
      socialOne: o.social_one,
      source: o.source,
      state: o.state,
      lastName: o.last_name,
      website: o.website,
      fullName: `${o.first_name || ''} ${o.last_name || ''}`,
      paymentMethodId: o.payment_method,
    };
  }

  /**
   * @param {Partial<Contact>} o
   * @return {ServerContact}
   */
  static mapReverse(o: Partial<Contact>): ServerContact {
    return <ServerContact>{
      id: o.id,
      ac_id: o.acId,
      address: o.address,
      category: o.category,
      city: o.city,
      company: o.company,
      deals: o.deals,
      email: o.email,
      instagram: o.instagram,
      linkedin: o.linkedin,
      first_name: o.firstName,
      not_recall: o.notRecall,
      notes: o.notes,
      telephone: o.phone && TelephoneMapper.mapReverse(o.phone),
      secondary_telephone:
        o.phoneSecondary && TelephoneMapper.mapReverse(o.phoneSecondary),
      pinterest: o.pinterest,
      province: o.province,
      social_one: o.socialOne,
      source: o.source,
      state: o.state,
      last_name: o.lastName,
      website: o.website,
      payment_method: o.paymentMethodId,
    };
  }

  /**
   * @param {ServerContact[]} models
   * @return {Contact[]}
   */
  static mapArray(models: ServerContact[]): Contact[] {
    return models.map(m => ContactMapper.map(m));
  }

  /**
   * @param {Contact[]} models
   * @return {ServerContact[]}
   */
  static mapReverseArray(models: Contact[]): ServerContact[] {
    return models.map(m => ContactMapper.mapReverse(m));
  }
}
