import { Mapper } from './Mapper';
import {
  ServerSettlementContractBlockSign,
  SettlementContractBlockSign,
  SettlementContractBlockSignMapper,
} from './SettlementContractBlockSign';

export interface SettlementContractBlock {
  id: number;
  contractBlockId: number;
  name: string;
  content: string;
  previewContent: string;
  sign: SettlementContractBlockSign;
}

/* eslint-disable camelcase */
export interface ServerSettlementContractBlock {
  id: number;
  contract_block: number;
  name: string;
  content: string;
  preview_content: string;
  sign: ServerSettlementContractBlockSign;
}

/* eslint-enable camelcase */

/**
 * Map SettlementContractBlock model
 */
export abstract class SettlementContractBlockMapper
  implements Mapper<SettlementContractBlock, ServerSettlementContractBlock> {
  /**
   * @param {Partial<ServerSettlementContractBlock>} o
   * @return {SettlementContractBlock}
   */
  static map(
    o: Partial<ServerSettlementContractBlock>,
  ): SettlementContractBlock {
    return <SettlementContractBlock>{
      id: o.id,
      contractBlockId: o.contract_block,
      name: o.name,
      content: o.content,
      previewContent: o.preview_content,
      sign: o.sign ? SettlementContractBlockSignMapper.map(o.sign) : undefined,
    };
  }

  /**
   * @param {Partial<SettlementContractBlock>} o
   * @return {ServerSettlementContractBlock}
   */
  static mapReverse(
    o: Partial<SettlementContractBlock>,
  ): ServerSettlementContractBlock {
    return <ServerSettlementContractBlock>{
      id: o.id,
      contract_block: o.contractBlockId,
      name: o.name,
      content: o.content,
      preview_content: o.previewContent,
      sign: o.sign
        ? SettlementContractBlockSignMapper.mapReverse(o.sign)
        : null,
    };
  }

  /**
   * @param {ServerSettlementContractBlock[]} models
   * @return {SettlementContractBlock[]}
   */
  static mapArray(
    models: ServerSettlementContractBlock[],
  ): SettlementContractBlock[] {
    return models.map(m => SettlementContractBlockMapper.map(m));
  }

  /**
   * @param {SettlementContractBlock[]} models
   * @return {ServerSettlementContractBlock[]}
   */
  static mapReverseArray(
    models: SettlementContractBlock[],
  ): ServerSettlementContractBlock[] {
    return models.map(m => SettlementContractBlockMapper.mapReverse(m));
  }
}
