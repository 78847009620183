import { Mapper } from './Mapper';
import {
  ServerSettlementDataField,
  SettlementDataField,
  SettlementDataFieldMapper,
  SettlementDataType,
} from './SettlementDataField';

export interface SettlementDataSection {
  id: number;
  name: string;
  title: string;
  icon: string;
  fields: SettlementDataField[];
}

/* eslint-disable camelcase */
export interface ServerSettlementDataSection {
  id: number;
  name: string;
  title: string;
  icon: string;
  datafield_set: ServerSettlementDataField[];
}

/* eslint-enable camelcase */

/**
 * Map SettlementDataSection model
 */
export abstract class SettlementDataSectionMapper
  implements Mapper<SettlementDataSection, ServerSettlementDataSection> {
  /**
   * @param {Partial<ServerSettlementDataSection>} o
   * @param {Record<string, { name: string, dataType: SettlementDataType }>} fieldsInfo
   * @return {SettlementDataSection}
   */
  static map(
    o: Partial<ServerSettlementDataSection>,
    fieldsInfo: Record<string, { name: string; dataType: SettlementDataType }>,
  ): SettlementDataSection {
    return <SettlementDataSection>{
      id: o.id,
      name: o.name,
      title: o.title,
      icon: o.icon,
      fields: o.datafield_set
        ? SettlementDataFieldMapper.mapArray(o.datafield_set, fieldsInfo)
        : [],
    };
  }

  /**
   * @param {Partial<SettlementDataSection>} o
   * @return {ServerSettlementDataSection}
   */
  static mapReverse(
    o: Partial<SettlementDataSection>,
  ): ServerSettlementDataSection {
    return <ServerSettlementDataSection>{
      id: o.id,
      name: o.name,
      title: o.title,
      icon: o.icon,
      datafield_set: o.fields
        ? SettlementDataFieldMapper.mapReverseArray(o.fields)
        : [],
    };
  }

  /**
   * @param {ServerSettlementDataSection[]} models
   * @param {Record<string, { name: string, dataType: SettlementDataType }>} fieldsInfo
   * @return {SettlementDataSection[]}
   */
  static mapArray(
    models: ServerSettlementDataSection[],
    fieldsInfo: Record<string, { name: string; dataType: SettlementDataType }>,
  ): SettlementDataSection[] {
    return models.map(m => SettlementDataSectionMapper.map(m, fieldsInfo));
  }

  /**
   * @param {SettlementDataSection[]} models
   * @return {ServerSettlementDataSection[]}
   */
  static mapReverseArray(
    models: SettlementDataSection[],
  ): ServerSettlementDataSection[] {
    return models.map(m => SettlementDataSectionMapper.mapReverse(m));
  }
}
