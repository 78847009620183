import { Mapper } from './Mapper';

export interface AmountOption {
  id: number;
  amount: number;
  isCustom: boolean;
  priceMin: number;
  priceMax: number;
}

/* eslint-disable camelcase */
export interface ServerAmountOption {
  id: number;
  amount: number;
  is_custom: boolean;
  price_min?: number;
  price_max?: number;
}

/* eslint-enable camelcase */

/**
 * Map AmountOption model
 */
export abstract class AmountOptionMapper
  implements Mapper<AmountOption, ServerAmountOption> {
  /**
   * @param {Partial<ServerAmountOption>} o
   * @return {AmountOption}
   */
  static map(o: Partial<ServerAmountOption>): AmountOption {
    return <AmountOption>{
      id: o.id,
      amount: o.amount,
      isCustom: !!o.is_custom,
      priceMin: o.price_min || 0,
      priceMax: o.price_max || 0,
    };
  }

  /**
   * @param {Partial<AmountOption>} o
   * @return {ServerAmountOption}
   */
  static mapReverse(o: Partial<AmountOption>): ServerAmountOption {
    return <ServerAmountOption>{
      id: o.id,
      amount: o.amount,
      is_custom: !!o.isCustom,
      price_min: o.priceMin || 0,
      price_max: o.priceMax || 0,
    };
  }

  /**
   * @param {ServerAmountOption[]} models
   * @return {AmountOption[]}
   */
  static mapArray(models: ServerAmountOption[]): AmountOption[] {
    return models.map(m => AmountOptionMapper.map(m));
  }

  /**
   * @param {AmountOption[]} models
   * @return {ServerAmountOption[]}
   */
  static mapReverseArray(models: AmountOption[]): ServerAmountOption[] {
    return models.map(m => AmountOptionMapper.mapReverse(m));
  }
}
