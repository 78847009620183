import { Mapper } from './Mapper';
import {
  ContractBlock,
  ContractBlockMapper,
  ServerContractBlock,
} from './ContractBlock';

export interface Contract {
  id: number;
  blocks: ContractBlock[];
  name: string;
  version: string;
}

/* eslint-disable camelcase */
export interface ServerContract {
  id: number;
  contractblock_set: ServerContractBlock[];
  name: string;
  version: string;
}
/* eslint-enable camelcase */

/**
 * Map Contract model
 */
export abstract class ContractMapper
  implements Mapper<Contract, ServerContract> {
  /**
   * @param {Partial<ServerContract>} o
   * @return {Contract}
   */
  static map(o: Partial<ServerContract>): Contract {
    return <Contract>{
      id: o.id,
      blocks: o.contractblock_set
        ? ContractBlockMapper.mapArray(o.contractblock_set)
        : [],
      name: o.name,
      version: o.version,
    };
  }

  /**
   * @param {Partial<Contract>} o
   * @return {ServerContract}
   */
  static mapReverse(o: Partial<Contract>): ServerContract {
    return <ServerContract>{
      id: o.id,
      contractblock_set: o.blocks
        ? ContractBlockMapper.mapReverseArray(o.blocks)
        : [],
      name: o.name,
      version: o.version,
    };
  }

  /**
   * @param {ServerContract[]} models
   * @return {Contract[]}
   */
  static mapArray(models: ServerContract[]): Contract[] {
    return models.map(m => ContractMapper.map(m));
  }

  /**
   * @param {Contract[]} models
   * @return {ServerContract[]}
   */
  static mapReverseArray(models: Contract[]): ServerContract[] {
    return models.map(m => ContractMapper.mapReverse(m));
  }
}
