import React from 'react';
import { Steps, Typography } from 'antd';
import locale from '../../locale';
import classNames from 'classnames';
import './Stepper.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

export interface StepperStep {
  key: string;
  title: string;
  status?: 'wait' | 'process' | 'finish' | 'error';
}

interface StepperProps {
  step: number;
  steps: StepperStep[];
  showBackButton?: boolean;
  onBack?: () => void;
  showCurrentOnly?: boolean;
  className?: string;
}

/**
 * @param {StepperProps} props
 * @constructor
 */
export default function Stepper({
  step,
  steps,
  onBack,
  showBackButton,
  showCurrentOnly,
  className,
}: StepperProps) {
  return (
    <div className="d-flex align-items-center">
      {showBackButton && step > 0 && (
        <ArrowLeftOutlined className="backButton" onClick={onBack} />
      )}

      <Steps
        current={step}
        size="small"
        className={classNames('stepper', className, {
          'show-current-only': !!showCurrentOnly,
        })}
      >
        {steps.map(s => (
          <Steps.Step status={s.status} key={s.key} title={s.title} />
        ))}
      </Steps>
      {steps?.[step]?.key !== 'finalized' && (
        <Typography.Text
          className={classNames('steps-overview', {
            'show-current-only': !!showCurrentOnly,
          })}
          type="secondary"
        >
          {locale('subtitles.stepOf', [step + 1, steps.length])}
        </Typography.Text>
      )}
    </div>
  );
}
