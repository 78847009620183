import { Mapper } from './Mapper';
import { Offer, OfferMapper, ServerOffer } from './Offer';
import {
  AmountOption,
  AmountOptionMapper,
  ServerAmountOption,
} from './AmountOption';
import { DurationType } from './DurationType.enum';
import {
  PaymentConfig,
  PaymentConfigMapper,
  ServerPaymentConfig,
} from './PaymentConfig';

export interface SettlementOffer {
  id?: number;
  offer: Offer;

  price?: AmountOption;
  priceValue: number;

  deposit?: AmountOption;
  depositValue: number;

  numberOfInstallments?: AmountOption;
  numberOfInstallmentsValue: number;
  installmentAmount: number;

  contractDuration?: AmountOption;
  contractDurationType: DurationType;
  contractDurationValue: number;

  firstInstallmentTimeFromApproval: number;
  firstInstallmentTimeFromApprovalDurationType: DurationType;
  paymentConfigs: PaymentConfig[];
  vatPercentage: number;
  hasCustomPaymentAmounts: boolean;
  hasCustomPaymentDates: boolean;
  hasCustomPaymentMethods: boolean;
}

/* eslint-disable camelcase */
export interface ServerSettlementOffer {
  id: number;

  /** Could be id only */
  offer: ServerOffer | number;

  /** Could be id only */
  price?: ServerAmountOption | number;
  price_value: number;

  /** Could be id only */
  deposit?: ServerAmountOption | number;
  deposit_value: number;

  /** Could be id only */
  number_of_installments?: ServerAmountOption | number;
  number_of_installments_value: number;
  installment_amount: number;

  /** Could be id only */
  contract_duration?: ServerAmountOption | number;
  contract_duration_type: DurationType;
  contract_duration_value: number;

  first_installment_time_from_approval: number;
  first_installment_time_from_approval_duration_type: DurationType;
  paymentconfig_set: ServerPaymentConfig[];
  vat_percentage: number;
  has_custom_payment_amounts: boolean;
  has_custom_payment_dates: boolean;
  has_custom_payment_methods: boolean;
}

/* eslint-enable camelcase */

/**
 * Map SettlementOffer model
 */
export abstract class SettlementOfferMapper
  implements Mapper<SettlementOffer, ServerSettlementOffer> {
  /**
   * @param {Partial<ServerSettlementOffer>} o
   * @return {SettlementOffer}
   */
  static map(o: Partial<ServerSettlementOffer>): SettlementOffer {
    return <SettlementOffer>{
      id: o.id,
      offer:
        typeof o.offer !== 'number'
          ? o.offer
            ? OfferMapper.map((o.offer as unknown) as ServerOffer)
            : null
          : { id: o.offer },
      price:
        typeof o.price !== 'number'
          ? o.price
            ? AmountOptionMapper.map((o.price as unknown) as ServerOffer)
            : null
          : { id: o.price },
      priceValue: o.price_value,
      deposit:
        typeof o.deposit !== 'number'
          ? o.deposit
            ? AmountOptionMapper.map((o.deposit as unknown) as ServerOffer)
            : null
          : { id: o.deposit },
      depositValue: o.deposit_value,
      numberOfInstallments:
        typeof o.number_of_installments !== 'number'
          ? o.number_of_installments
            ? AmountOptionMapper.map(
                (o.number_of_installments as unknown) as ServerOffer,
              )
            : null
          : { id: o.number_of_installments },
      numberOfInstallmentsValue: o.number_of_installments_value,
      installmentAmount: o.installment_amount,
      contractDuration:
        typeof o.contract_duration !== 'number'
          ? o.contract_duration
            ? AmountOptionMapper.map(
                (o.contract_duration as unknown) as ServerOffer,
              )
            : null
          : { id: o.contract_duration },
      contractDurationType: o.contract_duration_type,
      contractDurationValue: o.contract_duration_value,
      firstInstallmentTimeFromApproval: o.first_installment_time_from_approval,
      firstInstallmentTimeFromApprovalDurationType:
        o.first_installment_time_from_approval_duration_type,
      paymentConfigs: PaymentConfigMapper.mapArray(o.paymentconfig_set || []),
      vatPercentage: o.vat_percentage,
      hasCustomPaymentAmounts: o.has_custom_payment_amounts,
      hasCustomPaymentDates: o.has_custom_payment_dates,
      hasCustomPaymentMethods: o.has_custom_payment_methods,
    };
  }

  /**
   * @param {Partial<SettlementOffer>} o
   * @return {ServerSettlementOffer}
   */
  static mapReverse(o: Partial<SettlementOffer>): ServerSettlementOffer {
    return <ServerSettlementOffer>{
      id: o.id,
      offer: o.offer?.id || null,
      price: o.price?.id || null,
      price_value: o.priceValue,
      deposit: o.deposit?.id || null,
      deposit_value: o.depositValue,
      number_of_installments: o.numberOfInstallments?.id || null,
      number_of_installments_value: o.numberOfInstallmentsValue,
      installment_amount: o.installmentAmount,
      contract_duration: o.contractDuration?.id || null,
      contract_duration_type: o.contractDurationType,
      contract_duration_value: o.contractDurationValue,
      first_installment_time_from_approval: o.firstInstallmentTimeFromApproval,
      first_installment_time_from_approval_duration_type:
        o.firstInstallmentTimeFromApprovalDurationType,
      paymentconfig_set: PaymentConfigMapper.mapReverseArray(
        o.paymentConfigs || [],
      ),
      vat_percentage: o.vatPercentage,
      has_custom_payment_amounts: o.hasCustomPaymentAmounts,
      has_custom_payment_dates: o.hasCustomPaymentDates,
      has_custom_payment_methods: o.hasCustomPaymentMethods,
    };
  }

  /**
   * @param {ServerSettlementOffer[]} models
   * @return {SettlementOffer[]}
   */
  static mapArray(models: ServerSettlementOffer[]): SettlementOffer[] {
    return models.map(m => SettlementOfferMapper.map(m));
  }

  /**
   * @param {SettlementOffer[]} models
   * @return {ServerSettlementOffer[]}
   */
  static mapReverseArray(models: SettlementOffer[]): ServerSettlementOffer[] {
    return models.map(m => SettlementOfferMapper.mapReverse(m));
  }
}
