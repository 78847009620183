import { Mapper } from './Mapper';

export enum MethodType {
  StripeCard = 1,
  StripeSepaDebit = 2,
  PayPal = 3,
  BankTransfer = 4,
  BankSepaDebitOnline = 5,
  BankSepaDebitOffline = 6,
  Cash = 7,
}

export interface PaymentMethodType {
  id: number;
  isActive: boolean;
  isEnabledForSeller: boolean;
  isEnabledForUser: boolean;
  type: MethodType;
  name: string;
}

/* eslint-disable camelcase */
export interface ServerPaymentMethodType {
  id: number;
  is_active: boolean;
  is_enabled_for_seller: boolean;
  is_enabled_for_user: boolean;
  method_type: MethodType;
  name: string;
}

/* eslint-enable camelcase */

/**
 * Map PaymentMethod model
 */
export abstract class PaymentMethodTypeMapper
  implements Mapper<PaymentMethodType, ServerPaymentMethodType> {
  /**
   * @param {Partial<ServerPaymentMethodType>} o
   * @return {PaymentMethodType}
   */
  static map(o: Partial<ServerPaymentMethodType>): PaymentMethodType {
    return <PaymentMethodType>{
      id: o.id,
      isActive: !!o.is_active,
      isEnabledForSeller: !!o.is_enabled_for_seller,
      isEnabledForUser: !!o.is_enabled_for_user,
      type: o.method_type,
      name: o.name,
    };
  }

  /**
   * @param {Partial<PaymentMethodType>} o
   * @return {ServerPaymentMethodType}
   */
  static mapReverse(o: Partial<PaymentMethodType>): ServerPaymentMethodType {
    return <ServerPaymentMethodType>{
      id: o.id,
      is_active: o.isActive,
      is_enabled_for_seller: o.isEnabledForSeller,
      is_enabled_for_user: o.isEnabledForUser,
      method_type: o.type,
      name: o.name,
    };
  }

  /**
   * @param {ServerPaymentMethodType[]} models
   * @return {PaymentMethodType[]}
   */
  static mapArray(models: ServerPaymentMethodType[]): PaymentMethodType[] {
    return models.map(m => PaymentMethodTypeMapper.map(m));
  }

  /**
   * @param {PaymentMethodType[]} models
   * @return {ServerPaymentMethodType[]}
   */
  static mapReverseArray(
    models: PaymentMethodType[],
  ): ServerPaymentMethodType[] {
    return models.map(m => PaymentMethodTypeMapper.mapReverse(m));
  }
}
