import { Mapper } from './Mapper';

export interface Attachment {
  id: number;
  name: string;
  file: string;
  order: number;
  thumbnailUrl?: string;
}

/* eslint-disable camelcase */
export interface ServerAttachment {
  id: number;
  name: string;
  file: string;
  order: number;
  thumbnail_url?: string;
}
/* eslint-enable camelcase */

/**
 * Map Attachment model
 */
export abstract class AttachmentMapper
  implements Mapper<Attachment, ServerAttachment> {
  /**
   * @param {Partial<ServerAttachment>} o
   * @return {Attachment}
   */
  static map(o: Partial<ServerAttachment>): Attachment {
    return <Attachment>{
      id: o.id,
      name: o.name,
      file: o.file,
      order: o.order,
      thumbnailUrl: o.thumbnail_url,
    };
  }

  /**
   * @param {Partial<Attachment>} o
   * @return {ServerAttachment}
   */
  static mapReverse(o: Partial<Attachment>): ServerAttachment {
    return <ServerAttachment>{
      id: o.id,
      name: o.name,
      file: o.file,
      order: o.order,
      thumbnail_url: o.thumbnailUrl,
    };
  }

  /**
   * @param {ServerAttachment[]} models
   * @return {Attachment[]}
   */
  static mapArray(models: ServerAttachment[]): Attachment[] {
    return models.map(m => AttachmentMapper.map(m));
  }

  /**
   * @param {Attachment[]} models
   * @return {ServerAttachment[]}
   */
  static mapReverseArray(models: Attachment[]): ServerAttachment[] {
    return models.map(m => AttachmentMapper.mapReverse(m));
  }
}
