import React, { useState } from 'react';
import { Button, Card, Col, Descriptions, Form, Row } from 'antd';
import locale from '../../../../locale';
import { Settlement } from '../../../../models/Settlement';
import TextArea from 'antd/lib/input/TextArea';
import MediaUploader from '../../../../components/mediaUploader/MediaUploader';
import { UploadFile } from 'antd/es/upload/interface';
import { Utils } from '../../../../models/Utils';
import './FinalizeSection.scss';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import { FileDoneOutlined } from '@ant-design/icons';

interface FinalizeSectionProps {
  canEdit: boolean;
  settlement?: Settlement;
  notes: string;
  onFinalize: (files: UploadFile[], notes: string) => void;
  onDeleteAttachment: (id: number) => void;
}

/**
 *
 * @param {FinalizeSectionProps} props
 * @constructor
 */
export function FinalizeSection({
  canEdit,
  settlement,
  notes: initialNotes,
  onFinalize,
  onDeleteAttachment: deleteAttachment,
}: FinalizeSectionProps) {
  const isCustomer = !!useSelector(authSelector).user?.isCustomer;

  const [notes, setNotes] = useState<string>(initialNotes || '');
  const [files, setFiles] = useState<UploadFile[]>(
    settlement?.identityDocuments?.map(attachment => ({
      uid: `${attachment.id}`,
      url: attachment.file,
      thumbUrl: attachment.thumbnailUrl,
      name: attachment.name,
    })) || [],
  );

  const onSubmit = async () =>
    (await Utils.askForConfirmation()) && onFinalize(files, notes);

  const onDeleteAttachment = (uid: string) =>
    settlement?.identityDocuments?.find(a => a.id === +uid) &&
    deleteAttachment(+uid);

  return settlement ? (
    <Card
      size="small"
      title={locale('titles.additionalInfo')}
      className="finalize-section"
      actions={
        canEdit
          ? [
              <Button
                key="submit"
                type="primary"
                size="large"
                block
                icon={<FileDoneOutlined />}
                disabled={
                  settlement.isIdentityDocumentRequired && !files.length
                }
                onClick={onSubmit}
              >
                {locale(
                  `actions.${isCustomer ? 'finish' : 'finalizeContract'}`,
                )}
              </Button>,
            ]
          : []
      }
    >
      {canEdit && (
        <Form layout="vertical" className="mt-1">
          <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={locale('labels.identityDocuments')}
                {...(isCustomer
                  ? { extra: locale('subtitles.customerIdentityDocuments') }
                  : { tooltip: locale('subtitles.identityDocuments') })}
                required={settlement.isIdentityDocumentRequired}
              >
                <MediaUploader
                  canEdit={canEdit}
                  files={files}
                  onFilesChange={setFiles}
                  onDelete={onDeleteAttachment}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={locale('labels.additionalNotes')}
                {...(isCustomer
                  ? { tooltip: locale('subtitles.customerNotes') }
                  : { tooltip: locale('subtitles.notes') })}
              >
                <TextArea
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {!canEdit && (
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item label={locale('labels.identityDocuments')}>
            {!!files?.length ? (
              <MediaUploader
                canEdit={false}
                files={files}
                onFilesChange={setFiles}
              />
            ) : (
              '--'
            )}
          </Descriptions.Item>
          <Descriptions.Item label={locale('labels.customerNotes')}>
            {settlement.customerNotes || '--'}
          </Descriptions.Item>
          <Descriptions.Item label={locale('labels.sellerNotes')}>
            {settlement.sellerNotes || '--'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  ) : null;
}
