import React from 'react';
import environment from '../../../../environment';
import styles from './Footer.module.scss';
import { Layout, Typography } from 'antd';
import locale from '../../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout, authSelector } from '../../../features/auth/authSlice';

/**
 *
 * @constructor
 */
export function Footer() {
  const dispatch = useDispatch();
  const isAuthenticated = !!useSelector(authSelector).token;
  return (
    <Layout.Footer className={styles.footer}>
      <Typography.Text>
        {environment.appName} v{process.env.REACT_APP_VERSION} ©
        {new Date().getFullYear()} by Reddoak
      </Typography.Text>
      {isAuthenticated && (
        <Typography.Link
          className="mr-1"
          onClick={() => dispatch(authLogout())}
        >
          {locale('actions.logout')}
        </Typography.Link>
      )}
    </Layout.Footer>
  );
}
