import React from 'react';
import { Typography } from 'antd';
import locale from '../../../../locale';
import Contacts from '../../../contacts/Contacts';
import { Contact } from '../../../../models/Contact';
import ContactDetailModal from '../../../contactDetail/components/contactDetailModal/ContactDetailModal';

interface SettlementCreatorContactSetterProps {
  onContactSelected: (contact: Contact) => void;
}

const SettlementCreatorContactSetter = ({
  onContactSelected,
}: SettlementCreatorContactSetterProps) => {
  return (
    <>
      <Typography.Paragraph className="mb-0 px-2">
        {locale('subtitles.setSettlementContact')}
      </Typography.Paragraph>
      <Contacts onContactSelected={onContactSelected} />
      <ContactDetailModal />
    </>
  );
};

export default SettlementCreatorContactSetter;
