import React from 'react';
import { Card } from 'antd';
import { SettlementOffer } from '../../../../../../models/SettlementOffer';
import { SettlementContract } from '../../../../../../models/SettlementContract';
import SettlementOfferOverview from '../../../../../../components/settlementOfferOverview/SettlementOfferOverview';

interface OfferSectionProps {
  settlementOffer?: SettlementOffer;
  settlementContract?: SettlementContract;
  canEdit: boolean;
}

/**
 *
 * @param {OfferSectionProps} props
 * @constructor
 */
export function OfferSection({
  settlementOffer,
  settlementContract,
  canEdit,
}: OfferSectionProps) {
  return settlementOffer ? (
    <Card
      type="inner"
      size="small"
      className="h-100"
      title={settlementOffer.offer.name}
    >
      <SettlementOfferOverview
        settlementOffer={settlementOffer}
        settlementContract={settlementContract}
        canEdit={canEdit}
      />
    </Card>
  ) : null;
}
