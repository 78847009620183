import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Settlement } from '../../models/Settlement';
import { initialPagination, Pagination } from '../../models/Pagination';
import { RootState } from '../../store/store';
import { message } from 'antd';
import locale from '../../locale';
import { loaderActions } from '../loader/loaderSlice';
import { SettlementApiService } from '../../services/SettlementApiService';

export const settlementsInit = createAsyncThunk(
  'settlements/init',
  async (payload: { search: string }, { dispatch }) => {
    dispatch(settlementsActions.clearState());
    dispatch(settlementsNextPage(payload));
  },
);

export const settlementsNextPage = createAsyncThunk(
  'settlements/nextPage',
  async (
    payload: { search?: string },
    { getState, rejectWithValue, dispatch },
  ) => {
    dispatch(loaderActions.increment());
    try {
      const state: SettlementsSliceState = (<RootState>getState()).settlements;

      const search = payload.search || state.search;
      const pagination: Pagination = {
        ...state.pagination,
        page: state.pagination.page + 1,
      };

      const { list, resPagination } = await SettlementApiService.getList(
        pagination,
        search,
      );

      dispatch(loaderActions.decrement());

      return {
        search,
        list: [...state.list, ...list],
        pagination: {
          ...resPagination,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      };
    } catch (error) {
      message.error(locale('errors.fetchList'));
      dispatch(loaderActions.decrement());
      return rejectWithValue({ error });
    }
  },
);

interface SettlementsSliceState {
  search: string;
  list: Settlement[];
  pagination: Pagination;
}

const initialState: SettlementsSliceState = {
  search: '',
  list: [],
  pagination: initialPagination(),
};

export const settlementsSlice = createSlice({
  name: 'settlements',
  initialState,
  reducers: {
    clearState() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [<any>settlementsNextPage.fulfilled]: (state, { payload }) => {
      state.search = payload.search;
      state.list = payload.list;
      state.pagination = payload.pagination;
    },
  },
});

export const settlementsSelector = (state: RootState): SettlementsSliceState =>
  state.settlements;
export const settlementsActions = settlementsSlice.actions;
