import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import moment from 'moment';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { MethodType } from '../../../models/PaymentMethodType';
import { FieldData } from '../../../models/FieldData';
import { loaderActions } from '../../../features/loader/loaderSlice';
import locale from '../../../locale';
import { Utils } from '../../../models/Utils';
import environment from '../../../../environment';
import { PaymentMethodItem } from '../../../models/PaymentMethodItem';
import { PaymentMethod } from '../../../models/PaymentMethod';
import { SelectValue } from 'antd/lib/select';
import { CreditCardOutlined } from '@ant-design/icons';
import IbanForm from '../../IbanForm/IbanForm';

interface PaymentMethodTypeHandlerProps {
  idSettlement?: number;
  items: PaymentMethodItem[];
  uuid?: string;
  type: MethodType;
  vatPercentage: number;
  mustPayNow: boolean;
  paymentMethods?: PaymentMethod[];
  onPay: (type: MethodType, data: Record<string, any>) => void;
}

/**
 * Handle payment methods
 * @constructor
 */
export default function PaymentMethodTypeHandler({
  idSettlement,
  items,
  uuid,
  type,
  vatPercentage,
  mustPayNow,
  paymentMethods,
  onPay,
}: PaymentMethodTypeHandlerProps) {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [fields, setFields] = useState<FieldData[]>([
    { name: 'paymentDate', value: moment(undefined) },
    { name: 'bankAccountHolder', value: '' },
    { name: 'iban', value: '' },
    { name: 'bankAuthorizationId', value: '' },
    { name: 'firstName', value: '' },
    { name: 'lastName', value: '' },
    { name: 'fiscalCode', value: '' },
    { name: 'address', value: '' },
    { name: 'zipCode', value: '' },
    { name: 'city', value: '' },
    { name: 'district', value: '' },
  ]);

  const [isFormValid, setIsFormValid] = useState<boolean>(
    fields.every(f => f.value),
  );

  const cardPaymentMethods = paymentMethods?.filter(
    p => p.paymentMethodType.type === MethodType.StripeCard,
  );
  const cardsAvailable = !!cardPaymentMethods?.length;
  const [showCardSelect, setShowCardSelect] = useState(cardsAvailable);
  const [selectedCard, setSelectedCard] = useState<SelectValue>(
    cardPaymentMethods?.[0]?.id || '',
  );

  const [isCardValid, setIsCardValid] = useState(false);
  const [amount, setAmount] = useState<number | undefined>();

  useEffect(() => {
    let nextAmount: number | undefined;
    items.forEach(item => (nextAmount = (nextAmount || 0) + item.amount));
    setAmount(nextAmount);
  }, [items]);

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    if (!event.complete) {
      setIsCardValid(false);
    } else if (!event.error) {
      setIsCardValid(true);
    }
  };

  const onCardSelectChange = (value: SelectValue) => {
    if (value === '+') {
      setShowCardSelect(false);
    } else {
      setSelectedCard(value);
    }
  };

  const cancelNewCard = () => setShowCardSelect(true);

  const handleCardSubmit = async () => {
    if (showCardSelect && stripe && selectedCard) {
      const registeredCard: PaymentMethod | undefined = paymentMethods?.find(
        pm => pm.id === selectedCard,
      );
      onPay(type, { registeredCard });
    } else if (!showCardSelect && elements && stripe) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.update({ hidePostalCode: true });
        dispatch(loaderActions.increment());
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {},
        });
        if (!error) {
          onPay(type, { paymentMethod });
        } else {
          message.error(error.message);
        }
        dispatch(loaderActions.decrement());
      }
    }
  };

  const renderSDD = () => {
    return (
      <IbanForm
        onIbanFormSubmit={stripePaymentMethodId => {
          onPay(MethodType.StripeSepaDebit, {
            stripe_payment_method_id: stripePaymentMethodId,
          });
        }}
        countryCode="IT"
        mustPayNow={mustPayNow}
        idSettlement={idSettlement}
      ></IbanForm>
    );
  };

  const renderCard = () => {
    const canSubmit =
      (showCardSelect && selectedCard) ||
      (!showCardSelect && stripe && isCardValid);
    let amountPayNow: number | undefined;
    items.forEach(
      item =>
        (amountPayNow = !item.mustPayNow
          ? amountPayNow
          : (amountPayNow || 0) + item.amount),
    );
    return (
      <>
        {showCardSelect && (
          <Select
            className="w-100"
            value={selectedCard}
            onChange={onCardSelectChange}
          >
            {cardPaymentMethods?.map(pm => (
              <Select.Option key={pm.id} value={pm.id}>
                <div className="d-flex justify-content-space-between">
                  <div className="d-flex align-items-center">
                    <CreditCardOutlined className="mr-1" />
                    <span>{pm.last4}</span>
                  </div>
                  <span>
                    <small>{locale('labels.expiration')}: </small>
                    {pm.expiration}
                  </span>
                </div>
              </Select.Option>
            ))}

            <Select.Option value="+">
              + {locale('actions.addNewCard')}
            </Select.Option>
          </Select>
        )}
        {!showCardSelect && (
          <>
            <CardElement className="card-input" onChange={onCardChange} />
            {cardsAvailable && (
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography.Link onClick={cancelNewCard}>
                  Annulla
                </Typography.Link>
              </div>
            )}
          </>
        )}
        <Button
          block
          className="mt-2"
          type="primary"
          htmlType="submit"
          disabled={!canSubmit}
          onClick={handleCardSubmit}
        >
          {locale(
            `actions.${
              mustPayNow && amountPayNow
                ? amount === amountPayNow
                  ? 'payNowWithAmount'
                  : 'setCardAndPayNowWithAmount'
                : 'setCard'
            }`,
            [
              amountPayNow
                ? Utils.getFormattedPrice(
                    amountPayNow * ((vatPercentage || 0) / 100 + 1),
                  )
                : '--',
            ],
          )}
        </Button>
      </>
    );
  };

  const renderRid = () => (
    <>
      <Form
        layout="vertical"
        fields={fields}
        onFieldsChange={(_, allFields) => {
          setFields(allFields);
          setIsFormValid(allFields.every(f => f.value));
        }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.bankAccountHolder')}
              name="bankAccountHolder"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.fullName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.iban')}
              name="iban"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.iban')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.vatNumber')}
              name="bankAuthorizationId"
              rules={[{ required: true }]}
              tooltip={locale('subtitles.bankAuthorizationId')}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.vatNumber')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.firstName')}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.firstName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.lastName')}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.lastName')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.fiscalCode')}
              name="fiscalCode"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.fiscalCode')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.address')}
              name="address"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.address')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.zipCode')}
              name="zipCode"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.zipCode')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.city')}
              name="city"
              rules={[{ required: true }]}
            >
              <Input type="text" placeholder={locale('placeholders.city')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={locale('labels.district')}
              name="district"
              rules={[{ required: true }]}
            >
              <Input
                type="text"
                placeholder={locale('placeholders.district')}
              />
            </Form.Item>
          </Col>
          {/* TODO */}
          {/* <Col xs={24} sm={12}>*/}
          {/*  <Form.Item*/}
          {/*    label={locale('labels.paymentDate')}*/}
          {/*    tooltip={locale('subtitles.paymentDate')}*/}
          {/*    name="paymentDate"*/}
          {/*    rules={[{ required: true }]}*/}
          {/*  >*/}
          {/*    <DatePicker className="w-100" />*/}
          {/*  </Form.Item>*/}
          {/* </Col>*/}
        </Row>
      </Form>
      <Button
        disabled={!isFormValid}
        type="primary"
        block
        onClick={async () => {
          const data: Record<string, any> = {};
          fields.forEach(f => {
            data[(f.name as string[])[0]] = f.value;
          });
          onPay(type, { ...data });
        }}
      >
        {locale('actions.authorizePayment')}
      </Button>
    </>
  );

  const renderBankTransfer = () => (
    <>
      <Descriptions
        size="small"
        className="mb-1 text-left"
        title={locale('titles.bankAccountDetails')}
        column={1}
        bordered
      >
        <Descriptions.Item label={locale('labels.bankName')}>
          <Typography.Text copyable>
            {environment.bankAccountDetails.bankName}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.accountHolder')}>
          <Typography.Text copyable>
            {environment.bankAccountDetails.accountHolder}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.iban')}>
          <Typography.Text copyable>
            {environment.bankAccountDetails.iban}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.description')}>
          <Typography.Text copyable>
            {locale('labels.ibanDescription', [
              uuid || '--',
              items
                .map(item => locale(`paymentMethodType.${item.type}`))
                .join(' + '),
              environment.appName,
            ])}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={locale('labels.amount')}>
          {amount ? Utils.getFormattedPrice(amount) : '--'}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Text className="mt-3">
        {locale('actions.performBankTransferAndNotifySeller')}
      </Typography.Text>
    </>
  );

  return (
    <Space direction="vertical" className="w-100 text-center">
      {type === MethodType.BankSepaDebitOnline && renderRid()}
      {type === MethodType.StripeCard && renderCard()}
      {type === MethodType.StripeSepaDebit && renderSDD()}
      {type === MethodType.BankTransfer && renderBankTransfer()}
    </Space>
  );
}
