import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { OfferSection } from './components/offerSection/OfferSection';
import { Settlement } from '../../../../models/Settlement';
import locale from '../../../../locale';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { settlementCreatorActions } from '../../../settlementCreator/settlementCreatorSlice';
import { SettlementContract } from '../../../../models/SettlementContract';
import EditContractModal from './components/editContractModal/EditContractModal';
import PaymentConfigsModal from './components/paymentConfigsModal/PaymentConfigsModal';

interface OffersSectionProps {
  settlement: Settlement;
  canEdit: boolean;
}

/**
 *
 * @constructor
 */
export default function OffersSection({
  settlement,
  canEdit,
}: OffersSectionProps) {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [settlementContractsMap, setSettlementContractsMap] = useState<
    Record<string, SettlementContract>
  >({});
  const hasManyOffers = settlement?.settlementOffers?.length > 1;

  useEffect(() => {
    const nextSettlementContractsMap: Record<string, SettlementContract> = {};
    settlement.settlementContracts?.forEach(sc => {
      const settlementOffer = settlement.settlementOffers?.find(
        so => so.offer.contract.id === sc.contractId,
      );
      if (settlementOffer) {
        nextSettlementContractsMap[`${settlementOffer.id}`] = sc;
      }
    });
    setSettlementContractsMap(nextSettlementContractsMap);
  }, [settlement]);

  const onEditSettlementOffers = () => {
    dispatch(
      settlementCreatorActions.editSettlementOffer({
        settlementOffers: settlement.settlementOffers,
      }),
    );
  };

  return (
    <>
      <Card
        title={
          <div
            onClick={() => setCollapsed(!collapsed)}
            className="cursor-pointer"
          >
            <Button
              size="small"
              type="text"
              className="mr-1"
              icon={collapsed ? <DownOutlined /> : <UpOutlined />}
            />
            {locale('titles.offers')}
          </div>
        }
        size="small"
        extra={
          canEdit
            ? [
                <Button key="edit" type="link" onClick={onEditSettlementOffers}>
                  {locale('actions.edit')}
                </Button>,
              ]
            : []
        }
      >
        {!collapsed && (
          <Row gutter={[8, 16]}>
            {settlement.settlementOffers?.map(so => (
              <Col key={so.id} xs={24} md={hasManyOffers ? 12 : 24}>
                <OfferSection
                  settlementOffer={so}
                  settlementContract={settlementContractsMap[`${so.id}`]}
                  canEdit={canEdit}
                />
              </Col>
            ))}
          </Row>
        )}
      </Card>
      <EditContractModal />
      <PaymentConfigsModal
        canEditFirstInstallmentTimeFromApproval={
          canEdit && !settlement.installmentsMustPayNow
        }
      />
    </>
  );
}
